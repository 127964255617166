import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { useRef, useState } from 'react';

import { base64 } from '@/utils';

import * as Types from '../types';
import * as Utils from './utils';

export const useFleetMapItemsState = (mapInstance?: google.maps.Map | null) => {
  const [fleetMapItems, setFleetMapItems] = useState<Types.FleetMapItem[]>([]);
  const clusterer = useRef<MarkerClusterer>();

  const onInitialize: Types.UseFleetMapItemsStateReturn['onInitialize'] = ({
    fleetItems,
    onClick,
    fitBounds = true,
    clustering = true,
    coordinates,
  }) => {
    if (!fleetItems?.length) return;

    const itemBounds = new google.maps.LatLngBounds();

    const newFleetMapItems = fleetItems.map(FLEET => {
      // marker
      const fleetMarker = new google.maps.Marker({
        position: FLEET.coordinate,
        icon: `${base64},${Utils.getFleetMarkerSvgLiteral(
          FLEET.vehicle.fleetNumber,
          FLEET.isMyfleet
        )}`,
        title: 'fleet-pin-button',
        visible: true,
        map: mapInstance,
      });

      // add click listener
      fleetMarker.addListener('click', () => onClick?.(FLEET.vehicle.id));

      // expand bounds
      if (!coordinates) itemBounds.extend(FLEET.coordinate);

      return {
        marker: fleetMarker,
        details: FLEET,
      };
    });

    if (coordinates) {
      mapInstance?.setCenter(coordinates);
      mapInstance?.setZoom(11);
    }

    if (clustering) {
      clusterer.current = new MarkerClusterer({
        map: mapInstance,
        algorithm: new SuperClusterAlgorithm({}),
        markers: newFleetMapItems.map(X => X.marker),
        renderer: {
          render: ({ count, position }) => {
            return new google.maps.Marker({
              position,
              icon: {
                url: `${base64},${Utils.ClusterMarkerSvgBtoa}`,
              },
              label: {
                text: String(count),
                color: 'white',
                fontSize: '16px',
                fontWeight: 'bold',
              },
              visible: true,
            });
          },
        },
      });
    }

    setFleetMapItems(newFleetMapItems);
    if (fitBounds) mapInstance?.fitBounds(itemBounds);
  };

  const onClear: Types.UseFleetMapItemsStateReturn['onClear'] = vehicleId => {
    setFleetMapItems(state => {
      return state?.map(FLEET => {
        // center map to target marker
        if (vehicleId && vehicleId === FLEET.details.vehicle.id) {
          mapInstance?.setCenter(FLEET.details.coordinate);
          return FLEET;
        }

        // toggle off all other markers
        FLEET.marker.setClickable(false);
        FLEET.marker.setVisible(false);

        return FLEET;
      });
    });
    clusterer.current?.clearMarkers();
  };

  const onRestore: Types.UseFleetMapItemsStateReturn['onRestore'] = () => {
    setFleetMapItems(state => {
      return state?.map(FLEET => {
        // toggle on all markers
        FLEET.marker.setVisible(true);
        FLEET.marker.setClickable(true);

        return FLEET;
      });
    });
  };

  const onListView: Types.UseFleetMapItemsStateReturn['onListView'] = () => {
    return fleetMapItems
      .filter(FLEET => {
        return mapInstance?.getBounds()?.contains(FLEET.details.coordinate);
      })
      .map(FLEET => FLEET.details);
  };

  return {
    fleetMapItems,
    onListView,
    onInitialize,
    onClear,
    onRestore,
  };
};
