// NOTE: The values of these enums matter, they are mapped directly to what the BE expects
export enum BusinessCategory {
  CATERING = 'Catering',
  CONSTRUCTION = 'Construction',
  DSP = 'DSP',
  DELIVERY = 'DeliveryPackageHandling',
  ELECTRONICS = 'Electronics',
  EVENTS = 'Events',
  FLORIST = 'Florist',
  FREIGHT = 'Freight',
  FURNITURE = 'FurnitureDelivery',
  HOME = 'HomeStaging',
  INTERIOR_DESIGN = 'InteriorDesign',
  ISP = 'ISP',
  JUNK = 'JunkRemoval',
  LAUNDRY = 'LaundryServices',
  MOVING = 'MovingCompany',
  ROOFING = 'RoofingCompany',
  RIDE_SHARE = 'RideSharing',
  STORAGE = 'StorageUnit',
  OTHER = 'OtherBusinessType',
}

export const categories = Object.values(BusinessCategory);
