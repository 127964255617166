/* eslint-disable @typescript-eslint/ban-types */
import { ApiRoute } from './routes';

export type ApiRequest<
  TParams = undefined,
  TQuery = undefined,
  TBody = undefined
> = {
  headers?: HeadersInit;
  params?: TParams;
  query?: TQuery;
  body?: TBody;
} & (TParams extends object ? { params: TParams } : {}) &
  (TQuery extends object ? { query: TQuery } : {}) &
  (TBody extends object ? { body: TBody } : {});

export interface ApiResponse<SuccessResponse = any, FailResponse = any> {
  success: SuccessResponse;
  fail: FailResponse;
}

export type ApiResource = Record<ApiRoute, [ApiRequest, ApiResponse]>;

export enum ApiMethod {
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
