import { useTranslation } from 'next-i18next';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AlertSuccessVertical,
  Button,
  FormInputGroup,
  ModalGroup as Modal,
  SubmitButton,
  Text,
} from '@/base-components';
import {
  BusinessCategorySelect,
  categories,
} from '@/base-components/react-select-components/BusinessCategorySelect';
import { useUser } from '@/hooks';
import { FeedbackContext } from '@/lib/context';
import { patch, post } from '@/utils/helpers';

import { Names, UpgradeDataShape, UpgradeFormShape } from './types';

const UpgradeButton = ({ isMenu }: { isMenu: boolean }): JSX.Element => {
  const { t } = useTranslation(['common', 'org_menu']);
  const { user, refreshUser } = useUser();
  const { showFeedback } = useContext(FeedbackContext);

  const [open, setOpen] = useState(false);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      businessName: '',
      businessCategory: categories[0],
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = methods;

  const handleClose = (): void => {
    reset();
    setOpen(false);
    setUpgradeSuccess(false);
  };

  const orgs = user?.organizations || [];
  const isBusiness = orgs.length > 1;

  const submitPatch = async (body: UpgradeDataShape): Promise<void> => {
    try {
      const patchResponse = await patch('api/users', { organization: body });

      if (patchResponse?.status !== 'success')
        throw new Error(
          patchResponse?.messages[0] || t('org_menu:upgrade.error')
        );

      handleClose();
      await refreshUser();
    } catch (e) {
      showFeedback(e, 'error');
      console.error(e);
    }
  };

  const submitPost = async (body: UpgradeDataShape): Promise<void> => {
    try {
      const { status, messages } = await post(
        'api/organizations/upgrade',
        body
      );

      if (status !== 'success')
        throw new Error(messages?.[0] || t('org_menu:upgrade.error'));

      setUpgradeSuccess(true);
      await refreshUser();
    } catch (e) {
      showFeedback(e, 'error');
      console.error(e);
    }
  };

  const onSubmit = async ({
    businessCategory,
    businessName,
  }: UpgradeFormShape): Promise<void> => {
    const body: UpgradeDataShape = {
      name: businessName,
      category: businessCategory,
    };

    let firstUpgrade = true;
    if (isBusiness) {
      await submitPatch(body);
      firstUpgrade = false;
    } else {
      await submitPost(body);
    }

    try {
      window.dataLayer.push({
        event: 'upgrade',
        fluidId: user?.id,
        firstUpgrade,
        firstName: user?.first,
        lastName: user?.last,
        address: user?.address?.line1,
        addressTwo: user?.address?.line2,
        city: user?.address?.city,
        state: user?.address?.state,
        postalCode: user?.address?.['postal-code'],
        email: user?.email,
        phone: user?.['phone-number'],
        accountType: firstUpgrade ? 'personal' : 'business',
      });
    } catch (err) {
      console.warn('Error collecting google analytics', err);
    }
  };

  const classname = React.useMemo(() => {
    if (isMenu && isBusiness) {
      return 'account-top-bizcreate';
    }
    if (isMenu && !isBusiness) {
      return 'account-top-bizupgrade';
    }
    if (isBusiness) {
      return 'account-side-bizcreate';
    }
    return 'account-side-bizupgrade';
  }, [isMenu, isBusiness]);

  return (
    <>
      <Button
        data-test-id="upgrade-button"
        onClick={() => setOpen(true)}
        variant={isMenu ? 'link' : 'outline'}
        mr={isMenu ? 0 : '42px'}
        ml={0}
        my="3px"
        width="auto"
        fontSize={isMenu ? '14px' : '16px'}
        className={classname}
      >
        {isBusiness
          ? t('org_menu:addCompany')
          : t('org_menu:upgrade.modalTitle')}
      </Button>
      <Modal
        trapFocus
        isOpen={open}
        onClose={handleClose}
        closeOnOverlayClick={false}
        title={
          isBusiness && !upgradeSuccess
            ? t('org_menu:addBusinessTitle')
            : t('org_menu:upgrade.title')
        }
      >
        {upgradeSuccess && (
          <AlertSuccessVertical
            sx={{ mt: 0 }}
            title={t('org_menu:upgrade.successTitle')}
            description={t('org_menu:upgrade.successText')}
          />
        )}

        {!upgradeSuccess && (
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              data-test-id="upgrade-form"
              id="upgrade-form"
            >
              <Text>
                {isBusiness
                  ? t('org_menu:businessSubtext')
                  : t('org_menu:upgrade.subtext')}
              </Text>
              <FormInputGroup
                label={t('org_menu:businessName')}
                isInvalid={Boolean(errors?.businessName)}
                my={6}
                inputProps={{
                  ...register('businessName', {
                    required: true,
                    maxLength: 100,
                  }),
                }}
              />
              <BusinessCategorySelect name={Names.BUSINESS_CATEGORY} />
              <SubmitButton
                mt="60px"
                data-test-id="button-upgrade-submit"
                className={`${classname}-submit`}
                isLoading={isSubmitting}
              >
                {t('common:button.submit')}
              </SubmitButton>
            </form>
          </FormProvider>
        )}
      </Modal>
    </>
  );
};

export default UpgradeButton;
