import { Hide } from '@fluidtruck/core';
import { CircleCheckIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ApiRoute, usePOST } from '@/api-resources';
import {
  AlertButton,
  AlertGroup,
  Center,
  DialogHeader,
  FlexRow,
  FormInputGroup,
  GrayText,
  Grid,
  Password,
  SubmitButton,
  Text,
} from '@/base-components';
import { emailPhonePattern, passwordMinLength } from '@/utils/validations';

import SecurityCode from './SecurityCode';
import SuccessPage from './SuccessPage';

type FormState = {
  password: string;
  confirmPassword: string;
  emailPhone: string;
  securityCode?: string;
};

const defaultValues = {
  password: '',
  confirmPassword: '',
  emailPhone: '',
  securityCode: '',
};

export const ForgotPassword = ({
  setForgotPassword,
}: {
  setForgotPassword: (value: boolean) => void;
}): ReactElement => {
  const { t } = useTranslation();

  const [resetPasswordFail, setResetPasswordFail] = useState('');
  const [step, setStep] = useState(1);

  const methods = useForm<FormState>({
    mode: 'onBlur',
    defaultValues,
  });

  const {
    register,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
  } = methods;

  const { confirmPassword, emailPhone, password } = watch();

  const hasValues = !!confirmPassword && !!password;
  const passwordsMatch = hasValues && password === confirmPassword;
  const confirmPasswordValid =
    hasValues && !errors?.password && !errors?.confirmPassword;

  const postForgotPassword = usePOST({
    path: ApiRoute.API_USERS_FORGOT_PASSWORD,
  });

  const submit: SubmitHandler<FormState> = async (
    data: FormState
  ): Promise<void> => {
    if (!data) return;

    await postForgotPassword.handler({
      body: {
        username: emailPhone,
        password,
      },
      onSuccess: () => setStep(2),
      onError: () => setResetPasswordFail(t('login.noMatch')),
    });
  };

  const handleSetStep = (value: number): void => setStep(value);
  const onClose = () => setForgotPassword(false);

  return (
    <FormProvider {...methods}>
      {!!resetPasswordFail && <AlertGroup title={resetPasswordFail} />}
      {step === 1 && (
        <form onSubmit={handleSubmit(submit)}>
          <Center data-test-id="forgot-container" h="100%" flexDir="column">
            <DialogHeader
              hideMobileClose
              header={t('login.changePass')}
              onClose={onClose}
            />
            <GrayText mt={6}>{t('login.changePassSubheader')}</GrayText>
            <FormInputGroup
              mt={11}
              isInvalid={Boolean(errors?.emailPhone)}
              label={t('phoneOrEmail')}
              inputProps={{
                'data-test-id': 'input-emailPhone',
                ...register('emailPhone', {
                  ...emailPhonePattern(t('phoneOrEmailIncorrect')),
                }),
              }}
            />
            <Password
              id="forgot-password-group"
              isInvalid={Boolean(errors?.password)}
              errorMessage={errors?.password?.message}
              label={t('login.newPass')}
              inputProps={{
                'data-test-id': 'input-password',
                ...register(
                  'password',
                  passwordMinLength(t('login.newPassDetails'))
                ),
              }}
            />
            <Password
              id="forgot-password-group-confirm"
              isInvalid={Boolean(errors?.confirmPassword)}
              errorMessage={
                errors?.confirmPassword?.message || t('login.passNoMatch')
              }
              label={t('login.passConfirm')}
              inputProps={{
                'data-test-id': 'input-password-confirm',
                ...register('confirmPassword', {
                  ...passwordMinLength(t('login.newPassDetails')),
                  validate: value => value === password,
                }),
              }}
            />
            <Grid
              autoRows="1fr"
              mt={8}
              width="100%"
              className="verify-container"
            >
              <FlexRow
                alignItems="center"
                data-test-id={`password-${
                  confirmPasswordValid ? 'ok' : 'not-ok'
                }`}
              >
                <CircleCheckIcon
                  color={confirmPasswordValid ? 'green.500' : 'gray.400'}
                />
                <Text ml={2}>{t('login.passReq')}</Text>
              </FlexRow>
              <FlexRow
                alignItems="center"
                mb={4}
                className="password-icon-wrapper"
                data-test-id={`password-${
                  passwordsMatch ? 'matches' : 'no-match'
                }`}
              >
                <CircleCheckIcon
                  color={passwordsMatch ? 'green.500' : 'gray.400'}
                />
                <Text ml={2}>{t('login.passMatch')}</Text>
              </FlexRow>
            </Grid>
            <SubmitButton
              isLoading={isSubmitting || isSubmitSuccessful}
              mt="1.875rem"
              data-test-id="forgot-next"
            >
              {t('button.next')}
            </SubmitButton>
          </Center>
        </form>
      )}
      {step === 2 && (
        <SecurityCode
          step={step}
          handleSetStep={handleSetStep}
          onClose={onClose}
        />
      )}
      {step === 3 && <SuccessPage onClose={onClose} />}
      <Hide above="lg">
        <AlertButton mt={3} width="100%" onClick={onClose}>
          {t('common:button.cancel')}
        </AlertButton>
      </Hide>
    </FormProvider>
  );
};

export default ForgotPassword;
