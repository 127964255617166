export const heights = {
  topNav: 80,
  topNavMobile: 60,
  searchBar: 88,
  cartBar: 88,
  mobileSearchBox: 74,
  searchFilter: 66,
};

export const widths = {
  leftNavOpen: 337,
  leftNavClosed: 100,
  searchItem: 544,
  searchItemDefault: 544,
  searchDrawer: 544,
  liveViewRightPanel: 483,
  topRightMenu: 367,
  loginInner: 320,
};

export const padding = {
  topNav: {
    left: 48,
    mobileLeft: 20,
  },
};

// NOTE: https://developers.google.com/web/updates/2016/12/url-bar-resizing
// TODO: update height/width from vh/vw to 100% on mobile
// offset top nav to account for mobile browsers
export const topNavOffset = {
  base: `calc(100vh - ${heights.topNavMobile}px)`,
  lg: `calc(100vh - ${heights.topNav}px)`,
};
