import Cookies from 'js-cookie';
import fetch from 'node-fetch';

const geoOptions = {
  enableHighAccuracy: false,
  maximumAge: 30000,
  timeout: 27000,
};

export const checkBrowserLocation = () => {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject, options = geoOptions) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

  return new Promise(resolve => resolve({}));
};

export const setServerLocation = () => {
  const url = '/api/location';

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return fetch(url, requestOptions)
    .then(response => {
      if (response.status !== 200) {
        // error handling here
      }

      if (response) {
        return response.json();
      }
      return response;
    })
    .then(response => {
      if (response && response.lat) {
        const userLocation = {
          lat: response?.lat,
          lng: response?.lng,
          address: response?.address,
        };

        Cookies.set('serverLocation', userLocation);
        return userLocation;
      }
      return undefined;
    })
    .catch(error => error);
};

export const getUserLocation = () => {
  const location =
    Cookies.getJSON('userLocation') || Cookies.getJSON('serverLocation');

  return {
    lat: location ? Number(location.lat) : 39.7643389,
    lng: location ? Number(location.lng) : -104.8551114,
  };
};

export const currentLocation = async () => {
  const storedLocation = Cookies.get('userLocation');
  let serverLocation = Cookies.get('serverLocation');
  let serverLocationParsed;
  try {
    serverLocationParsed = JSON.parse(serverLocation);
  } catch (e) {
    serverLocation = await setServerLocation();
  }

  if (
    !serverLocation ||
    !serverLocationParsed?.lat ||
    !serverLocationParsed?.lng
  ) {
    serverLocation = await setServerLocation();
  }

  let locationData;
  if (storedLocation) {
    try {
      locationData =
        storedLocation && storedLocation !== 'undefined'
          ? JSON.parse(storedLocation)
          : undefined;
    } catch (e) {
      console.warn('Location cookie could not be read');
    }
  } else if (serverLocation) {
    if (typeof serverLocation === 'object') {
      return serverLocation;
    }
    try {
      locationData =
        serverLocation && serverLocation !== 'undefined'
          ? JSON.parse(serverLocation)
          : undefined;
    } catch (e) {
      console.warn('Location cookie could not be read');
    }
  }

  return locationData;
};
