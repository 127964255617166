import useSWR from 'swr';

import { get } from '@/utils/helpers';

import * as Types from './types';

export const useFleetTelematicsRecordsSwr: Types.UseFleetTelematicsRecordsSwr =
  ({ params }) => {
    const { data, error, mutate, isValidating } =
      useSWR<Types.TelematicsRecord>(
        params.itemId && params.orgId
          ? `api/myfleet/${params.orgId}/items/${params.itemId}/telematics/records`
          : null,
        get
      );

    const isLoading =
      (!data && isValidating) || (error !== undefined && isValidating);

    return {
      telematics: data,
      isLoading,
      isError: error,
      mutate,
    };
  };
