import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

import { Props } from './types';

const PopOverGroup = ({
  header,
  closeButtonProps,
  popover,
  body,
  triggerEl,
}: Props): ReactElement => {
  return (
    <Popover
      {...popover.options}
      isOpen={popover.options?.isOpen}
      onClose={popover.options?.onClose}
    >
      <PopoverTrigger>{triggerEl}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton {...closeButtonProps} />
        {header && (
          <PopoverHeader {...header?.options}>{header.content}</PopoverHeader>
        )}
        <PopoverBody {...body.options}>{body.content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { PopOverGroup };
