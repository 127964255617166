import { Popover } from '@material-ui/core';

import { useUser } from '@/hooks';
import * as Vars from '@/theme/variables';
import { chakra } from '@/utils';

import { LoginSignup } from '../../LoginSignup';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';
import ProfileHeader from '../../ProfileDropdown/ProfileHeader';
import ProfileOrgs from '../../ProfileDropdown/ProfileOrgs';
import * as Types from './types';

export const PopoverContent = chakra('div', {
  baseStyle: {
    bg: 'white',
    borderRadius: '0px 0px 10px 10px',
    overflow: 'hidden',
    shadow: 'base',
    p: 8,
    width: { base: '100%', lg: `${Vars.widths.topRightMenu}px` },
  },
  label: 'DesktopRightMenu-PopoverContent',
});

export const LoginDesktopContainer = chakra('div', {
  baseStyle: {
    p: 8,
  },
  label: 'DesktopRightMenu-LoginDesktopContainer',
});

export const DesktopRightMenu = ({
  isOpen,
  anchorEl,
  onClose,
  handleLoginFinish,
  handleSignupFinish,
}: Types.MenuProps) => {
  const { user } = useUser();

  const content = user?.id ? (
    <ProfileDropdown>
      <ProfileHeader />
      <ProfileOrgs handleClose={onClose} />
    </ProfileDropdown>
  ) : (
    <>
      <LoginSignup
        loginProps={{ onFinish: handleLoginFinish }}
        signupProps={{ onFinish: handleSignupFinish }}
      />
    </>
  );

  return (
    <Popover
      disableEnforceFocus={isOpen}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      data-test-id="org-menu-popover"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <PopoverContent>{content}</PopoverContent>
    </Popover>
  );
};
