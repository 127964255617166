import { ButtonGroupProps } from '@fluidtruck/core';
import React from 'react';

import { ButtonGroup } from './Buttons';

const inactiveStyles = {
  color: 'gray.700',
  borderColor: 'gray.100',
  bg: 'gray.100',
  _hover: { bg: 'gray.300' },
  _active: { bg: 'gray.200' },
};

/**
 * @example (
 * <SelectButtonGroup onChange={handleChangeValue} value={value}>
 *  <Button value={value1}>label1</Button>
 *  <Button value={value2}>label2</Button>
 * </SelectButtonGroup>
 * )
 * @note
 * must have multiple child elements
 */
export const SelectButtonGroup: React.FC<{
  onChange: (value: string) => void;
  value: string;
  groupProps?: ButtonGroupProps;
}> = ({ onChange, value, children, groupProps }) => {
  if (!children) throw new Error('Children required');
  // iterate over array of child nodes to apply extended props
  return (
    <ButtonGroup {...groupProps}>
      {React.Children.map(children as React.ReactElement[], CHILD => {
        return React.cloneElement(CHILD, {
          onClick: () => {
            if (value === CHILD?.props?.value) return;
            onChange(CHILD?.props?.value);
          },
          ...(value !== CHILD?.props?.value && { sx: inactiveStyles }),
        });
      })}
    </ButtonGroup>
  );
};
