import styled from '@emotion/styled';

import { generateMQ } from '@/utils/helpers';

const mq = generateMQ('min');

export const Tabs = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',

  [mq[0]]: {
    cursor: 'default',
  },
});

export const TabsLabel = styled.div(props => ({
  marginRight: 6,
  fontSize: 16,
  fontWeight: 'bold',
  color: props.theme.colors.black,
}));

export const TabsSelected = styled.div(props => ({
  position: 'relative',
  fontWeight: 'bold',
  fontSize: 16,
  color: props.theme.colors.blue[500],
}));

export const TabsArrow = styled.div({
  position: 'relative',
  top: 3,
  marginLeft: 5,
});

// export const TabsMenu = styled(StyledBlueTooltip)({
//   margin: '-1px 5px 0 0',
// });

// export const TabsMenuItems = styled.div({
//   padding: '10px 20px',
// });

export const TabContainer = styled.div(props => {
  const defaults = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  if (props.mobile) {
    return {
      ...defaults,
      minWidth: 60,
      justifyContent: 'center',
      padding: '3px 7px',
      fontSize: 12,
      fontWeight: 'bold',
      marginRight: 10,
      border: '1px solid',
      backgroundColor: props.selected
        ? props.theme.colors.blue[500]
        : props.theme.colors.white,
      borderColor: props.theme.colors.blue[500],
      borderRadius: 15,
      color: props.selected
        ? props.theme.colors.white
        : props.theme.colors.blue[500],
      '&:last-child': {
        marginRight: 0,
      },
    };
  }

  return {
    ...defaults,
    position: 'relative',
    justifyContent: 'space-between',
    marginRight: 38,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '20px',
    color: props.selected
      ? props.theme.colors.gray[900]
      : props.theme.colors.gray[700],
    '&:last-child': {
      marginRight: 0,
    },
    '&:after': props.selected && {
      position: 'absolute',
      bottom: -10,
      left: 0,
      right: 0,
      content: '""',
      height: 4,
      backgroundColor: props.theme.colors.blue[500],
    },
  };
});

export const TabCountContainer = styled.div(props => ({
  marginLeft: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  borderRadius: '100%',
  backgroundColor: props.error
    ? props.theme.colors.error
    : props.theme.colors.blue[500],
  fontSize: props.count > 10 ? 12 : 14,
  lineHeight: '16px',
  color: props.theme.colors.white,
}));

export const TabPanelContainer = styled.div(props => ({
  display: props.selected ? 'block' : 'none',
  [mq[0]]: {
    paddingTop: 20,
  },
}));
