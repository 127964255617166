import type { IconProps } from '@fluidtruck/core';
import { forwardRef } from '@fluidtruck/core';
import {
  BookIcon,
  BusinessIcon,
  CalendarIcon,
  ClaimsIcon,
  CreditCardIcon,
  DocumentsIcon,
  DriverMgmtIcon,
  ExclamationTriangleIcon,
  MapIcon,
  MessageIcon,
  ReceiptIcon,
  TeamIcon,
  UserIcon,
} from '@fluidtruck/icons';

import { AmplitudeFeatureFlag } from '@/lib/constants';
import { ROLE_TYPES } from '@/types';

import { MenuItemsShape } from './types';

export const TriangleIcon = forwardRef<IconProps, 'svg'>((_, ref) => (
  <ExclamationTriangleIcon ref={ref} h="6" w="6" color="red.500" />
));

export const UNIVERSAL_MENU_ITEMS: MenuItemsShape[] = [
  {
    icon: UserIcon,
    path: '/account',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'account-menu',
    text: 'org_menu:menu.account',
  },
  {
    icon: MessageIcon,
    path: '/messages',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'messages-menu',
    text: 'org_menu:menu.messages',
  },
];

export const PERSONAL_MENU_ITEMS: MenuItemsShape[] = [
  {
    icon: BookIcon,
    path: '/search',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'personal-book-menu-item',
    text: 'org_menu:menu.book',
  },
  {
    icon: CalendarIcon,
    path: '/reservations/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'personal-reservations',
    text: 'org_menu:menu.reservations',
  },
  {
    icon: CreditCardIcon,
    path: '/billing_methods/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'personal-payments',
    text: 'org_menu:menu.payment',
  },
  {
    icon: ClaimsIcon,
    path: '', // path is applied dynamically in LeftNavMenuContent.tsx
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'claims-menu',
    text: 'org_menu:menu.company.claims',
  },
  {
    icon: DocumentsIcon,
    path: '/insurance/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'insurance-menu',
    text: 'org_menu:menu.insurance',
    featureFlag: AmplitudeFeatureFlag.INSURANCE,
  },
];

export const ORG_MENU_ITEMS: MenuItemsShape[] = [
  {
    icon: BusinessIcon,
    path: '/account/organization/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'nav-company-details-menu',
    text: 'org_menu:menu.company.details',
  },
  {
    icon: TeamIcon,
    path: '/performance/team/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-team-performance',
    text: 'org_menu:menu.team',
  },
  {
    icon: CreditCardIcon, // Replace with new CreditCardIcon
    path: '/billing_methods/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-payments-menu',
    text: 'org_menu:menu.payment',
  },
  {
    icon: ReceiptIcon,
    path: '/account/organization/transactions/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-transactions-menu',
    text: 'org_menu:menu.company.receipts',
  },
  {
    icon: ClaimsIcon,
    path: '', // path is applied dynamically in LeftNavMenuContent.tsx
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-claims-menu',
    text: 'org_menu:menu.company.claims',
  },
];

export const FAILED_TRANSACTIONS_ITEM: MenuItemsShape = {
  icon: TriangleIcon,
  path: '/account/organization/transactions/failed/[orgId]',
  roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
  testId: 'nav-failed-menu',
  text: 'org_menu:menu.company.failed',
  featureFlag: AmplitudeFeatureFlag.FAILED_TRANSACTIONS,
};

export const FLEET_MENU_ITEMS: MenuItemsShape[] = [
  {
    icon: MapIcon,
    path: '/myfleet/[orgId]',
    private: true,
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-myfleet-menu',
    text: 'org_menu:menu.liveView',
  },
  {
    icon: CalendarIcon,
    path: '/reservations/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-reservations',
    text: 'org_menu:menu.fleet.reservations',
  },
  {
    icon: DriverMgmtIcon,
    path: '/driver_management/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN],
    testId: 'nav-driver-management',
    text: 'org_menu:menu.driverMgmt',
  },
  {
    icon: BookIcon,
    path: '/search',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'nav-fleet-book-menu-item',
    text: 'org_menu:menu.book',
  },
];

export const DRIVER_MENU_ITEMS: MenuItemsShape[] = [
  {
    icon: BusinessIcon,
    path: '/account/organization/[orgId]',
    roles: [ROLE_TYPES.OWNER, ROLE_TYPES.ADMIN, ROLE_TYPES.DRIVER],
    testId: 'nav-company-details-menu',
    text: 'org_menu:menu.company.details',
  },
];
