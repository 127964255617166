import React, { createContext, useState } from 'react';

export const LoadingContext = createContext({
  loading: false,
  updateLoading: (status: true | false) => {
    console.warn(
      'updateLoading was called without a LoadingContextProvider:',
      status
    );
  },
});

export const LoadingContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);

  const updateLoading = (status: true | false) => {
    setLoading(status);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading,
        updateLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
