import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import DriverScoreContext from '@/lib/context/DriverScoreContext';
import * as Types from '@/types';
import { get } from '@/utils/helpers';

export const useDriverScoreContext = (): Types.IDriverScore => {
  const context = useContext(DriverScoreContext);

  if (context === undefined) {
    throw new Error(
      'useDriverScoreContext must be used within a DriverScoreContext Provider'
    );
  }

  return context;
};

export const useMarkets = (
  marketID: string | string[] | undefined
): Types.MarketsHookReturn => {
  const marketsUrl = 'api/cup/markets';
  const [market, setMarket] = useState<Types.MarketObject>({
    city: '',
    'market-id': '0',
    region: '',
    state: '',
  });
  const [newMarketId, setNewMarketId] = useState(marketID || '');
  const { data: allMarkets, error } = useSWR(marketsUrl, get);

  const handleSetMarket = useCallback(
    newMarket => {
      if (newMarket) {
        setMarket(newMarket);
      } else {
        setMarket({
          city: '',
          'market-id': '0',
          region: 'National',
          state: '',
        });
      }
    },
    [setMarket]
  );

  useEffect(() => {
    const newMarket = allMarkets?.data?.find(
      (m: Types.MarketObject) => m['market-id'] === newMarketId
    );
    handleSetMarket(newMarket);
  }, [allMarkets, handleSetMarket, newMarketId]);

  return {
    allMarkets,
    error,
    market,
    newMarketId,
    setNewMarketId,
  };
};

export const useWeeks = (
  weekID: string | string[] | undefined
): Types.WeeksHookReturn => {
  const weeksUrl = 'api/cup/weeks';
  const [week, setWeek] = useState<Types.WeekObject>({
    'end-date': '',
    'paid-out': false,
    'start-date': '',
    'updated-at': new Date().toString(),
    'week-id': '0',
  });
  const [newWeekId, setNewWeekId] = useState(weekID || '0');
  const { data: allWeeks, error } = useSWR(weeksUrl, get);

  const handleSetWeek = useCallback(
    newWeek => {
      if (newWeek) {
        setWeek(newWeek);
      } else {
        setWeek({
          'end-date': '',
          'paid-out': false,
          'start-date': '',
          'updated-at': new Date().toString(),
          'week-id': '0',
        });
      }
    },
    [setWeek]
  );

  useEffect(() => {
    const newWeek = allWeeks?.data?.find(
      (w: Types.WeekObject) => w['week-id'] === newWeekId
    );
    handleSetWeek(newWeek);
  }, [allWeeks, handleSetWeek, newWeekId]);

  return {
    allWeeks,
    error,
    week,
    newWeekId,
    setNewWeekId,
  };
};

export const useAverageScore = (orgId: string): Types.ScoreHookReturn => {
  const start = moment().startOf('month').utc().format();
  const end = moment().endOf('month').utc().format();
  const scoreUrl = `api/scoring/org/${orgId}?start=${start}&end=${end}`;

  const { data, error } = useSWR(scoreUrl, get);

  return {
    error,
    scores: data?.data,
    status: data?.status,
  };
};
