import 'fontsource-nunito-sans';
import '../node_modules/@fluidtruck/core/dist/css/date-time-range-picker.css';
import './global.css';

import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client';
import { theme } from '@fluidtruck/core';
import Cookie from 'cookie';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';

import { ampli } from '@/amplitude';
import { SpinnerOverlay } from '@/base-components/spinners-loaders';
import Error from '@/components/Error';
import Feedback from '@/components/Feedback';
import DefaultPage from '@/components/page-layouts/DefaultPage';
import { PageLoader } from '@/lib/context/LoadingContext';
import { AppProviders, MainContext } from '@/lib/providers';

import { AmplitudeExperimentProvider } from '../lib/context/AmplitudeExperimentContext';

let experiment;
let ExperimentServer;
const devEnv = process.env.NODE_ENV === 'development';

if (typeof window === 'undefined') {
  // Initializing Server Experiment
  ExperimentServer =
    require('@amplitude/experiment-node-server').Experiment.initialize(
      process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOYMENT_KEY,
      { debug: devEnv }
    );
}

const tagManagerArgs = {
  gtmId: 'GTM-W8KKP5L',
};

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [mounted, setMounted] = useState(false);
  const [navigating, setNavigating] = useState(false);

  const handleStart = () => setNavigating(true);
  const handleComplete = () => setNavigating(false);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);

    setMounted(true);

    ampli.load({
      client: {
        apiKey: process.env.AMPLITUDE_API_KEY,
        configuration: {
          // logLevel: 'Debug',
          minIdLength: 1,
          defaultTracking: {
            sessions: true,
            pageViews: {
              trackHistoryChanges: 'all',
            },
          },
        },
      },
    });

    // initializes amplitude experiments below
    const isServerSide = typeof window === 'undefined';

    if (isServerSide) {
      // Initializing Client Experiment
      experiment = new ExperimentClient(
        process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOYMENT_KEY,
        {
          initialVariants: pageProps['amplitudeExperimentFlags'],
        }
      );
    } else if (!experiment) {
      experiment = Experiment.initializeWithAmplitudeAnalytics(
        process.env.NEXT_PUBLIC_AMPLITUDE_DEPLOYMENT_KEY,
        {
          initialVariants: pageProps['amplitudeExperimentFlags'],
        }
      );
    }

    async function initAndFetch() {
      // NB static import of branchsdk will fail
      const branch = (await import('branch-sdk')).default;
      branch.init(process.env.NEXT_PUBLIC_BRANCH_KEY);
    }
    initAndFetch();
  }, []);

  /* Page Transitions */
  useEffect(() => {
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete); // Also turn off loading on error

    // Cleanup event listeners on component unmount
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [setNavigating, router]);

  const Layout = Component.Layout || DefaultPage;
  const SxProps = Component.Sx;

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.png" type="image/ico" />
        {/* PWA primary color */}
        <meta name="theme-color" content={theme.colors.blue[500]} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Head>
      {mounted && (
        <AmplitudeExperimentProvider experiment={experiment}>
          <AppProviders>
            <MainContext {...pageProps}>
              <Layout sx={SxProps} {...pageProps}>
                <ErrorBoundary
                  fallbackRender={({ error, resetErrorBoundary }) => (
                    <Error
                      message={error}
                      isErrorBoundary
                      resetErrorBoundary={resetErrorBoundary}
                    />
                  )}
                >
                  {navigating && <SpinnerOverlay />}
                  <PageLoader />
                  <Component {...pageProps} />
                  <Feedback />
                </ErrorBoundary>
              </Layout>
            </MainContext>
          </AppProviders>
        </AmplitudeExperimentProvider>
      )}
    </>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ctx });
  }

  const cookies = Cookie.parse(ctx?.req?.headers?.cookie || '');
  if (Object.keys(cookies).length) {
    let token = cookies?.token;
    let refreshToken = cookies?.refresh;

    if (!token && refreshToken) {
      try {
        const res = await fetch(`${process.env.API_URL}api/refresh`, {
          method: 'POST',
          headers: {
            Refresh: refreshToken,
          },
        });

        if (!res.ok) {
          throw new Error('Failed to refresh token');
        }

        token = res.headers.get('Set-Authorization');
        refreshToken = res.headers.get('Set-Refresh');
        const tokenExpiresAt = res.headers.get('Set-AuthorizationExpireAt');
        const refreshTokenExpiresAt = res.headers.get('Set-RefreshExpireAt');

        ctx.res.setHeader('Set-Cookie', [
          `token=${token}; Path=/; Max-Age=${tokenExpiresAt}; SameSite=Lax;`,
          `refresh=${refreshToken}; Path=/; Max-Age=${refreshTokenExpiresAt}; SameSite=Lax;`,
        ]);
      } catch (error) {
        console.error('Error Refreshing Token', error);

        ctx.res.setHeader('Set-Cookie', [
          `token=; Path=/; Max-Age=0; SameSite=Lax;`,
          `refresh=; Path=/; Max-Age=0; SameSite=Lax;`,
        ]);
      }
    }

    let user = null;
    if (token) {
      const res = await fetch(`${process.env.API_URL}api/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const { data } = await res.json();
        user = data;
      }
    }

    pageProps.user = user;
    pageProps.cart = cookies?.cart;
    pageProps.defaultBilling = cookies?.defaultBilling;
    pageProps.reservationEstimateItem = cookies?.reservationEstimateItem;
    pageProps.reservationEstimate = cookies?.reservationEstimate;
  }

  // Fetch data from external APIs
  // see: https://github.com/amplitude/experiment-node-server/blob/main/packages/ssr-demo/contexts/experimentContext.tsx
  if (ctx.req) {
    // Fetching Experiment variants (server-side)
    const allFlags = await ExperimentServer.fetch({
      id: 'userId',
    });
    pageProps.amplitudeExperimentFlags = allFlags;
  } else {
    // client-side re-render
    return {};
  }

  return { pageProps };
};

export default appWithTranslation(MyApp);
