import React, { createContext, ReactElement, ReactNode, useState } from 'react';

import { WeeklyCalculatorShape } from './types';

export const WeeklyCalculatorContext = createContext<
  WeeklyCalculatorShape | undefined
>(undefined);

const WeeklyCalculatorProvider = ({
  children,
  showDivider = true,
}: {
  children: ReactNode;
  showDivider?: boolean;
}): ReactElement => {
  const [numberOfWeeks, setNumberOfWeeks] = useState(0);

  const onOpen = (): void => {
    setNumberOfWeeks(0);
  };

  const onClose = (): void => {
    setNumberOfWeeks(0);
  };

  return (
    <WeeklyCalculatorContext.Provider
      value={{
        onOpen,
        onClose,
        showDivider,
        numberOfWeeks,
        setNumberOfWeeks,
      }}
    >
      {children}
    </WeeklyCalculatorContext.Provider>
  );
};

export { WeeklyCalculatorProvider };
