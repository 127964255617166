import { useEffect, useState } from 'react';

/**
 * Breakpoints for responsive design
 * current breakpoints from '@fluidtruck/core'
 */
const breakpoints = {
  sm: 420, // '30em'
  md: 768, // '48em'
  lg: 991, // ''
  xl: 1280, // '80em'
  '2xl': 2496, // '96em',
};

export const BREAKPOINT_MOBILE = breakpoints.lg;
export const BREAKPOINT_MOBILE_SMALL = breakpoints.md;

export const useResize = () => {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
    isIpad: undefined,
    isMobile: undefined,
    isDesktop: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isIpad: window.innerWidth <= BREAKPOINT_MOBILE_SMALL,
        isMobile: window.innerWidth <= BREAKPOINT_MOBILE,
        isDesktop: window.innerWidth > BREAKPOINT_MOBILE,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};
