import { useState } from 'react';
import useSWR from 'swr';

import { fullUrl, get } from '@/utils/helpers';

import * as Types from './types';

export const useFleetVehicles: Types.UseFleetVehiclesDataSwr = ({
  orgId,
  shouldFetch,
  status,
  config,
}) => {
  const [data, setData] = useState<Types.UseFleetSwrModel | undefined>();
  const query: Record<string, any> = {};

  if (status !== undefined) {
    query.status = status;
  }

  const urlKey = fullUrl(`api/myfleet/v3/${orgId}/vehicles`, query);

  const { error, mutate, isValidating } = useSWR<Types.UseFleetSwrModel>(
    shouldFetch || shouldFetch === undefined ? urlKey : null,
    url =>
      get(url).then((res: Types.UseFleetSwrModel) => {
        setData(res);
        return res;
      }),
    config
  );

  return {
    vehicles: data?.vehicles,
    center: data?.center,
    isLoading: (!data && isValidating) || (!error && isValidating),
    isError: error,
    mutate,
  };
};
