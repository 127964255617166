import type {
  DatePickerProps,
  FlexProps,
  TimePickerProps,
} from '@fluidtruck/core';
import { DatePicker, TimePicker } from '@fluidtruck/core';
import moment from 'moment-timezone';
import React from 'react';

import { FlexRow } from '@/base-components/layout';

/** DateTimePicker - WIP: need to test different scenarios and values
 * @description Tidal DatePicker and TimePicker Component
 * `initValue` string will be converted to a moment object
 * @examples
        <Controller
          name="MyDatePicker"
          control={control}
          render={({ field, fieldState }) => (
            <DateTimePicker
              onDateChange={field.onChange}
              initValue={field.value}
            />
          )}
        />

        <DateTimePicker
          onDateChange={handleChange}
          initValue="2020-08-14T00:00:46.310051Z"
        />
};
 * */

const combineDayTime = (day: moment.Moment, time: number) => {
  const date = day.format('DD MMMM YYYY');
  const hour = moment(time, 'H HH').format('LT');
  return moment(`${date} ${hour}`).toISOString();
};

interface Props {
  container?: FlexProps;
  initValue?: string;
  onDateChange: (arg: string) => void;
  dateProps?: DatePickerProps;
  timeProps?: TimePickerProps;
}

export const DateTimePicker = ({
  container,
  onDateChange,
  initValue,
  dateProps,
  timeProps,
}: Props) => {
  const [focus, setFocus] = React.useState<boolean>(false);
  const [time, setTime] = React.useState(
    initValue ? moment(initValue).hour() : ''
  );
  const [day, setDay] = React.useState(moment(initValue) || undefined);
  const timeRef = React.useRef(null);

  const handleDayChange = (d: any) => {
    setDay(d);
    if (time) {
      const newDate = combineDayTime(d, Number(time));
      onDateChange(newDate);
    }
  };

  const handleTimeChange = (t: any) => {
    setTime(t);
    if (day) {
      const newDate = combineDayTime(day, t);
      onDateChange(newDate);
    }
  };

  const containerProps = {
    ...container,
    gap: 2,
    alignItems: 'center',
  };

  return (
    <FlexRow {...containerProps}>
      <DatePicker
        id="date-time-picker--date"
        {...dateProps}
        placeholder="MM/DD/YYYY"
        date={day as unknown as DatePickerProps['date']}
        focused={focus}
        onFocusChange={({ focused }: { focused: boolean }) => setFocus(focused)}
        onDateChange={handleDayChange}
      />
      <TimePicker
        id="date-time-picker--time"
        {...timeProps}
        ref={timeRef}
        isDisabled={!day}
        value={time}
        onChange={handleTimeChange}
      />
    </FlexRow>
  );
};
