import { Button, PropsOf } from '@fluidtruck/core';
import { CustomDomComponent, motion } from 'framer-motion';

import { chakra } from '@/utils';

/**
 * @description `chakra.button` as a motion component
 * the chakra.button does not contain the theme styling
 */

export const MotionButton: CustomDomComponent<PropsOf<typeof chakra.button>> =
  'custom' in motion
    ? (motion as any).custom(chakra.button)
    : (motion as any)(chakra.button);

/**
 * @description `Button` as a motion component
 * the Button component contains theme styling and variants
 */

export const MotionButtonPrimary: CustomDomComponent<PropsOf<typeof Button>> =
  'custom' in motion ? (motion as any).custom(Button) : (motion as any)(Button);
