export enum Day {
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
  SUN = 'sunday',
}

export interface TimeRange {
  start: string;
  end: string;
}

// ================================================================================
// Fleet
// ================================================================================
export interface FleetItem {
  isMyfleet: boolean;
  coordinate: google.maps.LatLngLiteral;
  eventDate: { seconds?: number };
  organizationId: number;
  location: Location;
  vehicle: Vehicle;
}

// V2 Vehicle Shape
export interface Vehicle {
  id: number;
  title: string;
  description: string;
  fleetNumber: string;
  subCategory: string;
  status: string;
  isElectricVehicle: boolean;
  fluidFleetService: boolean;
  instabook: boolean;
  active: boolean;
  flaggedForModeration: boolean;
  pickupInstructions: string;
  dropoffInstructions: string;
  featuredImageIdentifier: string;
  locationId: number;
  marketId: number;
  averageRating: number;
  firstPublishedAt: string | null;
  cud: {
    createdUpdated?: CreatedUpdated | null;
    deletedAt?: InsertedAt | null;
  };
  ismi: number;
  pictures: Picture[];
  details: string | null;
  measurements: string | null;
  vin: string;
  licensePlateNumber: string;
  licensePlateState: string;
  year: number;
  make: string;
  model: string;
  userId: number;
}

export interface Picture {
  id: string;
  uuid: string;
  url: string;
  full: string;
  max: string;
  original: string;
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  xxLarge: string;
  banner: string;
  name: string;
  index: number;
  largeSq: string;
  standardSq: string;
  smallSq: string;
  xSmallSq: string;
  largeBanner: string;
  standard: string;
  standard2X: string;
  standard3X: string;
  standardBanner: string;
  standardBanner2X: string;
  standardBanner3X: string;
  smallSt: string;
  smallSt2X: string;
  smallSt3X: string;
  thumbUrl: string;
  thumb: string;
  thumb2X: string;
  thumb3X: string;
}

export interface CreatedUpdated {
  created?: InsertedAt | null;
  updated?: InsertedAt | null;
}

export interface Location {
  id: number;
  coordinate: google.maps.LatLngLiteral;
  address: string;
  unassistedPickup: boolean;
  instructions: string;
  userId: number;
  timezone: string;
  geometry: string;
  neighborhoodId: number;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  insertedAt: InsertedAt;
  updatedAt: InsertedAt;
  uuid: string;
}

export type InsertedAt = {
  seconds?: number;
  nanos?: number;
};

// ================================================================================
// Geofence
// ================================================================================
export enum GeofenceType {
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
}

export enum GeofenceBoundaryType {
  IN = 'inside',
  OUT = 'outside',
}

export interface Geofence {
  uuid: string;
  name: string;
  boundaryType: GeofenceBoundaryType;
  type: GeofenceType;
  geometry: GeofenceGeometry;
  timezone: string;
}

export interface GeofenceNotification {
  realTime?: boolean;
  start?: string;
  end?: string;
  notificationMedium?: string;
  userIds?: number[];
}

export interface GeofenceMonitoring {
  doNotMonitor: boolean;
  schedule?: GeofenceSchedule[];
}

export interface GeofenceSchedule {
  day: Day;
  times: TimeRange[];
}

export interface GeofenceGeometry {
  type: GeofenceType;
  coordinates: GeoJSON.Position[][];
}
