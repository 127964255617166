import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Dot = styled.div(props => ({
  height: 18,
  width: 18,
  cursor: 'pointer',
  border: 0,
  background: 'none',
  padding: 0,
  div: {
    backgroundColor: !props.active
      ? props.theme.colors.gray[400]
      : props.theme.colors.blue[500],
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3,
  },
}));

const PaginationDot = ({ active }) => (
  <Dot role="presentation" active={active}>
    <div />
  </Dot>
);

PaginationDot.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default PaginationDot;
