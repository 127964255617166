import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';

import { FeedbackContext } from '@/lib/context';

function Feedback() {
  const { open, message, hideFeedback, severity } = useContext(FeedbackContext);

  return (
    <Snackbar
      style={{ zIndex: 1800 }}
      open={open}
      autoHideDuration={6000}
      onClose={hideFeedback}
    >
      <Alert
        severity={severity}
        variant="standard"
        data-test-id={`feedback-${severity}`}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Feedback;
