import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  forwardRef,
  HelpTextProps,
  Select,
} from '@fluidtruck/core';
import React from 'react';

export interface Props extends FormControlProps {
  name?: string;
  selectProps: React.ComponentProps<typeof Select>;
  labelProps?: FormLabelProps;
  errorProps?: FormErrorMessageProps;
  helpTextProps?: HelpTextProps;
  errorMessage?: string;
  helperText?: string;
  isLoading?: boolean;
}

export const SelectGroup = forwardRef<Props, 'div'>((props, ref) => {
  const {
    errorMessage,
    errorProps,
    helperText,
    helpTextProps,
    id,
    selectProps,
    isInvalid,
    label,
    labelProps,
    mt = 0,
    name,
    placeholder,
    ...rest
  } = props;
  const { size = 'sm', ...selectRest } = selectProps;
  const inputId = id ? `${id}-input` : `${name}-input`;
  return (
    <FormControl
      ref={ref}
      mt={mt}
      id={id}
      isInvalid={isInvalid}
      name={name}
      {...rest}
    >
      <FormLabel aria-label={name} htmlFor={inputId} mb={0} {...labelProps}>
        {label}
      </FormLabel>
      <Select
        id={inputId}
        isInvalid={isInvalid}
        size={size}
        placeholder={placeholder}
        {...selectRest}
      />
      {helperText && (
        <FormHelperText {...helpTextProps}>{helperText}</FormHelperText>
      )}
      {isInvalid && errorMessage && (
        <FormErrorMessage {...errorProps}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
});
