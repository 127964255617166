import useSWR from 'swr';

import { get } from '@/utils/helpers';

import * as Mappings from './mappings';
import * as Types from './types';

export const useFleetItemInspections: Types.UseFleetItemInspectionsSwr = ({
  params,
  config,
}) => {
  const fleetInspectionsURL = `api/v1/${params.orgId}/inspections/${params.vehicleId}?startDate=${params.startDate}&endDate=${params.endDate}`;

  const fleetInspectionsSwr = useSWR<Types.FleetDetailsTableRowData[]>(
    fleetInspectionsURL,
    url =>
      get(url).then((res: Types.FleetInspection[]) => {
        return res?.map(Mappings.mapFleetItemInspections);
      }),
    config
  );

  return { ...fleetInspectionsSwr };
};
