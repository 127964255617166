import type { SystemStyleObject } from '@fluidtruck/core';

export {
  chakra,
  get,
  filterUndefined,
  forwardRef,
  omit,
  pick,
  split,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from '@fluidtruck/core';

export const hoverTruncated: SystemStyleObject = {
  _hover: { overflow: 'visible', height: 'auto', whiteSpace: 'normal' },
};
