import type {
  ButtonProps,
  ResponsiveTableProps,
  SystemStyleObject,
} from '@fluidtruck/core';
import { ReactNode } from 'react';

export type RowsPerPageOptions = 5 | 10 | 20 | 50 | 100;

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export type Column = {
  id?: string | number;
  field?: string;
  default?: string;
  formatter?: (arg: any) => ReactNode;
  align?: any; // 'left' | 'right' | 'center' | 'end' | 'start' | 'justify' // for numeric: 'end', default: 'start'
};

export type HeadRow = {
  id: string;
  numeric?: boolean;
  label: string;
  sortable?: boolean;
  align?: any; // 'left' | 'right' | 'center' | 'end' | 'start' | 'justify' // for numeric: 'end', default: 'start'
};

export type TidalVaporTableProps<T = any> = {
  rowsPerPageOptions?: RowsPerPageOptions[];
  rowsPerPage?: number;
  headRows: HeadRow[];
  data?: T[];
  sortField?: string;
  sortOrder?: SORT_ORDER;
  totalCount: number;
  onChange?: (callback: any) => void;
  columns: Column[];
  hideSearch?: boolean;
  pagination?: {
    top: boolean;
    bottom: boolean;
  };
  loading?: boolean;
  tableProps?: ResponsiveTableProps;
  title?: string;
  styles?: {
    container?: SystemStyleObject;
    row?: (s: any) => SystemStyleObject;
  };
};

export type HeaderLabelProps = {
  sortField?: string;
  isASC: boolean;
  label: string;
  sortable: boolean;
} & ButtonProps;

export interface TableContextShape {
  orderBy: string;
  orderDirection: SORT_ORDER;
  rowsPerPage: RowsPerPageOptions;
  searchText: string;
  page: number;
  setOrderBy: (orderBy: string) => void;
  setOrderDirection: (direction: SORT_ORDER) => void;
  setPage: (page: number) => void;
  setRowsPerPage: (perPage: RowsPerPageOptions) => void;
  setSearchText: (text: string) => void;
}

// TODO: This interface subject to change
export interface FilesShape {
  id: string | number;
  fields: {
    name: string;
    content: JSX.Element;
  }[];
}
