import { omit, OptionGroup as StateOption } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { useResize } from '@/hooks';

import { ReactSelect } from '../ReactSelect';
import * as Consts from './consts';
import type { RHFFormProps } from './types';

export const StateSelect = <T extends FieldValues>({
  control,
  isAbbreviated = true,
  name,
  noLabel,
  rules,
  shouldUnregister,
  ...rest
}: RHFFormProps<T>): ReactElement => {
  const { t } = useTranslation();
  const { isDesktop } = useResize();

  const options: StateOption[] = isAbbreviated
    ? Consts.stateOptionsAbbr
    : Consts.stateOptions;

  const { field, fieldState } = useController<T>({
    name,
    rules,
    control,
    shouldUnregister,
  });

  return (
    <ReactSelect
      {...rest}
      {...omit(field, ['ref'])}
      isInvalid={!!fieldState.error}
      isSearchable={isDesktop}
      errorMessage={fieldState.error?.message}
      data-test-id="select-state"
      label={t('form.state')}
      noLabel={noLabel}
      options={options}
    />
  );
};
