import currency from 'currency-formatter';
import { tz } from 'moment-timezone';

import type { Coordinates } from '@/types';

export const distance = (coordsA?: Coordinates, coordsB?: Coordinates) => {
  if (!coordsA?.lat || !coordsB?.lat || !coordsA?.lng || !coordsB?.lng) {
    return 0.0;
  }
  // radius in KM
  const earthRadius = 6371;

  const lat = coordsA?.lat - coordsB?.lat;
  const lng = coordsA?.lng - coordsB?.lng;
  const latDistance = (lat * Math.PI * earthRadius) / 180; // Vertical distance
  const lngDistance = (lng * Math.PI * earthRadius) / 180; // Horizontal distance

  // Converted to miles
  return Math.sqrt(latDistance ** 2 + lngDistance ** 2) / 1.60934;
};

export const formatMoney = (amount: number) =>
  currency.format(amount || 0, { code: 'USD' });

export const centsToDollars = (amount: number) => (amount || 0) / 100.0;

export const withCurrentTimezone = (dt: string) => {
  if (dt) {
    return tz(dt, Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
  return null;
};
