import { useRouter } from 'next/router';
import { ReactElement, useState } from 'react';

import { Box, ButtonRow } from '@/base-components';
import { TopRightMenu } from '@/components/Nav/TopRightMenu';
import { useResize, useUser } from '@/hooks';

import { SearchPopover } from '../SearchPopover';
import { AppBar } from './components';
import { LoginMenuToggle, LoginProps } from './components/LoginMenuToggle';

interface NavBarProps {
  toggleMobileMenu?: () => void;
  isCollapsed?: boolean;
}

export const NavBar = ({
  toggleMobileMenu,
  isCollapsed,
}: NavBarProps): ReactElement => {
  const router = useRouter();
  const { user } = useUser();
  const { isMobile } = useResize();

  const [userAnchorEl, setUserAnchor] = useState<EventTarget | null>(null);

  let isUserOpen = Boolean(userAnchorEl);

  const authenticated = !!user?.email;
  const stepOneComplete = !!(user?.first && user?.last);
  const stepTwoComplete = !!user?.['phone-number-verified'];

  if (Object.keys(user || {}).length > 0) {
    isUserOpen =
      Boolean(userAnchorEl) &&
      authenticated &&
      stepOneComplete &&
      stepTwoComplete;
  }

  const handleUserClick: LoginProps['onClick'] = ({ currentTarget }): void => {
    if (authenticated && !stepOneComplete) {
      router.push('/account/create');
    } else if (authenticated && !stepTwoComplete) {
      router.push('/account/create/phone');
    } else {
      setUserAnchor(currentTarget || null);
    }
  };

  const handleUserClose = (): void => {
    setUserAnchor(null);
  };

  const isOutagePage = router.pathname === '/outage';

  const popoverProps = {
    disableActiveIndicator: isUserOpen,
    'data-test-id': 'nav-open-search-menu',
    isLoggedIn: !!authenticated,
  };

  return (
    <>
      <AppBar
        user={user}
        isMobile={isMobile}
        isOpen={!isCollapsed}
        onToggleClick={toggleMobileMenu}
      >
        <Box>
          <ButtonRow spacing={{ base: 2, sm: 4, lg: 2 }}>
            {!isMobile && !isOutagePage && <SearchPopover {...popoverProps} />}
            {router.pathname !== '/account/signup' && !isOutagePage && (
              <LoginMenuToggle
                user={user}
                data-test-id="nav-open-nav-menu"
                onClick={handleUserClick}
              />
            )}
          </ButtonRow>
        </Box>
      </AppBar>
      <TopRightMenu
        isUserOpen={isUserOpen}
        userAnchorEl={userAnchorEl}
        handleUserClose={handleUserClose}
      />
    </>
  );
};

export default NavBar;
