/* eslint-disable @typescript-eslint/no-empty-function */
import Cookie from 'js-cookie';
import React, { createContext, useState } from 'react';

import { CartContextShape } from './context-types';

export const CartContext = createContext<CartContextShape>({
  cart: undefined,
  updateCart: () => {},
  removeCart: () => {},
});
type Props = {
  children: React.ReactNode;
  cart?: string;
};

export const CartContextProvider: React.FC<Props> = ({
  children,
  ...pageProps
}: Props) => {
  const cartCookie = pageProps?.cart || Cookie.get('cart');
  const userCookie = Cookie.get('user');

  let cartData;

  try {
    cartData =
      !!userCookie && cartCookie && cartCookie !== undefined
        ? JSON.parse(cartCookie)
        : undefined;
  } catch (e) {
    console.warn('Cart cookie could not be read');
  }

  const [cart, setCartContext] = useState(cartData);

  const updateCart = (cartObj: any) => {
    const formattedCart = {
      'cart-id': cartObj?.['cart-id'],
      'pick-up': cartObj?.['pick-up'],
      'drop-off': cartObj?.['drop-off'],
      'vehicle-type': cartObj?.['vehicle-type'],
      location: cartObj?.location,
      items: cartObj?.items?.map((co: any) => ({ id: co?.id })),
      'organization-id': cartObj?.['organization-id'],
    };
    setCartContext(formattedCart);
    Cookie.set('cart', formattedCart);
  };

  const removeCart = () => {
    setCartContext(undefined);
    Cookie.remove('cart');
  };

  React.useEffect(() => {
    if (!userCookie) {
      removeCart();
    }
  }, [userCookie]);

  return (
    <CartContext.Provider
      value={{
        cart,
        updateCart,
        removeCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
