import type { UserBillingShape } from './billing-types';
import type { OrganizationShape, OrgDetails } from './org-types';

export enum ROLE_TYPES {
  NONE = '',
  OWNER = 'owner',
  ADMIN = 'admin',
  DRIVER = 'driver',
}

export enum CurrentFlow {
  DELIVERY = 'delivery',
  BOOKING = 'booking',
  HOME = 'home',
}

export interface AddressShape {
  id: number;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  'postal-code': string;
}

export enum IdStatusShape {
  INFO_REQUIRED = 'info required',
  VERIFIED = 'verified',
  EXPIRED = 'expired',
  PENDING = 'pending',
}

interface IdentificationShape {
  id: number;
  'access-level-requested'?: string;
  'checkr-report'?: any | null;
  'image-url-back'?: string;
  'image-url-front'?: string;
  'motor-vehicle-report'?: any | null;
  'need-more-information'?: boolean;
  notes?: string;
  number?: string;
  'postal-code'?: string;
  'rejected-region'?: string;
  'scan-data'?: string;
  'first-name'?: string;
  'last-name': string;
  'middle-name'?: string;
  'ownership-validation-image-url'?: string;
  'street-address'?: string;
  'expiration-date': string;
  'valid-for-identity-end'?: any | null;
  'valid-for-identity-start'?: any | null;
  'valid-for-vehicles-end'?: any | null;
  'valid-for-vehicles-start'?: any | null;
  status: IdStatusShape;
}

interface UnreadMessagesShape {
  id: number;
  body: string;
  'sender-id': number;
  'recipient-id': number;
  'inserted-at': string;
  'updated-at': string;
  read: boolean;
  'admin-id': number;
}

export interface UserOrgInfo {
  id: number;
  first?: string;
  last?: string;
  email?: string;
  'email-verified': boolean;
  'phone-number'?: string;
  'phone-number-verified': boolean;
  'app-login': boolean;
  'avatar-url'?: string;
  'checkr-candidate-id'?: string;
  'lender-reports-enabled'?: boolean;
  'phone-number-details'?: null | string;
  'trust-index'?: number;
  'vehicle-borrow-limit'?: number;
  'waive-deposit'?: boolean;
}

export interface UserProfileInfo {
  id: number;
  'organization-id': number;
  'user-id': number;
  role: ROLE_TYPES;
  'inserted-at': string;
  'updated-at': string;
}

export interface UserOrganizations {
  organization: OrgDetails;
  profile: UserProfileInfo;
}

export interface UserType extends UserOrgInfo {
  'accepted-terms-and-conditions-at': string;
  address: AddressShape | null;
  'address-id'?: number;
  'average-rating': number;
  'billing-methods': UserBillingShape;
  'blocked-users': [];
  'borrower-type': string;
  'completed-reservations': number;
  'credit-balance': number;
  'default-organization-id': number;
  'default-profile': OrganizationShape;
  'has-vehicles': boolean;
  identification: IdentificationShape | null;
  'inserted-at': string;
  organizations: UserOrganizations[];
  'personal-has-reservations-with-no-insurance': boolean;
  'referral-code': string;
  role: ROLE_TYPES;
  'unread-messages': UnreadMessagesShape[];
  'updated-at': string;
  uuid: string;
}

export interface UserWithToken extends UserType {
  token: string;
}

export interface UserPage {
  user: UserWithToken;
}

export interface UserContextShape {
  user: UserWithToken | null;
  login: (
    currentFlow?: CurrentFlow,
    disabledAmplitude?: boolean
  ) => Promise<UserWithToken>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<UserWithToken | void>;
  updateUser: (data: UserWithToken) => void;
}
