import moment from 'moment';

import * as Assets from '@/components/MapEventFooter/assets';
import { SnapApiResponse } from '@/types/maps-types';
import { base64 } from '@/utils';
import { get } from '@/utils/helpers';

import { RoutePoint } from '../types';
import * as Types from './types';

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '';

export const mapFleetEvent2GoogleMarker = (
  fleetEvent: Types.FleetEventPoint
): google.maps.Marker => {
  const position = { lat: fleetEvent.lat, lng: fleetEvent.lng };
  const title = moment(fleetEvent.eventDate).format('MM/DD/YYYYYY h:mm a');

  return new google.maps.Marker({
    position,
    title,
    icon: fleetEvent.icon,
  });
};

export function chunkArray<T>(array: Array<T>, chunkSize = 100): Array<T>[] {
  if (array.length <= chunkSize) {
    return [array];
  }
  return [
    array.slice(0, chunkSize),
    ...chunkArray(array.slice(chunkSize), chunkSize),
  ];
}

export const mapFleetEventCode2GoogleIcon = (
  code: number
): google.maps.Icon => {
  switch (code) {
    case 1:
      return {
        url: `${base64},${Assets.AccelerationSvgBtoa}`,
        size: new google.maps.Size(28, 24),
        anchor: new google.maps.Point(14, 12),
      };
    case 2:
      return {
        url: `${base64},${Assets.BrakingSvgBtoa}`,
        size: new google.maps.Size(28, 24),
        anchor: new google.maps.Point(14, 12),
      };
    default:
      return {
        url: `${base64},${Assets.CorneringSvgBtoa}`,
        size: new google.maps.Size(24, 24),
        anchor: new google.maps.Point(12, 12),
      };
  }
};

export const mapSnappedCoords2GooglePolyline = (
  snappedCoords: google.maps.LatLng[]
): google.maps.Polyline => {
  return new google.maps.Polyline({
    path: snappedCoords,
    strokeColor: '#00B2E3',
    strokeWeight: 2,
    strokeOpacity: 1,
  });
};

export const mapPaths2LatLng = async (
  paths: string[]
): Promise<google.maps.LatLng[]> => {
  const snapResponse: SnapApiResponse = await get(
    'https://roads.googleapis.com/v1/snapToRoads',
    undefined,
    {
      interpolate: true,
      key: googleMapsApiKey,
      path: paths.join('|'),
    }
  );

  return snapResponse?.snappedPoints?.map(
    ({ location }) =>
      new google.maps.LatLng(location.latitude, location.longitude)
  );
};

export const mapFleetRoute2StartEndMarkers = (
  route: RoutePoint[],
  map: google.maps.Map | null
): [google.maps.Marker, google.maps.Marker] => {
  const startPointMarker = new google.maps.Marker({
    position: route[0],
    map,
    zIndex: 1001,
    icon: {
      url: `${base64},${Assets.StartRouteSvgBtoa}`,
      size: new google.maps.Size(8, 8),
      anchor: new google.maps.Point(4, 4),
    },
  });
  const finishPointMarker = new google.maps.Marker({
    position: route?.[route?.length - 1],
    map,
    zIndex: 1001,
    icon: {
      url: `${base64},${Assets.FinishRouteSvgBtoa}`,
      size: new google.maps.Size(16, 16),
      anchor: new google.maps.Point(8, 8),
    },
  });

  return [startPointMarker, finishPointMarker];
};
