import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

import { CartContext, FeedbackContext } from '@/lib/context';
import { deleteRequest, get, patch, post } from '@/utils/helpers';

import { useKrakenSwr } from '../use-kraken-swr';
import { useUser } from '../use-user';
import {
  AddItemsToCartProps,
  CreateCartProps,
  UpdateCartOrgProps,
} from './types';

export const useCart = () => {
  const { t } = useTranslation('search');
  const { user } = useUser();
  const { showFeedback } = useContext(FeedbackContext);
  const { cart, removeCart, updateCart } = useContext(CartContext);

  const { data, error, mutate } = useKrakenSwr({
    key: `api/carts/${cart?.['cart-id']}`,
    isReadyToFetch: !!cart?.['cart-id'] && !!user?.id,
    fetcher: get,
  });

  const addItemsToCart = async ({
    itemIds,
    pickUp,
    dropOff,
    cartId = cart?.['cart-id'],
  }: AddItemsToCartProps) => {
    const body = {
      'item-ids': itemIds,
      'pick-up': moment(pickUp).toISOString(),
      'drop-off': moment(dropOff).toISOString(),
    };
    const updatingCartId = cartId || data?.data?.['cart-id'];
    const response = await patch(`api/v2/carts/${updatingCartId}`, body);

    if (response?.status === 'success') {
      updateCart(response?.data);
      mutate();
      showFeedback(t('cart.added'), 'success');
    }

    if (response?.status === 'fail') {
      const serverMessage =
        response?.messages?.[response?.messages?.length - 1];

      let message = serverMessage;
      if (serverMessage === 'item must be instabook to add to cart') {
        message = t('cart.error.insta');
      } else if (
        serverMessage ===
        'adding this item will surpass your vehicle borrow limit'
      ) {
        message = t('cart.error.limit');
      } else if (serverMessage === 'invalid vehicle type') {
        message = t('cart.error.same');
      }

      showFeedback(message, 'error');
    }
    return response;
  };

  const updateCartOrg = async ({ orgId, scheduled }: UpdateCartOrgProps) => {
    const url = `api/carts/${cart?.['cart-id']}`;

    const body = {
      'organization-id': orgId,
      scheduled,
    };

    const response = await patch(url, body);
    const { status, messages } = response;

    if (status === 'fail') {
      showFeedback(t('cart.error.orgSwitch'), 'error');
      console.error('Error:', messages);
    } else {
      if (cart?.['organization-id'] !== response.data['organization-id']) {
        showFeedback(t('cart.orgSwitch'), 'success');
      }

      mutate();
      updateCart(response.data);
    }
  };

  const removeItemFromCart = async (itemId: number) => {
    const response = await deleteRequest(
      `api/carts/${cart?.['cart-id']}/remove_item/${itemId}`,
      {}
    );
    if (response?.status === 'success') {
      mutate();
      updateCart(response?.data);
      return showFeedback(t('cart.removed'), 'success');
    }
    if (response?.status === 'fail') {
      return showFeedback(response?.messages?.[0], 'error');
    }
    removeCart();
    return showFeedback(t('cart.removed'), 'success');
  };

  const createCart = async (props: CreateCartProps) => {
    const {
      billingId,
      dropOff,
      itemId,
      location,
      organizationId,
      pickUp,
      promoCode,
      vehicleType,
      scheduled = false,
    } = props;

    const ret = await post('api/carts', {
      'organization-id': organizationId,
      'billing-method-id': billingId,
      'user-id': user?.id,
      'item-id': itemId,
      'pick-up': moment(pickUp).toISOString(),
      'drop-off': moment(dropOff).toISOString(),
      'vehicle-type': vehicleType,
      'promo-code': promoCode,
      scheduled,
      location: {
        lat: location?.lat,
        lng: location?.lng,
      },
    });

    if (ret?.status === 'success') {
      mutate();
      updateCart(ret?.data);
      showFeedback(t('cart.added'), 'success');
    }
    return ret;
  };

  return {
    cart: data?.data,
    isLoading: !data?.data && !error,
    isError: error || data?.status !== 'success',
    addItemsToCart,
    createCart,
    removeItemFromCart,
    updateCartOrg,
    mutateCart: mutate,
  };
};
