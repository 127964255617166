import MaskedInput, { Props } from 'react-input-mask';

import { forwardRef } from '@/utils';

import {
  FormControl,
  FormControlLabel as FormLabel,
  FormErrorMessage,
  FormHelperText,
} from './elements';
import { FormInputProps } from './FormInputGroup';
import { Input as TidalInput, InputGroup, InputLeftAddon } from './Inputs';

export type InputProps = Omit<Props, 'mask'> &
  Omit<React.ComponentProps<typeof TidalInput>, 'ref'>;
export type PhoneInputProps = Omit<FormInputProps, 'inputProps'> & {
  inputProps: InputProps;
};

export const PhoneInput = forwardRef<PhoneInputProps, 'div'>((props, ref) => {
  const {
    errorMessage,
    errorProps,
    helperText,
    helpTextProps,
    id: idProp,
    inputProps,
    isInvalid,
    label,
    labelProps,
    ...rest
  } = props;
  const { name = 'phone', isDisabled, ...inputRest } = inputProps;
  const id = idProp || name;
  const inputId = `${id}-input`;

  return (
    <FormControl ref={ref} id={id} isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel htmlFor={inputId} {...labelProps}>
        {label}
      </FormLabel>
      <InputGroup>
        <InputLeftAddon border="none">+1 </InputLeftAddon>
        <MaskedInput
          type="tel"
          mask="(999) 999-9999"
          alwaysShowMask
          style={{ paddingLeft: 4 }}
          disabled={isDisabled}
          {...inputRest}
        >
          <TidalInput type="tel" />
        </MaskedInput>
      </InputGroup>
      {helperText && (
        <FormHelperText {...helpTextProps}>{helperText}</FormHelperText>
      )}
      {isInvalid && errorMessage && (
        <FormErrorMessage {...errorProps}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
});
