import { Icon } from '@fluidtruck/core';
import { LayersIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import { MotionDiv, Text, Tooltip } from '@/base-components';
import MessagesUnread from '@/components/Messages/MessagesUnread';
import { SystemStyleObject } from '@/types';
import { chakra, useBoolean } from '@/utils';

import { useLeftNavMenuContext } from '../context/useLeftNavMenu';
import { MenuItemsShape, Path } from '../types';
import * as Styles from './styles';

interface LeftNavMenuItemProps {
  item: MenuItemsShape;
}

export const LeftNavItem = (
  props: LeftNavMenuItemProps
): React.ReactElement | null => {
  const [isActive, setState] = useBoolean();

  const { item } = props;
  const { path: pathProps, text, icon, testId } = item;

  const { t } = useTranslation('org_menu');
  const router = useRouter();
  const { pathname } = router;

  const {
    handleMenuChange,
    isCollapsed,
    myFleetId,
    isMobile,
    orgId: orgIdProps,
  } = useLeftNavMenuContext();

  const desktopCollapse = isCollapsed && !isMobile;
  const isMessages = pathProps === '/messages';
  const isExternal: boolean = pathProps.includes('zoho');

  const itemStyles: SystemStyleObject = {
    ...Styles.itemStyles,
    color: isActive ? 'fluidBlue' : 'darkGray',
    fontWeight: isActive ? 'bold' : 'semibold',
    pl: isCollapsed ? 0 : 8,
    pr: isCollapsed ? 0 : 6,
    justifyContent: desktopCollapse ? 'center' : 'start',
  };

  const iconStyles: SystemStyleObject = {
    ...Styles.iconStyles,
    color: isActive ? 'fluidBlue' : 'darkGray',
  };

  const rightIconStyles: SystemStyleObject = {
    ...Styles.rightIconStyles,
    color: isActive ? 'fluidBlue' : 'darkGray',
  };

  React.useEffect(() => {
    if (pathProps !== pathname) return setState.off();
    return setState.on();
  }, [pathProps, pathname, setState]);

  if ((item?.private && !myFleetId) || item?.roles.length === 0) {
    return null;
  }

  const handleMenuClick = (): void => {
    const isExactMatch = pathProps === pathname;

    if (isExactMatch) return;

    let path: Path = pathProps;

    if (pathProps.includes('[orgId]')) {
      path = pathProps.replace('[orgId]', `${orgIdProps}`);
    }
    handleMenuChange(path, isExternal);
  };

  return (
    <chakra.div data-test-id={testId} onClick={handleMenuClick} sx={itemStyles}>
      {isCollapsed ? (
        <Tooltip
          label={t(text as i18Translation)}
          aria-label={t(text as i18Translation)}
          placement="right"
        >
          <Icon as={icon} sx={iconStyles} />
        </Tooltip>
      ) : (
        <Icon as={icon} sx={iconStyles} />
      )}
      <MotionDiv
        animate={isCollapsed && !isMobile ? 'closed' : 'open'}
        gridTemplateColumns={isMessages ? 'repeat(2, 1fr)' : '1fr'}
        sx={Styles.titleColumn}
        variants={!isMobile ? Styles.ltrVariants : {}}
      >
        <Text ml={7}>
          {t(text as i18Translation)}
          {isExternal && <Icon as={LayersIcon} sx={rightIconStyles} />}
        </Text>

        {isMessages && <MessagesUnread />}
      </MotionDiv>
    </chakra.div>
  );
};
