export const AccelerationSvgString = `<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 2L25.2583 21.5L2.74167 21.5L14 2Z" fill="white" stroke="#9B51E0" stroke-width="4"/>
</svg>`;

export const BrakingSvgString = `<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 22L2.74167 2.5L25.2583 2.5L14 22Z" fill="white" stroke="#EB5757" stroke-width="4"/>
</svg>`;

export const CorneringSvgString = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="5" fill="#F2994A"/>
<rect x="4.80078" y="4.7998" width="14.4" height="14.4" rx="2" fill="white"/>
</svg>`;

export const StartRouteSvgString = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4" r="4" fill="#00B2E3"/>
</svg>`;

export const FinishRouteSvgString = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="6.5" fill="white" stroke="#00B2E3" stroke-width="3"/>
</svg>`;

export const StartRouteSvgBtoa =
  Buffer.from(StartRouteSvgString).toString('base64');
export const FinishRouteSvgBtoa =
  Buffer.from(FinishRouteSvgString).toString('base64');
export const CorneringSvgBtoa =
  Buffer.from(CorneringSvgString).toString('base64');
export const BrakingSvgBtoa = Buffer.from(BrakingSvgString).toString('base64');
export const AccelerationSvgBtoa = Buffer.from(AccelerationSvgString).toString(
  'base64'
);
