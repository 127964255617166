import styled from '@emotion/styled';

export const CommonTypography = styled.div(props => ({
  display: props.inline && 'inline',
  textTransform: props.uppercase && 'uppercase',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  color: props?.theme?.colors?.black,
}));

export const CommonHeader = styled(({ ...styles }) => (
  <CommonTypography {...styles} />
))({
  fontWeight: 'bold',
});

export const CommonHeaderLink = styled(({ ...styles }) => (
  <CommonHeader {...styles} />
))(props => ({
  color: props?.theme?.colors?.blue[500],
}));

export const CommonSubHeader = styled(({ ...styles }) => (
  <CommonTypography {...styles} />
))({
  fontWeight: 'normal',
});

export const Header = styled(({ ...styles }) => <CommonHeader {...styles} />)(
  props => ({
    fontSize: props?.size || 32,
    lineHeight: `${props?.size || 32}px`,
  })
);

export const SubHeader = styled(({ ...styles }) => (
  <CommonSubHeader {...styles} />
))(props => ({
  fontSize: props?.size || 22,
}));

export const HeaderLink = styled(({ ...styles }) => (
  <CommonHeaderLink {...styles} />
))(props => ({
  fontSize: props?.size || 40,
}));

export const Body = styled(({ ...styles }) => <CommonSubHeader {...styles} />)(
  props => ({
    lineHeight: `${props?.size || 16}px`,
    fontSize: props?.size || 16,
    color:
      props?.color === 'gray'
        ? props?.theme?.colors?.gray[900]
        : props?.theme?.colors?.black,
  })
);

export const Paragraph = styled(({ ...styles }) => <div {...styles} />)(
  props => ({
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    lineHeight: `${props?.size || 24}px`,
    fontSize: props?.size || 16,
    marginBottom: props?.size || 16,
    color:
      props?.color === 'gray'
        ? props?.theme?.colors?.gray[900]
        : props?.theme?.colors?.black,
    span: {
      fontWeight: 700,
    },
  })
);

export const Span = styled(({ ...styles }) => <span {...styles} />)(props => ({
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  lineHeight: `${props?.size || 24}px`,
  fontSize: props?.size || 16,
  marginBottom: props?.size || 16,
  color:
    props?.color === 'gray'
      ? props?.theme?.colors?.gray[900]
      : props?.theme?.colors?.black,
}));
