import useSWR from 'swr';

import { get } from '@/utils/helpers';

export const useOrgScoring = ({ orgId, start, end }) => {
  const { data, error } = useSWR(
    `api/scoring/org/${orgId}?start=${start}&end=${end}`,
    get
  );

  return {
    orgScores: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUserScoring = ({ orgId, driverId, start, end }) => {
  const { data, error } = useSWR(
    `api/scoring/user/${driverId}?orgID=${orgId}&start=${start}&end=${end}`,
    get
  );

  return {
    userScores: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useScoringRoutes = ({ orgId, driverId, start, end }) => {
  const { data, error } = useSWR(
    `api/scoring/route_information?start-time=${start}&end-time=${end}&userID=${driverId}&orgID=${orgId}`,
    url =>
      get(url, {
        'Content-Type': 'version=0.2',
      })
  );

  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};
