import React from 'react';

import { BoldText, GridItem, GridProps } from '@/base-components';
import { SystemStyleObject } from '@/types';
import { chakra } from '@/utils';

import { useLeftNavMenuContext } from '../context/useLeftNavMenu';
import { MenuItemsShape } from '../types';
import { LeftNavItem } from './LeftNavItem';
import * as Styles from './styles';

interface LeftNavMenuSectionProps extends GridProps {
  children?: React.ReactNode;
  menuItems: Array<MenuItemsShape>;
  sectionTitle?: string;
  testId?: string;
}

export const LeftNavMenuSection = (
  props: LeftNavMenuSectionProps
): React.ReactElement => {
  const { sectionTitle, children, menuItems, testId } = props;
  const { isCollapsed, isMobile } = useLeftNavMenuContext();

  const desktopCollapse = isCollapsed && !isMobile;

  const styles: SystemStyleObject = {
    ...Styles.menuSection,
    textAlign: desktopCollapse ? 'center' : 'start',
  };
  const title: SystemStyleObject = {
    ...Styles.menuSectionTitle,
    justifyContent: desktopCollapse ? 'center' : 'space-between',
    px: desktopCollapse ? 0 : 6,
  };

  return (
    <chakra.div data-test-id={testId} sx={styles}>
      {sectionTitle && (
        <GridItem colSpan={2} sx={title}>
          <BoldText>{sectionTitle}</BoldText>
          {!isCollapsed && children}
        </GridItem>
      )}
      {menuItems &&
        menuItems.map((item: MenuItemsShape) => (
          <LeftNavItem item={item} key={item?.text} />
        ))}
    </chakra.div>
  );
};
