import { EyeIcon, EyeSlashIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';

import {
  FormControl,
  FormControlLabel as FormLabel,
  FormErrorMessage,
  FormHelperText,
} from './elements';
import { FormInputProps } from './FormInputGroup';
import {
  Input,
  InputElementProps,
  InputGroup,
  InputRightElement,
} from './Inputs';

export const Password = (props: FormInputProps): ReactElement => {
  const { t } = useTranslation();
  const {
    errorMessage,
    errorProps,
    helperText,
    helpTextProps,
    id: idProp,
    inputProps,
    isInvalid,
    label = t('passwordInputLabel'),
    labelProps,
    mt = 6,
    placeholder,
    size = 'sm',
    ...rest
  } = props;
  const { name = 'password', ...inputRest } = inputProps;
  const [visible, setVisible] = useState(false);
  const id = idProp || name;
  const iconsx = { color: 'blue.500', h: 6, w: 6 };
  const Icon = visible ? <EyeIcon {...iconsx} /> : <EyeSlashIcon {...iconsx} />;
  const inputId = `${id}-input`;
  const inputElement: InputElementProps = {
    children: Icon,
    height: 'auto',
    width: 'auto',
    top: 1,
  };

  return (
    <FormControl id={id} isInvalid={isInvalid} mt={mt} name={name} {...rest}>
      <FormLabel aria-label={name} htmlFor={inputId} {...labelProps}>
        {label}
      </FormLabel>
      <InputGroup size={size}>
        <Input
          isInvalid={isInvalid}
          name={name}
          placeholder={placeholder}
          id={inputId}
          type={visible ? 'text' : 'password'}
          {...inputRest}
        />
        <InputRightElement
          sx={{ cursor: 'pointer' }}
          onClick={() => setVisible(state => !state)}
          {...inputElement}
        />
      </InputGroup>
      {helperText && (
        <FormHelperText {...helpTextProps}>{helperText}</FormHelperText>
      )}
      {isInvalid && (
        <FormErrorMessage {...errorProps}>
          {errorMessage || t('required')}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
