import {
  Heading as TidalHeading,
  HeadingProps,
  HTMLChakraProps,
  Text as TidalText,
  TextProps,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

import { chakra } from '@/utils';
/* -------------------------------------------------------------------------------------------------
 * Common Typography
 * https://tidal.fluidtruck.com/docs/layout/heading
 * https://tidal.fluidtruck.com/docs/layout/text
 * -----------------------------------------------------------------------------------------------*/
/** 
  fontSizes: {
    xs: '0.75rem', 12px
    sm: '0.875rem', 14px
    md: '1rem', 16px
    lg: '1.125rem', 18px
    xl: '1.25rem', 20px
    '2xl': '1.375rem', 22px
    '3xl': '1.5rem', 24px
    '4xl': '1.875rem', 30px
    '5xl': '2rem', 32px
    '6xl': '2.25rem', 36px
    '7xl': '3rem', 48px
    '8xl': '3.75rem', 60px
    '9xl': '4.5rem', 72px
  },

  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": ".75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "7": "1.75rem",
    "8": "2rem",
    "9": "2.25rem",
    "10": "2.5rem",
  }
  * Add responsive styles with fontSize={['36px', '48px']}
  * DEFAULTS
    - Heading: lineHeight: '26px'
    - Text: lineHeight: '24px'
*/
export type { HeadingProps, TextProps, HTMLChakraProps };

// "7xl" = 48px
export const LargeHeading = (props: HeadingProps): ReactElement => {
  return <TidalHeading fontSize="7xl" {...props} />;
};

// "4xl" = 30px
export const Heading = (props: HeadingProps): ReactElement => {
  return <TidalHeading {...props} />;
};

// 32px
export const Title = (props: HeadingProps): ReactElement => {
  return <TidalHeading fontSize="5xl" {...props} />;
};

// default "3xl" = 24px
export const SubHeading = (props: HeadingProps): ReactElement => {
  return <TidalHeading fontSize="3xl" fontWeight="bold" {...props} />;
};

// 22px
export const SubTitle = (props: HeadingProps): ReactElement => (
  <TidalHeading fontSize="2xl" {...props} />
);

// default "md", 16px
export const Text = (props: TextProps): ReactElement => (
  <TidalText fontSize="md" {...props} />
);

export const BoldText = (props: TextProps): ReactElement => (
  <Text fontWeight="bold" {...props} />
);

// "md" - 16px
export const GrayText = (props: TextProps): ReactElement => (
  <Text fontSize="md" color="darkGray" {...props} />
);

// "sm" - 14px
export const MidText = (props: TextProps): ReactElement => (
  <TidalText fontSize="sm" lineHeight="20px" {...props} />
);

// "xs" - 12px
export const SmallText = (props: TextProps): ReactElement => (
  <TidalText fontSize="xs" lineHeight="14px" {...props} />
);

export const SmallGrayText = (props: TextProps): ReactElement => (
  <TidalText color="darkGray" fontSize="xs" lineHeight="14px" {...props} />
);

export const SpanText = (
  props: HTMLChakraProps<'span'>
): React.ReactElement => {
  return <chakra.span {...props} />;
};

export const Anchor = (props: HTMLChakraProps<'a'>): ReactElement => (
  <chakra.a color="blue.500" {...props} />
);

export const Label = (props: HTMLChakraProps<'label'>): ReactElement => {
  return (
    <chakra.label
      fontSize="xs"
      color="gray.700"
      sx={{ _invalid: { color: 'red.500' } }}
      {...props}
    />
  );
};
