import { OptionGroup as Option } from '@fluidtruck/core';
import moment from 'moment';
import { useState } from 'react';

/**
 * TODO: move to helpers file
 * @param boundingBox google.maps.LatLngBounds
 * @param percentageIncrease number (in percent)
 * @example paddingBoundingBox(bounds, 1.3) -> increases by 30%
 * @returns
 */
export const paddedBoundingBox = (
  boundingBox: google.maps.LatLngBounds,
  percentageIncrease: number
): google.maps.LatLngBounds | null => {
  const southWest = boundingBox.getSouthWest();
  const northEast = boundingBox.getNorthEast();

  const southWestLat = southWest.lat();
  const southWestLng = southWest.lng();
  const northEastLat = northEast.lat();
  const northEastLng = northEast.lng();

  // subtract ne - se lat/lng then multiply by increase
  const latDisplacement =
    (northEastLat - southWestLat) * (percentageIncrease - 1);
  const lngDisplacement =
    (northEastLng - southWestLng) * (percentageIncrease - 1);

  // add lat and lng displacement
  const newptNorthEast = new google.maps.LatLng(
    northEastLat + latDisplacement,
    northEastLng + lngDisplacement
  );
  const newptSouthWest = new google.maps.LatLng(
    southWestLat - latDisplacement,
    southWestLng - lngDisplacement
  );

  return new google.maps.LatLngBounds(newptSouthWest, newptNorthEast);
};

export function getLastDayOfMonth(selectedMonth: Option): Option {
  const selectedAsMoment = moment(selectedMonth?.value);
  const value = moment(selectedAsMoment).endOf('month');
  return {
    value: value.toISOString(),
    label: `${value.format('MMMM')} ${value.format('YYYY')}`,
  };
}

export const buildMonthOptions = (): Option[] => {
  const months = [];

  for (let i = 0; i < 6; i += 1) {
    const current = moment().subtract(i, 'month');

    months.push({
      value: current.startOf('month').toISOString(),
      label: `${current.format('MMMM')} ${current.format('YYYY')}`,
    });
  }

  return months;
};

export interface UseMonthSelectReturn {
  startOfMonth: Option;
  endOfMonth: Option;
  lastSixMonths: Option[];
  onMonthChange: (month: Option) => void;
}

export const useMonthSelect = (): UseMonthSelectReturn => {
  const lastSixMonths = buildMonthOptions();
  const [selectedMonth, setSelectedMonth] = useState(
    lastSixMonths.reverse()[lastSixMonths.length - 1]
  );

  const onMonthChange: UseMonthSelectReturn['onMonthChange'] = month => {
    setSelectedMonth(month);
  };

  return {
    startOfMonth: selectedMonth,
    lastSixMonths,
    endOfMonth: getLastDayOfMonth(selectedMonth),
    onMonthChange,
  };
};

export const getPolygonBounds = (
  polygon: google.maps.Polygon
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();

  const paths = polygon.getPaths();
  for (let i = 0; i < paths.getLength(); i += 1) {
    const path = paths.getAt(i);
    for (let j = 0; j < path.getLength(); j += 1) {
      bounds.extend(path.getAt(j));
    }
  }

  return bounds;
};
