import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useOrg, useOrganizationContext, useResize, useUser } from '@/hooks';
import { createContextProvider } from '@/utils';
import { get } from '@/utils/helpers';

import { Path, UseLeftNavMenuProps, UseLeftNavShape } from '../types';

/* -------------------------------------------------------------------------------------------------
 * Create context to track menu state and logic
 * -----------------------------------------------------------------------------------------------*/

export const useLeftNavMenu = (props: UseLeftNavMenuProps): UseLeftNavShape => {
  const { user } = useUser();
  const {
    context: { org },
  } = useOrganizationContext();

  const { isMobile } = useResize();
  const { isCollapsed = true, collapse } = props;

  const router = useRouter();
  const { organization: currentOrg } = org;
  const orgCategory = currentOrg?.category;
  const orgId = currentOrg?.id;
  const myFleetId = currentOrg?.['organization-settings']?.['my-fleet-id'];

  const { org: orgProp } = useOrg(orgId);
  const orgCreditAmt = orgProp?.['credit-amount'];

  const [claimsUrl, setClaimsUrl] = useState('');

  useEffect(() => {
    (async () => {
      const isProd = process.env.NODE_ENV === 'production';
      if (orgId && !!user && isProd) {
        try {
          const response = await get(
            `api/v1/organizations/${orgId}/zoho_claims_link`
          );
          if (typeof response === 'string') {
            setClaimsUrl(response);
          }
        } catch (err) {
          console.warn(`Claims link failure: ${err}`);
        }
      }
    })();
  }, [orgId, user]);

  const userCredit = user?.['credit-balance'];
  const vehicleLimit = currentOrg?.['borrow-limit'];
  const defaultOrg = user?.['default-profile']?.organization?.id;

  const profileProps = {
    firstName: user?.first || '',
    lastName: user?.last || '',
    completedReservations: user?.['completed-reservations'] || 0,
    averageRating: user?.['average-rating'],
    avatarUrl: user?.['avatar-url'],
  };

  // Navigate Menu
  const handleMenuChange = (path: Path, external: boolean): void => {
    if (!external) {
      router.push(path);
    } else {
      // example of an external link is Claims in Zoho
      window.open(path, '_blank');
    }
    // if mobile, close the nav if user selects menu item
    if (isMobile) {
      setTimeout(() => collapse.on(), 300);
    }
  };

  return {
    claimsUrl,
    collapse,
    currentOrg,
    orgCreditAmt,
    defaultOrg,
    handleMenuChange,
    isCollapsed,
    isMobile,
    myFleetId,
    orgCategory,
    orgId,
    profileProps,
    user,
    userCredit,
    vehicleLimit,
  };
};

export type UseLeftNavMenuReturn = ReturnType<typeof useLeftNavMenu>;

export const [NavigationMenuProvider, useLeftNavMenuContext] =
  createContextProvider<UseLeftNavMenuReturn>({
    name: 'LeftNavMenuContext',
    errorMessage:
      'You forgot to wrap your component with the NavigationMenuProvider',
  });
