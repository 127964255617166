// @ts-nocheck
import React from 'react';

import {
  CartContextProvider,
  FeedbackContextProvider,
  LoadingContextProvider,
  OrganizationContextProvider,
  ReservationEstimateProvider,
  SignupContexProvider,
  UserContextProvider,
} from '../context';
import type { UserContextShape } from '../context/context-types';
import { GoogleMapsApiProvider } from '../context/GoogleMaps/GoogleMapsApiProvider';

type Props = {
  children: React.ReactNode;
  user: UserContextShape;
};
export const MainContext = ({ children, user, ...pageProps }: Props) => (
  <LoadingContextProvider>
    <FeedbackContextProvider>
      <GoogleMapsApiProvider>
        <SignupContexProvider>
          <UserContextProvider user={user}>
            <OrganizationContextProvider>
              <ReservationEstimateProvider {...pageProps}>
                <CartContextProvider {...pageProps}>
                  {children}
                </CartContextProvider>
              </ReservationEstimateProvider>
            </OrganizationContextProvider>
          </UserContextProvider>
        </SignupContexProvider>
      </GoogleMapsApiProvider>
    </FeedbackContextProvider>
  </LoadingContextProvider>
);

export default MainContext;
