export const urlEmbedParams = (
  rawUrl: string,
  params: Record<string, any>
): string => {
  if (!params) return rawUrl;

  let parsedUrl = rawUrl;

  Object.entries(params).forEach(([KEY, VALUE]) => {
    const regex = new RegExp(`:${KEY}`);
    parsedUrl = parsedUrl.replace(regex, `${VALUE}`);
  });

  return parsedUrl;
};

export const urlEmbedQuery = (
  path: string,
  body: Record<string, any>
): string => {
  let url = `${process.env.API_URL}${path}`;

  if (path.indexOf('http') !== -1) {
    url = path;
  }

  if (!body) {
    return url;
  }

  const qs = Object.keys(body)
    .map(key => {
      const val = encodeURIComponent(body[key] || '').toString();
      const regex = /%2C/gi;
      val.replace(regex, ','); // Decode commas to allow more delimited items.
      return `${key}=${val}`;
    })
    .join('&');

  url += `?${qs}`;

  return url;
};
