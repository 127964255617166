import moment from 'moment';

import { HomeItem } from '@/api-resources/contracts/api-contracts/items/GET-api-items-home';
import { ItemDetails, MapItemData } from '@/types/item-details-types';

export const mapDataToItems = (
  data?: HomeItem[]
): MapItemData[] | undefined => {
  return data?.map(i => ({
    item: {
      id: i.id,
      title: i.title,
      instabook: i.isInstantBook,
      'fleet-number': i.fleetId,
      'is-electric-vehicle': i.isEV,
      isAvailable: i.isAvailable,
      pictures: [
        {
          standard: i.pictures?.[0].standard,
          thumb: i.pictures?.[0].thumb_url,
          large: i.pictures?.[0].url,
        },
      ],
    },
    cost: i.cost,
    coordinates: {
      lat: i.lat,
      lng: i.long,
    },
    'distance-miles': i.distance,
    make: i.make,
    model: i.model,
    measurements: {
      'cargo-height': i.measurements.cargoHeight,
      'cargo-length': i.measurements.cargoLength,
      'cargo-width': i.measurements.cargoWidth,
      class: i.measurements.class,
      driveTrain: i.measurements.driveTrain,
      engineSize: i.measurements.engineSize,
      hasLadder: i.measurements.hasLadder,
      hasLiftGate: i.measurements.hasLiftGate,
      hasRamp: i.measurements.hasRamp,
      hasTowReceiver: i.measurements.hasTowReceiver,
      horsepower: i.measurements.horsepower,
      manufacturer: i.measurements.manufacturer,
      wheelBase: i.measurements.wheelBase,
    },
  }));
};

export const mapEntriesToItems = (
  data?: ItemDetails[]
): MapItemData[] | undefined => {
  return data?.map(entry => ({
    item: {
      id: entry.item.id,
      title: entry.item.title,
      instabook: entry.item.instabook,
      'is-electric-vehicle': entry.item['is-electric-vehicle'],
      'fleet-number': entry.item['fleet-number'],
      isAvailable: true,
      pictures: [
        {
          standard: entry.item.pictures?.[0].standard,
          thumb: entry.item.pictures?.[0].thumb_url,
          large: entry.item.pictures?.[0].url,
        },
      ],
    },
    cost: entry.cost,
    coordinates: {
      lat: entry.coordinates.lat,
      lng: entry.coordinates.lng,
    },
    'distance-miles': entry['distance-miles'],
    make: entry.item.make,
    model: entry.item.model,
    measurements: {
      'cargo-height': entry.measurements['cargo-height'],
      'cargo-length': entry.measurements['cargo-length'],
      'cargo-width': entry.measurements['cargo-width'],
      class: entry.measurements.class,
      driveTrain: entry.measurements.drivetrain,
      engineSize: entry.measurements['engine-size'],
      hasLadder: entry.measurements['has-ladder'],
      hasLiftGate: entry.measurements['has-lift-gate'],
      hasRamp: entry.measurements['has-ramp'],
      hasTowReceiver: entry.measurements['has-tow-receiver'],
      horsepower: entry.measurements.horsepower,
      manufacturer: entry.measurements.manufacturer,
      wheelBase: entry.measurements.wheelbase,
    },
  }));
};

export const defaultStart = moment()
  .utc()
  .add(1, 'day')
  .startOf('hour')
  .add(1, 'hour')
  .format('YYYY-MM-DD HH:mm:ss');

export const defaultEnd = moment()
  .utc()
  .add(1, 'day')
  .startOf('hour')
  .add(2, 'hour')
  .format('YYYY-MM-DD H:mm:ss');
