import { useTheme } from '@emotion/react';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';

import * as Assets from '@/components/MapEventFooter/assets';
import { chunkArray } from '@/hooks/use-fleet';
import { useGoogleMapContext } from '@/lib/context/GoogleMaps/GoogleMapProvider';
import { ACTIONS } from '@/lib/context/GoogleMaps/reducer/map_actions';
import * as Types from '@/types/maps-types';
import { base64 } from '@/utils';
import { get } from '@/utils/helpers';

const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '';

export const useGoogleMaps = (): Types.UseGoogleMapsReturn => {
  const { state, dispatch } = useGoogleMapContext();
  const { mapInstance } = state;
  const theme = useTheme();

  const [events, setEvents] = useState<
    Types.UseGoogleMapsReturn['events']['events']
  >(Types.initialEventsState);

  const markerInstances = useRef<Types.MapItemInstances>({
    [Types.MapItemInstanceType.FleetItem]: [],
    [Types.MapItemInstanceType.FleetEvent]: [],
    [Types.MapItemInstanceType.FleetPolylines]: [],
  });

  const onLoad: Types.UseGoogleMapsReturn['onLoad'] = useCallback(
    (map: google.maps.Map) => {
      dispatch({ type: ACTIONS.SET_MAP, payload: map });
    },
    [dispatch]
  );

  // TODO: Remove when boundingbox is provided for FT ItemMap
  const setMapLatLng: Types.UseGoogleMapsReturn['setMapLatLng'] =
    useCallback((): void => {
      if (!mapInstance) return;

      const center = mapInstance.getBounds()?.getCenter();
      if (!center) return;

      const lat = center?.lat();
      const lng = center?.lng();

      dispatch({
        type: ACTIONS.SET_LATLNG,
        payload: {
          lat,
          lng,
        },
      });
    }, [dispatch, mapInstance]);

  const createMarkerFromFleetMapItem: Types.CreateMarkerFromFleetMapItem =
    useCallback(
      fleetItem => {
        const fleetMarkerPosition = fleetItem.coordinate;
        const fleetMarkerIcon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 11,
          fillColor: fleetItem.isMyfleet
            ? theme.colors.yellow[400]
            : theme.colors.blue[500],
          fillOpacity: 1,
          strokeWeight: 3,
          strokeColor: 'white',
        };

        return new google.maps.Marker({
          position: fleetMarkerPosition,
          icon: fleetMarkerIcon,
          visible: true,
          map: mapInstance,
        });
      },
      [mapInstance, theme.colors.blue[500], theme.colors.yellow[400]]
    );

  const createMarkerFromFleetEvent: Types.CreateMarkerFromFleetEvent =
    useCallback(
      fleetEvent => {
        const eventMarkerPosition = {
          lat: fleetEvent.lat,
          lng: fleetEvent.lng,
        };
        const eventMarkerTitle = moment(fleetEvent.eventDate).format(
          'MM/DD/YYYYYY h:mm a'
        );

        return new google.maps.Marker({
          position: eventMarkerPosition,
          icon: fleetEvent.icon,
          title: eventMarkerTitle,
          map: mapInstance,
        });
      },
      [mapInstance]
    );

  const updateFleetEventsAndGetEventIcon: Types.UpdateFleetEventsAndGetEventIcon =
    fleetEvent => {
      let eventType: keyof Types.FleetEventsState;
      const eventIcon = {} as google.maps.Icon;

      switch (fleetEvent.greenDrivingType) {
        case 1:
          eventType = 'acceleration';
          eventIcon.url = `${base64},${Assets.AccelerationSvgBtoa}`;
          eventIcon.size = new google.maps.Size(28, 24);
          eventIcon.anchor = new google.maps.Point(14, 12);
          break;
        case 2:
          eventType = 'braking';
          eventIcon.url = `${base64},${Assets.BrakingSvgBtoa}`;
          eventIcon.size = new google.maps.Size(28, 24);
          eventIcon.anchor = new google.maps.Point(14, 12);
          break;
        default:
          eventType = 'cornering';
          eventIcon.url = `${base64},${Assets.CorneringSvgBtoa}`;
          eventIcon.size = new google.maps.Size(24, 24);
          eventIcon.anchor = new google.maps.Point(12, 12);
          break;
      }

      setEvents(prev => ({
        ...prev,
        [eventType]: prev[eventType] + 1,
      }));

      return eventIcon;
    };

  // ------------------------ Draw/Plot methods ------------------------ //

  // TODO: Cleanup drawRoutes
  const drawRoutes: Types.UseGoogleMapsReturn['drawRoutes'] = useCallback(
    (vehicleRouteData): void => {
      const drawSnappedPolyline: Types.DrawSnappedPolyline = snappedCoords => {
        if (!mapInstance) return;

        const snappedPolyline = new google.maps.Polyline({
          path: snappedCoords,
          strokeColor: '#00B2E3',
          strokeWeight: 10,
          strokeOpacity: 1,
          map: mapInstance,
        });

        markerInstances.current[Types.MapItemInstanceType.FleetPolylines].push(
          snappedPolyline
        );
      };
      const runSnapToRoadWithPaths: Types.RunSnapToRoadWithPaths =
        async pathValues => {
          if (!pathValues.length) return;

          const response: Types.SnapApiResponse = await get(
            'https://roads.googleapis.com/v1/snapToRoads',
            undefined,
            {
              interpolate: true,
              key: googleMapsApiKey,
              path: pathValues.join('|'),
            }
          );

          const coords = response?.snappedPoints?.map(
            ({ location }) =>
              new google.maps.LatLng(location.latitude, location.longitude)
          );

          drawSnappedPolyline(coords);
        };

      const routeBounds = new google.maps.LatLngBounds();
      const routeCoordsSortedByDate = vehicleRouteData.sort((a, b) =>
        moment(a.eventDate).diff(moment(b.eventDate), 'seconds')
      );

      const routeMapped2latlng = routeCoordsSortedByDate.map(path => {
        const { lat, lng } = path;

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
        });
        const position = marker.getPosition();
        if (position) {
          routeBounds.extend(position);
        }

        return `${lat},${lng}`;
      });

      const startPointMarker = new google.maps.Marker({
        position: routeCoordsSortedByDate[0],
        map: mapInstance,
        zIndex: 1001,
        icon: {
          url: `${base64},${Assets.StartRouteSvgBtoa}`,
          size: new google.maps.Size(34, 34),
          anchor: new google.maps.Point(17, 17),
        },
      });

      const endPointMarker = new google.maps.Marker({
        position:
          routeCoordsSortedByDate?.[routeCoordsSortedByDate?.length - 1],
        map: mapInstance,
        zIndex: 1000,
        icon: {
          url: `${base64},${Assets.FinishRouteSvgBtoa}`,
          size: new google.maps.Size(36, 36),
          anchor: new google.maps.Point(18, 18),
        },
      });

      markerInstances.current.fleetEvent.push(
        ...[startPointMarker, endPointMarker]
      );

      const paths = chunkArray(routeMapped2latlng, 100);

      Promise.all(paths.map(runSnapToRoadWithPaths));

      mapInstance?.fitBounds(routeBounds);
    },
    [mapInstance]
  );

  const plotFleetMarkers: Types.UseGoogleMapsReturn['markers']['plotFleetMarkers'] =
    useCallback(
      fleetItems => {
        const itemBounds = new google.maps.LatLngBounds();

        fleetItems.forEach(fleetItem => {
          const fleetMarker = createMarkerFromFleetMapItem(fleetItem);

          markerInstances.current[Types.MapItemInstanceType.FleetItem].push(
            fleetMarker
          );
          itemBounds.extend(fleetItem.coordinate);
        });

        if (fleetItems.length >= 1) mapInstance?.fitBounds(itemBounds);

        if (fleetItems.length === 1) mapInstance?.setZoom(16);
      },
      [createMarkerFromFleetMapItem, mapInstance]
    );

  const plotEvents: Types.UseGoogleMapsReturn['events']['plotEvents'] =
    useCallback(
      (eventsData): void => {
        eventsData.forEach(item => {
          const fleetEventMarker = createMarkerFromFleetEvent({
            ...item,
            icon: updateFleetEventsAndGetEventIcon(item),
          });

          markerInstances.current[Types.MapItemInstanceType.FleetEvent].push(
            fleetEventMarker
          );
        });
      },
      [createMarkerFromFleetEvent]
    );

  // ------------------------ Cleanup methods ------------------------ //
  const clearInstancesOfMapItemsType: Types.UseGoogleMapsReturn['clearInstancesOfMapItemsType'] =
    useCallback(instanceType => {
      markerInstances.current[instanceType].forEach(
        (INSTANCE: google.maps.Polyline | google.maps.Marker) => {
          google.maps.event.clearInstanceListeners(INSTANCE);
          INSTANCE.setMap(null);
        }
      );
    }, []);

  const resetEventsState = useCallback(() => {
    setEvents(Types.initialEventsState);
  }, []);

  return {
    state,
    dispatch,
    onLoad,
    drawRoutes,
    setMapLatLng,
    clearInstancesOfMapItemsType,
    events: { events, plotEvents, resetEventsState },
    markers: { plotFleetMarkers },
  };
};
