import { SWRConfiguration, SWRResponse } from 'swr';

import { ErrorApiResponseV2, GenericApiResponse } from '@/types';

import { EventPoint, RoutePoint } from '../../types';

/**
 * Use Fleet Routing
 * `api/scoring/org/:orgID/item/:itemID/route_information?start-date=${startTime}&end-date=${endTime}`
 * */

export enum FleetEventType {
  ACCELERATION = 'acceleration',
  BRAKING = 'braking',
  CORNERING = 'cornering',
}

export const initialEventsSummaryCount = {
  [FleetEventType.ACCELERATION]: 0,
  [FleetEventType.CORNERING]: 0,
  [FleetEventType.BRAKING]: 0,
};

export interface FleetRoutingData {
  itemID: number;
  vehicleRoute: RoutePoint[] | null;
  vehicleRouteEvents: EventPoint[] | null;
}

export type FleetRoutingSwrModel = GenericApiResponse<FleetRoutingData>;
export interface FleetRoutingMapped extends FleetRoutingData {
  eventsSummaryCount: typeof initialEventsSummaryCount;
}

export interface UseFleetRoutingSwrArgs {
  itemId: number | null | string;
  orgId: number | string;
  startTime?: string;
  endTime?: string;
  config: SWRConfiguration<FleetRoutingMapped, Error>;
}

export interface UseFleetRoutingSwrReturn {
  data?: FleetRoutingMapped | undefined;
  isLoading: boolean;
  isError: ErrorApiResponseV2;
  mutate: SWRResponse<FleetRoutingMapped, Error>['mutate'];
}

export type UseFleetRoutingSwr = (
  args: UseFleetRoutingSwrArgs
) => UseFleetRoutingSwrReturn;
