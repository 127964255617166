import Cookies from 'js-cookie';

import { BREAKPOINT_MOBILE, BREAKPOINT_MOBILE_SMALL } from '@/hooks/use-resize';
import { CurrentFlow } from '@/types';

const breakpoints = [BREAKPOINT_MOBILE, BREAKPOINT_MOBILE_SMALL];

let isRefreshing = false;

/**
 * Returns a Date object containing the expiration date of the token. If expireAt is provided, we
 * will use that to calculate the expiration date in seconds, subtracting 30 seconds to be
 * safe. If expiresAt is not provided, we'll default to 14 days from now.
 *
 * @param {number} expireAt
 * @returns {Date}
 */
export const getExpires = expireAt => {
  const expires = new Date();
  if (expireAt) {
    expires.setTime(expires.getTime() + (expireAt - 30) * 1000);
  } else {
    expires.setDate(expires.getDate() + 14);
  }

  return expires;
};

/**
 * Sets the new auth and refresh cookies, if set.
 *
 * @param {HeadersInit} headers
 */
const setAuthCookies = headers => {
  const setAuth = headers.get('set-authorization');
  if (setAuth) {
    Cookies.set('token', setAuth, {
      expires: getExpires(headers.get('set-authorizationexpireat')),
    });
  }

  const setRefresh = headers.get('set-refresh');
  if (setRefresh) {
    Cookies.set('refresh', setRefresh, {
      expires: getExpires(headers.get('set-refreshexpireat')),
    });
  }
};

/**
 * Sets the auth headers for a request, only if we're on a fluidtruck/fluidfleet API call.
 *
 * @param {string} path
 * @param {HeadersInit} headers
 * @returns {HeadersInit}
 */
const setAuthHeaders = async (path, headers) => {
  // We only want to inject headers on calls to our APIs.
  if (
    path.indexOf('http') !== -1 &&
    path.indexOf('fluidtruck') === -1 &&
    path.indexOf('fluidfleet') === -1
  ) {
    return headers;
  }

  if (
    path.indexOf('api/v2/signup') !== -1 ||
    path.indexOf('api/login') !== -1 ||
    path.indexOf('api/users/forgot_password') !== -1 ||
    path.indexOf('api/users/verify_password') !== -1
  ) {
    return headers;
  }

  const token = Cookies.get('token') || '';
  const refreshToken = Cookies.get('refresh') || '';

  const newHeaders = {
    ...headers,
  };

  // If we have a token, set the header and continue. If we don't have a token but we have a refresh
  // token, we'll attempt to refresh the token. If that fails, we'll redirect to `/search` to reset
  // the user's session.
  if (token) {
    newHeaders.Authorization = `Bearer ${token}`;
  } else if (!token && refreshToken) {
    const abortController = new AbortController();

    try {
      if (!isRefreshing) {
        isRefreshing = true;
        const res = await fetch(`${process.env.API_URL}api/refresh`, {
          method: 'POST',
          signal: abortController.signal,
          headers: {
            Refresh: refreshToken,
          },
        });

        if (!res.ok) {
          throw new Error('Failed to refresh token');
        }

        setAuthCookies(res.headers);
        newHeaders.Authorization = `Bearer ${res.headers.get(
          'set-authorization'
        )}`;
      } else {
        // If we're already refreshing, wait until we're done.
        let tries = 0;
        while (isRefreshing && tries < 30) {
          tries += 1;
          await new Promise(resolve => setTimeout(resolve, 1000));
        }

        if (tries === 30) {
          throw new Error('Failed to refresh token');
        }

        newHeaders.Authorization = `Bearer ${Cookies.get('token')}`;
      }
    } catch (e) {
      abortController.abort();

      Cookies.remove('token');
      Cookies.remove('refresh');

      window.location.href = '/search';
    } finally {
      isRefreshing = false;
    }
  }

  return newHeaders;
};

export const generateMQ = maxMin =>
  breakpoints.map(bp => `@media (${maxMin}-width: ${bp}px)`);

export const postPutPatch = type => {
  return async (path, body, headerProps = {}) => {
    const headers = await setAuthHeaders(path, {
      'Content-Type': 'application/json; charset=UTF-8',
      ...headerProps,
    });

    const res = await fetch(`${process.env.API_URL}${path}`, {
      method: type,
      headers,
      body: JSON.stringify(body),
    });

    if (res.ok) {
      setAuthCookies(res.headers);
    }

    if (res.status === 204) {
      return null;
    }

    const result = await res.json();

    return result;
  };
};

export const fullUrl = (path, body) => {
  let url = `${process.env.API_URL}${path}`;

  if (path.indexOf('http') !== -1) {
    url = path;
  }

  if (!body || !Object.keys(body).length) {
    return url;
  }

  const qs = new URLSearchParams(body).toString();

  url += `?${qs}`;

  return url;
};

export const put = postPutPatch('PUT');

export const post = postPutPatch('POST');

export const patch = postPutPatch('PATCH');

export const get = async (path, headers = {}, body) => {
  const url = fullUrl(path, body);
  const newHeaders = await setAuthHeaders(path, headers);
  const res = await fetch(url, {
    headers: newHeaders,
  });

  const data = await res.json();

  return data;
};

export const downloadCsv = async (
  filename,
  path,
  errorMessage = 'Error retrieving your CSV:',
  headersProps = {},
  body = {},
  type = 'file'
) => {
  const headers = await setAuthHeaders(path, {
    'Content-Type': 'text/csv',
    ...headersProps,
  });

  try {
    let blob;
    const url = fullUrl(path, body);
    const res = await fetch(url, { headers });
    const timestamp = new Date().toISOString();
    if (type === 'base64') {
      const { data } = await res.json();
      const csvContent = atob(data);
      blob = new Blob([csvContent], {
        type: 'data:application/octet-stream;base64',
      });
    }
    if (type === 'file') {
      blob = await res.blob();
    }
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = `${filename}-${timestamp}.csv`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (e) {
    console.warn(errorMessage, e.message);
  }
};

export const downloadZip = async (filename, path, headers = {}, body = {}) => {
  const newHeaders = await setAuthHeaders(path, {
    'Content-Type': 'application/zip',
    ...headers,
  });

  const url = fullUrl(path, body);

  const res = await fetch(url, { headers: newHeaders });
  const { data } = await res.json();
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/zip' });
  const fr = new FileReader();
  fr.readAsDataURL(blob);
  fr.onload = function onload(event) {
    const a = document.createElement('a');
    a.href = event.target.result;
    a.download = `${filename}.zip`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
};

export const deleteRequest = postPutPatch('DELETE');

const onDrop = (setBase64, setFileName, callback) => files => {
  if (!files.length) {
    // handle error for file selected that is not accepted
    // for android mobile devices
    callback?.(files);
    return;
  }
  const file = files[0];
  if (setFileName) setFileName(file?.name);
  const reader = new FileReader();
  reader?.readAsDataURL(file);
  reader.onload = event => {
    if (setBase64) setBase64(event?.currentTarget?.result);
    if (callback) callback(file?.name, event?.currentTarget?.result);
  };
};

export const handleDropState = (setBase64, setFileName) =>
  onDrop(setBase64, setFileName);

export const handleDrop = handleDropState;

export const handleDropCallback = callback =>
  onDrop(undefined, undefined, callback);

export const handleOrgDocumentUpload = async (
  orgId,
  type,
  fileName,
  rawData
) => {
  const split = rawData.split('data:application/pdf;base64,');
  const base64 = split[1];
  const body = {
    filename: fileName,
    filetype: type,
    payload: base64,
  };

  const { messages, data } = await post(
    `api/organizations/${orgId}/upload`,
    body
  );

  return {
    data,
    error: messages?.[0],
  };
};

const sliceEmail = userEmail =>
  userEmail && userEmail.slice(userEmail.length - 4, userEmail.length);

export const validateEmail = email => {
  if (sliceEmail(email) === '.con') {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(?!\s)(?!.*\s$)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const scrollToRef = ref => {
  ref?.current?.scrollIntoView({
    behavior: 'smooth',
  });
};

const findModelUrlString = model => {
  const lowerModel = (model || '').toLowerCase();
  if (lowerModel.indexOf('promaster') > -1) {
    return 'promaster';
  }
  if (lowerModel === '2500 tradesman' || lowerModel === 'ram 1500') {
    return 'tradesman';
  }
  if (lowerModel === 'conventional type truck single cab') {
    return 'hino';
  }
  if (lowerModel.indexOf('journey') > -1) {
    return 'journey';
  }
  return 'other';
};

const findSubCatUrlString = (subCat = '') => {
  if (subCat.indexOf('box_truck') > -1) {
    return 'box-truck';
  }
  if (subCat.indexOf('suv') > -1 || subCat === 'car' || subCat === 'sedan') {
    return 'car-suv';
  }
  if (subCat === 'put_2500_new' || subCat === 'truck') {
    return 'pickup-truck';
  }
  if (subCat.indexOf('cargo_van') > -1) {
    return 'cargo-van';
  }
  return 'misc-trailer';
};

export const taxonomicalHref =
  '/truck-rental/[city]/[category]/[brand]/[model]/[id]';

export const getTaxonomicalUrl = item => {
  const city = item?.city?.toLowerCase().replace(' ', '-');
  const urlItems = {
    brand: (
      item?.['item-specifications']?.manufacturer || 'other'
    ).toLowerCase(),
    model: findModelUrlString(item?.['item-specifications']?.model),
    langCode: 'en',
    countryCode: 'us',
    id: item?.id,
    city,
    category: findSubCatUrlString(item?.['sub-category']),
  };
  return {
    as: `/truck-rental/${urlItems.city}/${urlItems.category}/${urlItems.brand}/${urlItems.model}/${urlItems.id}`,
    href: taxonomicalHref,
  };
};

export const getTaxonomicalUrlFromPub = data => {
  const item = {
    ...data,
    city: data?.location?.city || 'other',
    'item-specifications': {
      ...(data?.details || {}),
    },
  };
  return getTaxonomicalUrl(item);
};

export const convertHrsMins = n => `${Math.floor(n / 60)}h ${n % 60}m`;

/**
 * @param rawUrl 'api/v1/items/:orgId'
 * @param params '{orgId: 12321}'
 * @returns 'api/v1/items/12321
 */
export const parseUrlKey = (rawUrl, params) => {
  if (!params) return rawUrl;

  let parsedUrl = rawUrl;

  Object.entries(params).forEach(([KEY, VALUE]) => {
    const regex = new RegExp(`:${KEY}`);
    parsedUrl = parsedUrl.replace(regex, VALUE);
  });

  return parsedUrl;
};

export const getUserFlow = route => {
  const isBookingFlow = !!route.booking;

  if (isBookingFlow) {
    return CurrentFlow.BOOKING;
  } else {
    return CurrentFlow.HOME;
  }
};

export const getQueryParam = route => {
  const isBooking = !!route.booking;

  if (isBooking) {
    return '?booking=true';
  } else {
    return '';
  }
};
