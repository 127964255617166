import { TidalProvider } from '@fluidtruck/core';
import {
  CssBaseline,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { ThemeProvider } from 'emotion-theming';
import PropTypes from 'prop-types';

import muiTheme from '@/theme/muiTheme';
import { theme as tidalTheme } from '@/theme/Theme';

const AppProviders = ({ children }) => (
  <MuiThemeProvider theme={muiTheme}>
    <TidalProvider theme={tidalTheme}>
      <ThemeProvider theme={tidalTheme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          {children}
        </StylesProvider>
      </ThemeProvider>
    </TidalProvider>
  </MuiThemeProvider>
);

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProviders };
