export const getFleetMarkerSvgLiteral = (
  id: string,
  isMyfleet: boolean
): string => {
  return btoa(
    `<svg width="93" height="76" viewBox="0 0 93 76" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_dd_3477_83961)"><rect x="8" y="6" width="77" height="32" rx="5" fill="white"/><text style="font-family:Menlo;font-size:16px;font-style:normal;text-anchor:middle" x="50%" y="28" fill="#00B2E3">${id}</text></g><g filter="url(#filter1_d_3477_83961)"><circle cx="47" cy="52" r="12" fill="${
      isMyfleet ? '#FFCD00' : '#00B2E3'
    }" stroke="white" stroke-width="4"/></g><defs><filter id="filter0_dd_3477_83961" x="0" y="0" width="93" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="3"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3477_83961"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="4"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/><feBlend mode="normal" in2="effect1_dropShadow_3477_83961" result="effect2_dropShadow_3477_83961"/><feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3477_83961" result="shape"/></filter><filter id="filter1_d_3477_83961" x="29" y="38" width="36" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="6"/><feGaussianBlur stdDeviation="2"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3477_83961"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3477_83961" result="shape"/></filter></defs></svg>`
  );
};

export const ClusterMarkerSvgBtoa = Buffer.from(
  '<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="27" cy="27" r="23" fill="#00B2E3" stroke="white" stroke-width="4"/></svg>'
).toString('base64');
