import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
} from '@fluidtruck/core';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import { heights } from '@/theme';

import { MotionDiv } from '../../base-components/animations/MotionDiv';
import { LeftNavMenuContent, LeftNavProfile } from './components';
import * as Styles from './components/styles';
import { useLeftNavMenuContext } from './context/useLeftNavMenu';

const { topNavMobile, searchBar } = heights;

/* NOTE: Should only be handling whether the menu is open or closed */
export const MenuNavigationCollapse = () => {
  const { isCollapsed, isMobile, collapse } = useLeftNavMenuContext();
  const padding = topNavMobile + searchBar;
  if (isMobile && collapse)
    return (
      <Drawer
        size="full"
        placement="left"
        preserveScrollBarGap
        isFullHeight
        onClose={collapse.on}
        isOpen={!isCollapsed}
      >
        <DrawerContent sx={{ top: `${topNavMobile}px !important` }}>
          <DrawerHeader padding={0}>
            <LeftNavProfile />
          </DrawerHeader>
          <DrawerBody overflow="scroll" sx={{ padding: `0 0 ${padding}px 0` }}>
            <LeftNavMenuContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );

  return (
    <AnimatePresence>
      <MotionDiv
        initial="closed"
        animate={isCollapsed ? 'closed' : 'open'}
        data-test-id="left-nav-collapse-container"
        variants={Styles.collapseVariants}
        sx={Styles.menuNavigationCollapseSx}
      >
        <LeftNavProfile />
        <LeftNavMenuContent />
      </MotionDiv>
    </AnimatePresence>
  );
};
