import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  BoldText,
  Box,
  Grid,
  GridItem,
  SpanText,
  Text,
} from '@/base-components';
import { Avatar } from '@/base-components/Avatar';

// import { centsToDollars, formatMoney } from '@/utils';
import { useLeftNavMenuContext } from '../context/useLeftNavMenu';
import * as Styles from './styles';

export const LeftNavProfile = (): React.ReactElement => {
  const { t } = useTranslation('org_menu');
  const {
    currentOrg,
    isCollapsed,
    isMobile,
    profileProps,
    orgCategory,
    // userCredit,
    vehicleLimit,
  } = useLeftNavMenuContext();

  const { lastName, avatarUrl, firstName } = profileProps;

  const isPersonal = orgCategory === 'Personal';

  const profileName = isPersonal
    ? `${firstName} ${lastName}`
    : currentOrg?.name;

  return (
    <Box sx={Styles.profileContainer}>
      {isCollapsed && !isMobile && (
        <Box textAlign="center">
          <Avatar
            data-test-id="left-nav-avatar"
            src={avatarUrl}
            h={12}
            w={12}
          />
        </Box>
      )}
      {!isCollapsed && (
        <Grid
          templateColumns="1fr auto"
          templateRows={isPersonal ? '1fr auto' : 'auto1fr'}
          columnGap={4}
          rowGap={0}
        >
          <GridItem>
            <BoldText fontSize="lg">{profileName}</BoldText>
          </GridItem>
          <GridItem colStart={2}>
            <Text fontSize="sm">
              <SpanText color="darkGray">{t('menu.vehicleLimit')} </SpanText>
              {vehicleLimit}
            </Text>
          </GridItem>
          {/*  NOTE: hiding user credit for now - fluid-4964
          {isPersonal && (
            <GridItem colStart={1}>
              <Text fontSize="sm">
                <SpanText color="darkGray">{t('menu.credit')}: </SpanText>
                {formatMoney(centsToDollars(userCredit))}
              </Text>
            </GridItem>
          )} */}
        </Grid>
      )}
    </Box>
  );
};
