import React, { ReactNode } from 'react';

import * as Styles from '../styles';
import { NavigationWrapper } from './NavigationWrapper';

interface Props {
  children: ReactNode;
}

// NOTE: For all pages except home/search page
const HideLeftNavContainer = ({ children }: Props) => (
  <NavigationWrapper>
    <Styles.HideNavContainer data-test-id="hide-nav-content">
      {children}
    </Styles.HideNavContainer>
  </NavigationWrapper>
);

export { HideLeftNavContainer };
