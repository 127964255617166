export enum ACTIONS {
  SET_MAP = 'SET_MAP',
  SET_RADIUS = 'SET_RADIUS',
  SET_ZOOM = 'SET_ZOOM',
  SET_LATLNG = 'SET_LATLNG',
  SET_MAP_LOADED = 'SET_MAP_LOADED',
  SET_BOUNDING_BOX = 'SET_BOUNDING_BOX',
}

export interface MapState {
  mapInstance: google.maps.Map | null;
  zoom: number | null;
  boundingBox: google.maps.LatLngBounds | null;
  latLng: google.maps.LatLngLiteral | null;
  isMapLoaded: boolean;
}

type setMapAction = { type: ACTIONS.SET_MAP; payload: MapState['mapInstance'] };
type setZoomAction = { type: ACTIONS.SET_ZOOM; payload: MapState['zoom'] };
type setLatLng = {
  type: ACTIONS.SET_LATLNG;
  payload: MapState['latLng'];
};
type setIsMapLoaded = {
  type: ACTIONS.SET_MAP_LOADED;
  payload: MapState['isMapLoaded'];
};
type setBoundingBox = {
  type: ACTIONS.SET_BOUNDING_BOX;
  payload: MapState['boundingBox'];
};

export type Action =
  | setMapAction
  | setZoomAction
  | setLatLng
  | setIsMapLoaded
  | setBoundingBox;
