import { ReactElement } from 'react';

import type { LoginSignupProps } from '@/components/LoginSignup';

export enum ACCEPT_TYPE {
  LOGIN = 'login',
  SIGNUP = 'signup',
}

export enum Names {
  IS_BUSINESS_TYPE = 'isTypeBusiness',
  FIRST = 'first',
  LAST = 'last',
  EMAIL = 'email',
  ADDRESS = 'address',
  ADDRESS_TWO = 'addressTwo',
  STATE = 'state',
  CITY = 'city',
  PHONE = 'phone',
  ZIPCODE = 'zipcode',
  BUSINESS_NAME = 'businessName',
  BUSINESS_CATEGORY = 'businessCategory',
}

export type UpgradeFormShape = {
  [Names.BUSINESS_CATEGORY]: string;
  [Names.BUSINESS_NAME]: string;
};

export type UpgradeDataShape = {
  name: string;
  category: string;
};
export interface FormType {
  first: string;
  last: string;
  deviceType: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  addressTwo: string;
  city: string;
  state: string;
  zipcode: string;
  isTypeBusiness: boolean;
  businessName: string;
  businessCategory: string;
}

export interface CreateBodyShape {
  firstName: string;
  lastName: string;
  deviceType: string;
  email: string;
  password: string;
  phoneNumber?: string;
  acceptedTermsAndConditions: boolean;
  organization?: {
    name: string;
    category: string;
  };
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    'postal-code': string;
  };
}

export interface AccountCreateProps {
  onFinish: LoginSignupProps['loginProps']['onFinish'];
}
export type AccountCreateReturn = ({
  onFinish,
}: AccountCreateProps) => ReactElement;
