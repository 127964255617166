/* eslint-disable @typescript-eslint/ban-types */
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useOrganizationContext, useResize } from '@/hooks';
import { findMatchOrg } from '@/lib/context/OrganizationContext';

import { LoginSignupProps } from '../LoginSignup';
import { DesktopRightMenu, MobileRightMenu } from './components';

export interface Props {
  isUserOpen: boolean;
  userAnchorEl?: object | null;
  handleUserClose: () => void;
}

export const TopRightMenu = ({
  isUserOpen: isOpen,
  userAnchorEl: anchorEl,
  handleUserClose: onClose,
}: Props) => {
  const router = useRouter();
  const { isDesktop } = useResize();
  const { actions } = useOrganizationContext();

  const handleLoginFinish: LoginSignupProps['loginProps']['onFinish'] =
    userInfo => {
      const matchedOrg = findMatchOrg(userInfo);

      if (matchedOrg) {
        actions.setOrg({
          newOrg: matchedOrg.organization,
          updateRoute: false,
          makeRequest: false,
        });
      }

      onClose();
    };

  const handleSignupFinish: LoginSignupProps['signupProps']['onFinish'] =
    () => {
      onClose();
      router.push('/account/create');
    };

  const menuProps = {
    handleSignupFinish,
    handleLoginFinish,
    isOpen,
    anchorEl,
    onClose,
  };

  useEffect(() => {
    if (isOpen) onClose();
  }, [router.pathname]);

  if (isDesktop) return <DesktopRightMenu {...menuProps} />;
  return <MobileRightMenu {...menuProps} />;
};
