/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react';

import {
  getPolygonBounds,
  paddedBoundingBox,
} from '@/features/myfleet/shared/utils';

import * as Consts from './consts';
import * as Types from './types';

// Create and Edit Geofences/Polygons
export const useGeofenceCreate: Types.UseGeofenceCreate = ({
  mapInstance,
  onPolygonComplete,
}) => {
  const [geofence, setGeofence] = useState<
    Types.UseGeofenceCreateReturn['geofence']
  >();

  // ref to preserve the event listeners for polygon
  const polygonRef = useRef<Types.CreatePolygonRef>({
    onEdit: () => {},
    polygon: undefined,
  });

  const drawingManager = useRef<google.maps.drawing.DrawingManager>(
    new google.maps.drawing.DrawingManager(Consts.defaultOptions)
  );

  // Data: so we can store each polygon shape in edit mode
  // and export the polygon in GeoJSON
  const dataLayer = new google.maps.Data();

  const toGEOJSON = (): void => {
    const poly = polygonRef.current?.polygon;
    if (poly) {
      const polygonFeature = new google.maps.Data.Feature({
        geometry: new google.maps.Data.Polygon([poly.getPath().getArray()]),
      });
      dataLayer.forEach(feat => {
        dataLayer.remove(feat);
      });
      dataLayer.add(polygonFeature);
      dataLayer.toGeoJson(object => {
        const { features } = object as GeoJSON.FeatureCollection;
        const [feature] = features;
        if (feature.geometry.type === 'Polygon') {
          setGeofence(feature.geometry);
        }
      });
    }
  };

  const removePolygon = (): void => {
    polygonRef.current?.polygon?.setMap(null);
    polygonRef.current = {
      polygon: undefined,
      onEdit: () => {},
    };
  };

  const onLoad: Types.UseGeofenceCreateReturn['onLoad'] = () => {
    drawingManager.current.setMap(mapInstance);
    drawingManager.current.setDrawingMode(
      google.maps.drawing.OverlayType.POLYGON
    );
  };

  const onClear: Types.UseGeofenceCreateReturn['onClear'] = () => {
    removePolygon();
    // remove previous data layers
    if (dataLayer) {
      dataLayer.forEach(feat => {
        dataLayer.remove(feat);
      });
    }
    drawingManager.current.setMap(mapInstance);
    drawingManager.current.setDrawingMode(
      google.maps.drawing.OverlayType.POLYGON
    );
  };

  const onUnmount: Types.UseGeofenceCreateReturn['onUnmount'] = () => {
    removePolygon();
    dataLayer.setMap(null);
    drawingManager.current.setDrawingMode(null);
    drawingManager.current.setMap(null);
  };

  const onDraw: Types.UseGeofenceCreateReturn['onDraw'] = () => {
    drawingManager.current.setDrawingMode(
      google.maps.drawing.OverlayType.POLYGON
    );
  };

  const onEdit = (): void => {
    drawingManager.current.setDrawingMode(null);
    if (polygonRef.current?.polygon) {
      toGEOJSON();
    }
  };

  /* ----- Polygon Completion Editing EventListeners ----- */
  // The listener must be set on each path.
  useEffect(() => {
    google.maps.event.addListener(
      drawingManager.current,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => {
        // map is still editable
        drawingManager.current.setDrawingMode(null);

        // add preserved listeners to ref
        polygonRef.current = {
          polygon,
          onEdit,
        };

        // attach listeners
        const path = polygon.getPath();
        path.addListener('drag', polygonRef.current.onEdit);
        path.addListener('dragEnd', polygonRef.current.onEdit);
        path.addListener('insert_at', polygonRef.current.onEdit);
        path.addListener('remove_at', polygonRef.current.onEdit);
        path.addListener('set_at', polygonRef.current.onEdit);

        const bounds = getPolygonBounds(polygon);
        const paddedBounds = paddedBoundingBox(bounds, 1.8);
        mapInstance?.fitBounds(paddedBounds as google.maps.LatLngBounds);

        // set to DataLayer
        toGEOJSON();
        onPolygonComplete?.(polygon);
      }
    );

    return () => {
      google.maps.event.clearInstanceListeners(drawingManager?.current);
    };
  }, [drawingManager.current, mapInstance]);

  return {
    geofence,
    onLoad,
    onClear,
    onDraw,
    onUnmount,
    polygonRef,
  };
};
