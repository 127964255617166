import PropTypes from 'prop-types';
import React from 'react';

import { useResize } from '@/hooks';

import * as Styles from './styles';

const Tabs = ({ children: childrenProp, onChange, value }) => {
  const { isMobile } = useResize();

  const handleChange = (e, newTab) => {
    onChange(e, newTab);
  };

  let childIndex = 0;
  const children = React.Children.map(childrenProp, child => {
    if (!child) {
      return child;
    }

    const childValue =
      child.props.value === undefined ? childIndex : child.props.value;
    const selected = childValue === value;

    childIndex += 1;

    return React.cloneElement(child, {
      selected,
      onChange: handleChange,
      value: childValue,
      mobile: isMobile,
    });
  });

  return <Styles.Tabs>{children}</Styles.Tabs>;
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default Tabs;
