import { heights, widths } from '@/theme';
import { SystemStyleObject } from '@/types';

export const profileContainer: SystemStyleObject = {
  borderBottom: '1px',
  borderColor: 'keylineGray',
  alignItems: 'center',
  m: { base: '1.5rem 1.5rem 1rem', lg: '1.5rem 1.5rem 2rem' },
  pb: 4,
};

// LeftNavMenuSection
export const menuSection: SystemStyleObject = {
  mb: '18px',
  mt: 3,
};

export const menuSectionTitle: SystemStyleObject = {
  display: 'flex',
  pb: '18px',
};

// LeftNavMenuContainer
export const mobileVariants = {
  open: {
    height: 'fit-content',
    position: 'static',
    transition: { delayChildren: 0 },
  },
  closed: {
    height: 0,
    position: 'absolute',
    transition: { delayChildren: 0 },
  },
};

export const desktopVariants = {
  open: {
    transition: { delayChildren: 0.8 },
  },
  closed: {
    transition: { delayChildren: 0.8 },
  },
};

export const menuNavigationCollapseSx: SystemStyleObject = {
  borderRight: '1px',
  borderColor: 'keylineGray',
  height: '100%',
  pb: { base: 2, lg: `${heights.topNav}px` },
  overflow: 'scroll',
};

export const collapseVariants = {
  open: {
    width: widths.leftNavOpen,
    transition: {
      x: { velocity: -100 },
    },
  },
  closed: {
    width: widths.leftNavClosed,
    transition: {
      x: { velocity: 0 },
    },
  },
};

export const buttonVariants = {
  open: {
    x: '321px',
  },
  closed: {
    x: '85px',
  },
};

export const buttonStyles: SystemStyleObject = {
  position: 'absolute',
  top: 3,
  h: 8,
  w: 8,
  zIndex: 'sticky',
  alignContent: 'center',
  justifyContent: 'center',
  color: 'white',
  ':focus, :active': { borderColor: 'white' },
  borderRadius: '100%',
  background: 'fluidBlue',
  display: { base: 'none', lg: 'flex' },
};

// LeftNavItem
export const ltrVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 100, velocity: -100 },
    },
  },
  closed: {
    x: -50,
    opacity: 0,
    display: 'none',
    transition: {
      x: { stiffness: 100, velocity: -100 },
    },
  },
};

export const topToBottom = {
  open: {
    y: 0,
    opacity: 1,
    height: '100%',
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: -50,
    opacity: 0,
    height: 0,
    transition: {
      y: { stiffness: 1000, velocity: 100 },
    },
  },
};

export const itemStyles: SystemStyleObject = {
  cursor: 'pointer',
  _active: {
    '> *': {
      color: 'fluidBlue',
      fontWeight: 'bold',
    },
  },
  _hover: {
    bg: 'lightGray',
  },
  py: '14px',
  display: 'flex',
  alignItems: 'center',
};

export const titleColumn: SystemStyleObject = {
  display: 'grid',
  div: { justifySelf: 'end' },
};

export const iconStyles: SystemStyleObject = {
  alignSelf: 'center',
  h: '18px',
  w: '18px',
};

export const rightIconStyles: SystemStyleObject = {
  h: '10px',
  marginLeft: '5px',
  marginTop: '-10px',
  w: '10px',
};
