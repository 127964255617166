import React, { ReactElement } from 'react';

import {
  Box,
  BoxProps,
  Grid,
  GridItem as TidalGridItem,
  GridItemProps,
  GridProps,
  Heading,
  HeadingProps,
  SpanText,
  SubTitle,
  TextProps,
} from '@/base-components';
import { SystemStyleObject } from '@/types';

export const BannerMainContainer = (props: BoxProps): ReactElement => {
  const styles: SystemStyleObject = {
    backgroundImage: {
      base: 'url("/img/SafeDriverCup.svg"), radial-gradient(circle at center, rgba(0,88,133,1) 0%, rgba(0,26,41,1) 90%)',
      md: 'url("/img/SafeDriverCup.svg"), radial-gradient(circle at right, rgba(0,88,133,1) 0%, rgba(0,26,41,1) 90%)',
    },
    backgroundPosition: {
      base: '50% 20%',
      md: 'right 21% bottom 36%',
      xl: 'right 21% bottom 36%',
      '2xl': 'right 21% bottom 36%',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: {
      base: '300px, cover',
      md: '350px, cover',
      xs: '250px, cover',
    },
    pb: { base: 8, md: 16 },
    pt: { base: 80, md: 16 },
    position: 'relative',
    width: '100%',
  };
  return <Box sx={styles} {...props} />;
};

export const BannerMainContent = (props: GridProps): ReactElement => {
  const styles: SystemStyleObject = {
    color: 'white',
    zIndex: 'banner',
    alignItems: 'center',
    textAlign: { base: 'center', md: 'left' },
  };
  return (
    <Grid
      templateColumns={{
        sm: '24px auto 24px',
        md: '10% 1.6fr 1fr',
        lg: '12% 1.8fr 1.2fr', // 992
        xl: '15.75em 1.8fr 1.5fr', // 1280
        '2xl': '18% 1.4fr 1.5fr', // 1536
      }}
      templateRows="repeat(6,auto)"
      sx={styles}
      {...props}
    />
  );
};

export const BannerTeamPageContainer = (props: BoxProps): ReactElement => {
  const styles: SystemStyleObject = {
    backgroundImage: {
      base: 'url("/img/SafeDriverCup.svg"), radial-gradient(circle at center, rgba(0,88,133,1) 0%, rgba(0,26,41,1) 90%)',
      md: 'url("/img/SafeDriverCup.svg"), radial-gradient(circle at right, rgba(0,88,133,1) 0%, rgba(0,26,41,1) 90%)',
    },
    backgroundPosition: {
      base: '50% 20%',
      md: 'right 0% bottom 30%',
      xl: 'right 15% bottom 30%',
      '2xl': 'right 30% bottom 30%',
    },
    backgroundRepeat: 'no-repeat',
    pb: { base: 8, md: 16 },
    pt: { base: 80, md: 16 },
    position: 'relative',
    width: '100%',
  };
  return <Box sx={styles} {...props} />;
};

export const BannerTeamPageContent = (props: GridProps): ReactElement => {
  const styles: SystemStyleObject = {
    color: 'white',
    zIndex: 'banner',
    alignItems: 'center',
    textAlign: { base: 'center', md: 'left' },
    px: { base: 4, lg: 0 },
  };
  return (
    <Grid
      templateColumns={{
        sm: '24px auto 24px',
        md: '5em 1fr', // 60
        lg: '5em 1fr', // 993
        xl: '5em 1.8fr 23em', // 1280
        '2xl': '5em .8fr',
      }}
      templateRows="repeat(5,auto)"
      sx={styles}
      {...props}
    />
  );
};

export const GridRow = (props: GridItemProps): ReactElement => {
  return (
    <TidalGridItem
      justifySelf={{ base: 'center', md: 'left' }}
      colStart={2}
      colEnd={2}
      {...props}
    />
  );
};

export const GridRowHidden = (props: GridItemProps): ReactElement => {
  return <GridRow display={{ base: 'none', md: 'inherit' }} {...props} />;
};

export const BannerHeading = (props: HeadingProps): ReactElement => {
  const styles: SystemStyleObject = {
    fontSize: { base: '2.5rem', xl: '7xl' },
    lineHeight: '48px',
    fontWeight: 600,
  };
  return <Heading sx={styles} {...props} />;
};

export const BannerRow = (props: GridItemProps): ReactElement => {
  return <GridRow {...props} />;
};

export const BannerRowPrizes = (props: GridItemProps): ReactElement => {
  const styles: SystemStyleObject = {
    fontSize: '2xl',
    mb: 5,
  };
  return <GridRow sx={styles} {...props} />;
};

export const RewardsTitle = (props: HeadingProps): ReactElement => (
  <SubTitle fontSize={{ base: '2xl', lg: 'lg' }} {...props} />
);

export const SpanSeperator = (props: TextProps): ReactElement => (
  <SpanText letterSpacing="1px" {...props}>
    {' '}
    |{' '}
  </SpanText>
);
