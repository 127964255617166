import {
  Button as TidalButton,
  ButtonGroup as TidalButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  CloseButton as TidalCloseButton,
  CloseButtonProps,
  forwardRef,
  HStack,
  IconButton as TidalIconButton,
  IconButtonProps,
  StackProps,
} from '@fluidtruck/core';
import { CloseIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';

import { ampli, ButtonClickedProperties } from '@/amplitude';
// eslint-disable-next-line import/no-cycle
import { useUser } from '@/hooks';

/** Button
 * https://tidal.fluidtruck.com/docs/form/button
 * https://tidal.fluidtruck.com/docs/form/icon-button
 */

export type { ButtonProps, IconButtonProps };

export const onClickAmplitude = ({
  buttonText,
  pageName,
  entityId,
  userId,
  orgId,
}: ButtonClickedProperties) => {
  ampli.buttonClicked({
    buttonText,
    pageName,
    entityId,
    userId,
    orgId,
  });
};

export const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const router = useRouter();
  const user = useUser();
  return (
    <TidalButton
      ref={ref}
      {...props}
      onClick={e => {
        onClickAmplitude({
          buttonText: e.currentTarget?.innerText || props?.['aria-label'],
          pageName: window.document.title,
          entityId: Object.entries(router.query),
          userId: user?.user?.id,
          orgId: user?.user?.['default-organization-id'],
        });
        props.onClick?.(e);
      }}
    />
  );
});

export type { CloseButtonProps };
export const CloseButton = forwardRef<CloseButtonProps, 'button'>(
  (props, ref) => {
    const router = useRouter();
    const user = useUser();
    return (
      <TidalCloseButton
        {...props}
        ref={ref}
        onClick={e => {
          onClickAmplitude({
            buttonText: 'Close Button',
            pageName: window.document.title,
            entityId: Object.entries(router.query),
            userId: user?.user?.id,
            orgId: user?.user?.['default-organization-id'],
          });
          props.onClick?.(e);
        }}
      />
    );
  }
);

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  (props, ref) => {
    const router = useRouter();
    const user = useUser();
    return (
      <TidalIconButton
        {...props}
        ref={ref}
        onClick={e => {
          onClickAmplitude({
            buttonText: props?.['aria-label'] || props?.name || 'Icon Button',
            pageName: window.document.title,
            entityId: Object.entries(router.query),
            userId: user?.user?.id,
            orgId: user?.user?.['default-organization-id'],
          });
          props.onClick?.(e);
        }}
      />
    );
  }
);

export const PillButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const router = useRouter();
  const user = useUser();
  return (
    <TidalButton
      borderRadius="xl"
      lineHeight="normal"
      px={3}
      ref={ref}
      size="xs"
      textTransform="uppercase"
      {...props}
      onClick={e => {
        onClickAmplitude({
          buttonText:
            e.currentTarget?.innerText ||
            props?.['aria-label'] ||
            'Pill Button',
          pageName: window.document.title,
          entityId: Object.entries(router.query),
          userId: user?.user?.id,
          orgId: user?.user?.['default-organization-id'],
        });
        props.onClick?.(e);
      }}
    />
  );
});

export const AlertButton = (props: ButtonProps): ReactElement => {
  return <Button colorScheme="red" {...props} />;
};

export const SubmitButton = (props: ButtonProps): ReactElement => {
  return <Button isFullWidth mt={6} type="submit" {...props} />;
};

export const LinkButton = (props: ButtonProps): ReactElement => {
  return <Button size="xs" variant="link" isFullWidth {...props} />;
};

export const ButtonGroup = forwardRef<ButtonGroupProps, 'div'>((props, ref) => {
  const { isAttached, spacing = 2 } = props;
  const styles = {
    flexDirection: { base: 'column', lg: 'row' },
    '& > *:not(style) ~ *:not(style)': {
      marginStart: { base: 0, lg: spacing },
      marginTop: { base: 3, lg: 0 },
    },
  };
  if (!isAttached) {
    return <TidalButtonGroup ref={ref} sx={{ ...styles }} {...props} />;
  }
  return <TidalButtonGroup ref={ref} {...props} />;
});

export const ButtonRow = (props: StackProps): ReactElement => {
  const { spacing = 3 } = props;
  return <HStack my={4} spacing={spacing} {...props} />;
};

export const CancelApplyButtons = ({
  onClose,
  onSubmit,
  submitId,
}: {
  onClose: () => void;
  onSubmit: () => void;
  submitId?: string;
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <HStack my={4} spacing={3}>
      <Button w="6.25rem" onClick={onSubmit}>
        {t('button.apply')}
      </Button>
      <Button data-test-id={submitId} onClick={onClose} variant="link">
        <CloseIcon h="1.5rem" w="1.5rem" />
        {t('button.cancel')}
      </Button>
    </HStack>
  );
};

export * from './SelectButtonGroup';
