import { Trans, useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ApiRoute, usePOST, usePUT } from '@/api-resources';
import {
  AlertGroup,
  Button,
  Center,
  DialogHeader,
  Form,
  FormInputGroup,
  GrayText,
  SubmitButton,
  VStack,
} from '@/base-components';
import { useBoolean } from '@/utils';

interface SecurityCodeProps {
  handleSetStep: (value: number) => void;
  onClose: () => void;
  step: number;
}

type SecurityCodeReturn = (props: SecurityCodeProps) => ReactElement;

const SecurityCode: SecurityCodeReturn = props => {
  const { onClose, handleSetStep } = props;
  const [isLoading, setLoading] = useBoolean();
  const { t } = useTranslation();
  const {
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
    setValue,
    register,
  } = useFormContext();

  const { emailPhone } = watch();
  const { password } = getValues();

  const isEmail = emailPhone?.includes('@');
  const [securityCodeFail, setSecurityCodeFail] = useState<string | null>(null);

  const postForgotPassword = usePOST({
    path: ApiRoute.API_USERS_FORGOT_PASSWORD,
  });
  const putVerifyPassword = usePUT({
    path: ApiRoute.API_USERS_VERIFY_PASSWORD,
  });

  const submit = async (): Promise<void> => {
    setLoading.on();
    const body = {
      code: watch('securityCode'),
    };
    await putVerifyPassword.handler({
      body,
      onSuccess: async res => {
        setValue('emailPhone', res?.data?.email);
        setLoading.off();
        handleSetStep(3);
      },
      onError: async res => {
        const details = isEmail
          ? t('login.code.resend')
          : t('login.code.goBackConfirm');
        setSecurityCodeFail(
          t('common:login.code.invalid', {
            details,
          })
        );
        console.warn(`Security Code Error: ${res?.message}`);
        setLoading.off();
      },
    });
  };

  const handleClick = async (): Promise<void> => {
    if (isEmail) {
      setLoading.on();
      const body = {
        username: emailPhone,
        password,
      };
      await postForgotPassword.handler({
        body,
        onError: () => setSecurityCodeFail(t('common:login.code.resendErr')),
        onSuccess: () => setLoading.off(),
      });
    } else {
      handleSetStep(1);
    }
  };

  const codeInvalid = Boolean(errors?.securityCode);
  const codeLabel = codeInvalid
    ? t('login.code.isInvalid')
    : t('login.code.enterCode');

  return (
    <>
      {securityCodeFail && (
        <AlertGroup title={securityCodeFail || t('login.code.goBackConfirm')} />
      )}
      {!emailPhone && <AlertGroup title={t('login.code.goBackConfirm')} />}
      <Form onSubmit={handleSubmit(submit)} height="100%">
        <Center flexDir="column" height="100%">
          <DialogHeader
            hideMobileClose
            header={t('login.code.securityCode')}
            onClose={onClose}
          />
          {isEmail && !!emailPhone && (
            <GrayText mt={6}>
              <Trans t={t} i18nKey="login.code.sentTo">
                We&apos;ve sent a security code to{' '}
                <strong>{{ emailPhone }}</strong>
              </Trans>
            </GrayText>
          )}
          {!isEmail && <GrayText mt={6}>{t('login.code.resentText')}</GrayText>}
          <FormInputGroup
            mt={6}
            label={codeLabel}
            isInvalid={Boolean(errors?.securityCode)}
            inputProps={{
              'data-test-id': 'input-code',
              ...register('securityCode', {
                required: true,
                minLength: 6,
              }),
            }}
          />
          <VStack width="100%" mt={6} spacing={3}>
            <Button
              isFullWidth
              variant="outline"
              isLoading={isLoading}
              onClick={handleClick}
            >
              {isEmail ? t('login.code.resendIt') : t('button.goBack')}
            </Button>
            <SubmitButton
              isLoading={isLoading}
              data-test-id="button-code-submit"
            >
              {t('button.submit')}
            </SubmitButton>
          </VStack>
        </Center>
      </Form>
    </>
  );
};

export default SecurityCode;
