import styled from '@emotion/styled';
import { ChevronLeftIcon, ChevronRightIcon } from '@fluidtruck/icons';
import PropTypes from 'prop-types';
import React from 'react';

import PaginationDot from './PaginationDot';

const PaginationDiv = styled.div({
  bottom: 8,
  right: 8,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 16,
  svg: {
    margin: '0 16px',
    cursor: 'pointer'
  }
});

export const Pagination = ({ onChangeIndex, index, dots }) => {
  const children = [];

  const onArrowLeftClick = () => {
    if (index === 0 || index > dots) {
      return null;
    }
    return onChangeIndex(index - 1);
  };
  const onArrowRightClick = () => {
    if (index < 0 || index === dots - 1) {
      return null;
    }
    return onChangeIndex(index + 1);
  };

  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot key={`${i}-dot`} index={i} active={i === index} />,
    );
  }

  return (
    <Wrapper>
      <div role="presentation" onClick={onArrowLeftClick}>
        <ChevronLeftIcon color="fluidBlue" />
      </div>
      <PaginationDiv>{children}</PaginationDiv>
      <div role="presentation" onClick={onArrowRightClick}>
        <ChevronRightIcon color="fluidBlue" />
      </div>
    </Wrapper>
  );
};

Pagination.propTypes = {
  dots: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func,
};
