/**
 * useKrakenSwr module.
 * @module hooks/use-swr
 * Also, check out {@link https://swr.vercel.app/ SWR Docs}
 */
import type { BareFetcher, Key, SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';

interface Props<T> {
  key: Key;
  fetcher: BareFetcher<T>;
  isReadyToFetch?: boolean;
  options?: SWRConfiguration<T>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SwrReturn<T> extends SWRResponse<T> {}
/**
 * A common SWR hook for Fluid Truck
 * @function
 * @param {string, array} key - The url "key" for SWR Url.  This is a single url string or array of [multiple arguments](https://swr.vercel.app/docs/arguments#multiple-arguments)
 * @param {function} fetcher - The "GET" function SWR consumes to make the fetch
 * @param {isReadyToFetch} boolean optional - Conditionally add the URL to the cache based on your params
 * @param {object} options - Any additional [options](https://swr.vercel.app/docs/options) for SWR
 * @returns {object} An object based on the SWR response: SwrReturn
 */

export const useKrakenSwr = <T>({
  key: urlkey,
  fetcher,
  isReadyToFetch = true,
  options,
}: Props<T>): SwrReturn<T> => {
  const url = isReadyToFetch ? urlkey : null;

  const { data, error, isLoading, isValidating, mutate } = useSWR<T>(
    url,
    fetcher,
    options
  );

  return {
    data,
    error,
    isValidating,
    isLoading,
    mutate,
  };
};
