import { ApiResourcePUT } from '@/api-resources/resources/resourcePUT';
import { urlEmbedParams, urlEmbedQuery } from '@/api-resources/utils';
import { postPutPatch } from '@/utils/helpers';

import { HandlerContract } from '../types';

export function usePUT<TPath extends keyof ApiResourcePUT>(props: {
  path: TPath;
}) {
  const handler = async (
    args: Omit<ApiResourcePUT[TPath][0], 'path'> &
      HandlerContract<ApiResourcePUT[TPath][1]>
  ) => {
    try {
      let url: string = props.path;

      if (args.params) {
        url = urlEmbedParams(url, args.params);
      }

      if (args.query) {
        url = urlEmbedQuery(url, args.query);
      }

      const response:
        | ApiResourcePUT[TPath][1]['success']
        | ApiResourcePUT[TPath][1]['fail'] = await postPutPatch('PUT')(
        url,
        args.body,
        args.headers
      );

      if ('messages' in response || 'message' in response) {
        const fail = response as any;
        if (fail?.message) throw new Error(fail.message);
        if (fail?.messages) throw new Error(JSON.stringify(fail?.messages));
        throw new Error('failed to complete PUT request');
      }

      args?.onSuccess?.(response);

      return response;
    } catch (err) {
      if (err instanceof Error) {
        args?.onError?.(err);
      } else {
        args?.onError?.(new Error('failed to complete POST request'));
      }

      return undefined;
    }
  };

  return { handler };
}
