import useSWR from 'swr';

import { get } from '@/utils/helpers';

import { UseFleetSwrModel } from '../use-fleet-vehicles';
import * as Types from './types';

export const useFleetItemDetailsSwr: Types.UseFleetItemDetailsSwr = ({
  params,
  config,
}) => {
  const swr = useSWR<Types.FleetItem>(
    params.orgId && params.itemId
      ? `api/myfleet/v3/${params.orgId}/vehicles`
      : null,
    url =>
      get(url).then((res: UseFleetSwrModel) => {
        return res.vehicles?.find(
          VEH => VEH.vehicle.id.toString() === (params.itemId as string)
        ) as Types.FleetItem;
      }),
    config
  );

  return swr;
};
