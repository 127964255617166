import {
  ResponsiveTable as Table,
  RTbody as Tbody,
  RTd as Td,
  RTh as Th,
  RThead as Thead,
  RTr as Tr,
} from '@fluidtruck/core';
import { CaretDownIcon, CaretUpIcon } from '@fluidtruck/icons';
import React from 'react';

import {
  AlertGroup,
  Button,
  FlexCol,
  Grid,
  SpinnerContainer as Spinner,
  SubHeading,
} from '@/base-components';
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';

import { Search } from '../search';
import { Pagination } from './pagination';
import type {
  HeaderLabelProps,
  RowsPerPageOptions,
  TidalVaporTableProps,
} from './types';
import { SORT_ORDER } from './types';
import { useTidalTableContext } from './useTidalTable';

const HeaderLabel = ({
  sortField,
  id,
  label,
  sortable = true,
  isASC,
  ...rest
}: HeaderLabelProps) => {
  return (
    <Button
      isDisabled={!sortable}
      variant="link"
      sx={{
        _disabled: { color: 'black' },
        cursor: !sortable ? 'default' : 'cursor',
        fontSize: 'sm',
      }}
      {...rest}
    >
      {sortable &&
        sortField === id &&
        (isASC ? <CaretDownIcon /> : <CaretUpIcon />)}
      {label}
    </Button>
  );
};

export const TidalVaporTable = <T extends Record<string, any>>({
  data,
  sortField: sortFieldProps,
  sortOrder: sortOrderProps,
  rowsPerPage: rowsPerPageProp,
  columns,
  headRows,
  rowsPerPageOptions = [20, 50, 100],
  totalCount,
  hideSearch,
  onChange: onChangeProp,
  pagination,
  styles,
  loading,
  tableProps,
  title,
}: TidalVaporTableProps<T>) => {
  const { state, methods } = useTidalTableContext();
  const { searchTerm, sortField, sortOrder, page, rowsPerPage = 20 } = state;

  const { setSortField, setSortOrder, setPage, setRowsPerPage, setSearchText } =
    methods;

  /* ----- HANDLERS ----- */
  const debounced = useDebouncedCallback(text => {
    setSearchText(text);
    // if search term is not empty, then default to relevance search
    onChangeProp?.({
      page,
      rowsPerPage: rowsPerPageProp,
      sortOrder: sortOrderProps,
      sortField: sortFieldProps,
      searchTerm: text || '',
    });
  }, 500);

  const shared = {
    searchTerm,
    sortField,
    sortOrder,
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    onChangeProp?.({
      ...shared,
      rowsPerPage,
      page: newPage,
    });
  };

  const handleRowsChange = (size: number) => {
    setRowsPerPage(size as RowsPerPageOptions);
    onChangeProp?.({
      ...shared,
      page,
      rowsPerPage: size,
    });
  };

  const directionASC = sortOrder === SORT_ORDER.ASC;

  const handleRequestSort = (newSortField: string) => {
    const _default = {
      page,
      searchTerm,
      rowsPerPage,
    };
    if (sortField === newSortField) {
      setSortOrder(directionASC ? SORT_ORDER.DESC : SORT_ORDER.ASC);
      onChangeProp?.({
        ..._default,
        sortField,
        sortOrder: directionASC ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      });
    } else {
      setSortField(newSortField);
      onChangeProp?.({
        ..._default,
        sortOrder,
        sortField: newSortField,
      });
    }
  };

  /* ----- PROPS ----- */
  const paginationProps = {
    selectableSizes: rowsPerPageOptions,
    count: totalCount,
    pageSize: rowsPerPage,
    pageNumber: page,
    onPageChange: handlePageChange,
    onSizeChange: handleRowsChange,
    sx: { justifyContent: 'start' },
  };

  const noData = !loading && !data?.length;
  // NOTE: hide the header div if these values do not exist
  const hideHeader = !title && hideSearch && !pagination?.top;

  const getRowStyle = (rowData: T) => (styles?.row ? styles?.row(rowData) : {});

  return (
    <FlexCol sx={styles?.container} gap={8}>
      {!hideHeader && (
        <Grid
          gap={4}
          paddingEnd={3}
          paddingStart={!!hideSearch ? 0 : 3}
          id="tidal-table-header"
          justifyContent="start"
          templateColumns="repeat(2, auto)"
        >
          <Grid
            gap={title ? 6 : 0}
            templateColumns="repeat(2, auto)"
            alignItems="center"
          >
            {title && <SubHeading fontSize="xl">{title}</SubHeading>}
            {!hideSearch && (
              <Search
                isLoading={loading}
                onChange={e => debounced(e.target.value)}
              />
            )}
          </Grid>
          {pagination?.top && !noData && <Pagination {...paginationProps} />}
        </Grid>
      )}

      <Table size="sm" {...tableProps}>
        <Thead>
          <Tr>
            {headRows.map(row => (
              <Th key={row.id} textAlign={row?.align || 'start'}>
                <HeaderLabel
                  sortField={sortField}
                  id={row.id}
                  isASC={directionASC}
                  sortable={row.sortable !== false}
                  color={sortField === row.id ? 'fluidBlue' : 'black'}
                  aria-label={sortOrder}
                  label={row.label}
                  onClick={
                    row.sortable !== false
                      ? () => handleRequestSort(row.id)
                      : undefined
                  }
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading && (
            <Tr>
              <Td colSpan={columns.length}>
                <Spinner h={20} />
              </Td>
            </Tr>
          )}
          {!noData &&
            data?.map(ROW => (
              <Tr
                data-test-id={`tablerow-${ROW?.id}`}
                key={`${ROW?.field}-${Math.random()}`}
                sx={getRowStyle(ROW)}
              >
                {columns.map(COL => (
                  <Td key={COL.id} textAlign={COL?.align || 'start'}>
                    {COL.field &&
                      COL.formatter?.(ROW[COL?.field as keyof typeof ROW])}
                    {!COL.field && COL.formatter?.(ROW)}
                    {!COL.formatter && (
                      <span>
                        {ROW[COL.field as keyof typeof ROW] || COL.default}
                      </span>
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          {noData && (
            <Tr>
              <Td colSpan={columns.length}>
                <AlertGroup
                  status="warning"
                  description="No Data"
                  justifyContent="center"
                />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      <Grid justifyContent="start" p={pagination?.bottom ? 6 : 2}>
        {pagination?.bottom && !noData && <Pagination {...paginationProps} />}
      </Grid>
    </FlexCol>
  );
};
