import { Box, Tab, TabList, TabPanels } from '@fluidtruck/core';

import * as Vars from '@/theme/variables';
import { chakra } from '@/utils';

export const SignUpLoginContainer = chakra('div', {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    minW: { base: '100%', xs: `${Vars.widths.loginInner}px` },
  },
  label: 'SignUpLoginContainer',
});

export const ForgotPasswordSignUp = chakra(Box, {
  baseStyle: {
    // py: 8,
    height: ['100%', 'calc(100% - 40px)'],
    maxW: { base: '100%', xs: `${Vars.widths.loginInner}px` },
    margin: 'auto',
  },
  label: 'ForgotPasswordSignUp',
});

export const LoginTab = chakra(Tab, {
  baseStyle: {
    _focus: { boxShadow: 'none' },
    px: 0,
  },
  label: 'LoginTab',
});

export const LoginTabPanels = chakra(TabPanels, {
  baseStyle: {
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
  },
  label: 'LoginTabPanels',
});

export const LoginTabList = chakra(TabList, {
  baseStyle: {
    margin: 'auto',
    justifyContent: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gridColumnGap: 10,
    // pl: 1,
  },
  label: 'LoginTabList',
});
