import { useContext } from 'react';

import { SignUpContext, SignupShape } from '@/lib/context/SignUpContext';

export const useSignupContext = (): SignupShape => {
  const context = useContext(SignUpContext);

  if (context === undefined) {
    throw new Error('useSignupContext must be used within a Signup Provider');
  }

  return context;
};
