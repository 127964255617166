import {
  GridItem,
  SystemStyleObject,
  TimePicker as TidalTimePicker,
  TimePickerProps as TidalTimePickerProps,
} from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { SmallText } from '../../typography/Common';

type TimePickerProps = Omit<TidalTimePickerProps, 'ref'> &
  Omit<ControllerProps, 'render'>;

interface TPP extends TimePickerProps {
  dataTestId?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  sx?: SystemStyleObject;
}

export const TimePickerController = (props: TPP): JSX.Element => {
  const {
    name: nameProp,
    dataTestId,
    rules,
    defaultValue: defaultValueProp = '',
    value: valueProp,
    error,
    sx,
  } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const defaultValue =
    typeof valueProp === 'number' ? valueProp : defaultValueProp;

  return (
    <Controller
      control={control}
      name={nameProp}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <GridItem sx={sx}>
            <TidalTimePicker
              isInvalid={!!fieldState.error}
              isDisabled={!!fieldState.error}
              data-test-id={dataTestId}
              {...field}
            />
            {error && (
              <SmallText pt={2} color="red.500">
                {t('required')}
              </SmallText>
            )}
          </GridItem>
        );
      }}
    />
  );
};
