import { useCallback, useState } from 'react';

import * as Mappings from '../mappings';
import * as Types from '../types';

export const useFleetItemMapEventsState: Types.UseFleetItemMapEventsState = map => {
  const [fleetEventMarkers, setFleetEventMarkers] = useState<
    google.maps.Marker[]
  >();

  const onIntialize: Types.UseFleetItemMapEventsStateReturn['onIntialize'] = useCallback(
    fleetEvents => {
      if (!fleetEvents) return;

      const newFleetEvents = fleetEvents.map(EVENT => {
        // create marker
        const fleetEventMarker = Mappings.mapFleetEvent2GoogleMarker({
          ...EVENT,
          icon: Mappings.mapFleetEventCode2GoogleIcon(EVENT.greenDrivingType),
        });
        fleetEventMarker.setMap(map);

        return fleetEventMarker;
      });

      setFleetEventMarkers(newFleetEvents);
    },
    [map]
  );

  const onClear: Types.UseFleetItemMapEventsStateReturn['onClear'] = () => {
    setFleetEventMarkers(state => {
      return state?.map(EVENT => {
        EVENT.setMap(null);
        google.maps.event.clearInstanceListeners(EVENT);
        return EVENT;
      });
    });
  };

  return {
    onIntialize,
    onClear,
    fleetEventMarkers,
  };
};
