import { TabPanel, Tabs } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { ampli } from '@/amplitude';
import { SubTitle } from '@/base-components';
import { ForgotPassword } from '@/components/ForgotPassword';

import { Login, LoginProps } from './Login';
import { Signup, SignupProps } from './Signup';
import * as Styles from './styles';

export interface LoginSignupProps {
  loginProps: Omit<LoginProps, 'setForgotPassword'>;
  signupProps: SignupProps;
  onAccept?: () => Promise<void>;
}

type Component = (props: LoginSignupProps) => JSX.Element;

export const LoginSignup: Component = ({
  loginProps,
  signupProps,
  onAccept,
}) => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const { t } = useTranslation();

  const router = useRouter();

  const isSignupPage = !!router.pathname.includes('signup');

  const pageIndex = isSignupPage ? 1 : 0;

  if (forgotPassword) {
    return (
      <Styles.ForgotPasswordSignUp>
        <ForgotPassword setForgotPassword={setForgotPassword} />
      </Styles.ForgotPasswordSignUp>
    );
  }

  return (
    <>
      <Tabs isLazy defaultIndex={pageIndex}>
        <Styles.LoginTabList>
          <Styles.LoginTab
            data-test-id="tab-login"
            onClick={() => ampli.loginTabClick()}
          >
            <SubTitle fontSize="3xl">{t('login.logIn')}</SubTitle>
          </Styles.LoginTab>
          <Styles.LoginTab
            data-test-id="tab-signup"
            onClick={() => ampli.signupTabClick()}
          >
            <SubTitle fontSize="3xl">{t('signup.signUp')}</SubTitle>
          </Styles.LoginTab>
        </Styles.LoginTabList>
        <Styles.LoginTabPanels>
          <TabPanel px={0}>
            <Login
              {...loginProps}
              setForgotPassword={setForgotPassword}
              onAccept={onAccept}
            />
          </TabPanel>
          <TabPanel px={0}>
            <Signup {...signupProps} />
          </TabPanel>
        </Styles.LoginTabPanels>
      </Tabs>
    </>
  );
};
