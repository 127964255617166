import { cvv, number } from 'card-validator';
import moment from 'moment';

export const validator = trigger => e => trigger(e.target.name);

export const cardNumberRegistry = {
  required: true,
  validate: value => number(value)?.isValid || false,
};

export const cardDateRegistry = {
  required: true,
  validate: value => moment().startOf('month') < moment(value, 'MM/YY'),
};

export const cardCvvRegistry = {
  required: true,
  validate: value => cvv(value) || false,
};

export const emailPhoneFormat = /^(?!\s)(?!.*\s$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|([0-9]{10})+$/;

export const emailRegistry = {
  required: true,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
    message: 'Invalid Email Address',
  },
};

export const zipRule = {
  pattern: {
    value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
    message: 'Invalid Zip Code',
  },
};

export const phoneRule = {
  pattern: {
    value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\s*$/,
    message: 'Invalid Phone Number',
  },
};
