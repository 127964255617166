import { useEffect, useRef } from 'react';

/**
 * @what -
 * - debouncing hook with using library.  Works well with Chakra AsyncSelect.
 * @example
 * const loadOptions = useDebouncedCallback(async (input, cb) => {
 * if(!input) return;
 * fetch(`/api?${input}`)
 *  .then(res => res.json())
 *  .then(res => cb(res))
 * }, 500)
 *
 * <AsyncSelect loadOptions={loadOptions} />
 */
export function useDebouncedCallback<A extends any[]>(
  callback: (...args: A) => void,
  wait: number
) {
  // track args & timeout handle between calls
  const argsRef = useRef<A>();
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  function cleanup() {
    if (!timeout.current) return;
    clearTimeout(timeout.current);
  }

  // make sure our timeout gets cleared if
  useEffect(() => cleanup, []);

  return function debouncedCallback(...args: A) {
    // capture latest args
    argsRef.current = args;

    // clear debounce timer
    cleanup();

    // start waiting again
    timeout.current = setTimeout(() => {
      if (!argsRef.current) return;
      callback(...argsRef.current);
    }, wait);
  };
}
