import { Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Cookies from 'js-cookie';
import throttle from 'lodash.throttle';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { SpinnerContainer } from '@/base-components';
import { ItemsContext } from '@/lib/context';

const autocompleteService = { current: null };

// TODO: Convert to .tsx
const LocationAutocomplete = props => {
  const { setLocationAddress, location, testId, className } = props;
  const { t } = useTranslation('search');
  const { locationBlocked, updateLocationBlocked, userLocation } =
    useContext(ItemsContext);

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedUserPosition, setFetchedUserPosition] = useState(userLocation);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const initialValue = !isInitialLoad && location;
  const [value, setValue] = useState(initialValue);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService?.current && window?.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.geocoder = new window.google.maps.Geocoder();
    }

    if (inputValue === '' && !locationBlocked) {
      setOptions(
        value ? [value] : [{ description: t('autoComplete.currentLocation') }]
      );
      return undefined;
    }

    if (inputValue === '' && locationBlocked) {
      setOptions(
        value ? [value] : [{ description: t('autoComplete.pleaseSearch') }]
      );
      return undefined;
    }

    if (!autocompleteService?.current) {
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];
        if (
          value &&
          !value?.description
            ?.toLowerCase()
            .includes(t('location').toLowerCase())
        ) {
          newOptions = newOptions?.concat(value);
        }

        if (results) {
          newOptions = newOptions?.concat(results);
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleFindAddress = (
    lat = fetchedUserPosition?.lat,
    lng = fetchedUserPosition?.lng
  ) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      const geoResults = results?.[0];

      if (status === 'OK' && geoResults?.formatted_address) {
        const newLocation = {
          lat,
          lng,
          address: geoResults?.formatted_address,
          description: geoResults?.formatted_address,
        };

        Cookies.set('allowedLocation', newLocation);
        setLocationAddress({
          placeId: geoResults?.place_id,
          lat,
          lng,
          address: geoResults?.formatted_address,
          description: geoResults?.formatted_address,
        });

        setValue(geoResults?.formatted_address);
        setInputValue(geoResults?.formatted_address);
        setIsLoading(false);
      }

      setIsLoading(false);
    });
  };
  const handleGetLocation = () => {
    if (!isInitialLoad) {
      return;
    }

    if (navigator) {
      navigator?.geolocation?.getCurrentPosition(
        position => {
          const { latitude, longitude } = position?.coords || {};
          setIsLoading(true);
          updateLocationBlocked(false);
          setIsInitialLoad(false);
          setFetchedUserPosition({
            lat: latitude,
            lng: longitude,
          });

          return handleFindAddress(latitude, longitude);
        },
        error => {
          setIsLoading(true);
          setIsInitialLoad(false);

          if (error?.message) {
            Cookies.remove('allowedLocation');
            updateLocationBlocked(true);
            return setIsLoading(false);
          }
          return setIsLoading(false);
        }
      );
    }
  };

  const handleSelectOption = innerText => {
    if (
      innerText &&
      innerText.includes(t('autoComplete.currentLocation')) &&
      !locationBlocked
    ) {
      handleGetLocation();
      setIsLoading(true);
      return handleFindAddress(
        fetchedUserPosition?.lat,
        fetchedUserPosition?.lng
      );
    }

    return null;
  };

  useEffect(() => {
    if (Cookies.getJSON('allowedLocation')) {
      const currentLocation = Cookies.getJSON('allowedLocation');

      setIsInitialLoad(false);
      setValue(location?.address);
      setInputValue(location?.address);

      setFetchedUserPosition({
        lat: currentLocation?.lat,
        lng: currentLocation?.lng,
      });
    }
  }, []);

  useEffect(() => {
    const newVal = { ...location };
    if (!newVal.description) {
      newVal.description = newVal.address;
    }
    setValue(newVal);

    // changes default dropdown option from 'Use Current Location' to 'Please Search For A Location'
    navigator?.permissions?.query({ name: 'geolocation' }).then(status => {
      if (status?.state === 'denied') {
        updateLocationBlocked?.(true);
        setValue(location?.address);
      }
    });
  }, [location]);

  if (isLoading) return <SpinnerContainer />;

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      id="google-map-demo"
      className={className}
      getOptionSelected={option => {
        if (typeof option === 'string') return option;
        return option.description;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }

        if (!option.description) {
          return '';
        }

        const descriptions = [
          t('autoComplete.useCurrentLocation'),
          t('autoComplete.pleaseSearch'),
        ];

        if (locationBlocked && descriptions.includes[option.description]) {
          return '';
        }

        return option.description;
      }}
      filterOptions={x => x}
      options={options}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          inputProps={{
            ...params.inputProps,
            'data-test-id': testId,
          }}
          margin="dense"
          variant="standard"
          placeholder={t('searchPlaceholder')}
        />
      )}
      renderOption={option => {
        let parts;

        if (option && option.structured_formatting) {
          const matches =
            option?.structured_formatting?.main_text_matched_substrings;

          if (matches?.length) {
            parts = parse(
              option?.structured_formatting?.main_text,
              matches &&
                matches?.map(match => [
                  match?.offset,
                  match?.offset + match?.length,
                ])
            );
          }
        }

        let currentOption;

        if (inputValue) {
          currentOption = option?.structured_formatting?.secondary_text;
        }

        if (!inputValue && !locationBlocked) {
          currentOption = t('autoComplete.useCurrentLocation');
        }

        if (!inputValue && locationBlocked) {
          currentOption = t('autoComplete.pleaseSearch');
        }

        return currentOption ? (
          <Grid
            container
            alignItems="center"
            data-test-id="autocomplete-options"
          >
            <Grid item xs>
              {parts?.map(part => (
                <span
                  key={`${part?.text}`}
                  style={{ fontWeight: part?.highlight ? 700 : 400 }}
                >
                  {part?.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {currentOption}
              </Typography>
            </Grid>
          </Grid>
        ) : null;
      }}
      onChange={(e, newValue) => {
        handleSelectOption(newValue?.description);

        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if (newValue) {
          autocompleteService?.geocoder?.geocode(
            { placeId: newValue?.place_id },
            res => {
              if (!res) return;
              const [geoRes] = res;

              if (!res || !geoRes || !geoRes?.geometry?.location) return;
              setLocationAddress({
                placeId: geoRes?.place_id,
                lat: geoRes?.geometry.location.lat(),
                lng: geoRes?.geometry.location.lng(),
                boundingBox: geoRes?.geometry.bounds,
                address: newValue?.description,
                description: newValue?.description,
              });
            }
          );
        } else {
          setInputValue('');
        }
      }}
      onInputChange={(event, newInputValue) => {
        if (
          newInputValue &&
          newInputValue !== t('autoComplete.useCurrentLocation')
        ) {
          setInputValue(newInputValue);
          return setIsLoading(false);
        }
        return setInputValue('');
      }}
    />
  );
};

export { LocationAutocomplete };

LocationAutocomplete.propTypes = {
  setLocationAddress: PropTypes.func,
  location: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  testId: PropTypes.string,
};
