import styled from '@emotion/styled';

import { generateMQ } from '@/utils/helpers';

const mqMax = generateMQ('max');

const standardChevron = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  width: 24,
  height: 24,
};

export const ArrowContainer = styled.div(props => ({
  background: '#FFFFFF',
  opacity: props.disabled ? 0.6 : 0.8,
  width: 32,
  height: 32,
  borderRadius: '100%',
  position: 'relative',
  '&:hover': {
    opacity: props.disabled ? 0.6 : 1,
    cursor: props.disabled ? 'default' : 'pointer',
  },
}));

export const Container = styled.div(props => ({
  width: props.width || 456,
  position: 'relative',
  [mqMax[0]]: {
    width: '100%',
  },
}));

export const DotMatrix = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ImageBase = styled.div(({ src }) => ({
  width: '100%',
  overflow: 'hidden',
  borderRadius: 10,
  [mqMax[0]]: {
    borderRadius: 0,
  },
  background: `url(${src})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

export const ImageDefault = styled((props) => <ImageBase {...props} />)({
  maxWidth: 456,
  height: 360,
  [mqMax[0]]: {
    height: 300,
  },
});

export const ImageInspectionCarousel = styled((props) => <ImageBase {...props} />)({
  maxWidth: 796,
  maxHeight: 796,
  height: '100%',
  width: '100%'
});

export const PositionedChevronLeft = styled.div({
  ...standardChevron,
  left: -3,
});

export const PositionedChevronRight = styled.div({
  ...standardChevron,
  right: -3,
});

export const SelectedDot = styled.div({
  width: 8,
  height: 8,
  borderRadius: '100%',
  background: '#FFFFFF',
  marginLeft: 4,
  marginRight: 4,
});

export const UnselectedDot = styled(({ ...styles }) => (
  <SelectedDot {...styles} />
))({
  opacity: 0.4,
  cursor: 'pointer',
});

export const ImageContainer = styled.div({
  [mqMax[0]]: {
    display: 'flex',
    justifyContent: 'center',
  },
});
