import { useContext } from 'react';

import { UserContext } from '@/lib/context/UserContext';
import { UserContextShape } from '@/types';

export const useUser = (): UserContextShape => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a User Provider');
  }

  return context;
};
