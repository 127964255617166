import { Input } from '@fluidtruck/core';
import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FormInputGroup, FormInputProps } from '../FormInputGroup';

type FIProps = Omit<FormInputProps, 'inputProps'> & {
  inputProps?: React.ComponentProps<typeof Input>;
};

export type RHFFormProps<T extends FieldValues> = FIProps &
  UseControllerProps<T>;

export const FormInputController = <T extends FieldValues>(
  props: RHFFormProps<T>
): JSX.Element => {
  const {
    inputProps = {},
    label,
    mt = 0,
    placeholder,
    id,
    defaultValue,
    name,
    control,
    rules,
    shouldUnregister,
    ...rest
  } = props;

  const { field, fieldState } = useController<T>({
    name,
    rules,
    defaultValue,
    control,
    shouldUnregister,
  });

  return (
    <FormInputGroup
      id={id}
      mt={mt}
      onBlur={inputProps?.onBlur}
      onChange={inputProps?.onChange}
      errorMessage={fieldState.error?.message}
      isInvalid={!!fieldState.error}
      label={label}
      placeholder={placeholder}
      inputProps={{ ...field, ...inputProps }}
      {...rest}
    />
  );
};
