import {
  AnyFunction,
  Box,
  createStandaloneToast,
  isArray,
  isFunction,
  theme,
  useBoolean,
  useId,
  UseToastOptions,
} from '@fluidtruck/core';
import React, { useState } from 'react';

import { createContextProvider } from '@/utils';

export enum Statuses {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

type Options = UseToastOptions & { callbackFns?: AnyFunction | AnyFunction[] };

type State = {
  hide: () => void;
  show: (opts: Options) => void;
  isOpen: boolean;
};

type UseFeedback = () => State;

export const useFeedback: UseFeedback = () => {
  const [open, setOpen] = useBoolean(false);
  const [options, setOptions] = useState<Options | null>(null);

  const toast = createStandaloneToast({ theme });
  const id = useId();
  const status = options?.status || Statuses.ERROR;
  const cb = options?.callbackFns;

  const show = (opts: UseToastOptions) => {
    setOpen.on();
    setOptions(opts);
  };

  const hide = () => {
    setOpen.off();
    setOptions(null);
  };

  if (open && options && !toast.isActive(id)) {
    toast({
      position: 'top',
      duration: 1500,
      id,
      ...options,
      status,
      onCloseComplete: () => {
        if (isArray(cb) && cb.length) {
          cb.forEach(fn => fn());
        }
        if (isFunction(cb)) cb();
        options?.onCloseComplete?.();
        hide();
      },
      // eslint-disable-next-line react/display-name
      render: () => (
        <Box color="white" p={3} bg={status as string}>
          {options?.title}
        </Box>
      ),
    });
  }

  return { show, hide, isOpen: open };
};

type UseFeedbackReturn = ReturnType<typeof useFeedback>;

export const [
  Provider,
  useFeedbackContext,
] = createContextProvider<UseFeedbackReturn>({
  name: 'FeedbackContext',
  errorMessage: 'You forgot to wrap your component with the FeedbackProvider',
});

export const FeedbackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <Provider value={useFeedback()}>{children}</Provider>;
