import { useRadio, useRadioGroup, UseRadioProps } from '@fluidtruck/core';
import { CircleCheckIcon, RingIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';

import { Box, Button, FlexRow, ModalGroup, VStack } from '@/base-components';
import { BoldText, Text } from '@/base-components/typography/Common';
import { useOrganizationContext, useResize, useUser } from '@/hooks';
import { CartContext } from '@/lib/context';
import { UserOrganizations } from '@/types';
import { chakra } from '@/utils/tidal-utils';

import UpgradeButton from '../../features/account/components/UpgradeButton';

interface RadioCheckProps extends UseRadioProps {
  children: React.ReactElement | string;
}

export const resortOrgListWithProfileLast = (
  orgs: UserOrganizations[]
): UserOrganizations[] => {
  return [...orgs].sort((a, b) => {
    if (a.organization.category === 'Personal') return 1;

    return b.organization.category === 'Personal' ? -1 : 1;
  });
};

const RadioCheck = (props: RadioCheckProps): React.ReactElement => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const inputProps = getInputProps();
  const { children, isChecked } = props;
  const RadioIcon = isChecked ? CircleCheckIcon : RingIcon;
  const textStyles = {
    color: isChecked ? 'black' : 'gray.900',
    fontSize: 'lg',
    fontWeight: isChecked ? '700' : '400',
    margin: '8px 0',
    display: 'flex',
  };

  return (
    <Box as="label" _hover={{ backgroundColor: 'gray.50' }}>
      <Text sx={textStyles}>
        <chakra.input {...inputProps} />
        <RadioIcon
          w={6}
          h={6}
          cursor="pointer"
          color={isChecked ? 'blue.500' : 'gray.800'}
          borderRadius="full"
          data-checked={isChecked}
          mr={3}
          _focus={{
            boxShadow: '0 0 1px 1px #1BCCFD',
          }}
          _checked={{
            borderColor: 'blue.300',
          }}
          {...getCheckboxProps()}
        />
        {children}
      </Text>
    </Box>
  );
};

const ProfileOrgs = ({
  handleClose,
}: {
  handleClose: () => void;
}): React.ReactElement => {
  const { user } = useUser();
  const {
    context: { org, userOrgRole },
    actions: { setOrg },
  } = useOrganizationContext();
  const { cart, removeCart } = useContext(CartContext);
  const itemCount = cart?.items.length;
  const router = useRouter();
  const { isMobile } = useResize();
  const { pathname } = router;

  const { t } = useTranslation(['common', 'account', 'org_menu']);

  const defaultOrg = user?.['default-profile']?.organization;
  const orgs = user?.organizations || [];

  const [maybeNewOrg, setMaybeNewOrg] = useState(defaultOrg);
  const [openSwitchOrgWarningModal, setOpenSwitchOrgWarningModal] =
    useState(false);

  const newBorrowLimit = maybeNewOrg?.['borrow-limit'];
  const originalBorrowLimit = org?.organization?.['borrow-limit'];

  const updateOrg = (orgId: string | number): void => {
    const newOrg = orgs.find(o => o.organization.id === Number(orgId));
    if (!newOrg) return;

    const doNotRedirectList = ['/messages', '/account'];

    const shouldNotRedirect = doNotRedirectList.includes(pathname);

    if (shouldNotRedirect) {
      setOrg({ newOrg: newOrg.organization });
      return;
    }

    if (
      (userOrgRole === 'admin' || userOrgRole === 'owner') &&
      newOrg?.profile?.role === 'driver'
    ) {
      router.replace(`/account/organization/${orgId}`);
      setOrg({ newOrg: newOrg.organization });
      return;
    }

    const redirectList = [
      '/reservation',
      '/account/organization',
      '/performance',
      '/driver_management',
      '/account/organization/success',
    ];

    const shouldRedirect = redirectList.some(option =>
      pathname.includes(option)
    );

    if (shouldRedirect) {
      if (newOrg.organization.category.toLowerCase() === 'personal') {
        router.replace(`/account`);
      } else {
        setOrg({ newOrg: newOrg.organization });
        router.replace(`/account/organization/${orgId}`);
      }
    }

    const prepareOrgSwitchForConfirm = () => {
      setMaybeNewOrg(newOrg.organization);
      setOpenSwitchOrgWarningModal(true);
    };

    switch (pathname) {
      case '/book':
        prepareOrgSwitchForConfirm();
        break;
      case '/book_cart':
      case '/search': {
        if (itemCount) {
          prepareOrgSwitchForConfirm();
        } else {
          setOrg({ newOrg: newOrg.organization });
        }

        break;
      }
      default: {
        setOrg({ newOrg: newOrg.organization });
      }
    }
    if (isMobile) handleClose();
  };

  const handleSwitchAndKillCart = (): void => {
    removeCart();
    if (!maybeNewOrg) return;

    setOrg({ newOrg: maybeNewOrg });
    setOpenSwitchOrgWarningModal(false);

    if (pathname === '/book_cart' || pathname === '/book') {
      router.replace('/search');
    }
  };

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: 'organization',
    defaultValue: org?.organization?.id?.toString(),
    onChange: newOrg => {
      updateOrg(newOrg);
    },
  });
  const { ref, ...rootProps } = getRootProps();

  const handleAbortSwitch = (): void => {
    if (!org.organization) {
      return;
    }
    setValue(org.organization.id.toString());
    setOpenSwitchOrgWarningModal(false);
  };

  const sortedOrgList = resortOrgListWithProfileLast(orgs);

  return (
    <>
      <FlexRow justify="space-between" m={{ base: '0 0 24px', lg: '32px 0' }}>
        <BoldText>{t('common:company')}</BoldText>
        <UpgradeButton isMenu />
      </FlexRow>

      <VStack {...rootProps} align="left">
        {sortedOrgList.map(({ organization }) => {
          return (
            <RadioCheck
              ref={ref}
              key={organization.id}
              {...getRadioProps({ value: organization.id.toString() })}
            >
              {organization.name}
            </RadioCheck>
          );
        })}
      </VStack>

      <ModalGroup
        hideCloseButton
        lockFocusAcrossFrames
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isOpen={openSwitchOrgWarningModal}
        title={t('org_menu:switchOrgModal.modalTitle')}
        onClose={() => setOpenSwitchOrgWarningModal(false)}
      >
        {t('org_menu:switchOrgModal.areYouSure')}
        {(newBorrowLimit || 0) < (originalBorrowLimit || 0) &&
          t('org_menu:switchOrgModal.lowerLimit', {
            limit: newBorrowLimit,
          })}

        <Button
          isFullWidth
          variant="outline"
          mt={6}
          data-test-id="btn-no-thanks"
          onClick={handleAbortSwitch}
        >
          {t('org_menu:switchOrgModal.noThanks')}
        </Button>
        <Button
          isFullWidth
          mt={6}
          data-test-id="btn-switch-profile"
          onClick={handleSwitchAndKillCart}
        >
          {t('org_menu:switchOrgModal.switchProfile')}
        </Button>
      </ModalGroup>
    </>
  );
};

export default ProfileOrgs;
