import {
  chakra,
  Checkbox as TidalCheckbox,
  CheckboxGroup as TidalCheckboxGroup,
  CheckboxGroupProps,
  CheckboxProps,
  FormControl as TidalFormControl,
  FormControlProps,
  FormErrorMessage as TidalFormErrorMessage,
  FormErrorMessageProps,
  FormHelperText as TidalFormHelperText,
  FormLabel as TidalFormLabel,
  FormLabelProps,
  forwardRef,
  HelpTextProps,
  HTMLChakraProps,
  Radio as TidalRadio,
  RadioGroup as TidalRadioGroup,
  RadioGroupProps,
  RadioProps,
  Select as TidalSelect,
  SelectProps,
} from '@fluidtruck/core';
import React from 'react';

/* -------------------------------------------------------------------------------------------------
 * Layout Components: https://tidal.fluidtruck.com/docs/getting-started
 * -----------------------------------------------------------------------------------------------*/

// CHECKBOXES
export const Checkbox = forwardRef<CheckboxProps, 'input'>((props, ref) => (
  <TidalCheckbox {...props} ref={ref} />
));

export const CheckboxGroup = (
  props: CheckboxGroupProps
): React.ReactElement => {
  return <TidalCheckboxGroup {...props} />;
};

// RADIOS
export const Radio = forwardRef<RadioProps, 'input'>((props, ref) => (
  <TidalRadio ref={ref} sx={{ cursor: 'pointer' }} {...props} />
));

export const RadioGroup = forwardRef<RadioGroupProps, 'div'>((props, ref) => (
  <TidalRadioGroup ref={ref} {...props} />
));

// SELECTS
export const Select = (props: SelectProps): React.ReactElement => (
  <TidalSelect {...props} />
);

/**  FORM CONTROL
 * FormControl should expect an event handler, not RadioGroup
 */
export type {
  FormControlProps,
  FormLabelProps,
  FormErrorMessageProps,
  HelpTextProps,
};
export const FormControlLabel = forwardRef<FormLabelProps, 'label'>(
  (props, ref) => <TidalFormLabel {...props} ref={ref} />
);

export const FormErrorMessage = forwardRef<FormErrorMessageProps, 'div'>(
  (props, ref) => <TidalFormErrorMessage {...props} ref={ref} />
);

export const FormHelperText = forwardRef<HelpTextProps, 'div'>((props, ref) => (
  <TidalFormHelperText {...props} ref={ref} />
));

export const FormControl = forwardRef<FormControlProps, 'div'>((props, ref) => (
  <TidalFormControl {...props} ref={ref} />
));

// Basic form element
export const Form = (props: HTMLChakraProps<'form'>): React.ReactElement => {
  return <chakra.form {...props} />;
};
