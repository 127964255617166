import React, { ReactElement, ReactNode } from 'react';

import { LeftNavContainer } from '@/components/LeftNavMenu/components';

import { HideLeftNavContainer, NavigationWrapper } from '../containers';
import { useLayout } from '../context';
import * as Styles from '../styles';

interface Props {
  children?: ReactNode;
}

const FleetMapLayout = ({ children }: Props): ReactElement | null => {
  const { hideLeftNav } = useLayout();

  if (hideLeftNav) {
    return <HideLeftNavContainer>{children}</HideLeftNavContainer>;
  }

  return (
    <NavigationWrapper>
      <Styles.FleetMapLayoutGrid data-test-id="fleet-map-layout-grid">
        <LeftNavContainer />
        <Styles.FleetMapContent data-test-id="fleet-map-content">
          {children}
        </Styles.FleetMapContent>
      </Styles.FleetMapLayoutGrid>
    </NavigationWrapper>
  );
};

export { FleetMapLayout };
