import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

import { Names } from '@/features/account/components/types';
import { useResize } from '@/hooks';
import { omit } from '@/utils';

import { ReactSelect } from '../ReactSelect';
import * as Consts from './consts';
import type { RHFFormProps } from './types';

export const BusinessCategorySelect = <T extends FieldValues>({
  sx,
  control,
  name = Names.BUSINESS_CATEGORY as Path<T>,
  noLabel,
  rules,
  shouldUnregister,
  ...rest
}: RHFFormProps<T>): ReactElement => {
  const { t } = useTranslation('org_menu');
  const { isDesktop } = useResize();

  const categoriesOptions = Consts.categories.map(c => ({
    value: c,
    label: t(`business.categories.${c}`),
  }));

  const { field, fieldState } = useController<T>({
    name,
    rules,
    control,
    shouldUnregister,
  });

  return (
    <ReactSelect
      {...rest}
      {...omit(field, ['ref'])}
      isSearchable={isDesktop}
      isInvalid={!!fieldState.error}
      errorMessage={fieldState.error?.message}
      data-test-id="business-category-select"
      sx={{ marginTop: 6, ...sx }}
      label={t('business.categoryTitle')}
      noLabel={noLabel}
      options={categoriesOptions}
    />
  );
};
