import { useRouter } from 'next/router';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import { get } from '@/utils/helpers';

export const useChat = (pageSize = 10) => {
  const router = useRouter();
  const { id } = router.query;

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    (i, prevData) => {
      if (prevData && !prevData.length) {
        return null;
      }

      let key = `api/chats/history?mark-read=true&user-two=${id}&limit=${pageSize}`;
      if (i !== 0) {
        const lastItem = prevData[prevData.length - 1].id;
        key = `${key}&cursor=${lastItem}`;
      }

      return key;
    },
    url => get(url).then(json => json?.data?.entries)
  );

  const messages = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < pageSize);
  const isRefreshing = isValidating && data && data.length === size;

  return {
    messages,
    mutate,
    size,
    setSize,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isRefreshing,
  };
};

export const useChats = (status: string) => {
  const { data, error } = useSWR(
    `api/chats?page-size=99&status=${status}`,
    get,
    {
      refreshInterval: 5000,
      refreshWhenHidden: false,
    }
  );

  return {
    chats: data?.data?.entries,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useChatsUnreadCount = () => {
  const { pathname } = useRouter();
  const key = pathname === '/messages' ? 'api/v1/chats/unread' : null;
  const { data, error, mutate } = useSWR(key, get, {
    refreshInterval: 5000,
    refreshWhenHidden: false,
  });

  return {
    count: data?.status === 'success' ? data.data : 0,
    isLoading: !data && !error,
    isError: error,
    mutate,
  };
};
