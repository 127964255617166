import React from 'react';

import { Center } from '@/base-components';
import { useChatsUnreadCount } from '@/hooks';
import { SystemStyleObject } from '@/types';

const MessagesUnread = (): React.ReactElement | null => {
  const { count } = useChatsUnreadCount();
  const styles: SystemStyleObject = {
    backgroundColor: 'fluidBlue',
    borderRadius: 'full',
    width: 6,
    height: 6,
    fontSize: 'xs',
    textAlign: 'center',
    color: 'white',
  };

  if (!count) return <Center />;
  return <Center sx={styles}>{count > 99 ? '99+' : count}</Center>;
};

export default MessagesUnread;
