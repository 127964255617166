import { useJsApiLoader } from '@react-google-maps/api';
// eslint-disable-next-line import/no-unresolved
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { createContext, ReactElement, ReactNode } from 'react';

export const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || '';

export interface GMapsAPIShape {
  isGoogleLoaded: boolean;
  googleMapsLoadError: Error | undefined;
}

const libraries: Libraries = ['places', 'geometry', 'drawing'];

export const GoogleMapsApiContext = createContext<GMapsAPIShape>(
  {} as GMapsAPIShape
);
const GoogleMapsApiProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const { isLoaded: isGoogleLoaded, loadError: googleMapsLoadError } =
    useJsApiLoader({
      googleMapsApiKey,
      libraries,
    });

  return (
    <GoogleMapsApiContext.Provider
      value={{ isGoogleLoaded, googleMapsLoadError }}
    >
      {children}
    </GoogleMapsApiContext.Provider>
  );
};

export { GoogleMapsApiProvider };
