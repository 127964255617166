import { Tooltip as Tidaltooltip, TooltipProps } from '@fluidtruck/core';
import { InfoIcon } from '@fluidtruck/icons';
import React from 'react';

import { useResize } from '@/hooks';
import { forwardRef, useBoolean } from '@/utils';

/**
 * @description tidal/chakra-ui tooltip
 * @example
 * <Tooltip
 *  isOpen
 *  width="14rem"
 *  label="This is how you do something"
 *  aria-label="A tooltip"
 *  placement="auto"
 * >
 *  <Button>Do Something</Button>
 * </Tooltip>
 */

export const Tooltip = forwardRef<TooltipProps, 'div'>((props, ref) => {
  const { children, isOpen, ...rest } = props;
  const clone = React.isValidElement(children)
    ? React.cloneElement(children, {
        cursor: 'pointer',
      })
    : null;
  return (
    <Tidaltooltip isOpen={isOpen} {...rest} ref={ref}>
      {clone}
    </Tidaltooltip>
  );
});

type Props = Omit<TooltipProps, 'children'> & { dataTestId?: string };

export const InfoTooltip = forwardRef<Props, 'div'>((props, ref) => {
  const { isMobile } = useResize();
  const [isOpen, setIsOpen] = useBoolean();
  const { dataTestId, ...rest } = props;

  const handleMouseEnter = React.useCallback(() => {
    if (isMobile) return setIsOpen.on();
    return undefined;
  }, [isMobile, setIsOpen]);

  const handleMouseLeave = React.useCallback(() => {
    if (isMobile) return setIsOpen.off();
    return undefined;
  }, [isMobile, setIsOpen]);

  return (
    <Tidaltooltip
      {...rest}
      isOpen={isMobile ? isOpen : undefined}
      placement={isMobile ? 'top' : 'auto'}
      ref={ref}
    >
      <InfoIcon
        data-test-id={dataTestId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseEnter}
        sx={{ cursor: 'pointer' }}
        color="fluidBlue"
        h={6}
        w={6}
      />
    </Tidaltooltip>
  );
});
