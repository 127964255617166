import {
  chakra,
  cx,
  Flex,
  forwardRef,
  HTMLChakraProps,
  Logo,
  ThemingProps,
} from '@fluidtruck/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import { MenuToggle } from '@/components/MenuToggle';
import { UserWithToken } from '@/types';

export interface AppBarProps extends HTMLChakraProps<'div'>, ThemingProps {
  /**
   * The logo component to use.
   *
   * @default <Logo />
   */
  logo?: React.ReactNode;
  isMobile?: boolean;
  user?: UserWithToken | null;
  isOpen?: boolean;
  onToggleClick?: () => void;
}

export const AppBar = forwardRef<AppBarProps, 'div'>((props, ref) => {
  const {
    children,
    className,
    user,
    onToggleClick,
    isOpen,
    isMobile,
    logo,
    ...rest
  } = props;
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexShrink: 0,
      boxSizing: 'border-box',
      width: '100%',
      height: {
        base: '60px',
        md: '80px',
      },
      px: { base: 5, lg: 8 },
      backgroundColor: 'white',
      boxShadow: 'base',
      position: 'sticky',
      zIndex: 1,
    },
    logo: {
      cursor: 'pointer',
      width: {
        base: '107px',
        md: '153px',
      },
      height: {
        base: '22px',
        md: '32px',
      },
    },
  };

  const router = useRouter();
  const isProd = (process?.env?.API_URL || '').indexOf('-prod') > -1;
  const logoLink = isProd ? 'https://fluidtruck.com' : '/';
  const showToggle =
    isMobile && user && !router.pathname.includes('/truck-rental');

  const isDuringOutage = router.pathname.includes('/outage');

  return (
    <chakra.div
      {...rest}
      __css={styles.root}
      className={cx('tidal-app-bar', className)}
      ref={ref}
    >
      <Flex>
        {showToggle && (
          <Flex alignItems="center">
            <MenuToggle isOpen={isOpen} onClick={onToggleClick} />
          </Flex>
        )}
        {isDuringOutage ? (
          <chakra.div __css={styles.logo} className="tidal-app-bar__logo">
            <Logo />
          </chakra.div>
        ) : (
          <Link href={logoLink} as={logoLink}>
            <chakra.div __css={styles.logo} className="tidal-app-bar__logo">
              {logo || <Logo />}
            </chakra.div>
          </Link>
        )}
      </Flex>
      {children || <div />}
    </chakra.div>
  );
});
