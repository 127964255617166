import { Flex, Grid } from '@fluidtruck/core';

import { SystemStyleObject } from '@/types';
import { chakra } from '@/utils';

export const popoverContentSx: SystemStyleObject = {
  backgroundColor: 'white',
  borderRadius: '0 0 10px 10px',
  boxShadow: 'base',
  padding: { base: '40px 12px', baseDesktop: '58px 54px' },
  width: '100%',
  minW: { base: '100vw', baseDesktop: '1256px' },
};

export const Container = chakra(Grid, {
  baseStyle: {
    gridRowGap: 12,
    gridTemplateRows: '209px 58px',
  },
  label: 'Container',
});

export const VehicleTypeGrid = chakra(Grid, {
  baseStyle: {
    alignItems: 'end',
    gridTemplateColumns: 'repeat(5, auto)',
    gridGap: { base: 4, baseDesktop: 7 },
  },
  label: 'VehicleTypeGrid',
});

export const LocationDatesGrid = chakra(Grid, {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: 5,
  },
  label: 'LocationDatesGrid',
});

export const TypeButton = chakra('button', {
  baseStyle: {
    flex: '0 0 110px',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    outline: 'none',
  },
  label: 'TypeButton',
});

export const TypeIcon = chakra('div', {
  baseStyle: {
    flex: '0 0 110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: { base: 'auto', baseDesktop: '137px' },
    marginBottom: 6,
    position: 'relative',
  },
  label: 'TypeIcon',
});

export const TypeCheck = chakra(Flex, {
  baseStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0,
    _selected: { opacity: 1 },
    _hover: { opacity: 1 },
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      borderRadius: '100%',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
    },
  },
  label: 'TypeCheck',
});

export const Location = chakra(Flex, {
  baseStyle: {
    flex: '0 0 250px',
    flexDirection: 'column',
    '& div div div': {
      zIndex: 5,
    },
    '.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiAutocomplete-root': {
      width: '100%',
    },
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot':
      {
        paddingRight: 6,
        width: '100%',
      },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
  },
  label: 'Location',
});

export const DtrpContainer = chakra('div', {
  baseStyle: {
    width: '100%',
  },
  label: 'DtrpContainer',
});
