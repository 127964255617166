import { Dispatch } from 'react';

import type {
  EventPoint,
  FleetItem as FleetMapItem,
  RoutePoint,
} from '@/hooks/use-fleet';
import { Action, MapState } from '@/lib/context/GoogleMaps/reducer/map_actions';

// ------------------ enums ------------------//
export enum MapItemInstanceType {
  FleetItem = 'fleetItem',
  FleetEvent = 'fleetEvent',
  FleetPolylines = 'fleetPolylines',
}

// ------------------ Misc ------------------//
export type Coordinates = {
  lat: number;
  lng: number;
};

export interface LocationAddress {
  placeId: string;
  lat: number;
  lng: number;
  boundingBox: google.maps.LatLngBounds;
  address: string;
  description: string;
}

export interface EventMarker {
  url: string;
  scaledSize: google.maps.Size;
}

export type MapItemInstances = {
  [MapItemInstanceType.FleetItem]: google.maps.Marker[];
  [MapItemInstanceType.FleetEvent]: google.maps.Marker[];
  [MapItemInstanceType.FleetPolylines]: google.maps.Polyline[];
};

export interface SnapApiResponse {
  snappedPoints: {
    location: {
      latitude: number;
      longitude: number;
    };
    originalIndex: number;
    placeId: string;
  }[];
  warningMessage?: string;
}

// ------------------ hook ------------------//
export interface UseGoogleMapsReturnEvents {
  plotEvents: PlotEvents;
  events: FleetEventsState;
  resetEventsState: () => void;
}

export interface UseGoogleMapsReturnMarkers {
  plotFleetMarkers: (items: FleetMapItem[]) => void;
}
export interface UseGoogleMapsReturn {
  state: MapState;
  dispatch: Dispatch<Action>;
  onLoad: (map: google.maps.Map) => void;
  drawRoutes: DrawRoutes;
  setMapLatLng: () => void;
  clearInstancesOfMapItemsType: (type: MapItemInstanceType) => void;
  events: UseGoogleMapsReturnEvents;
  markers: UseGoogleMapsReturnMarkers;
}

export interface FleetEventsState {
  braking: number;
  acceleration: number;
  cornering: number;
}

export interface FleetEventPoint extends EventPoint {
  icon: google.maps.Icon;
}

// ------------------ methods ------------------//
export type DrawSnappedPolyline = (snappedCoords: google.maps.LatLng[]) => void;
export type DrawRoutes = (vehicleRouteData: RoutePoint[]) => void;
export type RunSnapToRoadWithPaths = (pathList: string[]) => Promise<void>;
export type PlotEvents = (eventsData: EventPoint[]) => void;

export type CreateMarkerFrom_<TItem> = (item: TItem) => google.maps.Marker;
export type CreateMarkerFromFleetMapItem = CreateMarkerFrom_<FleetMapItem>;
export type CreateMarkerFromFleetEvent = CreateMarkerFrom_<FleetEventPoint>;

export type UpdateFleetEventsAndGetEventIcon = (
  fleetEvent: EventPoint
) => google.maps.Icon;

export const initialEventsState = {
  braking: 0,
  acceleration: 0,
  cornering: 0,
};
