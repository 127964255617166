import useSWR from 'swr';

import { get } from '@/utils/helpers';

import * as Mappings from './mappings';
import * as Types from './types';

export const useFleetDrivingHistorySwr: Types.UseFleetDrivingHistorySwr = ({
  params,
  config,
}) => {
  const hasAllParams = !!(
    params.startDate &&
    params.endDate &&
    params.orgId &&
    params.vehicleId
  );

  const fleetTripURL = hasAllParams
    ? `api/myfleet/${params.orgId}/items/${params.vehicleId}/trips?start-date=${params.startDate}&end-date=${params.endDate}`
    : null;

  const fleetTripSwr = useSWR<Types.FleetDetailsTableRowData[]>(
    fleetTripURL,
    url =>
      get(url).then((res: Types.FleetTripApi) => {
        if (!res.trips) return [];
        return res.trips.reverse().map(Mappings.mapFleetDrivingHistory);
      }),
    config
  );

  return { ...fleetTripSwr };
};
