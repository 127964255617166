import type { GroupBase, OptionGroup } from '@fluidtruck/core';
import {
  ChakraReactSelect,
  FormControl,
  FormLabel,
  GridItem,
  isString,
} from '@fluidtruck/core';
import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import type { CRSProps } from '../ReactSelect';

const generateYearArray = (maxYear: number) => {
  const currentYear = moment().year();
  const years = [];
  for (let year = currentYear; year <= maxYear; year++) {
    years.push(year);
  }
  return years;
};

const combineMonthYear = (month: string, year: string) => {
  const date = moment(`${month} ${year}`, 'MMMM YYYY');
  return moment.utc(date);
};

type Props = Omit<CRSProps, 'onChange' | 'value'> & {
  onChange: (value: Moment) => void;
  value: Moment | string;
};

export const MonthYearSelect = ({
  value: valueProp,
  isInvalid,
  onChange: onChangeProp,
}: Props) => {
  const { t } = useTranslation('common');
  const [month, setMonth] = useState<string>();
  const [year, setYear] = useState<string>();

  const styles = {
    control: (provided: any) => ({
      ...provided,
      color: 'fluidBlue',
    }),
  };

  const months = [
    { value: 'january', label: t('form.months.jan') },
    { value: 'february', label: t('form.months.feb') },
    { value: 'march', label: t('form.months.mar') },
    { value: 'april', label: t('form.months.apr') },
    { value: 'may', label: t('form.months.may') },
    { value: 'june', label: t('form.months.jun') },
    { value: 'july', label: t('form.months.jul') },
    { value: 'august', label: t('form.months.aug') },
    { value: 'september', label: t('form.months.sep') },
    { value: 'october', label: t('form.months.oct') },
    { value: 'november', label: t('form.months.nov') },
    { value: 'december', label: t('form.months.dec') },
  ];
  const years = generateYearArray(2099).map(val => ({
    value: val.toString(),
    label: val.toString(),
  }));

  const filteredValue = (options: OptionGroup[]) =>
    options.find(opt => {
      if ('value' in opt)
        return opt?.value === (valueProp as unknown as string);
      return opt || {};
    });

  const input = (option: any, options: OptionGroup[]) => {
    return isString(option) ? filteredValue(options) : option;
  };

  const handleMonthChange = (newValue: any) => {
    setMonth?.(newValue.value);
  };
  const handleYearChange = (newValue: any) => {
    setYear?.(newValue.value);
  };
  const handleBlur = () => {
    if (!month || !year) return;

    const expDate = combineMonthYear(month, year);

    onChangeProp?.(expDate);
  };

  return (
    <>
      <FormControl
        display="grid"
        gridTemplateColumns="repeat(2, auto)"
        isInvalid={isInvalid}
      >
        <GridItem colSpan={2}>
          <FormLabel>{t('form.expirationDate')}</FormLabel>
        </GridItem>
        <ChakraReactSelect<OptionGroup, false, GroupBase<OptionGroup>>
          options={months}
          onBlur={handleBlur}
          onChange={handleMonthChange}
          value={input(month, months)}
          menuPlacement="auto"
          placeholder={`${t('form.monthLabel')}...`}
          chakraStyles={styles}
        />
        <ChakraReactSelect<OptionGroup, false, GroupBase<OptionGroup>>
          options={years}
          onBlur={handleBlur}
          onChange={handleYearChange}
          value={input(year, years)}
          menuPlacement="auto"
          placeholder={`${t('form.yearLabel')}...`}
          chakraStyles={styles}
        />
      </FormControl>
    </>
  );
};
