import PropTypes from 'prop-types';
import React from 'react';

import * as Styles from './styles';

const Tab = ({
  count,
  countProps = {},
  mobile = false,
  onChange,
  onClick,
  selected = false,
  text,
  value,
}) => {
  const hasCount = count !== undefined && count !== 0;

  const handleClick = e => {
    if (onChange) {
      onChange(e, value);
    }

    if (onClick) {
      onClick(e);
    }
  };

  let textOutput = text;
  if (hasCount && mobile) {
    textOutput += ` (${count})`;
  }

  return (
    <Styles.TabContainer mobile={mobile} selected={selected} onClick={handleClick}>
      {textOutput}
      {hasCount && !mobile && (
        <Styles.TabCountContainer {...countProps} count={count}>
          {count > 99 ? 99 : count}
        </Styles.TabCountContainer>
      )}
    </Styles.TabContainer>
  );
};

Tab.propTypes = {
  count: PropTypes.number,
  countProps: PropTypes.shape({
    error: PropTypes.bool,
  }),
  mobile: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default Tab;
