export const polygonOptions: google.maps.PolygonOptions = {
  fillColor: `#00b2e3`,
  strokeColor: `#00b2e3`,
  fillOpacity: 0,
  strokeWeight: 3,
  zIndex: 1,
};

export const polygonDrawOptions: google.maps.PolygonOptions = {
  fillColor: `transparent`,
  strokeColor: `#666666`,
  strokeWeight: 3,
  zIndex: 1,
};
