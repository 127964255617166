import { Avatar, Text } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React, { MouseEvent, ReactElement } from 'react';

import * as Styles from '@/components/Nav/NavBar.styles';
import { useOrganizationContext, useResize } from '@/hooks';
import { UserWithToken } from '@/types';
import { chakra } from '@/utils';

export interface LoginProps {
  user: UserWithToken | null;
  'data-test-id': string;
  onClick: (e: MouseEvent) => void;
}

export const LoginMenuToggle = (props: LoginProps): ReactElement => {
  const { user, ...rest } = props;
  const {
    context: {
      org: { organization: { name } = {} },
    },
  } = useOrganizationContext();

  const { t } = useTranslation('common');
  const { isMobile } = useResize();

  const label =
    user?.first && user?.last
      ? `${user.first} ${user.last}`
      : t('login.menuLabel');

  return (
    <chakra.div {...rest}>
      <Styles.LoginButton variant="outline" size={isMobile ? 'md' : 'lg'}>
        <chakra.div
          fontSize="sm"
          lineHeight="15.6px"
          mr={2}
          isTruncated={isMobile}
          maxW={{ base: '120px', lg: '100%' }}
        >
          {label}
          {user ? (
            <Text fontWeight="normal" isTruncated={isMobile}>
              {name !== 'Personal Profile' && name}
            </Text>
          ) : null}
        </chakra.div>
        <Avatar size={isMobile ? 'sm' : 'md'} src={user?.['avatar-url']} />
      </Styles.LoginButton>
    </chakra.div>
  );
};
