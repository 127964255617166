import { Hide } from '@fluidtruck/core';
import React from 'react';

import { SystemStyleObject } from '@/types/tidal-types';

import { CloseButton } from '../buttons';
import { Center, Divider } from '../layout';
import { SubHeading } from '../typography/Common';

/**
 * @description Dialog Header that hides CloseButton on mobile
 * if you are going to use this, make sure you add an element
  id: <AlertButton onClick={onClose} /> to the parent component
  to handle the onClose event
 */

export const DialogHeader = ({
  onClose,
  header,
  sx,
  hideMobileClose,
}: {
  onClose: () => void;
  header: string;
  hideMobileClose?: boolean;
  sx?: SystemStyleObject;
}) => {
  return (
    <>
      <Center justifyContent="space-between" mb={4} sx={sx} width="100%">
        <SubHeading>{header}</SubHeading>
        <Hide below={hideMobileClose ? 'lg' : ''}>
          <CloseButton role="presentation" onClick={onClose} />
        </Hide>
      </Center>
      <Divider />
    </>
  );
};
