import moment, { Moment } from 'moment';
import { useCallback, useContext } from 'react';

import { WeeklyCalculatorContext } from '.';
import { WeeklyCalculatorShape } from './types';

export const useWeekCalculator = (): WeeklyCalculatorShape => {
  const context = useContext(WeeklyCalculatorContext);

  if (!context) {
    throw new Error(
      'useWeekCalculator must be used within the WeeklyCalculatorProvider'
    );
  }

  return context;
};

export const useCalculateWeeksToDays = (): {
  calculateWeeksToDaysDate: (formPickUp: Date | Moment, hours?: number) => Date;
} => {
  const { numberOfWeeks } = useWeekCalculator();

  const calculateWeeksToDaysDate = useCallback(
    (formPickUp: Date | Moment, hours = 0): Date => {
      return moment(formPickUp)
        .add(numberOfWeeks * 7, 'days')
        .add(hours, 'hour')
        .startOf('hour')
        .toDate();
    },
    [numberOfWeeks]
  );

  return { calculateWeeksToDaysDate };
};
