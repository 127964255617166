import { reset } from '@amplitude/analytics-browser';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import React, { createContext, ReactElement, useState } from 'react';
import { useSWRConfig } from 'swr';

import { ampli } from '@/amplitude';
import { useSignupContext } from '@/hooks/use-signup'; // @hooks displays deps cycle err
import type {
  GenericApiResponse,
  UserContextShape,
  UserWithToken,
} from '@/types';
import { CurrentFlow } from '@/types';
import { get, post } from '@/utils/helpers';

export const UserContext = createContext<UserContextShape>(
  {} as UserContextShape
);

interface UserProviderProps {
  children: React.ElementType;
  user?: UserWithToken;
}

export const UserContextProvider = ({
  children,
  user: userProp,
}: UserProviderProps): ReactElement => {
  const router = useRouter();
  const isDriverScore = router.pathname.includes('driver_score');
  const [user, setUser] = useState<UserWithToken | undefined>(userProp);
  const { cache, mutate } = useSWRConfig();

  const { setSignupType, setOrgName, setInviteDetails, setEmail, setPhone } =
    useSignupContext();

  const login: UserContextShape['login'] = async (
    currentFlow = CurrentFlow.HOME,
    disableAmplitude?: boolean
  ) => {
    try {
      const response: GenericApiResponse<UserWithToken> = await get(
        'api/users'
      );

      if (!response || response.status !== 'success') {
        const [message] = response.messages;
        throw new Error(message || JSON.stringify(response));
      }
      const { data } = response;

      Cookie.remove('organization');
      setUser(data);

      if (!disableAmplitude) {
        ampli.identify(`${data.id}`);
        ampli.login({
          userId: data.id,
          firstName: data.first,
          lastName: data.last,
          flow: currentFlow,
        });
      }

      return data;
    } catch (error) {
      console.error(error);
      throw new Error('Invalid email or password');
    }

    // clean out potential stale org cookie
  };

  const logout: UserContextShape['logout'] = async () => {
    if (user) {
      ampli.logout({
        userId: user.id,
        firstName: user.first,
        lastName: user.last,
      });

      reset();
    }

    await post('api/logout', {});
    Cookie.remove('cart');
    Cookie.remove('organization');
    Cookie.remove('user');
    Cookie.remove('token');
    Cookie.remove('refresh');

    setUser(undefined);
    setSignupType('');
    setOrgName('');
    setPhone('');
    setEmail('');
    setInviteDetails(undefined);

    if (cache && cache.keys) {
      // @ts-ignore
      for (const key of cache.keys()) {
        mutate(key, undefined, { revalidate: false });
      }
    }
    if (!isDriverScore) router.push('/');
  };

  const updateUser: UserContextShape['updateUser'] = (data): void => {
    setUser(data);
  };

  const refreshUser: UserContextShape['refreshUser'] = async () => {
    if (!user) return user;

    const response: GenericApiResponse<UserWithToken> = await get('api/users');
    if (response.status !== 'success') {
      const { messages } = response;
      throw new Error(messages?.[0]);
    }
    const { data } = response;
    setUser(data);
    return data;
  };

  return (
    <UserContext.Provider
      value={{
        user: user?.id ? user : null,
        login,
        logout,
        refreshUser,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
