import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const FeedbackContext = createContext();
const SEVERITIES = ['error', 'warning', 'info', 'success'];

export const FeedbackContextProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('error');

  const showFeedback = (newMessage, newSeverity) => {
    // Support instances where we pass `res.status` from the API.
    let sev = newSeverity;
    if (sev === 'fail') {
      sev = 'error';
    }

    if (SEVERITIES.includes(sev)) {
      setMessage(newMessage);
      setSeverity(sev);
      setOpen(true);
    }
  };

  const hideFeedback = () => {
    setOpen(false);
    setMessage(null);
  };

  return (
    <FeedbackContext.Provider
      value={{
        showFeedback,
        hideFeedback,
        severity,
        message,
        open,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

FeedbackContextProvider.propTypes = {
  children: PropTypes.element,
};
