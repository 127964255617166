import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';

import { FormInputProps } from '../FormInputGroup';
import { Password } from '../Password';

export type PasswordControllerProps = Omit<
  ControllerProps & FormInputProps,
  'render'
>;

export const PasswordController = (
  props: PasswordControllerProps
): JSX.Element => {
  const { inputProps, label, mt = 0, placeholder, id, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      onBlur={inputProps?.onBlur}
      onChange={inputProps?.onChange}
      control={control}
      {...rest}
      render={({ field, fieldState }) => (
        <Password
          mt={mt}
          id={id}
          errorMessage={fieldState.error?.message}
          isInvalid={!!fieldState.error}
          label={label}
          placeholder={placeholder}
          inputProps={{ ...inputProps, ...field }}
        />
      )}
    />
  );
};

export default PasswordController;
