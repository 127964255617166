import { theme } from '@fluidtruck/core';
import { createTheme } from '@material-ui/core';

const muiTheme = createTheme({
  palette: {
    common: {
      black: theme.colors.black,
      white: theme.colors.white,
    },
    primary: {
      main: theme.colors.blue[500],
      contrastText: theme.colors.white,
    },
    secondary: {
      main: theme.colors.gray[700],
      contrastText: theme.colors.black,
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    'undefined-popover': {
      zIndex: 1500,
    },
    MuiTableCell: {
      head: {
        color: theme.colors.gray[900],
        fontWeight: 'bold',
      },
    },
  },
});

export default muiTheme;
