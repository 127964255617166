import { ApiResourceDELETE } from '@/api-resources/resources/resourceDELETE';
import { urlEmbedParams, urlEmbedQuery } from '@/api-resources/utils';
import { postPutPatch } from '@/utils/helpers';

import { HandlerContract } from '../types';

export function useDELETE<TPath extends keyof ApiResourceDELETE>(props: {
  path: TPath;
}) {
  const handler = async (
    args: Omit<ApiResourceDELETE[TPath][0], 'path'> &
      HandlerContract<ApiResourceDELETE[TPath][1]>
  ) => {
    try {
      let url: string = props.path;

      if (args.params) {
        url = urlEmbedParams(url, args.params);
      }

      if (args.query) {
        url = urlEmbedQuery(url, args.query);
      }

      const response:
        | ApiResourceDELETE[TPath][1]['success']
        | ApiResourceDELETE[TPath][1]['fail'] = await postPutPatch('DELETE')(
        url,
        args.body,
        args.headers
      );

      if ('messages' in response || 'message' in response) {
        const fail = response as any;
        if (fail?.message) throw new Error(fail.message);
        if (fail?.messages) throw new Error(JSON.stringify(fail?.messages));
        throw new Error('failed to complete DELETE request');
      }

      args?.onSuccess?.(response);

      return response;
    } catch (err) {
      if (err instanceof Error) {
        args?.onError?.(err);
      } else {
        args?.onError?.(new Error('failed to complete POST request'));
      }

      return undefined;
    }
  };

  return { handler };
}
