import {
  Box,
  ChakraReactSelect,
  ChakraReactSelectProps as Props,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GroupBase,
  isString,
  OptionGroup,
  SystemStyleObject,
} from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';

/**
 * @description ChakraReactSelect
 * https://tidal.fluidtruck.com/docs/form/chakra-react-select
 * Important: For Single Values
 * @example
    const options = [{ value: 'red', label: 'RED' }, ....]

    <MonthlySelect
      defaultValue={options[0].value}
      options={options}
      placeholder="Choose a color"
      onChange={handleSelectOnChange}
    />
 */

export type CRSProps = {
  noLabel?: boolean;
  label?: string;
  errorMessage?: string;
  'data-test-id'?: string;
  id?: string;
  sx?: SystemStyleObject;
  variant?: string;
} & Props<OptionGroup, false, GroupBase<OptionGroup>>;

export const ReactSelect = ({
  options = [],
  noLabel,
  label,
  value: valueProp,
  isInvalid,
  'data-test-id': testId,
  onChange: onChangeProp,
  errorMessage,
  onBlur: onBlurProp,
  isSearchable = false,
  sx,
  isMulti = false,
  placeholder,
  ...rest
}: CRSProps): ReactElement => {
  const { t } = useTranslation('common');
  const filteredValue = options.find(opt => {
    if ('value' in opt) return opt?.value === (valueProp as unknown as string);
    return opt || {};
  });

  const input = (option?: any) => {
    return isString(option) ? filteredValue : option;
  };

  const output = (option: any) => (isString(option) ? option : option.value);

  const handleChange = (newValue: any, actionMeta: any) => {
    onChangeProp?.(output(newValue), actionMeta.action);
  };

  return (
    <Box data-test-id={testId} as={FormControl} isInvalid={isInvalid} sx={sx}>
      {!noLabel && <FormLabel>{label}</FormLabel>}
      <ChakraReactSelect<OptionGroup, typeof isMulti, GroupBase<OptionGroup>>
        options={options}
        onBlur={onBlurProp}
        {...rest}
        onChange={handleChange}
        value={input(valueProp)}
        isSearchable={isSearchable}
        placeholder={placeholder || `${t('button.search')}...`}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </Box>
  );
};
