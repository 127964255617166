import moment from 'moment';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import { Select } from '@/base-components';
import { useDriverScoreContext, useWeeks } from '@/hooks';
import {
  ComponentWithAs,
  IconProps,
  SystemStyleObject,
  WeekObject,
} from '@/types';

interface IconType {
  color?: string;
  icon: ComponentWithAs<'svg', IconProps>;
  size?: string | number;
}
interface WeekSelectProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  rightIcon?: IconType;
  variant?: string;
  width?: string | number;
}

const getFormattedWeek = (w: WeekObject): string => {
  const start = moment(w['start-date']).format('MMMM Do');
  const end = moment(w['end-date']).format('MMMM Do YYYY');
  return `${start} - ${end}`;
};

export const WeekSelect = (props: WeekSelectProps): React.ReactElement => {
  const { t } = useTranslation('team');
  const router = useRouter();
  const routeObj = { ...router.query };
  const { weekID } = routeObj;
  const { fontSize = '5xl', color = 'black', width = 72, ...rest } = props;
  const {
    actions: { setIsLoading },
  } = useDriverScoreContext();

  const {
    allWeeks,
    error: weekError,
    newWeekId,
    setNewWeekId,
  } = useWeeks(weekID);

  let selectWeekValue = '';
  if (!Array.isArray(newWeekId)) {
    selectWeekValue = newWeekId as string;
  }

  const changeWeek = (id: string): void => {
    setIsLoading(true);
    setNewWeekId(id);
    routeObj.weekID = id;
    router.replace({
      pathname: '/driver_score',
      query: routeObj,
    });
  };

  if (weekError || !allWeeks) return <div>{t('driverCup.banner.noWeeks')}</div>;

  const styles: SystemStyleObject = {
    color: 'black',
    mb: 2,
    width,
    '* > .chakra-select__select-span-value': {
      color,
      fontSize,
      fontWeight: 'bold',
    },
  };

  const weekArr = allWeeks?.data.map(wk => ({
    label: getFormattedWeek(wk),
    value: wk['week-id'].toString(),
  }));

  return (
    <Select
      defaultValue={weekArr?.[0].value}
      id="week-select"
      options={weekArr}
      sx={styles}
      value={selectWeekValue}
      onChange={(value: string) => changeWeek(value)}
      {...rest}
    />
  );
};
