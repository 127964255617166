import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';

import { ampli } from '@/amplitude';
import { Form, FormInputController, SubmitButton } from '@/base-components';
import { Password } from '@/base-components/forms';
import { useSignupContext } from '@/hooks';
import { emailPhonePattern, passwordMinLength as requirements } from '@/utils';

import { SignUpLoginContainer } from './styles';

enum SignupNames {
  PASSWORD = 'password',
  EMAIL_PHONE = 'emailPhone',
  COPY_PASSWORD = 'copypassword',
}

type FormState = {
  emailPhone: string;
  password: string;
  copypassword: string;
};

export interface SignupProps {
  onFinish?: () => void;
}

export type Component = (props: SignupProps) => JSX.Element;

export const Signup: Component = ({ onFinish }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { setEmail, setPass, setPhone } = useSignupContext();

  const {
    control,
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
    handleSubmit,
  } = useForm<FormState>({
    mode: 'onSubmit',
    defaultValues: {
      emailPhone: (router?.query?.email as string) ?? '',
      password: '',
      copypassword: '',
    },
  });

  const submit = ({ emailPhone, password }: FormState): void => {
    if (emailPhone.includes('@')) {
      setEmail(emailPhone);
    } else {
      setPhone(emailPhone);
    }
    setPass(password);

    onFinish?.();
  };

  const readOnly = Boolean(router?.query?.email);

  return (
    <SignUpLoginContainer className="sign-up-container">
      <Form
        onSubmit={handleSubmit(submit)}
        data-test-id="signup-form"
        id="signup-form"
        width="100%"
      >
        <FormInputController
          name={SignupNames.EMAIL_PHONE}
          id={SignupNames.EMAIL_PHONE}
          mt={6}
          control={control}
          rules={emailPhonePattern(t('phoneOrEmailIncorrect'))}
          label={t('phoneOrEmail')}
          inputProps={{
            isReadOnly: readOnly,
            'data-test-id': 'input-email',
          }}
        />
        <Controller
          name={SignupNames.PASSWORD}
          control={control}
          rules={requirements(t('login.newPassDetails'))}
          render={({ field, fieldState }) => (
            <Password
              id="sign-up-password"
              isInvalid={!!fieldState.error}
              errorMessage={fieldState.error?.message}
              inputProps={{ 'data-test-id': 'input-password', ...field }}
            />
          )}
        />
        <Controller
          name={SignupNames.COPY_PASSWORD}
          control={control}
          rules={{
            validate: value =>
              value === watch('password') || (t('login.passNoMatch') as string),
            ...requirements(t('login.newPassDetails')),
          }}
          render={({ field, fieldState }) => (
            <Password
              id="sign-up-password-copy"
              label={t('signup.confirmPass')}
              isInvalid={!!fieldState.error}
              errorMessage={fieldState.error?.message}
              inputProps={{
                'data-test-id': 'input-password-confirm',
                ...field,
              }}
            />
          )}
        />
        <SubmitButton
          data-test-id="button-signup"
          isLoading={isSubmitting || isSubmitSuccessful}
          onClick={() => ampli.createAccountClick()}
        >
          {t('signup.createAcct')}
        </SubmitButton>
      </Form>
    </SignUpLoginContainer>
  );
};
