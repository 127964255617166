import { PopoverProps, useMediaQuery } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, Divider, FlexRow } from '@/base-components';
import { PopOverGroup } from '@/base-components/PopoverGroup';

import { BodyContent } from './helpers';
import { useWeekCalculator } from './useWeekCalculator';

export const WeekTextWrapper = ({
  options,
  originalDate,
  timezone,
}: {
  options?: PopoverProps;
  originalDate: Date;
  timezone: string;
}): JSX.Element => {
  const { showDivider, onClose, onOpen } = useWeekCalculator();
  const [isSmallerThan30em] = useMediaQuery('(max-width: 30em)');
  const { t } = useTranslation('search');

  return (
    <FlexRow
      alignItems="center"
      data-test-id="week-text-wrapper"
      h="inherit"
      sx={{
        justifyContent: isSmallerThan30em ? 'flex-end' : 'center',
        '.chakra-popover__content': {
          width: '324px !important', // this is narrower than "xs" chakra var
        },
      }}
    >
      {showDivider && (
        <Divider
          orientation="vertical"
          h="inherit"
          borderColor="gray.900"
          mx={2}
        />
      )}
      <PopOverGroup
        popover={{
          options: {
            ...options,
            onOpen,
            onClose,
          },
        }}
        closeButtonProps={{ sx: { top: '0.5rem' } }}
        header={{
          content: t('oneWeekIs'),
          options: {
            sx: { fontSize: 14, textAlign: 'center' },
          },
        }}
        body={{
          content: (
            <BodyContent originalDate={originalDate} timezone={timezone} />
          ),
          options: { p: 4 },
        }}
        triggerEl={
          <Button
            size="xs"
            variant="link"
            color="fluidBlue"
            fontWeight="bold"
            id="button-calculate-week" // for Digital Marketing
            data-test-id="button-calculate-week"
            fontSize={14}
            onClick={onOpen}
          >
            {t('weeklyCalc')}
          </Button>
        }
      />
    </FlexRow>
  );
};
