import { extendTheme, Theme } from '@fluidtruck/core';

import { breakpoints, shadows, zIndices } from './foundations';
/**
 * Customizing theme with additions and overrides
 * https://tidal.fluidtruck.com/docs/theming/customize-theme
 * import
 * Use:
    const overrides = {
      zIndeces,
      // Other foundational style overrides go here
      components: {
        Button,
        // Other components go here
      },
}
 *
*/

export const theme: Theme = extendTheme({
  breakpoints,
  zIndices,
  shadows,
  styles: {
    global: {
      a: {
        color: 'blue.500',
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
  components: {
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: 'var(--chakra-shadows-base)',
          },
        },
      },
    },
    Tabs: {
      variants: {
        'solid-rounded': {
          tab: {
            padding: '2',
            borderRadius: 'full',
            _selected: { bg: 'fluidBlue' },
          },
        },
      },
    },
  },
});

/**
 * Tidal colors are being imported directly from the library
 * https://tidal.fluidtruck.com/docs/theming/theme#black--white
 * To utlize the colors, you can directly access them from
 * Use:
    Tidal Component: `<SomeIcon color="blue.500" />` or `<SomeIcon color="fluidBlue" />`
    Emotion: `color: theme.colors.blue[500]` or `color: theme.colors.blue[500]`
 *
    const blue = {
      50: '#AAEFFF',
      100: '#55DAFF', - Light FluidBlue
      200: '#1BCCFD', - FluidBlue Hover
      300: '#0094C9', - ADA Blue 3:45:1
      400: '#007EB0', - ADA Blue 4.55:1
      500: '#00B2E3', - FluidBlue
      600: '#1758A0', - Dark Mode Light Hover
      700: '#005885', - Dark Mode Light
      800: '#003D61', - Dark Mode Medium
      900: '#001A29', - Dark Mode Dark
    }
    const gray = {
      50: '#F7F7F7', - Lightest Gray
      100: '#F2F2F2', - Light Gray
      200: '#E5E5E5', - Component Gray
      300: '#D4D4D4', - Keylines Gray
      400: 'gray.400', - 253 Keylines Gray?
      500: '#A7B2BB', - Fluid Gray
      600: '#B0B3B5', - Silver
      700: '#949494', - ADA Gray 3:1
      800: '#8F8F8F', - Secondary Gray
      900: '#666666', - Dark Gray
    }
    const colors = {
      // Common Colors
      transparent: 'transparent',
      currentColor: 'currentColor',
      black: '#2D333A',
      white: '#FFFFFF',
      // Color Overrides
      blue: blue,
      cyan: blue,
      orange: orange,
      red: red,
      green: green,
      gray: gray,
      adaGray: gray[700],
      componentGray: gray[200],
      error: red[500],
      fluidBlue: blue[500],
      midGray: gray[500], // fluid gray
      lightBlue: blue[200],
      keylineGray: gray[400],
      lightGray: gray[100],
      lightestGray: gray[50],
      secondaryGray: gray[800],
      darkGray: gray[900],
      success: green[500],
      successBg: 'rgba(0, 171, 97, 0.1)', // 10% success green
      darkBlue: blue[900], // team banner
      warning: orange[500], // battery warning
      warningBg: 'rgba(226, 140, 1, 0.1)',
      bronze: orange[900], - Driver Cup
      gold: orange[600], - Driver Cup
      silver: gray[600], - Driver Cup
    }
*/
