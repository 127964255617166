import { Geofence } from '@/api-resources/contracts/api-contracts/my_fleet/shared';

export const mapGeometryToMapPolygon = (
  geometry: Geofence['geometry']
): google.maps.Polygon => {
  const newCoords = geometry.coordinates[0].map(coords => {
    const [lng, lat] = coords;

    return { lat, lng };
  });

  return new google.maps.Polygon({
    paths: newCoords,
  });
};
