import {
  Action,
  ACTIONS,
  MapState,
} from '@/lib/context/GoogleMaps/reducer/map_actions';

export const ZOOM_LEVELS = {
  15: 1.11,
  14: 2.22,
  13: 4.43,
  12: 8.86,
  11: 17.73,
  10: 35.45,
};
export const initialState: MapState = {
  mapInstance: null,
  zoom: null,
  latLng: null,
  boundingBox: null,
  isMapLoaded: false,
};

export function googleMapReducer(state: MapState, action: Action): MapState {
  switch (action.type) {
    case ACTIONS.SET_MAP:
      return {
        ...state,
        mapInstance: action.payload,
      };

    case ACTIONS.SET_ZOOM:
      return {
        ...state,
        zoom: action.payload,
      };

    case ACTIONS.SET_LATLNG:
      return {
        ...state,
        latLng: action.payload,
      };

    case ACTIONS.SET_MAP_LOADED:
      return {
        ...state,
        isMapLoaded: action.payload,
      };

    case ACTIONS.SET_BOUNDING_BOX:
      return {
        ...state,
        boundingBox: action.payload,
      };

    default:
      throw new Error(`Unexpected Action: ${action}`);
  }
}
