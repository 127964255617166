import type {
  SystemStyleObject,
  TabProps as ChakraTabProps,
} from '@fluidtruck/core';
import {
  Button,
  chakra,
  Tooltip,
  useMultiStyleConfig,
  useTab,
} from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React from 'react';

export const tabStyles: SystemStyleObject = {
  outline: 'none',
  _focus: { outline: 'none' },
  fontSize: 'xl',
  alignContent: 'end',
  mx: 4,
  _disabled: {
    color: 'gray.400',
  },
};

type TabProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  ref?: any;
} & ChakraTabProps;

export const SearchTab = React.forwardRef(
  (props: TabProps, ref: React.ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation('search');

    const tabProps = useTab({ ...props, ref }) as TabProps;
    const styles = useMultiStyleConfig('Tabs', tabProps);

    const isDisabled = !!tabProps.disabled;

    const _css = {
      ...styles.tab,
      ...tabStyles,
    };

    return (
      <Button {...tabProps} __css={_css}>
        <Tooltip label={isDisabled ? t('searchAll.tabs.disabledSearch') : ''}>
          <chakra.span>{t('searchAll.tabs.searchSpecific')}</chakra.span>
        </Tooltip>
      </Button>
    );
  }
);
SearchTab.displayName = 'SearchTab';
