import { useContext } from 'react';

import {
  GMapsAPIShape,
  GoogleMapsApiContext,
} from '@/lib/context/GoogleMaps/GoogleMapsApiProvider';

export const useGoogleMapsApi = (): GMapsAPIShape => {
  const context = useContext(GoogleMapsApiContext);

  if (context === undefined) {
    throw new Error(
      'useGoogleMapsApi must be used within a GoogleMapsApiProvider'
    );
  }

  return context;
};
