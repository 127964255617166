import {
  Alert as TidalAlert,
  AlertDescription as TidalAlertDescription,
  AlertDescriptionProps,
  AlertIcon as TidalAlertIcon,
  AlertIconProps,
  AlertProps,
  AlertTitle as TidalAlertTitle,
  AlertTitleProps,
  forwardRef,
} from '@fluidtruck/core';
import { CheckOutlineIcon } from '@fluidtruck/icons';
import React, { ReactElement } from 'react';

import { MidText, TextProps } from '../typography/Common';

export const ErrorText = (props: TextProps): ReactElement => {
  return <MidText color="red.500" {...props} />;
};

export const SuccessText = (props: TextProps): ReactElement => {
  return <MidText color="green.500" {...props} />;
};

export type {
  AlertProps,
  AlertIconProps,
  AlertDescriptionProps,
  AlertTitleProps,
};
/** Alert Components
 * https://tidal.fluidtruck.com/docs/feedback/alert
 */
export const Alert = forwardRef<AlertProps, 'div'>((props, ref) => {
  const { status = 'error', ...sx } = props;
  return <TidalAlert ref={ref} status={status} {...sx} />;
});

export const AlertIcon = (props: AlertIconProps): ReactElement => {
  return <TidalAlertIcon {...props} />;
};

export const AlertTitle = (props: AlertTitleProps): ReactElement => {
  return <TidalAlertTitle {...props} />;
};

export const AlertDescription = (
  props: AlertDescriptionProps
): React.ReactElement => {
  return <TidalAlertDescription {...props} />;
};

/** AlertGroup
 * Composes all of the Alert Components
 * you can directly import the Alert components,
 * this is saves you importing multiple components.
 */
export interface AlertGroupProps extends AlertProps {
  alertProps?: AlertProps;
  descriptionProps?: AlertDescriptionProps;
  iconProps?: AlertIconProps;
  titleProps?: AlertTitleProps;
  description?: string;
  title?: string;
  isCentered?: boolean;
}

export const AlertGroup = forwardRef<AlertGroupProps, 'div'>((props, ref) => {
  const {
    alertProps,
    children,
    description,
    descriptionProps,
    iconProps,
    isCentered,
    status = 'error',
    title,
    titleProps,
    sx,
    ...rest
  } = props;

  const icon = React.isValidElement(iconProps?.icon) ? (
    React.cloneElement(<TidalAlertIcon />, {
      icon: iconProps?.icon,
      ...iconProps,
    })
  ) : (
    <TidalAlertIcon {...iconProps} />
  );
  const centered = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const styles = {
    ...(isCentered && centered),
    ...sx,
  };
  return (
    <TidalAlert ref={ref} status={status} sx={styles} {...rest} {...alertProps}>
      {icon}
      {title && (
        <TidalAlertTitle color="black" {...titleProps}>
          {title}
        </TidalAlertTitle>
      )}
      {description && (
        <TidalAlertDescription {...descriptionProps}>
          {description}
        </TidalAlertDescription>
      )}
      {children}
    </TidalAlert>
  );
});

/** Vertical Orientation
 * https://tidal.fluidtruck.com/docs/feedback/alert#orientation
 */
export const AlertSuccessVertical = (props: AlertGroupProps): JSX.Element => {
  const { sx, ...rest } = props;
  return (
    <AlertGroup
      status="success"
      orientation="vertical"
      sx={{ height: 'auto', mt: 4, ...sx }}
      iconProps={{ icon: CheckOutlineIcon, color: 'success', h: 12, w: 12 }}
      {...rest}
    />
  );
};
