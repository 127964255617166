import { chakra, Divider, Image } from '@fluidtruck/core';
import Cookies from 'js-cookie';
import moment from 'moment';
import { tz as momentTz } from 'moment-timezone';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  DTRPController,
  GrayText,
  SubHeading,
  SubmitButton,
  Text,
} from '@/base-components';
import { LocationAutocomplete } from '@/components/LocationAutocomplete';
import {
  useCalculateWeeksToDays,
  useWeekCalculator,
} from '@/features/search/weekCalculator/useWeekCalculator';
import { WeekTextWrapper } from '@/features/search/weekCalculator/WeekCalculator';
import {
  useGetOrgEligibleForMonthReservations,
  useOrganizationContext,
} from '@/hooks';
import { useReservationEstimate } from '@/lib/context';
import { currentLocation } from '@/utils';

import * as Styles from './styles';

const DEFAULT_START = moment().startOf('hour').add(2, 'hour');
const DEFAULT_END = moment().startOf('hour').add(3, 'hour');

const TYPES = [
  {
    icon: 'cargo-vans',
    size: '300-500 Cu Ft',
    title: 'vehicleTypes.cargoVan',
    value: 'cargo-van',
  },
  {
    icon: 'box-trucks',
    size: '400-1600 Cu Ft',
    title: 'vehicleTypes.boxTruck',
    value: 'box-truck',
  },
  {
    icon: 'pickups',
    size: '30-70 Cu Ft',
    title: 'vehicleTypes.pickupTruck',
    value: 'pickup-truck',
  },
  {
    icon: 'cars',
    size: '',
    title: 'vehicleTypes.carsSuv',
    value: 'car-suv',
  },
  {
    icon: 'trailers',
    size: '',
    title: 'vehicleTypes.TrailersMisc',
    value: 'misc-trailer',
  },
];

const SearchAll = () => {
  const { t } = useTranslation(['common', 'search']);
  const tz = momentTz.guess();
  const router = useRouter();
  const { calculateWeeksToDaysDate } = useCalculateWeeksToDays();
  const { numberOfWeeks } = useWeekCalculator();
  const [type, setType] = useState(TYPES[0].value);
  const [location, setLocation] = useState({
    ...Cookies.getJSON('serverLocation'),
    description: '',
  });
  const {
    context: { org: { organization } = {} },
  } = useOrganizationContext();

  const { updateEstimate } = useReservationEstimate();

  const isMontlyReservationEnabled = useGetOrgEligibleForMonthReservations({
    ...organization?.['ach-enabled'],
    ...organization?.['organization-settings'],
  });

  const { control, formState, handleSubmit, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      pickupDate: DEFAULT_START,
      dropoffDate: DEFAULT_END,
      pickupTime: DEFAULT_START.hour(),
      dropoffTime: DEFAULT_END.hour(),
    },
  });

  const handleTypeClick = (e, val) => {
    e.preventDefault();
    setType(val);
  };

  const setNewLocation = async () => {
    setLocation(await currentLocation());
  };

  useEffect(() => {
    setNewLocation();
  }, []);

  const submit = data => {
    const tz = momentTz.guess();
    const { pickupDate, pickupTime, dropoffTime, dropoffDate } = data;

    const query = qs.stringify({
      start: momentTz(pickupDate.hour(pickupTime).startOf('h'), tz).format(
        'MM/DD/YYYY h:mm a z'
      ),
      end: momentTz(dropoffDate.hour(dropoffTime).startOf('h'), tz).format(
        'MM/DD/YYYY h:mm a z'
      ),
      type,
      location: location.address,
    });

    updateEstimate({
      'pick-up': pickupDate.toISOString(),
      'drop-off': dropoffDate.toISOString(),
    });

    // NOTE: check is needed for a deep rooted issue
    // in /search that does not handle router.push() correctly
    // when this component is used while on /search.
    if (router.pathname.includes('/search')) {
      window.location.href = `/search?${query}`;
    }
    router.push({ pathname: '/search', query });
  };

  useEffect(() => {
    if (!numberOfWeeks) return;
    const [pickupDate, pickupTime] = getValues(['pickupDate', 'pickupTime']);
    const date = calculateWeeksToDaysDate(
      pickupDate?.clone().set('h', pickupTime).startOf('h')
    );
    setValue('dropoffDate', moment(date), { shouldValidate: true });
    setValue('dropoffTime', pickupTime);
  }, [numberOfWeeks]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Styles.Container>
        <Styles.VehicleTypeGrid>
          {TYPES.map(vehicleType => {
            const selected = vehicleType.value === type;

            const checkIcon = `https://storage.googleapis.com/fluid-tidal-assets-stage/svg/check${
              selected ? '' : '-hover'
            }.svg`;

            return (
              <Styles.TypeButton
                key={vehicleType.value}
                onClick={e => handleTypeClick(e, vehicleType.value)}
              >
                <Styles.TypeIcon>
                  <Styles.TypeCheck aria-selected={selected}>
                    <Image src={checkIcon} alt="" />
                  </Styles.TypeCheck>
                  <Image
                    src={`https://storage.googleapis.com/fluid-tidal-assets-stage/svg/${vehicleType.icon}.svg`}
                    alt=""
                  />
                </Styles.TypeIcon>
                <SubHeading>{t(vehicleType.title)}</SubHeading>
                <Text lineHeight="4">
                  {vehicleType.size || t('vehicleTypes.sizes.vary')}
                </Text>
              </Styles.TypeButton>
            );
          })}
        </Styles.VehicleTypeGrid>
        <Styles.LocationDatesGrid>
          <Styles.Location>
            <GrayText fontSize="sm" textAlign="left">
              {t('search:location')}
            </GrayText>
            <LocationAutocomplete
              setLocationAddress={setLocation}
              location={location}
              testId="popover-input-location"
            />
          </Styles.Location>
          <Divider orientation="vertical" />
          <chakra.div position="relative">
            <Styles.DtrpContainer>
              <DTRPController
                openDirection="up"
                maxHours={
                  organization?.['organization-settings']?.[
                    'book-with-purchase-order'
                  ] ||
                  organization?.['ach-enabled'] ||
                  organization?.['organization-settings']?.[
                    'credit-card-payg-enabled'
                  ]
                    ? 8760
                    : 720
                }
                control={control}
                labels={{
                  start: t('common:pickup'),
                  end: t('common:dropOff'),
                }}
                names={{
                  startDate: 'pickupDate',
                  startTime: 'pickupTime',
                  endDate: 'dropoffDate',
                  endTime: 'dropoffTime',
                }}
              />
            </Styles.DtrpContainer>
            {isMontlyReservationEnabled && (
              <chakra.span
                sx={{
                  h: '10px',
                  mt: '7px',
                  display: 'block',
                  zIndex: 'popover',
                  position: 'absolute',
                  top: '-1px',
                  left: '327px',
                }}
              >
                <WeekTextWrapper
                  options={{ offset: [0, 42] }}
                  originalDate={DEFAULT_START}
                  timezone={tz}
                />
              </chakra.span>
            )}
          </chakra.div>
          <Divider orientation="vertical" />
          <SubmitButton
            data-test-id="popover-button-search"
            isDisabled={!location || !formState.isValid}
            isLoading={formState.isSubmitting || formState.isSubmitSuccessful}
            mt={0}
            size="lg"
            flex="0 0 150px"
          >
            {t('button.search')}
          </SubmitButton>
        </Styles.LocationDatesGrid>
      </Styles.Container>
    </form>
  );
};

export default SearchAll;
