import {
  Link as TidalLink,
  LinkProps as TidalLinkProps,
} from '@fluidtruck/core';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ReactNode } from 'react';

/** Tidal Link as NextLink
 * @description: https://tidal.fluidtruck.com/docs/layout/link
 * To use the Link with Next.js, all you need to do is to wrap Link
 * with Next.js Link component and pass the passHref prop.
 * passHref Forces Next.js Link to send the href property to its child.
 */

type LinkProps = {
  children: ReactNode;
  className?: string;
  startIcon?: ReactNode;
  target?: string;
} & NextLinkProps &
  Omit<TidalLinkProps, 'href' | 'as'>;

const Link = ({
  as,
  children,
  className,
  startIcon,
  target,
  href,
  onClick,
  ...styleProps
}: LinkProps) => {
  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    color: 'fluidBlue',
    fontWeight: 'bold',
    textDecoration: 'none',
    ...styleProps,
  };

  const content = (
    <TidalLink
      className={className}
      onClick={onClick}
      href={href as string}
      sx={baseStyle}
      target={target}
      role="link"
    >
      {startIcon}
      {children}
    </TidalLink>
  );

  if (target === '_blank') {
    return content;
  }
  return (
    <NextLink href={href} as={as} passHref>
      {content}
    </NextLink>
  );
};

export default Link;
