import type { GroupBase, OptionBase, OptionGroup } from '@fluidtruck/core';

import * as ApiTypes from '@/api-resources/contracts/api-contracts/my_fleet/shared';
import type { OrgProfile } from '@/api-resources/contracts/api-contracts/organizations/shared';
import type { UserWithToken } from '@/types';

export type { UserWithToken, OptionBase };
/**
 * Initial Geofences
 * */

export interface InitialGeofenceShape {
  type: ApiTypes.GeofenceType;
  uuid: string;
  name: string;
  boundaryType: ApiTypes.GeofenceBoundaryType;
  timezone: string;
}

export enum MONITOR {
  DAILY = 'daily',
  CUSTOM = 'custom',
  NONE = 'none',
}
export enum NOTIFS {
  RANGE = 'range',
  REAL_TIME = 'realTime',
  SMS = 'sms',
  NONE = 'none',
}

export const daysArray = Object.values(ApiTypes.Day);

export interface TimeRangeFormShape {
  start?: string | number;
  end?: string | number;
}

export type DayRangeFormShape = {
  checked: boolean;
  times: TimeRangeFormShape[];
};

export type ScheduleFormShape = Record<ApiTypes.Day, DayRangeFormShape>;

export interface GeofenceMonitorFormState {
  dailyEnd?: string | number;
  dailyStart?: string | number;
  monitorType?: MONITOR;
  schedule?: ScheduleFormShape;
}

export interface GeofenceNotifsFormState {
  sendSMS: boolean;
  notificationType: NOTIFS;
  rangeEnd?: string | number;
  rangeStart?: string | number;
  userIds?: number[];
  selectedUsers?: UserMultiSelectShape['selectedUsers'];
}

export type FormState = {
  boundaryType: ApiTypes.GeofenceBoundaryType;
  userNotifications?: UserMultiSelectShape;
  monitoring?: GeofenceMonitorFormState;
  notifications?: GeofenceNotifsFormState;
  geofence?: ApiTypes.Geofence;
};

export type UserMultiSelectShape = {
  userOptions?: GroupBase<OptionGroup>['options'];
  selectedUsers?: GroupBase<OptionGroup>['options'];
};

/** geofence mapping functions */
export type FormatMonitoring = (
  data?: GeofenceMonitorFormState
) => ApiTypes.GeofenceMonitoring;

export type FormatNotifications = (
  data?: FormState
) => ApiTypes.GeofenceNotification[];

export type MapGeofenceNotification = (
  data?: ApiTypes.GeofenceNotification[]
) => GeofenceNotifsFormState;

export type MapGeofenceMonitoring = (
  data?: ApiTypes.GeofenceMonitoring
) => GeofenceMonitorFormState;

export type FormatDailyMonitoring = (
  data?: GeofenceMonitorFormState
) => ApiTypes.GeofenceMonitoring;

export type FormatCustomMonitoring = (
  schedule: ScheduleFormShape
) => ApiTypes.GeofenceMonitoring;

export type MapDayValues = (data: ApiTypes.TimeRange[]) => TimeRangeFormShape[];

export type MapAdminProfiles = (
  profiles?: OrgProfile[],
  userIds?: GeofenceNotifsFormState['userIds']
) => UserMultiSelectShape;
