import { ReactNode } from 'react';

import { LeftNavContainer } from '@/components/LeftNavMenu/components';
import { SystemStyleObject } from '@/types';

import { HideLeftNavContainer, NavigationWrapper } from '../containers';
import { useLayout } from '../context';
import * as Styles from '../styles';

/**
 * @description The layout container for each page
 * Contains the left navigation context hiding, opening & closing
 * Contains default page styles
 * @example
 * const Page = () => <PageComponents></PageComponents>
 * const pageSx = {
    pt: { base: 5, lg: 0 },
  };
 * Page.Layout = CommonLayout
 * Page.Sx = pageSx
 */

interface Props {
  children?: ReactNode;
  sx?: SystemStyleObject;
}

const CommonLayout = ({ children, sx }: Props) => {
  const { hideLeftNav } = useLayout();

  if (hideLeftNav) {
    return <HideLeftNavContainer>{children}</HideLeftNavContainer>;
  }

  return (
    <NavigationWrapper>
      <Styles.CommonContainerGrid data-test-id="common-layout-container">
        <LeftNavContainer />
        <Styles.CommonLayoutContent
          data-test-id="common-layout-content"
          sx={sx}
        >
          {children}
        </Styles.CommonLayoutContent>
      </Styles.CommonContainerGrid>
    </NavigationWrapper>
  );
};

export { CommonLayout };
