import { useState } from 'react';
import { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { ApiResponse } from '@/api-resources';
import { ApiResourceGET } from '@/api-resources/resources/resourceGET';
import { urlEmbedParams, urlEmbedQuery } from '@/api-resources/utils';
import { get } from '@/utils/helpers';

import { HandlerContract } from '../types';

export const buildPath = (url: string, body: any) => {
  const query = Object.keys(body)
    .map(key => {
      const val = encodeURIComponent(body[key] || '').toString();
      const regex = /%2C/gi;
      val.replace(regex, ','); // Decode commas to allow more delimited items.
      return `${key}=${val}`;
    })
    .join('&');

  if (!query) return url;
  return `${url}?${query}`;
};

interface SwrContract<TResponse extends ApiResponse> {
  config?: SWRConfiguration<TResponse['success']>;
  shouldFetch?: boolean;
}

export const useGET = <TPath extends keyof ApiResourceGET>(
  props: { path: TPath } & ApiResourceGET[TPath][0] &
    SwrContract<ApiResourceGET[TPath][1]>
) => {
  const shouldFetch = props.shouldFetch === undefined || props.shouldFetch;

  const url = (() => {
    let s = String(props.path);
    if (props.params) s = urlEmbedParams(s, props.params as any);
    if (props.query) s = buildPath(s, props.query);
    return s;
  })();

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    shouldFetch ? url : null,
    key => get(key, props.headers, props.body),
    { ...props.config }
  );

  return {
    data,
    error,
    isValidating,
    isLoading,
    mutate,
  };
};

export function useHandlerGET<TPath extends keyof ApiResourceGET>(props: {
  path: TPath;
}) {
  const [data, setData] = useState<ApiResourceGET[TPath][1]['success']>();

  const handler = async (
    args: Omit<ApiResourceGET[TPath][0], 'path'> &
      HandlerContract<ApiResourceGET[TPath][1]>
  ) => {
    try {
      let url: string = props.path;

      if (args.params) {
        url = urlEmbedParams(url, args.params);
      }

      if (args.query) {
        url = urlEmbedQuery(url, args.query);
      }

      const response:
        | ApiResourceGET[TPath][1]['success']
        | ApiResourceGET[TPath][1]['fail'] = await get(url);

      if ('messages' in response || 'message' in response) {
        const fail = response as any;
        if (fail?.message) throw new Error(fail.message);
        if (fail?.messages) throw new Error(JSON.stringify(fail?.messages));
        throw new Error('failed to complete POST request');
      }

      setData(response);
      args?.onSuccess?.(response);

      return response;
    } catch (err) {
      if (err instanceof Error) {
        args?.onError?.(err);
      } else {
        args?.onError?.(new Error('failed to complete GET request'));
      }

      return undefined;
    }
  };

  return { handler, data };
}
