import React, { ReactElement, ReactNode } from 'react';

import { Box, Grid } from '@/base-components';
import NavBar from '@/components/Nav/NavBar';

interface Props {
  children: ReactNode;
}

const DefaultLayout = ({ children }: Props): ReactElement | null => (
  <>
    <NavBar />
    <Grid overflow="scroll" data-test-id="default-layout-grid">
      <Box>{children}</Box>
    </Grid>
  </>
);

export { DefaultLayout };
