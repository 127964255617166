import { useCallback, useState } from 'react';

import * as Mappings from '../mappings';
import * as Types from '../types';

export const useFleetItemMapRouteState: Types.UseFleetItemMapRouteState = mapInstance => {
  const [fleetRoutePolylines, setFleetRoutePolylines] = useState<
    google.maps.Polyline[]
  >();
  const [fleetRouteMarkers, setFleetRouteMarkers] = useState<
    google.maps.Marker[]
  >();

  const onInitialize: Types.UseFleetItemMapRouteStateReturn['onInitialize'] = useCallback(
    (vehicleRouteData): void => {
      if (!vehicleRouteData) return;

      // initialize route boundary
      const routeBounds = new google.maps.LatLngBounds();

      // NOTE: move to mapping
      const routeMapped2latlng = vehicleRouteData.map(path => {
        const { lat, lng } = path;

        routeBounds.extend(new google.maps.LatLng(lat, lng));

        return `${lat},${lng}`;
      });

      mapInstance?.fitBounds(routeBounds);

      // create start/end markers
      const startEndMarkers = Mappings.mapFleetRoute2StartEndMarkers(
        vehicleRouteData,
        mapInstance
      );

      setFleetRouteMarkers(startEndMarkers);

      // split route into path chunks
      const chunkedCoordinates = Mappings.chunkArray(routeMapped2latlng);

      // map tp snapped coords
      const snappedCoordinatesResponse = Promise.all(
        chunkedCoordinates.map(Mappings.mapPaths2LatLng)
      );

      // create polylines + set map
      snappedCoordinatesResponse.then(latLngs => {
        const polylines = latLngs.map(latLng => {
          const routePolylines = Mappings.mapSnappedCoords2GooglePolyline(
            latLng
          );
          routePolylines.setMap(mapInstance);

          return routePolylines;
        });

        setFleetRoutePolylines(polylines);
      });
    },
    [mapInstance]
  );

  const onClear: Types.UseFleetItemMapRouteStateReturn['onClear'] = () => {
    setFleetRouteMarkers(state => {
      return state?.map(MARKER => {
        google.maps.event.clearInstanceListeners(MARKER);
        MARKER.setMap(null);

        return MARKER;
      });
    });

    setFleetRoutePolylines(state => {
      return state?.map(POLY => {
        google.maps.event.clearInstanceListeners(POLY);
        POLY.setMap(null);

        return POLY;
      });
    });
  };

  return {
    onInitialize,
    onClear,
    fleetRouteMarkers,
    fleetRoutePolylines,
  };
};
