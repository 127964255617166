import { useState } from 'react';

import { createContextProvider } from '@/utils/context';

import { RowsPerPageOptions, SORT_ORDER } from './types';

export const initialTableState = {
  sortField: 'id',
  sortOrder: SORT_ORDER.DESC,
  page: 0,
  rowsPerPage: 20 as RowsPerPageOptions,
  searchTerm: '',
};

export interface UseTidalTableProps {
  // children: ReactNode;
  initialSortField?: string;
  sortOrder?: SORT_ORDER;
  rowsPerPage?: number;
  rowsPerPageOptions?: RowsPerPageOptions[];
}

/**
 * @description
 * @example
    <TidalTableProvider
      value={useTidalTable({
        initialSortField: SORT_ORDER.DESC,
        sortOrder: 'title',
      })}
    >
      <TableComponents />
    </TidalTableProvider>
 */

export const useTidalTable = ({
  initialSortField,
  sortOrder: sortOrderProp,
  rowsPerPage: rowsPerPageProps,
}: UseTidalTableProps) => {
  const [sortField, setSortField] = useState(
    initialSortField || initialTableState.sortField
  );
  const [sortOrder, setSortOrder] = useState<SORT_ORDER>(
    sortOrderProp || SORT_ORDER.DESC
  );
  const [page, setPage] = useState(initialTableState.page);
  const [rowsPerPage, setRowsPerPage] = useState(
    rowsPerPageProps || initialTableState.rowsPerPage
  );
  const [searchTerm, setSearchText] = useState(initialTableState.searchTerm);

  const methods = {
    setSortOrder,
    setPage,
    setRowsPerPage,
    setSearchText,
    setSortField,
  };

  const state = {
    sortField,
    sortOrder,
    page,
    rowsPerPage,
    searchTerm,
  };

  return { state, methods };
};

type UseTidalTableReturn = ReturnType<typeof useTidalTable>;

export const [TidalTableProvider, useTidalTableContext] =
  createContextProvider<UseTidalTableReturn>({
    name: 'TidalTableContext',
    errorMessage:
      'You forgot to wrap your component with the TidalTableProvider',
  });
