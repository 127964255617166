import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps as TidalModalProps,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

/** ModalProps
 * https://tidal.fluidtruck.com/docs/overlay/modal#props
 */
type ContentProps = ModalContentProps & { 'data-test-id'?: string };

interface ModalProps extends TidalModalProps {
  bodyProps?: ModalBodyProps;
  footerProps?: ModalFooterProps;
  contentProps?: ContentProps;
  headerProps?: ModalHeaderProps;
  footerChildren?: React.ReactNode | string;
  noOverlay?: boolean;
  hideCloseButton?: boolean;
  children: React.ReactNode | string;
  title?: string;
}

export type { ModalProps };

/** Modal
 * https://tidal.fluidtruck.com/docs/overlay/modal
 * NOTE: to add a data-test-id, pass to specific elm,
 * ie: `contentProps={{ 'data-test-id': 'modal-add-payment' }}`
 */
export const ModalGroup = (props: ModalProps): ReactElement => {
  const {
    bodyProps,
    children,
    contentProps,
    footerChildren,
    footerProps,
    headerProps,
    noOverlay,
    title,
    hideCloseButton,
    ...rest
  } = props;
  return (
    <Modal isCentered {...rest}>
      {!noOverlay && <ModalOverlay />}
      <ModalContent {...contentProps}>
        {title && (
          <ModalHeader lineHeight="normal" {...headerProps}>
            {title}
          </ModalHeader>
        )}
        {!hideCloseButton && <ModalCloseButton />}
        <ModalBody {...bodyProps}>{children}</ModalBody>
        {footerChildren && (
          <ModalFooter {...footerProps}>{footerChildren}</ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
