import { useRouter } from 'next/router';

import { ApiRoute, useGET } from '@/api-resources';
import { downloadZip } from '@/utils/helpers';

import * as Types from './types';

export const useReservation = () => {
  const router = useRouter();
  const { id } = router.query;

  const {
    data: reservationData,
    error,
    mutate,
  } = useGET({
    path: ApiRoute.API_RESERVATIONS_ID,
    params: {
      reservationId: id as string,
    },
    shouldFetch: !!id,
    headers: { 'Content-Type': 'application/json; version=0.3' },
  });

  return {
    loading: !error && !reservationData,
    error,
    reservation: reservationData?.data,
    mutate,
  };
};

export const useReservations = ({
  status,
  orgId,
  page = 1,
  pageSize = 20,
  searchTerm = '',
  revalidateOnFocus = false,
}: Types.UseReservationArgs) => {
  const statusType = status
    ? Types.RESERVATION_STATUSES[status]
    : Object.values(Types.RESERVATION_STATUSES);

  const {
    data: statusRes,
    error,
    isValidating,
  } = useGET({
    path: ApiRoute.API_ORGANIZATIONS_ORGID_RESERVATIONS,
    shouldFetch: !!orgId,
    params: { orgId },
    query: {
      status: statusType.join(','),
      'page-number': page,
      'page-size': pageSize,
      'search-term': searchTerm.length ? searchTerm : '',
    },
    config: { revalidateOnFocus },
  });

  const pendingCount = statusRes?.data?.['pending-count'] || 0;
  const confirmedCount = statusRes?.data?.['confirmed-count'] || 0;
  const activeCount = statusRes?.data?.['active-count'] || 0;

  return {
    upcoming: Number(pendingCount) + Number(confirmedCount),
    active: activeCount,
    reservations: statusRes?.data?.entries || [],
    total: statusRes?.data?.total || 0,
    isLoading: !error && !statusRes,
    isError: error,
    isValidating,
  };
};

export const useDownloadInvoices = (ids: number[]) => {
  const idsParam = ids.join(',');

  return async () => {
    downloadZip(
      'receipt',
      ApiRoute.API_RESERVATIONS_INVOICE_DOWNLOAD,
      {},
      {
        'reservation-ids': idsParam,
      }
    );
  };
};
