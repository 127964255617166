import { ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import { createContext, ReactNode } from 'react';

// see: https://github.com/amplitude/experiment-node-server/blob/main/packages/ssr-demo/contexts/experimentContext.tsx

export interface ExperimentContextProps {
  experiment: ExperimentClient;
  getVariant: (flag: string) => Variant | undefined;
}

interface ExperimentProviderProps {
  experiment: ExperimentClient;
  children: ReactNode;
}

export const AmplitudeExperimentContext =
  createContext<ExperimentContextProps | null>(null);

export const AmplitudeExperimentProvider = ({
  experiment,
  children,
}: ExperimentProviderProps) => {
  const getVariant = (variantId: string) => {
    return experiment.variant(variantId);
  };

  return (
    <AmplitudeExperimentContext.Provider
      value={{
        experiment,
        getVariant,
      }}
    >
      {children}
    </AmplitudeExperimentContext.Provider>
  );
};
