import moment from 'moment';
import { useTranslation } from 'next-i18next';

import { Button } from '@/base-components';
import { FlexCol, FlexRow } from '@/base-components';

import type { WeeklyCalculatorShape } from './types';
import { useWeekCalculator } from './useWeekCalculator';

const formatContent = (numberOfWeeks: number) => {
  const { t } = useTranslation('search');
  if (numberOfWeeks >= 52) {
    return '(1 year)';
  }
  return t('numWeeks', { count: numberOfWeeks });
};

export function WeekButton({
  week,
  originalDate,
  timezone,
  onClick,
}: {
  week: number;
  originalDate: Date;
  timezone: string;
  onClick: WeeklyCalculatorShape['setNumberOfWeeks'];
}): JSX.Element {
  const FORMAT_DATE = 'MM/DD/YY';
  const buttonDate = moment
    .tz(moment(originalDate).add(week * 7, 'days'), timezone)
    .format(FORMAT_DATE);

  return (
    <Button
      key={week}
      sx={{
        my: 1,
        border: 'none',
        fontWeight: 'initial',
        fontSize: 14,
        color: 'initial',
        _hover: {
          backgroundColor: 'fluidBlue',
          color: 'white',
        },
      }}
      variant="ghost"
      size="xs"
      onClick={() => onClick(week)}
      data-test-id={`button-week-${week}`}
    >
      {buttonDate} {formatContent(week)}
    </Button>
  );
}

export const BodyContent = ({
  originalDate,
  timezone,
}: {
  originalDate: Date;
  timezone: string;
}): JSX.Element => {
  const { setNumberOfWeeks } = useWeekCalculator();

  return (
    <FlexCol>
      <FlexRow>
        <FlexCol flex="1 0 50%">
          {[4, 8, 12, 16, 20, 24, 28].map(num => (
            <WeekButton
              onClick={setNumberOfWeeks}
              key={`week-btn-${num}`}
              week={num}
              originalDate={originalDate}
              timezone={timezone}
            />
          ))}
        </FlexCol>
        <FlexCol flex="1 0 50%">
          {[32, 36, 40, 44, 48, 52].map(num => (
            <WeekButton
              onClick={setNumberOfWeeks}
              key={`week-btn-${num}`}
              week={num}
              originalDate={originalDate}
              timezone={timezone}
            />
          ))}
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};
