import { motion, SVGMotionProps, Transition } from 'framer-motion';
import React from 'react';

import { chakra } from '@/utils';

interface Props extends SVGMotionProps<any> {
  isOpen?: boolean;
  onClick?: () => void;
  height?: number;
  width?: number;
}

export const MenuToggle = ({
  isOpen = false,
  height = 16,
  width = 20,
  ...rest
}: Props): React.ReactElement => {
  const transition: Transition = {
    type: 'spring',
    stiffness: 260,
    damping: 20,
  };
  const variant = isOpen ? 'opened' : 'closed';
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 2,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  };
  let linePropsAttrs = {};
  linePropsAttrs = {
    stroke: 'rgb(0, 178, 227)',
    strokeLinecap: 'round',
    strokeWidth: 2,
    vectorEffect: 'non-scaling-stroke',
    initial: 'closed',
    animate: variant,
    transition,
  };
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;
  return (
    <chakra.div mr={4}>
      <motion.svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        {...rest}
      >
        <motion.line
          x1="0"
          x2={isOpen ? 4 : unitWidth - 1}
          y1="0"
          y2="0"
          variants={top}
          {...linePropsAttrs}
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="2"
          y2="2"
          variants={center}
          {...linePropsAttrs}
        />
        <motion.line
          x1="0"
          x2={isOpen ? 4 : unitWidth - 1.5}
          y1="4"
          y2="4"
          variants={bottom}
          {...linePropsAttrs}
        />
      </motion.svg>
    </chakra.div>
  );
};
