import { useRouter } from 'next/router';

import { useUser } from '@/hooks';
import { UserWithToken } from '@/types';
import { createContextProvider } from '@/utils';

export interface UseLayoutShape {
  defaultCollapsed: boolean;
  hideLeftNav: boolean;
  unverifiedUser: boolean;
  user: UserWithToken | null;
}

export const useLayout = (): UseLayoutShape => {
  const { user } = useUser();
  const router = useRouter();
  const { pathname } = router;

  const ignoredRoutes = [
    '/account/create',
    '/account/signup',
    '/book',
    '/book_cart',
    '/fleetsolutions/[orgId]/billing',
  ];

  const isManage = pathname.startsWith('/driver_management');
  const isFleet = pathname.startsWith('/myfleet');
  const isSearch = pathname.startsWith('/search');

  const hideLeftNavRoutes = ignoredRoutes.some(route =>
    pathname.startsWith(route)
  );

  // check if new user is not completed
  const authenticated = user?.email === null;
  const noUserName = !user?.first && !user?.last;
  const unverifiedNumber = !user?.['phone-number-verified'];
  const unverifiedUser = authenticated || noUserName || unverifiedNumber;

  const defaultCollapsed = isManage || isFleet || isSearch;
  const hideLeftNav = hideLeftNavRoutes || unverifiedUser || !user;

  return {
    user,
    defaultCollapsed,
    unverifiedUser,
    hideLeftNav,
  };
};

export type UseLayoutReturn = ReturnType<typeof useLayout>;

export const [LayoutProvider, useLayoutContext] =
  createContextProvider<UseLayoutReturn>({
    name: 'LayoutContext',
    errorMessage: 'You forgot to wrap your component with the LayoutProvider',
  });
