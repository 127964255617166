import {
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  TabProps,
  Tabs,
  TabsProps,
} from '@fluidtruck/core';
import { ReactElement, ReactNode } from 'react';

/* -----------------------------------------------------------
 * Common Tabs
 * https://tidal.fluidtruck.com/docs/disclosure/tabs
 * ----------------------------------------------------------- */

interface Props {
  tabData: { name: string; label: ReactNode; content: ReactNode }[];
  tabListProps?: TabListProps;
  tabPanelProps?: TabPanelProps;
  tabPanelsProps?: TabPanelsProps;
  tabsProps?: Omit<TabsProps, 'children'>;
  tabProps?: TabProps;
}

const TabsComponent = ({
  tabData,
  tabListProps,
  tabPanelProps,
  tabPanelsProps,
  tabsProps,
  tabProps,
}: Props): ReactElement => {
  return (
    <Tabs {...tabsProps}>
      <TabList {...tabListProps}>
        {tabData.map(tab => (
          <Tab
            key={tab.name}
            _focus={{ boxShadow: 'none' }}
            px={0}
            mr={6}
            {...tabProps}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels {...tabPanelsProps}>
        {tabData.map(panel => (
          <TabPanel
            key={panel.name}
            px={0}
            borderColor="transparent"
            {...tabPanelProps}
          >
            {panel.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export { TabsComponent };
