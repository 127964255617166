import moment from 'moment';

import * as Types from './types';

export const mapfleetRouting = (
  data: Types.FleetRoutingData
): Types.FleetRoutingMapped => {
  const eventsSummaryCount = (data.vehicleRouteEvents || [])?.reduce(
    (SUMMARY, EVENT) => {
      const updateSummary = { ...SUMMARY };
      switch (EVENT.greenDrivingType) {
        case 1:
          updateSummary[Types.FleetEventType.ACCELERATION] += 1;
          break;
        case 2:
          updateSummary[Types.FleetEventType.BRAKING] += 1;
          break;
        default:
          updateSummary[Types.FleetEventType.CORNERING] += 1;
          break;
      }
      return updateSummary;
    },
    Types.initialEventsSummaryCount
  );

  return {
    eventsSummaryCount,
    itemID: data.itemID,
    vehicleRoute:
      data?.vehicleRoute?.sort((a, b) =>
        moment(a.eventDate).diff(moment(b.eventDate), 'seconds')
      ) || null,
    vehicleRouteEvents: data.vehicleRouteEvents,
  };
};
