export enum ApiRoute {
  API_MYFLEET_ORGID_ITEMS_ITEMID_TELEMATICS_RECORDS = 'api/myfleet/:orgId/items/:itemId/telematics/records',
  API_V2_SIGNUP = 'api/v2/signup',
  API_BILLINGMETHODS_BILLINGMETHODID = 'api/billing_methods/:billingMethodId',
  API_BILLINGMETHODS_BILLINGMETHODID_VERIFY = 'api/billing_methods/:billingMethodId/verify',
  API_USERS_FORGOT_PASSWORD = 'api/users/forgot_password',
  API_USERS_VERIFY_PHONE_NUMBER = 'api/users/verify_phone_number',
  API_USERS_VERIFY_PASSWORD = 'api/users/verify_password',
  API_USERS = 'api/users',
  API_CALENDARS_ORGID_ASSIGNED_DRIVERS = 'api/calendars/:orgId/assigned_drivers',
  API_CALENDARS_ORGID_UNASSIGNED_DRIVERS = 'api/calendars/:orgId/unassigned_drivers',
  API_V2_CALENDARS_ORGID_RESERVED_VEHICLES = 'api/v2/calendars/:orgId/reserved_vehicles',
  API_CALENDARS_ORGID_UNASSIGNED_VEHICLES = 'api/calendars/:orgId/unassigned_vehicles',
  API_CALENDARS_ORGID_RESERVED_VEHICLES = 'api/calendars/:orgId/reserved_vehicles',
  API_CALENDARS_ORGID_ASSIGNMENTS_ASSIGNMENTID = 'api/calendars/:orgId/assignments/:assignmentId',
  API_CALENDARS_ORGID_ASSIGNMENTS = 'api/calendars/:orgId/assignments',
  API_V2_CALENDARS_ORGID_ASSIGNMENTS_ASSIGNMENTID = 'api/v2/calendars/:orgId/assignments/:assignmentId',
  API_V1_NOTIFICATIONS = 'api/v1/notifications',
  API_V1_MYFLEET_ORGID_GEOFENCES = 'api/v1/myfleet/:orgId/geofences',
  API_V1_MYFLEET_ORGID_GEOFENCES_GEOFENCEUUID = 'api/v1/myfleet/:orgId/geofences/:geofenceUuid',
  API_V1_MYFLEET_ORGID_GEOFENCES_GEOFENCEUUID_MONITORING = 'api/v1/myfleet/:orgId/geofences/:geofenceUuid/monitoring',
  API_V1_MYFLEET_ORGID_ITEMSPREVIEW = 'api/v1/myfleet/:orgId/items_preview',
  API_V1_MAINTENANCE_RECORDS = 'api/v1/maintenance/records',
  API_V3_ORGANIZATIONS_ORGID_PROFILES = 'api/v3/organizations/:orgId/profiles',
  API_MYFLEET_V1_ORGID_VEHICLES = 'api/myfleet/v1/:orgId/vehicles',
  API_ORGANIZATIONS_ORGID = 'api/organizations/:orgId',
  API_MYFLEET_V3_ORGID_VEHICLES = 'api/myfleet/v3/:orgId/vehicles',
  API_MESSAGES = 'api/messages',
  API_VEHICLES_VEHICLEID_AVAILABILITY = 'api/vehicles/:vehicleId/availability',
  API_ORGANIZATIONS_ORGID_INVITATIONS_ID_RESEND = 'api/organizations/:orgId/invitations/:id/resend',
  API_ORGANIZATIONS_ORGID_INVITATIONS_ID_DELETE = 'api/organizations/:orgId/invitations/:id',
  API_REPORT_ORGANIZATIONS_ORGID_RESERVATION_TRANSACTIONS = 'api/report/organizations/:orgId/reservation_transactions',
  API_SCORING_ORG_ORGID = 'api/scoring/org/:orgId',
  API_SCORING_USER_USERID = 'api/scoring/user/:userId',
  API_ORGANIZATIONS_ORGID_INVITATIONS_ID = 'api/organizations/:orgId/invitations/:id',
  API_ORGANIZATIONS_ORGID_RESERVATIONS = 'api/organizations/:orgId/reservations',
  API_RESERVATIONS_ID = 'api/reservations/:reservationId',
  API_RESERVATIONS_INVOICE_DOWNLOAD = 'api/reservations/invoices/download',
  API_RESERVATIONS_EXTEND_COSTESTIMATE = 'api/reservations/extend/cost_estimate',
  API_RESERVATIONS_EXTEND = 'api/reservations/extend',
  API_ITEMS_HOME = 'api/items/home',
  API_ITEMS = 'api/items',
  API_V1_RESERVATIONS_BILLING_METHOD = 'api/v1/reservations/billing_method',
}
