import { useReducer, useState } from 'react';

import * as Types from '@/types/driver-route-types';

// simple toggle hook
export const useToggle: Types.UseToggleReducerHook = (initVal = false) => {
  return useReducer(state => !state, initVal);
};

export const useCustomDatePicker = (): Types.UseCustomDatePickerHookValues => {
  // needed to have a queue startDate value as a placeholder to...
  // prevent map from rerendering before having selected an endDate
  const [queueStartDate, setQueueStartDate] = useState<Types.FluidDate>();
  const [showDatePicker, toggleShowDatePicker] = useToggle(false);
  const [
    customDateRange,
    setCustomDateRange,
  ] = useState<Types.FluidDateRange>();

  const handleCustomDateRangeSelect: Types.CustomDateRangeSelect = ([
    start,
    end,
  ]) => {
    const startDate = start;
    const endDate = end;

    // both start and end dates are provided
    if (startDate && endDate) {
      // reset queue start date
      setQueueStartDate(undefined);

      // set start time to 00:00; end time to 23:59
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59);

      // set custom date range
      setCustomDateRange({ startDate, endDate });

      // close date picker
      toggleShowDatePicker();
    }

    // only start date provided
    if (startDate && !endDate) {
      // set queue start date
      setQueueStartDate(startDate);
    }

    if (!startDate && !endDate) {
      setQueueStartDate(undefined);
      setCustomDateRange({ startDate: undefined, endDate: undefined });
    }
  };

  const getInputValue: Types.UseCustomDatePickerHookValues['getInputValue'] = placeholder => {
    // queue value exists; return only the string start queue date
    if (queueStartDate) {
      return `${queueStartDate.toLocaleDateString('en-US')} - `;
    }

    // date range exists; return stringed date range
    if (
      customDateRange &&
      customDateRange.startDate &&
      customDateRange.endDate
    ) {
      return `${customDateRange?.startDate?.toLocaleDateString(
        'en-US'
      )} - ${customDateRange?.endDate?.toLocaleDateString('en-US')}`;
    }

    // neither queue or date range set; return empty placeholder
    return placeholder;
  };

  return {
    queueStartDate,
    showDatePicker,
    getInputValue,
    toggleShowDatePicker,
    customDateRange,
    setCustomDateRange,
    handleCustomDateRangeSelect,
  };
};
