import { Box, Grid } from '@/base-components';
import { heights } from '@/theme';
import { chakra } from '@/utils';

const { searchFilter, topNav, topNavMobile } = heights;

const fleetMapHeight = `calc(100% - ${topNavMobile}px)`;

const heightOffset = {
  base: `calc(100% - ${topNavMobile}px)`,
  lg: `calc(100vh - ${topNav}px)`,
};

/* Search Page - search-layout-container */
/* Must use style conditional to keep
 * search components mounted after logging in
 */
export const commonLayoutGridSx = {
  display: 'grid',
  position: { base: 'relative', lg: 'fixed' },
  height: heightOffset,
  gridTemplateColumns: { base: '1fr', lg: 'auto 1fr auto' },
  width: '100%',
};

/* common-layout-container */
export const CommonContainerGrid = chakra('div', {
  // NOTE: fixed on desktop for LeftNav & scroll for right content
  baseStyle: commonLayoutGridSx,
  label: 'CommonContainerGrid',
});

/* Search Page No User - search-layout-container */
/* Must use style conditional to keep
 * search components mounted after logging in
 */
export const signedOutSx = {
  position: { base: 'relative', lg: 'fixed' },
  height: {
    base: `calc(100% - ${searchFilter}px)`,
    lg: `calc(100vh - ${topNav}px)`,
  },
  width: '100%',
};

/* search-layout-content - children wrapper */
export const SearchLayoutContent = chakra(Box, {
  baseStyle: {
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },
  label: 'SearchLayoutContent',
});

/* common-layout-content */
export const CommonLayoutContent = chakra(Box, {
  baseStyle: {
    width: '100%',
    height: '100%',
    padding: { base: ' 40px 20px', lg: '80px 40px 80px 88px' },
    overflow: 'scroll',
  },
  label: 'CommonLayoutContent',
});

/* fleet-map-layout-container */
export const FleetMapLayoutGrid = chakra(Grid, {
  // NOTE: fixed on desktop for LeftNav & scroll for right content
  baseStyle: {
    position: { base: 'relative', lg: 'fixed' },
    height: { base: fleetMapHeight, lg: '100%' },
    width: '100%',
    gridTemplateColumns: { base: '1fr', lg: 'auto 1fr auto' },
  },
  label: 'FleetMapLayoutGrid',
});

/* fleet-map-layout-content */
export const FleetMapContent = chakra(Box, {
  baseStyle: {
    height: '100%',
    overflow: 'hidden',
  },
  label: 'FleetMapContent',
});

/* hide-nav-content */
export const HideNavContainer = chakra(Box, {
  baseStyle: {
    height: `100%`,
    overflow: 'scroll',
    padding: {
      base: '20px 20px 60px',
      lg: '80px 40px 100px',
    },
  },
  label: 'HideNavContainer',
});

// DriverScore Page
export const DriverScoreDefaultGrid = chakra(Grid, {
  baseStyle: {
    h: '100%',
    w: '100%',
    gridTemplateRows: {
      base: `${heights.topNavMobile}px 1fr`,
      lg: `${heights.topNav}px 1fr`,
    },
  },
  label: 'DriverScoreDefaultGrid',
});

export const DriverScoreBody = chakra(Box, {
  baseStyle: {
    overflowX: 'auto',
  },
  label: 'DriverScoreBody',
});

export const DriverScoreContent = chakra(Box, {
  baseStyle: {
    mt: '0',
    mb: 12,
    mx: 'auto',
    textAlign: 'center',
    px: { base: 6, lg: 0 },
    width: { base: '100%', md: '80%', lg: '60%', xl: '40%' },
  },
  label: 'DriverScoreContent',
});
