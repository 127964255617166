import type {
  ButtonGroupProps,
  ButtonProps as TBProps,
} from '@fluidtruck/core';
import { ButtonGroup } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button } from './Buttons';

type ButtonProps = TBProps & {
  'data-test-id'?: string;
  ref?: React.RefObject<HTMLButtonElement>;
};
type Props = {
  /**
   * @default alignContent="center"
   */
  container?: ButtonGroupProps;
  destructive?: {
    /**
     * @default variant="solidLight"
     */
    props?: ButtonProps;
    /**
     * @default "Delete"
     */
    text?: string;
  };
  primary?: {
    /**
     * @default type="submit"
     */
    props?: Omit<ButtonProps, 'onClick'>;
    /**
     * @default "Save"
     */
    text?: string;
  };
  secondary?: {
    /**
     * @default variant="outline"
     */
    props?: ButtonProps;
    /**
     * @default "Cancel"
     */
    text?: string;
  };
};

/** ActionButtons
 * @description Button Group for form actions such as Save/Cancel and/or Delete
 * @example
 * <ActionsButtons
 *     destructive={{
 *        props: { onClick: deleteSomething },
 *     }}
 *     primary={{
 *         props: { isLoading: isSubmitting, onClick: saveSomething  },
 *     }}
 *     secondary={{
 *         props: { onClick: cancelAction  },
 *     }}
 *  />
 */
export const ActionButtons = ({
  container,
  secondary,
  primary,
  destructive,
}: Props) => {
  const { t } = useTranslation(['common']);
  const containerProps = {
    bp: 'sm',
    ...container,
  };
  return (
    <ButtonGroup alignContent="center" {...containerProps}>
      {secondary && (
        <Button variant="outline" {...secondary.props}>
          {secondary.text || t('common:button.cancel')}
        </Button>
      )}
      {destructive && (
        <Button colorScheme="red" {...destructive.props}>
          {destructive.text || t('common:button.delete')}
        </Button>
      )}
      {primary && (
        <Button type="submit" {...primary.props}>
          {primary.text || t('common:button.save')}
        </Button>
      )}
    </ButtonGroup>
  );
};
