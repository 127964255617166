import { ApiRoute, useGET } from '@/api-resources';
import { downloadZip } from '@/utils/helpers';

export const useTransactions = ({
  orgId,
  pageNumber = 1,
  pageSize = 20,
  searchTerm = '',
}) => {
  const { data, error } = useGET({
    path: ApiRoute.API_REPORT_ORGANIZATIONS_ORGID_RESERVATION_TRANSACTIONS,
    params: { orgId },
    query: {
      'page-number': pageNumber,
      'page-size': pageSize,
      'search-term': searchTerm,
    },
    shouldFetch: !!orgId,
    headers: { 'Content-Type': 'application/json; version=0.3' },
  });

  return {
    transactions: data?.data?.entries || [],
    total: data?.data?.total,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useDownloadTransactions = () => {
  return async chargeIds => {
    downloadZip(
      'transactions',
      'api/reservations/invoices/payments/download',
      {
        'Content-Type': 'application/zip; version=0.2',
      },
      {
        'charge-ids': chargeIds.join(','),
      }
    );
  };
};
