import { UserIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

import {
  Box,
  Button,
  Divider,
  FlexCol,
  FlexRow,
  SpanText,
} from '@/base-components';
import Link from '@/base-components/Link';
import { useOrganizationContext, useUser } from '@/hooks';
import { CartContext, useReservationEstimate } from '@/lib/context';

const ProfileDropdown = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useUser();
  const { actions } = useOrganizationContext();
  const { removeCart } = useContext(CartContext);
  const { removeAllEstimateStorage } = useReservationEstimate();

  const handleLogout = () => {
    actions.setOrg({
      newOrg: undefined,
      updateRoute: false,
      makeRequest: false,
    });
    removeCart();
    removeAllEstimateStorage();
    logout();
  };

  return (
    <Box data-test-id="profile-dropdown">
      {children}
      <Divider my="24px" />
      <FlexCol>
        <FlexRow>
          <UserIcon width={5} height={5} color="gray.500" />
          <Link as="/account" sx={{ fontWeight: 'normal' }} href="/account">
            <SpanText
              color="blackAlpha.800"
              pl={4}
              fontSize="lg"
              cursor="pointer"
            >
              {t('common:accountTitle')}
            </SpanText>
          </Link>
        </FlexRow>
        <Button
          my={4}
          data-test-id="button-logout"
          width="100%"
          size="sm"
          variant="outline"
          onClick={handleLogout}
        >
          {t('common:signOut')}
        </Button>
      </FlexCol>
    </Box>
  );
};

export default ProfileDropdown;
