import { SWRResponse } from 'swr';

import type { Reservation } from '@/types/reservation-types';
import { ReservationStatus } from '@/types/reservation-types';

export enum StatusQuery {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  PAST = 'past',
}

export const RESERVATION_STATUSES = {
  [StatusQuery.UPCOMING]: [
    ReservationStatus.PENDING,
    ReservationStatus.PENDING_ADMIN_APPROVAL,
    ReservationStatus.APPROVED,
  ],
  [StatusQuery.ACTIVE]: [ReservationStatus.ACTIVE, ReservationStatus.RETURNED],
  [StatusQuery.PAST]: [
    ReservationStatus.COMPLETED,
    ReservationStatus.DENIED,
    ReservationStatus.CANCELLED,
  ],
};

export type UseReservationArgs = {
  status: StatusQuery;
  orgId: string | number;
  page: string | number;
  pageSize: string | number;
  searchTerm: string;
  revalidateOnFocus?: boolean;
};

export type UseReservationShape = {
  reservation: Reservation | Record<string, unknown>;
  error?: Error;
  loading: boolean;
  mutate: SWRResponse<Reservation, Error>['mutate'];
};
