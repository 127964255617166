import { Box } from '@fluidtruck/core';
import React, { ReactNode } from 'react';

import { LeftNavContainer } from '@/components/LeftNavMenu/components';

import { NavigationWrapper } from '../containers';
import { useLayout } from '../context';
import * as Styles from '../styles';
/**
 * @description Search Page layout container
 * Contains the left navigation context hiding, opening & closing
 * Contains default page styles
 * @example
 * const Page = () => <PageComponents></PageComponents>
 * Page.Layout = SearchLayout
 */

interface Props {
  children?: ReactNode;
}

const SearchLayout = ({ children }: Props) => {
  const { hideLeftNav } = useLayout();

  const searchLayoutContainer = hideLeftNav
    ? Styles.signedOutSx
    : Styles.commonLayoutGridSx;

  return (
    <NavigationWrapper>
      <Box sx={searchLayoutContainer} data-test-id="search-layout-container">
        {!hideLeftNav && <LeftNavContainer />}
        <Styles.SearchLayoutContent data-test-id="search-layout-content">
          {children}
        </Styles.SearchLayoutContent>
      </Box>
    </NavigationWrapper>
  );
};

export { SearchLayout };
