/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 20
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '',
  development: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '20',
    branch: 'main',
    source: 'web',
    versionId: '50c1e1e0-0725-404d-951f-d8fa7e1aea27'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface AmplitudePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Domain"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Location"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
}

export interface AbandonReservationProperties {
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * List of item id(s) associated with a booking.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | number |
   */
  itemIds?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * Time that associated with when a reservation will end prior to possibly extending that reservation.
   */
  reservationEndTime?: string;
  /**
   * Property that is associated with when a reservation will begin.
   */
  reservationStartTime?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface AddBookingPaymentMethodProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  billingMethodId?: number;
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ButtonClickedProperties {
  buttonText?: string;
  entityId?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  pageName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ClickDeliverySearchButtonProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ClickDeliverySearchIconProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface CompleteBookingReservationProperties {
  /**
   * web-mobile or web-desktop
   */
  deviceType?: string;
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * Optional property that is associated with the insurance option that a user selects on the booking page prior to completing a reservation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | premium_vehicle_insurance, basic_vehicle_insurance, declined_vehicle_insurance |
   */
  insuranceType?: "premium_vehicle_insurance" | "basic_vehicle_insurance" | "declined_vehicle_insurance";
  /**
   * List of item id(s) associated with a booking.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | number |
   */
  itemIds?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * Property associated with the promo code a user uses on the booking reservation page prior to completing a reservation.
   */
  promoCode?: string;
  /**
   * Time that associated with when a reservation will end prior to possibly extending that reservation.
   */
  reservationEndTime?: string;
  /**
   * Property that is associated with when a reservation will begin.
   */
  reservationStartTime?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface CompleteDeliveryReservationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface DisplayDeliverySearchButtonProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ErrorAddingPaymentMethodProperties {
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ErrorBookingReservationProperties {
  errorMessage?: string;
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface LoginProperties {
  firstName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | booking, delivery, home |
   */
  flow?: "booking" | "delivery" | "home";
  lastName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface LogoutProperties {
  firstName?: string;
  lastName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface SelectDeliveryVehicleProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  vehicleId?: number;
  vehicleType: string;
}

export interface SignupProperties {
  /**
   * This property designates which "flow" a user is in specific to logging in and creating a new account (sign up). The three possible flow values are "booking", "delivery" and "home".
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | home, delivery, booking |
   */
  flow?: "home" | "delivery" | "booking";
}

export interface VehicleDetailsReserveProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ViewBookingReviewPageProperties {
  deviceType?: string;
  /**
   * Optional property whose value is set to 'true' when a user has more than one vehicle in their cart and is in the cart booking flow.
   */
  hasBookingCart?: boolean;
  /**
   * Optional property that is associated with the insurance option that a user selects on the booking page prior to completing a reservation.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | premium_vehicle_insurance, basic_vehicle_insurance, declined_vehicle_insurance |
   */
  insuranceType?: "premium_vehicle_insurance" | "basic_vehicle_insurance" | "declined_vehicle_insurance";
  /**
   * List of item id(s) associated with a booking.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | number |
   */
  itemIds?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * Property associated with the promo code a user uses on the booking reservation page prior to completing a reservation.
   */
  promoCode?: string;
  /**
   * Time that associated with when a reservation will end prior to possibly extending that reservation.
   */
  reservationEndTime?: string;
  /**
   * Property that is associated with when a reservation will begin.
   */
  reservationStartTime?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ViewDeliveryBookingReviewPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  orgId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ViewVehicleDetailsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface ViewVehicleOnMapProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  userId?: number;
}

export interface WebSearchProperties {
  dropOff: string;
  location: string;
  pickUp: string;
  vehicleType: string;
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AbandonReservation implements BaseEvent {
  event_type = 'abandonReservation';

  constructor(
    public event_properties?: AbandonReservationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddBookingPaymentMethod implements BaseEvent {
  event_type = 'addBookingPaymentMethod';

  constructor(
    public event_properties?: AddBookingPaymentMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddVehicleAndBookMore implements BaseEvent {
  event_type = 'addVehicleAndBookMore';
}

export class ButtonClicked implements BaseEvent {
  event_type = 'buttonClicked';

  constructor(
    public event_properties: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDeliverySearchButton implements BaseEvent {
  event_type = 'clickDeliverySearchButton';

  constructor(
    public event_properties?: ClickDeliverySearchButtonProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDeliverySearchIcon implements BaseEvent {
  event_type = 'clickDeliverySearchIcon';

  constructor(
    public event_properties?: ClickDeliverySearchIconProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloseReservationSummary implements BaseEvent {
  event_type = 'closeReservationSummary';
}

export class CompleteBookingReservation implements BaseEvent {
  event_type = 'completeBookingReservation';

  constructor(
    public event_properties?: CompleteBookingReservationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompleteDeliveryReservation implements BaseEvent {
  event_type = 'completeDeliveryReservation';

  constructor(
    public event_properties?: CompleteDeliveryReservationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinueToBook implements BaseEvent {
  event_type = 'continueToBook';
}

export class CreateAccountClick implements BaseEvent {
  event_type = 'createAccountClick';
}

export class DisplayDeliverySearchButton implements BaseEvent {
  event_type = 'displayDeliverySearchButton';

  constructor(
    public event_properties?: DisplayDeliverySearchButtonProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorAddingPaymentMethod implements BaseEvent {
  event_type = 'errorAddingPaymentMethod';

  constructor(
    public event_properties?: ErrorAddingPaymentMethodProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorBookingReservation implements BaseEvent {
  event_type = 'errorBookingReservation';

  constructor(
    public event_properties?: ErrorBookingReservationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ForgotPassword implements BaseEvent {
  event_type = 'forgotPassword';
}

export class Login implements BaseEvent {
  event_type = 'login';

  constructor(
    public event_properties?: LoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginButtonClick implements BaseEvent {
  event_type = 'loginButtonClick';
}

export class LoginTabClick implements BaseEvent {
  event_type = 'loginTabClick';
}

export class Logout implements BaseEvent {
  event_type = 'logout';

  constructor(
    public event_properties?: LogoutProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PromoCodeAttempt implements BaseEvent {
  event_type = 'promoCodeAttempt';
}

export class SelectDeliveryVehicle implements BaseEvent {
  event_type = 'selectDeliveryVehicle';

  constructor(
    public event_properties: SelectDeliveryVehicleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Signup implements BaseEvent {
  event_type = 'signup';

  constructor(
    public event_properties?: SignupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupTabClick implements BaseEvent {
  event_type = 'signupTabClick';
}

export class VehicleDetailsReserve implements BaseEvent {
  event_type = 'vehicleDetailsReserve';

  constructor(
    public event_properties?: VehicleDetailsReserveProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewBookingReviewPage implements BaseEvent {
  event_type = 'viewBookingReviewPage';

  constructor(
    public event_properties?: ViewBookingReviewPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewDeliveryBookingReviewPage implements BaseEvent {
  event_type = 'viewDeliveryBookingReviewPage';

  constructor(
    public event_properties?: ViewDeliveryBookingReviewPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewDeliveryBookingSuccessPage implements BaseEvent {
  event_type = 'viewDeliveryBookingSuccessPage';
}

export class ViewVehicleDetails implements BaseEvent {
  event_type = 'viewVehicleDetails';

  constructor(
    public event_properties?: ViewVehicleDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewVehicleOnMap implements BaseEvent {
  event_type = 'viewVehicleOnMap';

  constructor(
    public event_properties?: ViewVehicleOnMapProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebSearch implements BaseEvent {
  event_type = 'webSearch';

  constructor(
    public event_properties: WebSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Owner: Michael Oakley
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * abandonReservation
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/abandonReservation)
   *
   * Event that is fired when a reservation is abandoned in the booking flow.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. hasBookingCart)
   * @param options Amplitude event options.
   */
  abandonReservation(
    properties?: AbandonReservationProperties,
    options?: EventOptions,
  ) {
    return this.track(new AbandonReservation(properties), options);
  }

  /**
   * addBookingPaymentMethod
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/addBookingPaymentMethod)
   *
   * Event that is fired when a user successfully adds a payment method while in the booking flow (cart flow when hasBookingCart is set to 'true').
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. billingMethodId)
   * @param options Amplitude event options.
   */
  addBookingPaymentMethod(
    properties?: AddBookingPaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddBookingPaymentMethod(properties), options);
  }

  /**
   * addVehicleAndBookMore
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/addVehicleAndBookMore)
   *
   * When a user clicks "Add Vehicle & Book More" on the Reservation Summary panel
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  addVehicleAndBookMore(
    options?: EventOptions,
  ) {
    return this.track(new AddVehicleAndBookMore(), options);
  }

  /**
   * buttonClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/buttonClicked)
   *
   * Any time a button (Button, CloseButton, IconButton, PillButton, AlertButton, SubmitButton, and LinkButton) is clicked on the page, with button text, page name, userId, orgId, and entityId
   *
   * Owner: Jordan Triplett
   *
   * @param properties The event's properties (e.g. buttonText)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * clickDeliverySearchButton
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/clickDeliverySearchButton)
   *
   * Click event associated with the number of times the Delivery button on the search page has been clicked by users.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  clickDeliverySearchButton(
    properties?: ClickDeliverySearchButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickDeliverySearchButton(properties), options);
  }

  /**
   * clickDeliverySearchIcon
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/clickDeliverySearchIcon)
   *
   * Click event associated with the number of times the Delivery search icon on the deliveries page has been clicked by users. This shows how many times users have actually submitted a search for delivery vehicles.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  clickDeliverySearchIcon(
    properties?: ClickDeliverySearchIconProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickDeliverySearchIcon(properties), options);
  }

  /**
   * closeReservationSummary
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/closeReservationSummary)
   *
   * When the 'X' button is clicked within the Reservation Summary panel
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  closeReservationSummary(
    options?: EventOptions,
  ) {
    return this.track(new CloseReservationSummary(), options);
  }

  /**
   * completeBookingReservation
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/completeBookingReservation)
   *
   * Event that is fired when a reservation is successfully booked/created at the end of the booking flow (cart flow when hasBookingCart is set to 'true').
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. deviceType)
   * @param options Amplitude event options.
   */
  completeBookingReservation(
    properties?: CompleteBookingReservationProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompleteBookingReservation(properties), options);
  }

  /**
   * completeDeliveryReservation
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/completeDeliveryReservation)
   *
   * Event that is fired when a user successfully completes a delivery reservation (goes through entire flow from start to finish).
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  completeDeliveryReservation(
    properties?: CompleteDeliveryReservationProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompleteDeliveryReservation(properties), options);
  }

  /**
   * continueToBook
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/continueToBook)
   *
   * When a user clicks "Continue to Book" on the Reservation Summary panel
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  continueToBook(
    options?: EventOptions,
  ) {
    return this.track(new ContinueToBook(), options);
  }

  /**
   * createAccountClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/createAccountClick)
   *
   * Any clicks on the "Create Account" button on the account/signup  page as well as the signup modal
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  createAccountClick(
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountClick(), options);
  }

  /**
   * displayDeliverySearchButton
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/displayDeliverySearchButton)
   *
   * Event associated with the number of times the Delivery button on the search page has been shown.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  displayDeliverySearchButton(
    properties?: DisplayDeliverySearchButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new DisplayDeliverySearchButton(properties), options);
  }

  /**
   * errorAddingPaymentMethod
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/errorAddingPaymentMethod)
   *
   * Event that is fired when a user received a failed response when attempting to add a payment method.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. hasBookingCart)
   * @param options Amplitude event options.
   */
  errorAddingPaymentMethod(
    properties?: ErrorAddingPaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorAddingPaymentMethod(properties), options);
  }

  /**
   * errorBookingReservation
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/errorBookingReservation)
   *
   * Event that is fired when a user receives a failed response when attempting to book a reservation on the /book page.
   *
   * Owner: jchavez@fluidtruck.com
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  errorBookingReservation(
    properties?: ErrorBookingReservationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorBookingReservation(properties), options);
  }

  /**
   * forgotPassword
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/forgotPassword)
   *
   * Any clicks on the "Forgot Password" button on the account/login page as well as the login modal
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  forgotPassword(
    options?: EventOptions,
  ) {
    return this.track(new ForgotPassword(), options);
  }

  /**
   * login
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/login)
   *
   * testing
   *
   * Owner: Michael Oakley
   *
   * @param properties The event's properties (e.g. firstName)
   * @param options Amplitude event options.
   */
  login(
    properties?: LoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new Login(properties), options);
  }

  /**
   * loginButtonClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/loginButtonClick)
   *
   * Any clicks on the "Log In" button on the account/login page as well as the login modal
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  loginButtonClick(
    options?: EventOptions,
  ) {
    return this.track(new LoginButtonClick(), options);
  }

  /**
   * loginTabClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/loginTabClick)
   *
   * Any clicks on the login tab on the account/signup or account/login pages as well as the login/signup modal
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  loginTabClick(
    options?: EventOptions,
  ) {
    return this.track(new LoginTabClick(), options);
  }

  /**
   * logout
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/logout)
   *
   * Owner: Michael Oakley
   *
   * @param properties The event's properties (e.g. firstName)
   * @param options Amplitude event options.
   */
  logout(
    properties?: LogoutProperties,
    options?: EventOptions,
  ) {
    return this.track(new Logout(properties), options);
  }

  /**
   * promoCodeAttempt
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/promoCodeAttempt)
   *
   * When the "Apply" button is clicked for Promo Codes
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  promoCodeAttempt(
    options?: EventOptions,
  ) {
    return this.track(new PromoCodeAttempt(), options);
  }

  /**
   * selectDeliveryVehicle
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/selectDeliveryVehicle)
   *
   * Click event associated with the number of times an actual vehicle is selected from the deliveries page.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  selectDeliveryVehicle(
    properties: SelectDeliveryVehicleProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectDeliveryVehicle(properties), options);
  }

  /**
   * signup
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/signup)
   *
   * Event that is fired with a user creates an account.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. flow)
   * @param options Amplitude event options.
   */
  signup(
    properties?: SignupProperties,
    options?: EventOptions,
  ) {
    return this.track(new Signup(properties), options);
  }

  /**
   * signupTabClick
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/signupTabClick)
   *
   * Any clicks on the signup tab on the account/signup or account/login pages as well as the login/signup modal
   *
   * Owner: Jordan Triplett
   *
   * @param options Amplitude event options.
   */
  signupTabClick(
    options?: EventOptions,
  ) {
    return this.track(new SignupTabClick(), options);
  }

  /**
   * vehicleDetailsReserve
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/vehicleDetailsReserve)
   *
   * When a user clicks "Reserve" within the Vehicle Details panel
   *
   * Owner: Jordan Triplett
   *
   * @param properties The event's properties (e.g. userId)
   * @param options Amplitude event options.
   */
  vehicleDetailsReserve(
    properties?: VehicleDetailsReserveProperties,
    options?: EventOptions,
  ) {
    return this.track(new VehicleDetailsReserve(properties), options);
  }

  /**
   * viewBookingReviewPage
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/viewBookingReviewPage)
   *
   * Event that is fired when a user visits the booking review page while in the booking flow (cart flow when hasBookingCart is set to 'true').
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. deviceType)
   * @param options Amplitude event options.
   */
  viewBookingReviewPage(
    properties?: ViewBookingReviewPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewBookingReviewPage(properties), options);
  }

  /**
   * viewDeliveryBookingReviewPage
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/viewDeliveryBookingReviewPage)
   *
   * Event that is fired when a user views the delivery booking review details (checkout) page.
   *
   * Owner: Kurt Miller
   *
   * @param properties The event's properties (e.g. orgId)
   * @param options Amplitude event options.
   */
  viewDeliveryBookingReviewPage(
    properties?: ViewDeliveryBookingReviewPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewDeliveryBookingReviewPage(properties), options);
  }

  /**
   * viewDeliveryBookingSuccessPage
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/viewDeliveryBookingSuccessPage)
   *
   * Owner: Kurt Miller
   *
   * @param options Amplitude event options.
   */
  viewDeliveryBookingSuccessPage(
    options?: EventOptions,
  ) {
    return this.track(new ViewDeliveryBookingSuccessPage(), options);
  }

  /**
   * viewVehicleDetails
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/viewVehicleDetails)
   *
   * When a user clicks to "View" vehicle details on both Desktop (via map view) and Mobile (via map view or list card)
   *
   * Owner: Jordan Triplett
   *
   * @param properties The event's properties (e.g. userId)
   * @param options Amplitude event options.
   */
  viewVehicleDetails(
    properties?: ViewVehicleDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewVehicleDetails(properties), options);
  }

  /**
   * viewVehicleOnMap
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/viewVehicleOnMap)
   *
   * Clicks on desktop list view results that brings up map location
   *
   * Owner: Jordan Triplett
   *
   * @param properties The event's properties (e.g. userId)
   * @param options Amplitude event options.
   */
  viewVehicleOnMap(
    properties?: ViewVehicleOnMapProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewVehicleOnMap(properties), options);
  }

  /**
   * webSearch
   *
   * [View in Tracking Plan](https://data.amplitude.com/fluid-truck/Fluid%20Truck%20Web%20App/events/main/latest/webSearch)
   *
   * Tracking all searches made on web, include vehicle type, location, pickup, and dropoff dates
   *
   * Owner: Jordan Triplett
   *
   * @param properties The event's properties (e.g. dropOff)
   * @param options Amplitude event options.
   */
  webSearch(
    properties: WebSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebSearch(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
