import { chakra, Logo } from '@fluidtruck/core';

import { Button } from '@/base-components';

export const MenuWrap = chakra('div', {
  baseStyle: {
    alignSelf: 'center',
    cursor: 'pointer',
    img: {
      width: { base: 5, lg: 6 },
      height: { base: 5, lg: 6 },
    },
  },
});

export const LoginButton = chakra(Button, {
  baseStyle: {
    borderColor: 'gray.400',
    borderRadius: '3xl',
    textAlign: 'right',
    pr: '0.125rem',
    pl: '1.125rem',
    color: 'black',
  },
  label: 'LoginButton',
});

export const TidalLogo = chakra(Logo, {
  baseStyle: {
    height: {
      lg: '35px',
      base: '22px',
    },
    width: {
      lg: '154px',
      base: '107px',
    },
    cursor: 'pointer',
  },
});
