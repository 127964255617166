import { useRef } from 'react';

import {
  getPolygonBounds,
  paddedBoundingBox,
} from '@/features/myfleet/shared/utils';

import * as Consts from './consts';
import * as Mappings from './mappings';
import * as Types from './types';

// Retrieive All Geofences and draw polygons
export const useGeofencesState: Types.UseGeofencesState = mapInstance => {
  const geofences = useRef<Types.UseGeofencesStateReturn['geofences']>();
  const geofencesBounds = useRef<google.maps.LatLngBounds>(
    new google.maps.LatLngBounds()
  );

  // ref to preserve the event listeners for polygon
  const polygonHandlersRef = useRef<Types.PolygonHandlers>({});

  const onInitialize: Types.UseGeofencesStateReturn['onInitialize'] = ({
    geofences: geofencesProp,
    onClick,
  }) => {
    if (!geofencesProp?.length || !mapInstance) return;

    // reset geofenceBounds on initialize
    geofencesBounds.current = new google.maps.LatLngBounds();

    const newGeofences = geofencesProp?.map(GEOFENCE => {
      const poly = Mappings.mapGeometryToMapPolygon(GEOFENCE.geometry);

      let polyCenter = new google.maps.LatLngBounds();
      const polyArea = google.maps.geometry.spherical.computeArea(
        poly.getPath()
      );

      poly.getPath().forEach(element => {
        polyCenter = polyCenter.extend(element);
      });

      // listener on geofence click
      const onGeofenceClick = (): void => {
        onClick?.(GEOFENCE.uuid);
        google.maps.event.clearInstanceListeners(poly);

        // getting bounding box of polygon: https://stackoverflow.com/a/34835203
        const bounds = getPolygonBounds(poly);
        const paddedBounds = paddedBoundingBox(bounds, 1.8);
        mapInstance?.fitBounds(paddedBounds as google.maps.LatLngBounds);
      };
      // listener on geofence mouseover/hover
      const onGeofenceMouseOver = (): void => {
        poly.setOptions({ ...Consts.polygonOptions, fillOpacity: 0.25 });
      };
      // listener on geofence mouseout/onBlur
      const onGeofenceMouseOut = (): void => {
        poly.setOptions({ ...Consts.polygonOptions, fillOpacity: 0 });
      };

      // add preserved listeners to ref
      polygonHandlersRef.current[GEOFENCE.uuid] = {
        onGeofenceClick,
        onGeofenceMouseOver,
        onGeofenceMouseOut,
      };

      // attach listeners
      poly.addListener('click', onGeofenceClick);
      poly.addListener('mouseover', onGeofenceMouseOver);
      poly.addListener('mouseout', onGeofenceMouseOut);

      // extend goefence bounds from coordinates
      GEOFENCE?.geometry?.coordinates[0].forEach(GEO_OUTER => {
        const [lng, lat] = GEO_OUTER;

        geofencesBounds.current.extend({ lat, lng });
      });

      poly.setMap(mapInstance);
      poly.setOptions(Consts.polygonOptions);

      // NOTE: Only add markers for polygons smaller than 100000sq
      let centerMarker: google.maps.Marker | null = null;

      // applying center marker on polygon
      if (polyArea < 100000) {
        centerMarker = new google.maps.Marker({
          visible: true,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: 'white',
            fillOpacity: 1,
            scale: 10,
            strokeWeight: 4,
            strokeColor: '#00B2E3',
          },
        });
        centerMarker.setPosition(polyCenter.getCenter());
        centerMarker.setMap(mapInstance);
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { geometry, ...rest } = GEOFENCE;
      return {
        ...rest,
        ...(centerMarker && { marker: centerMarker }),
        polygon: poly,
      };
    });

    mapInstance.fitBounds(geofencesBounds.current);
    geofences.current = newGeofences;
  };

  const onClear: Types.UseGeofencesStateReturn['onClear'] = geofenceUUID => {
    geofences.current = geofences.current?.map(GEOFENCE => {
      if (geofenceUUID && geofenceUUID === GEOFENCE.uuid) return GEOFENCE;

      GEOFENCE.polygon.setMap(null);
      GEOFENCE?.marker?.setMap(null);

      return GEOFENCE;
    });
  };

  const onDelete: Types.UseGeofencesStateReturn['onDelete'] = geofenceUUID => {
    geofences.current = geofences.current?.filter(GEOFENCE => {
      if (geofenceUUID && geofenceUUID !== GEOFENCE.uuid) return true;

      google.maps.event.clearInstanceListeners(GEOFENCE.polygon);

      GEOFENCE.polygon.setMap(null);
      GEOFENCE?.marker?.setMap(null);

      return false;
    });
  };

  const onRestore: Types.UseGeofencesStateReturn['onRestore'] = () => {
    if (!geofences.current?.length) return;

    geofences.current?.forEach(GEOFENCE => {
      GEOFENCE.polygon.setOptions({ ...Consts.polygonOptions });
      GEOFENCE.polygon.setMap(mapInstance);
      GEOFENCE?.marker?.setMap(mapInstance);
    });

    mapInstance?.fitBounds(geofencesBounds.current);
  };

  const onDisableListeners: Types.UseGeofencesStateReturn['onDisableListeners'] = uuid => {
    const disablePolygon = geofences?.current?.find(
      GEOFENCE => GEOFENCE.uuid === uuid
    );

    if (!disablePolygon) return;

    google.maps.event.clearInstanceListeners(disablePolygon.polygon);
  };

  const onRestoreListeners: Types.UseGeofencesStateReturn['onRestoreListeners'] = uuid => {
    if (!uuid) {
      geofences.current?.forEach(GEO => {
        GEO.polygon.addListener(
          'click',
          polygonHandlersRef.current[GEO.uuid].onGeofenceClick
        );
        GEO.polygon.addListener(
          'mouseover',
          polygonHandlersRef.current[GEO.uuid].onGeofenceMouseOver
        );
        GEO.polygon.addListener(
          'mouseout',
          polygonHandlersRef.current[GEO.uuid].onGeofenceMouseOut
        );
      });
      return;
    }

    const restorePolygon = geofences.current?.find(
      GEOFENCE => GEOFENCE.uuid === uuid
    );
    restorePolygon?.polygon.addListener(
      'click',
      polygonHandlersRef.current[uuid].onGeofenceClick
    );
    restorePolygon?.polygon.addListener(
      'mouseover',
      polygonHandlersRef.current[uuid].onGeofenceMouseOver
    );
    restorePolygon?.polygon.addListener(
      'mouseout',
      polygonHandlersRef.current[uuid].onGeofenceMouseOut
    );
  };

  const onDrawShow: Types.UseGeofencesStateReturn['onDrawShow'] = () => {
    geofences.current?.forEach(GEOFENCE => {
      google.maps.event.clearInstanceListeners(GEOFENCE.polygon);
      GEOFENCE.polygon.setOptions({ ...Consts.polygonDrawOptions });
      GEOFENCE.polygon.setMap(mapInstance);
    });
  };

  return {
    geofences: geofences.current,
    polygonHandlersRef,
    onInitialize,
    onClear,
    onDelete,
    onDrawShow,
    onRestore,
    onRestoreListeners,
    onDisableListeners,
  };
};
