import moment from 'moment';
import { tz } from 'moment-timezone';

import * as Types from './types';

// Fleet Item Trip
export function formatLocalToUTC(
  startDate: string,
  endDate: string,
  timezone: string
): { startTime: string; endTime: string } {
  const startTime = tz(startDate, 'YYYY-MM-DD HH:mm:ss', timezone)
    .utc()
    .format();
  const endTime = tz(endDate, 'YYYY-MM-DD HH:mm:ss', timezone).utc().format();
  return {
    startTime,
    endTime,
  };
}

export const mapFleetDrivingHistory = (
  trip: Types.FleetTrip
): Types.FleetDetailsTableRowData => {
  const utcDate = formatLocalToUTC(trip.startDate, trip.endDate, trip.timezone);

  const durationStartTime = moment(trip.startDate).utc().format('h:mm A');
  const durationEndTime = moment(trip.endDate).utc().format('h:mm A');
  const durationTimezone = tz(trip.timezone).zoneAbbr();

  return {
    data: {
      startTime: utcDate.startTime,
      endTime: utcDate.endTime,
    },
    cells: [
      // date
      {
        label: { children: moment(trip.date).utc().format('M/DD') },
      },
      // fsds
      {
        label: {
          children: Math.round(trip.tripScore),
        },
      },
      // distance
      {
        label: {
          children: Math.round(
            trip.distanceTraveled * 0.000621371
          ).toLocaleString(),
        },
      },
      // duration
      {
        label: { children: `${trip.hoursDriven.toFixed(1)} hours` },
        subLabel: {
          children: `${durationStartTime} - ${durationEndTime}, ${durationTimezone}`,
        },
      },
      // driver
      {
        label: {
          children:
            trip.driverAssignment?.firstName || trip.driverAssignment?.lastName
              ? `${trip.driverAssignment?.firstName} ${trip.driverAssignment?.lastName}`
              : '--',
        },
        subLabel: {
          children: trip.driverAssignment?.email,
        },
      },
    ],
  };
};
