import {
  Center,
  Grid,
  GridProps,
  Spinner as TidalSpinner,
  SpinnerProps,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

export type { SpinnerProps };

export const Spinner = (props: SpinnerProps): ReactElement => {
  return (
    <TidalSpinner
      emptyColor="lightGray"
      color="fluidBlue"
      size="xl"
      thickness="4px"
      {...props}
    />
  );
};

export const SpinnerContainer = (props: GridProps): ReactElement => {
  return (
    <Grid
      height="inherit"
      justifyContent="center"
      alignContent="center"
      {...props}
    >
      <Spinner size="xl" color="fluidBlue" />
    </Grid>
  );
};

export const SpinnerOverlay = (props: GridProps): ReactElement => {
  return (
    <Grid
      justifyContent="center"
      alignContent="center"
      position="absolute"
      bg="whiteAlpha.800"
      zIndex="pageLoader"
      top={0}
      bottom={0}
      right={0}
      left={0}
      {...props}
    >
      <Spinner size="xl" color="fluidBlue" />
    </Grid>
  );
};

export const SectionLoader = () => (
  <Center px={7}>
    <Spinner size="md" />
  </Center>
);
