import { IconProps } from '@fluidtruck/core';
import React from 'react';

import {
  ComponentWithAs,
  OrgDetails,
  ROLE_TYPES,
  UseBooleanProps,
  UserProfileInfo,
  UserWithToken,
} from '@/types';

export enum ORG_CATEGORY_TYPES {
  PERSONAL = 'personal',
  DSP = 'DSP',
  DRIVER = 'driver',
}
export interface UseLeftNavShape {
  claimsUrl: string;
  currentOrg?: OrgDetails;
  defaultOrg?: number;
  orgCreditAmt?: number;
  handleMenuChange: (path: string, external: boolean) => void | null;
  isCollapsed?: boolean;
  isMobile?: boolean;
  myFleetId?: string;
  orgCategory?: string;
  orgId?: number;
  profileProps: ProfileShape;
  /*
   * set to useBoolean
   * React hook to manage boolean (on - off) states
   * to use: setIsNavigation.off()
   */
  collapse: UseBooleanProps;
  user: UserWithToken | null;
  userCredit?: number;
  userOrgProfile?: UserProfileInfo;
  vehicleLimit?: number;
}

export interface UseLeftNavMenuProps {
  isCollapsed: boolean;
  collapse: UseBooleanProps;
  children?: React.ReactNode;
}

export type Path = string;

export interface MenuItemsShape {
  path: Path;
  icon: ComponentWithAs<'svg', IconProps>;
  roles: ROLE_TYPES[];
  private?: boolean;
  text: string;
  testId: string;
  featureFlag?: string;
}

export type ProfileShape = {
  avatarUrl?: string;
  averageRating?: number;
  completedReservations?: number;
  firstName: string;
  lastName: string;
};
