import { useContext } from 'react';

import {
  AmplitudeExperimentContext,
  ExperimentContextProps,
} from '@/lib/context';

export const useExperiment = (): ExperimentContextProps => {
  const context = useContext(AmplitudeExperimentContext);

  if (context === null) {
    throw new Error(
      'useExperiment must be used within an AmplitudeExperimentProvider'
    );
  }

  return context;
};
