import React from 'react';

import {
  Box as Container,
  FlexRow,
  GrayText,
  SubHeading,
} from '@/base-components';
import type { SystemStyleObject } from '@/types';

export const AccountSection = ({
  children,
  description,
  title,
  secondaryTitle,
  sx,
}: {
  children?: React.ReactNode;
  description?: string | HTMLElement;
  title?: string;
  secondaryTitle?: string;
  sx?: SystemStyleObject;
}) => {
  const styles: SystemStyleObject = {
    borderTop: '1px solid',
    borderColor: 'gray.400',
    '&:first-of-type': {
      borderTop: 0,
      pt: 0,
    },
    py: 10,
    px: { base: 5, lg: 0 },
    ...sx,
  };
  return (
    <Container sx={styles}>
      <FlexRow justify="space-between">
        {title && <SubHeading mb={3}>{title}</SubHeading>}
        {secondaryTitle && <SubHeading>{secondaryTitle}</SubHeading>}
      </FlexRow>
      {description && <GrayText mb={3}>{description}</GrayText>}
      {children}
    </Container>
  );
};
