import React, { Dispatch, SetStateAction } from 'react';

import { UserType } from './user-types';

export interface IDriverScore {
  actions: {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
  context: { driverData: DriverData[]; isLoading: boolean };
}

export type DriverData = {
  avatarUrl: string;
  location: string;
  miles: number;
  name: string;
  rating: number;
  users: UserType[];
  ['updated-at']: string;
};

export type MarketObject = {
  city: string;
  ['market-id']: string;
  region: string;
  state: string;
};

export type MarketData = {
  data: MarketObject[];
};

export type MarketsHookReturn = {
  allMarkets: MarketData;
  error: boolean;
  market: MarketObject;
  newMarketId: string | string[];
  setNewMarketId: Dispatch<SetStateAction<string | string[]>>;
};

export type ScoreHookReturn = {
  error: boolean;
  scores: ScoreObject;
  status: string;
};

export type ScoreObject = {
  ['average-score']: number;
  ['average-score-grade']: string;
};

export type WeekObject = {
  ['end-date']: string;
  ['paid-out']: boolean;
  ['start-date']: string;
  ['updated-at']: string;
  ['week-id']: string;
};

export type WeekData = {
  data: WeekObject[];
};

export type WeeksHookReturn = {
  allWeeks: WeekData;
  error: boolean;
  week: WeekObject;
  newWeekId: string | string[];
  setNewWeekId: Dispatch<SetStateAction<string | string[]>>;
};
