import { ArrowDownIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import {
  Alert,
  AlertIcon,
  Anchor,
  Button,
  Select,
  SpanText,
} from '@/base-components';
import { useDriverScoreContext, useMarkets } from '@/hooks';
import { MarketObject, SystemStyleObject } from '@/types';

import {
  BannerHeading,
  BannerRow,
  BannerRowPrizes,
  GridRow,
  GridRowHidden,
  RewardsTitle,
  SpanSeperator,
} from './styles';
import { WeekSelect } from './WeekSelect';

export const DriverCupBanner = ({
  isTeamPerformance = false,
}: {
  isTeamPerformance?: boolean;
}): React.ReactElement => {
  const router = useRouter();
  const routeObj = { ...router.query };
  const { marketID } = routeObj;
  const { t } = useTranslation('team');

  const {
    actions: { setIsLoading },
  } = useDriverScoreContext();
  const {
    allMarkets,
    error: marketError,
    newMarketId,
    setNewMarketId,
  } = useMarkets(marketID);

  let selectMarketValue = '';
  if (!Array.isArray(newMarketId)) {
    selectMarketValue = newMarketId as string;
  }

  const changeMarket = (id: string): void => {
    setIsLoading(true);
    setNewMarketId(id);
    routeObj.marketID = id;
    router.replace({
      pathname: '/driver_score',
      query: routeObj,
    });
  };

  const getMarketOptions = (): JSX.Element => {
    if (marketError)
      return (
        <Alert status="warning">
          <AlertIcon />
          {t('driverCup.banner.noMarkets')}
        </Alert>
      );

    const styles: SystemStyleObject = {
      color: 'black',
      width: { base: 96 },
      '* > .chakra-select__select-span-value': { fontSize: '5xl' },
      '* > svg': { color: 'blue.100' },
      '* > svg:nth-of-type(2)': { h: 6, w: 6 },
    };

    const marketList = allMarkets?.data?.map((mkt: MarketObject) => ({
      label: `${mkt.region || mkt.city}, ${mkt.state}`,
      value: mkt['market-id'].toString(),
    }));

    return (
      <Select
        isSearchable
        id="market-select"
        defaultValue={selectMarketValue}
        onChange={changeMarket}
        options={marketList}
        placeholder={t('driverCup.banner.national')}
        rightIcon={{ icon: ArrowDownIcon, color: 'blue.100', size: 6 }}
        sx={styles}
        value={selectMarketValue}
        variant="dark"
      />
    );
  };

  return (
    <>
      <GridRowHidden mb={isTeamPerformance ? 5 : 3}>
        <BannerHeading>{t('driverCup.banner.headline')}</BannerHeading>
      </GridRowHidden>
      {!isTeamPerformance && (
        <GridRow mb={6}>{allMarkets && getMarketOptions()}</GridRow>
      )}
      <BannerRow mb={2}>
        <RewardsTitle>{t('driverCup.banner.rewards')}</RewardsTitle>
      </BannerRow>
      <BannerRowPrizes mb={4}>
        <SpanText>
          {t('driverCup.banner.first')}&nbsp;&nbsp;<strong>$300</strong>
        </SpanText>
        <SpanSeperator />
        <SpanText>
          {t('driverCup.banner.second')}&nbsp;&nbsp;<strong>$100</strong>
        </SpanText>
        <SpanSeperator />
        <SpanText>
          {t('driverCup.banner.third')}&nbsp;&nbsp;<strong>$50</strong>
        </SpanText>
        <SpanSeperator />
        <SpanText>
          {t('driverCup.banner.fourth')}&nbsp;&nbsp;<strong>$5 - $10</strong>
        </SpanText>
      </BannerRowPrizes>
      {!isTeamPerformance && (
        <GridRowHidden>
          <WeekSelect
            fontSize="md"
            rightIcon={{ icon: ArrowDownIcon, color: 'blue.100', size: 6 }}
            variant="dark"
          />
        </GridRowHidden>
      )}
      <GridRowHidden>{t('driverCup.banner.rewardsInfo')}</GridRowHidden>
      {isTeamPerformance && (
        <GridRow>
          <Anchor href="/driver_score">
            <Button mt={8} borderColor="fluidBlue">
              {t('driverCup.banner.standingsLink')}
            </Button>
          </Anchor>
        </GridRow>
      )}
    </>
  );
};
