import { Moment } from 'moment';
import { SWRConfiguration, SWRResponse } from 'swr';

import { FleetDetailsTableRowData } from '@/features/myfleet/VehicleDetails/Table';

// use fleet inspections
export enum FleetInspectionType {
  PICKUP = 'vehicle_pickup',
  DROPOFF = 'vehicle_dropoff',
}

export enum FleetInspectionViewGroup {
  EXTERIOR = 'exterior_pictures',
  INTERIOR = 'interior_pictures',
  FUEL_MILEAGE = 'fuel_mileage_pictures',
  OTHER = 'other',
}

export enum FleetInspectionFieldType {
  PICTURE = 'picture_url',
  TEXT = 'text',
}

export interface FleetInspectionField {
  type: FleetInspectionFieldType;
  value: string;
}

export interface FleetInspectionStep {
  name: string;
  uuid: string;
  completedAt: string;
  displayName: string;
  optional: boolean;
  viewGroup: FleetInspectionViewGroup;
  fields: FleetInspectionField[];
}

export interface FleetInspection {
  uuid: string;
  organizationID: number;
  completedBy: string;
  completedAt: string;
  type: FleetInspectionType;
  steps: FleetInspectionStep[];
}

export interface FleetInspectionImage {
  url: string;
  completedAt: string;
  completedBy: string;
  name: string;
  key: string;
  uuid: string;
  comments?: string;
}

export interface FleetInspectionDetails {
  type: FleetInspectionType;
  date: Moment;
  timezone: string;
  uuid: string;
  steps: Record<FleetInspectionViewGroup, FleetInspectionImage[]>;
}

export interface UseFleetItemInspectionsArgs {
  params: {
    orgId: string;
    vehicleId: string | number;
    startDate: string;
    endDate: string;
  };
  config?: SWRConfiguration<FleetDetailsTableRowData[], Error>;
}

export type UseFleetItemInspectionsSwr = (
  args: UseFleetItemInspectionsArgs
) => SWRResponse<FleetDetailsTableRowData[], Error>;

export type { FleetDetailsTableRowData };
