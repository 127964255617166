export type GenericApiResponse<T> =
  | { status: 'fail'; messages: string[] }
  | { status: 'success'; data: T };

export type ErrorApiResponseV2 = { messages?: string[]; message?: string };

export interface GenericApiResponseV2<T> extends ErrorApiResponseV2 {
  data?: T;
}
export type GenericApiResponseV3<T> = ErrorApiResponseV2 & T;

export enum LOADING_STATUS {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export type ErrorResponseType = { message: string; traceID: string };

export type PagintedQueryParams = {
  'page-number': any;
  'page-size': any;
  'sort-field': any;
  'sort-order': any;
  'search-term': any;
};

export type PaginatedResponseV1<T> = {
  page: number;
  'page-size': number;
  total: number;
  'total-pages': number;
  entries: T;
};

export type PaginatedShapeV2 = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
};
