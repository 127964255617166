import {
  Avatar as TidalAvatar,
  AvatarBadge as TidalAvatarBadge,
  AvatarBadgeProps,
  AvatarProps,
} from '@fluidtruck/core';
import React from 'react';

export type { AvatarProps };

/** Avatar
 * https://tidal.fluidtruck.com/docs/components/avatar
 */

export const Avatar = (props: AvatarProps): React.ReactElement => {
  // TODO: update lib theme with 48px
  const { height = 12, width = 12 } = props;
  return (
    <TidalAvatar
      alt="profile-avatar-image"
      height={height}
      width={width}
      {...props}
    />
  );
};
export const AvatarBadge = (props: AvatarBadgeProps): React.ReactElement => {
  return <TidalAvatarBadge alt="profile-avatar-badge" {...props} />;
};
