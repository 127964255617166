import qs from 'qs';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { LoadingContext, OrganizationContext } from '@/lib/context';
import {
  GenericApiResponse,
  OrgSettingsWithACH,
  UserProfileInfo,
} from '@/types';
import {
  BillingMethods,
  OrgBillingShape,
  OrgContextShape,
  OrgDocsShape,
  OrgInvitesDataShape,
  UseGetOrgShape,
} from '@/types/org-types';
import { get } from '@/utils/helpers';

import { useKrakenSwr } from './use-kraken-swr';
import { useUser } from './use-user';

export const OTHER_BILLING_METHODS = {
  PURCHASE_ORDER: 1,
};

export const useOrg = (
  orgId: number | undefined,
  config = {}
): {
  org: UseGetOrgShape | undefined;
  isLoading: boolean;
  isError: any;
  mutate: (
    newData: UseGetOrgShape,
    shouldRevalidate?: boolean
  ) => Promise<void>;
} => {
  const { isLoading, data, error, mutate } = useKrakenSwr({
    isReadyToFetch: !!orgId,
    key: `api/organizations/${orgId}`,
    fetcher: get,
    options: config,
  });

  return {
    org: data?.status === 'success' ? data.data : undefined,
    isLoading: isLoading || (!!orgId && !data?.status && !error),
    isError: error || data?.status !== 'success',
    mutate,
  };
};

export const useOrganizationContext = (): OrgContextShape => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useOrganizationContext must be used within an OrganizationContext Provider'
    );
  }

  return context;
};

export const useOrgDocuments = (): {
  isError: any;
  isLoading: boolean;
  mutate: (
    newData: UseGetOrgShape,
    shouldRevalidate?: boolean
  ) => Promise<void>;
  org?: UseGetOrgShape;
  orgId?: number;
  userOrgProfile?: UserProfileInfo;
  updateDocuments: (data: OrgDocsShape) => void;
} => {
  const { context } = useOrganizationContext();
  const {
    org: { organization: { id: orgId } = {} },
    userOrgProfile,
  } = context;
  const { isLoading, mutate, org, ...swr } = useOrg(orgId, {
    shouldRevalidateOnFocus: false,
  });

  const loadintCtxt = useContext(LoadingContext);

  useEffect(() => {
    loadintCtxt?.updateLoading(isLoading);
  }, [isLoading, loadintCtxt?.updateLoading]);

  const updateDocuments = useCallback(
    (data: OrgDocsShape) => {
      if (!org?.documents) return;
      const documents = org?.documents?.filter(o => o.id !== data.id);
      documents.push(data);

      mutate({ ...org, documents });
    },
    [mutate, org]
  );

  return {
    isLoading,
    mutate,
    org,
    orgId: org?.organization.id,
    userOrgProfile,
    updateDocuments,
    ...swr,
  };
};

export const useOrgBilling = (): {
  defaultOrganization: OrgBillingShape | null;
  defaultBilling: BillingMethods | null;
  organizations: OrgBillingShape[] | null;
} => {
  const { user } = useUser();
  const {
    context: {
      org: { organization },
    },
  } = useOrganizationContext();

  const { error, data } = useSWR<GenericApiResponse<OrgBillingShape[]>>(
    user && 'api/v1/organizations/profiles/billing_methods',
    get
  );
  if (error) {
    throw new Error(JSON.stringify(error));
  }
  if (
    !user ||
    !Object.keys(user || {}).length ||
    !organization ||
    !Object.keys(organization || {}).length ||
    data?.status !== 'success'
  ) {
    return {
      defaultOrganization: null,
      defaultBilling: null,
      organizations: null,
    };
  }

  // special handling, SWR appears to be mutating the array
  // setting `[]` to `[undefined]` causing downstream effects
  const organizations =
    data.data.map(datum => {
      if (!datum['billing-methods'][0]) {
        return { ...datum, 'billing-methods': [] };
      }
      return datum;
    }) || [];

  if (
    organization?.['organization-settings']?.['book-with-purchase-order'] &&
    organizations.length
  ) {
    const orgWithPOIndex = organizations.findIndex(
      org => org['organization-id'] === organization?.id
    );
    if (
      orgWithPOIndex >= 0 &&
      !organizations[orgWithPOIndex]['billing-methods'].some(
        method => method.type === 'purchaseOrder'
      )
    ) {
      const billingMethods = organizations[orgWithPOIndex][
        'billing-methods'
      ].map(method => ({
        ...method,
        'is-default': false,
      }));

      const poItem: BillingMethods = {
        id: OTHER_BILLING_METHODS.PURCHASE_ORDER,
        type: 'purchaseOrder',
        'is-default': true,
        details: undefined,
      };

      billingMethods.push(poItem);
      organizations[orgWithPOIndex]['billing-methods'] = billingMethods;
    }
  }

  const defaultOrganization =
    organizations.find(o => o?.name === 'Personal Profile') || null;
  const defaultBilling = defaultOrganization?.['billing-methods']?.[0] || null;

  return {
    defaultOrganization,
    defaultBilling,
    organizations,
  };
};

export const useOrgInvites = ({
  orgId,
}: {
  orgId?: number;
}): {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  total: number | undefined;
  isLoading: boolean;
  isError: boolean;
  data:
    | {
        status: 'success';
        data: OrgInvitesDataShape;
      }
    | undefined;
  mutate: (
    newData: {
      status: 'success';
      data: OrgInvitesDataShape;
    },
    shouldRevalidate?: boolean
  ) => Promise<GenericApiResponse<OrgInvitesDataShape> | undefined>;
} => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const query = qs.stringify({
    'page-number': page + 1,
    'page-size': pageSize,
  });

  const { data, error, mutate } = useSWR<
    GenericApiResponse<OrgInvitesDataShape>
  >(`api/organizations/${orgId}/invitations?${query}`, get);
  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    total: data?.status === 'success' ? data.data.total : undefined,
    isLoading: !error && !data,
    isError: error,
    data: data?.status === 'success' ? data : undefined,
    mutate,
  };
};

export const useGetOrgEligibleForMonthReservations = (
  settings: OrgSettingsWithACH
): boolean => {
  return (
    settings['book-with-purchase-order'] ||
    settings['credit-card-payg-enabled'] ||
    settings['ach-enabled']
  );
};
