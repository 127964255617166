import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ampli } from '@/amplitude';
import {
  Box,
  Button,
  Form,
  FormInputController,
  PasswordController,
  Spinner,
  SubmitButton,
} from '@/base-components';
import { AlertGroup } from '@/base-components/alerts';
import { useUser } from '@/hooks';
import type { UserWithToken } from '@/types';
import { emailPhonePattern, passwordMinLength } from '@/utils';
import { getUserFlow, post } from '@/utils/helpers';

import { SignUpLoginContainer } from './styles';

export interface LoginProps {
  setForgotPassword: (bool: boolean) => void;
  onFinish: (data: UserWithToken) => void;
  blockOnSubmit?: boolean;
  onAccept?: () => Promise<void>;
}

type Component = (props: LoginProps) => JSX.Element;

interface Methods {
  onSubmit: (form: FormProps) => Promise<void>;
}

interface FormProps {
  password: string;
  emailLogin: string;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const Login: Component = ({
  setForgotPassword,
  onFinish,
  blockOnSubmit,
  onAccept,
}) => {
  const router = useRouter();

  const isTeamInviteScreen = router.pathname === '/account/organization/accept';

  const methods = useForm<FormProps>({
    mode: 'onBlur',
    defaultValues: {
      password: '',
      emailLogin: (router?.query?.email as string) ?? '',
    },
  });
  const { control, handleSubmit, formState, setValue } = methods;
  const { isSubmitting } = formState;
  const { login } = useUser();
  const [fail, setFail] = useState<string | null>(null);
  const { t } = useTranslation();

  // Handle submit
  const submit: Methods['onSubmit'] = async ({ emailLogin, password }) => {
    const body = {
      username: emailLogin,
      password,
      remember: true,
    };

    const headers = {
      Platform: 'FT Web App',
    };

    const { status, data } = await post('api/login', body, headers);

    if (status === 'success') {
      try {
        window.dataLayer.push({
          event: 'login',
          fluidId: data?.user?.id,
        });
      } catch (err) {
        console.warn('Error collecting google analytics', err);
      }

      // if login form is the "Fluid Team Invite" form, fire onAccept after successful login
      if (isTeamInviteScreen) {
        await onAccept?.();
      }

      try {
        const currentFlow = getUserFlow(router.query);

        const userInfo = await login(currentFlow);

        if (onFinish) {
          window.scrollTo(0, 0);
          document.body.scrollTop = 0;
          onFinish(userInfo);
        }
      } catch (e) {
        setFail(t('login.loginFail'));
      }
    } else {
      setFail(t('login.loginFail'));
    }
  };

  const handleForgotPassword = () => {
    ampli.forgotPassword();
    setForgotPassword(true);
  };

  const readOnly = Boolean(router?.query?.email);

  return (
    <SignUpLoginContainer data-test-id="login-container">
      {isSubmitting && blockOnSubmit && (
        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          height="40px"
        >
          {isSubmitting && blockOnSubmit && <Spinner />}
        </Box>
      )}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(submit)} width="100%">
          <>
            {fail && (
              <AlertGroup
                status="error"
                mt={2}
                data-test-id="login-fail"
                description={fail}
              />
            )}
            <FormInputController
              name="emailLogin"
              mt={6}
              control={control}
              rules={emailPhonePattern(t('phoneOrEmailIncorrect'))}
              label={t('phoneOrEmail')}
              inputProps={{
                onBlur: e => setValue('emailLogin', e.target.value.trim()),
                isReadOnly: readOnly,
                'data-test-id': 'input-email',
              }}
            />
            <PasswordController
              name="password"
              mt={6}
              rules={passwordMinLength(t('login.passReq'))}
              label={t('passwordInputLabel')}
              inputProps={{ 'data-test-id': 'input-password' }}
            />
            <Button
              my={4}
              variant="link"
              data-test-id="button-forgot-password"
              onClick={handleForgotPassword}
            >
              {t('forgot')}
            </Button>
            <SubmitButton
              data-test-id="button-login"
              isLoading={isSubmitting}
              onClick={() => ampli.loginButtonClick()}
            >
              {t('button.login')}
            </SubmitButton>
          </>
        </Form>
      </FormProvider>
    </SignUpLoginContainer>
  );
};
