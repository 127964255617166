export const capitalize = (str: string) => {
  if (!str) return;
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const base64 = 'data:image/svg+xml;charset=UTF-8;base64';

/* for parsing and returning token expiration*/
export const parseExpiration = (data: string) => {
  if (!data) return new Date();
  const jwtPayload = JSON?.parse(atob(data?.split('.')[1]));
  return new Date(jwtPayload?.exp * 1000);
};
