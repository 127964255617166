import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import {
  NavigationMenuProvider,
  useLeftNavMenu,
} from '@/components/LeftNavMenu/context/useLeftNavMenu';
import NavBar from '@/components/Nav/NavBar';
import { useBoolean } from '@/utils';

import { LayoutProvider, useLayout } from '../context';

const NavigationWrapper: FC = props => {
  const [isCollapsed, collapse] = useBoolean(true);
  const { children } = props;
  const router = useRouter();
  // context is handling all the layout changes based on routes
  const layoutContext = useLayout();
  const { user, defaultCollapsed, hideLeftNav } = layoutContext;

  const navBarCollapsed = !user || hideLeftNav;
  const navContext = useLeftNavMenu({
    collapse,
    isCollapsed,
  });

  useEffect(() => {
    if (!user && router.pathname !== '/book_cart') {
      router.replace('/');
    }
  }, [user]);

  // init: we only want the left menu to load the default for the page once
  useEffect(() => {
    if (!defaultCollapsed && !isCollapsed) collapse.off();
  }, []);

  useEffect(() => {
    const collapsibleHandler = () => {
      if (!isCollapsed) collapse.on();
    };

    router.events.on('routeChangeComplete', collapsibleHandler);
    return () => {
      router.events.off('routeChangeComplete', collapsibleHandler);
    };
  }, []);

  return (
    <LayoutProvider value={layoutContext}>
      <NavigationMenuProvider value={navContext}>
        <NavBar
          isCollapsed={navBarCollapsed || isCollapsed}
          toggleMobileMenu={collapse.toggle}
        />
        {children}
      </NavigationMenuProvider>
    </LayoutProvider>
  );
};

export { NavigationWrapper };
