import React from 'react';

/**
 * Creates a named context, provider, and hook.
 *
 * @param options create context options
 */
export function createContext(options = {}) {
  const {
    strict = true,
    errorMessage = 'useContext: `context` is undefined. Seems you forgot to wrap the component within the provider.',
    name,
  } = options;

  const Context = React.createContext(undefined);

  Context.displayName = name;

  function useContext() {
    const context = React.useContext(Context);

    if (!context && strict) {
      throw new Error(errorMessage);
    }

    return context;
  }

  return [Context.Provider, useContext, Context];
}
