/* eslint-disable react/destructuring-assignment */
import {
  Center,
  Divider,
  PageNavigator,
  PageResults,
  PageSizing,
  Pagination as TidalPagination,
} from '@fluidtruck/core';
import { useMemo, useState } from 'react';

import type { RowsPerPageOptions } from './types';

/**
 * @description Pagination with usePagination
 * @example
    const [page, start, end, setPage] = usePagination();

    const rows = data?.slice(start, end).map(item => <tr>{item}</tr>)

    const paginationProps = {
      count: data?.length,
      pageNumber: page.number,
      pageSize: page.size,
      onSizeChange: setPage.sizeChange,
      onPageChange: setPage.numberChange,
      selectableSizes: [10, 20, 50],
    };
    <Table>{rows}</Table>
    <Pagination {...paginationProps} />
 */

export interface PaginationProps {
  count: number;
  onPageChange: (page: number) => void;
  onSizeChange?: (size: number) => void;
  pageNumber: number;
  pageSize: number;
  selectableSizes?: RowsPerPageOptions[];
  hideSizing?: boolean;
}

export const usePagination = () => {
  const [values, setValues] = useState({
    number: 0,
    size: 20,
  });

  const start = values.number * values.size;
  const end = values.number * values.size + values.size;

  const callbacks = useMemo(
    () => ({
      sizeChange: (size: number) => setValues({ ...values, number: 0, size }),
      numberChange: (number: number) => setValues({ ...values, number }),
    }),
    [values]
  );
  return [values, start, end, callbacks] as const;
};

export const Pagination = (props: PaginationProps) => (
  <TidalPagination {...props}>
    {!props.hideSizing && (
      <>
        <PageSizing label="rows per page" />
        <Center>
          <Divider orientation="vertical" />
        </Center>
      </>
    )}
    <PageResults maxW="150px" minW="150px" w="150px" />
    <Center>
      <Divider orientation="vertical" />
    </Center>
    <PageNavigator />
  </TidalPagination>
);
