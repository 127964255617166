import PropTypes from 'prop-types';
import React from 'react';

import * as Styles from './styles';

const TabPanel = ({ children, index, prefetch, value }) => {
  const selected = index === value;

  if (!prefetch && !selected) {
    return null;
  }

  return <Styles.TabPanelContainer selected={selected}>{children}</Styles.TabPanelContainer>;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any,
  prefetch: PropTypes.bool,
  value: PropTypes.any,
};

export default TabPanel;
