import type { PopoverProps } from '@fluidtruck/core';
import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@fluidtruck/core';
import { SearchIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import { FlexCol, Title } from '@/base-components';
import { ItemSearchSelect } from '@/base-components/forms/ItemSearchSelect';
import { WeeklyCalculatorProvider } from '@/features/search/weekCalculator';
import { useGoogleMapsApi } from '@/hooks/use-google-maps-api';

import { SearchTab, tabStyles } from '../SearchTab';
import SearchAll from './SearchAll';
import * as Styles from './styles';

type SearchPopoverProps = {
  disableActiveIndicator: boolean;
  isLoggedIn: boolean;
};

export const SearchPopover = (props: SearchPopoverProps) => {
  const { disableActiveIndicator = false, isLoggedIn } = props;
  const popover = useDisclosure();

  const { t } = useTranslation('search');
  const router = useRouter();

  const { isGoogleLoaded } = useGoogleMapsApi();

  const isSearchOpen = router.pathname.startsWith('/search');

  if (!isGoogleLoaded) {
    return null;
  }

  const popoverProps: PopoverProps = {
    ...popover,
    isLazy: true,
    closeOnBlur: false,
    placement: 'bottom-start',
  };

  return (
    <>
      <Box>
        <Popover {...popoverProps}>
          <PopoverTrigger>
            <Button
              borderRadius="3xl"
              size="lg"
              aria-describedby="fluidshare-search-content"
              aria-selected={!disableActiveIndicator && isSearchOpen}
              data-test-id="nav-open-search-menu"
              leftIcon={
                <SearchIcon aria-labelledby={t('searchFindVehicles')} />
              }
            >
              {t('searchFindVehicles')}
            </Button>
          </PopoverTrigger>
          <PopoverContent sx={Styles.popoverContentSx}>
            <PopoverCloseButton size="lg" />
            <PopoverBody>
              <Tabs aria-label="search tabs">
                <TabList
                  pb={6}
                  display="grid"
                  gridTemplateColumns="1fr repeat(2, auto)"
                >
                  <Title fontWeight="bold">{t('searchAll.title')}</Title>
                  <Tab data-test-id="search-all-tab-one" sx={tabStyles}>
                    {t('searchAll.tabs.searchAll')}
                  </Tab>
                  <SearchTab isDisabled={!isLoggedIn} />
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <WeeklyCalculatorProvider>
                      <SearchAll />
                    </WeeklyCalculatorProvider>
                  </TabPanel>
                  <TabPanel>
                    <FlexCol maxW="50%">
                      {t('searchAll.tabs.searchByFleet')}
                      <ItemSearchSelect closePopover={popover.onClose} />
                    </FlexCol>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
};
