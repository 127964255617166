import {
  ChakraProps,
  ChakraReactSelectProps as Props,
  GroupBase,
  OptionGroup,
} from '@fluidtruck/core';
import { ReactElement } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { omit } from '@/utils';

import { ReactSelect } from '../../../react-select-components';

export type CRSControllerProps = {
  noLabel?: boolean;
  label?: string;
  id?: string;
  isInvalid?: boolean;
  __css?: ChakraProps['__css'];
} & Props<OptionGroup, false, GroupBase<OptionGroup>>;

type RHFFormProps<T extends FieldValues> = CRSControllerProps &
  UseControllerProps<T>;

/**
 * @description ChakraReactSelect with useController
 * @example

 */

export const ChakraSelectController = <T extends FieldValues>({
  id,
  control,
  name,
  options = [],
  rules,
  defaultValue,
  shouldUnregister,
  ...rest
}: RHFFormProps<T>): ReactElement => {
  const { field, fieldState } = useController<T>({
    name,
    rules,
    control,
    defaultValue,
    shouldUnregister,
  });

  return (
    <ReactSelect
      id={id}
      options={options}
      errorMessage={fieldState?.error?.message}
      isInvalid={!!fieldState?.error}
      {...rest}
      {...omit(field, ['ref'])}
      {...fieldState}
    />
  );
};
