import moment from 'moment';
import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import useSWR from 'swr';

import { useOrganizationContext } from '@/hooks';
import { FeedbackContext } from '@/lib/context/FeedbackContext';
import {
  CostEstimateReturn,
  GenericApiResponse,
  Insurance,
  ReservationEstimateRequest,
} from '@/types';
import { get } from '@/utils/helpers';

import { defaultReservationEnd, defaultReservationStart } from '../constants';

export interface ReservationEstimateContextShape {
  reservationEstimate: CostEstimateReturn;
  reservationEstimateSchedule: CostEstimateReturn;
  removeAllEstimateStorage: () => void;
  errorMessage: string;
  loading: boolean;
  updateEstimate: (estimate: UpdateEstimateParams) => void;
}

export const ReservationEstimateContext = createContext<
  ReservationEstimateContextShape | undefined
>(undefined);

export type UpdateEstimateParams = Partial<ReservationEstimateRequest>;
interface BuildEstimateArgs {
  estimateRequestParams: UpdateEstimateParams;
  currentOrgId?: number;
}

const buildEstimateUrl = ({
  estimateRequestParams,
  currentOrgId,
}: BuildEstimateArgs) => {
  const params = [];
  if (estimateRequestParams?.['pick-up']) {
    params.push(`pick-up=${estimateRequestParams?.['pick-up']}`);
  }
  if (estimateRequestParams?.['drop-off']) {
    params.push(`drop-off=${estimateRequestParams?.['drop-off']}`);
  }
  if (estimateRequestParams?.['organization-id'] && currentOrgId) {
    params.push(`organization-id=${currentOrgId}`);
  }
  if (estimateRequestParams?.['promo-code']) {
    params.push(`promo-code=${estimateRequestParams['promo-code']}`);
  }
  if (estimateRequestParams?.insurance) {
    params.push(`insurance=${estimateRequestParams.insurance}`);
  }

  return `api/items/${
    estimateRequestParams?.['item-id']
  }/cost_estimate?${params.join('&')}`;
};

const getDataFromStorage = (scheduled = false) => {
  const lsKeyString = `reservationEstimate${scheduled ? 'Scheduled' : ''}`;
  let reservationEstimateData = {} as CostEstimateReturn;

  try {
    const reservationEstimateStorage = localStorage.getItem(lsKeyString);
    reservationEstimateData = reservationEstimateStorage
      ? JSON.parse(reservationEstimateStorage)
      : {};
  } catch (e: any) {
    console.warn('Reservation Estimate cookie could not be read', e.message);
  }

  return reservationEstimateData;
};
interface Props {
  children: ReactNode;
}

const ReservationEstimateProvider = ({ children }: Props) => {
  const reservationEstimateLS = getDataFromStorage();
  const { query } = useRouter();
  const { showFeedback } = useContext(FeedbackContext);
  const {
    context: { org },
  } = useOrganizationContext();

  const { organization: { id: currentOrgId } = {} } = org;

  const saveEstimate =
    (scheduled = false) =>
    (resObj: CostEstimateReturn) => {
      const varString = `reservationEstimate${scheduled ? 'Scheduled' : ''}`;
      localStorage.setItem(varString, JSON.stringify(resObj));
    };

  const removeReservationEstimate = (scheduled = false) => {
    const varString = `reservationEstimate${scheduled ? 'Scheduled' : ''}`;
    localStorage.removeItem(varString);
  };

  const [estimateRequestParams, setEstimateReqestParams] =
    useState<UpdateEstimateParams>({
      'item-id': reservationEstimateLS?.item?.id,
      'pick-up': query?.start
        ? moment(query?.start).toISOString()
        : defaultReservationStart.toISOString(),
      'drop-off': query?.end
        ? moment(query?.end).toISOString()
        : defaultReservationEnd.toISOString(),
      'organization-id': currentOrgId,
      'promo-code': '',
      insurance: Insurance.DECLINED,
    });

  const updateEstimateParams = (newParams: UpdateEstimateParams): void => {
    setEstimateReqestParams(prevParams => ({
      ...prevParams,
      ...newParams,
    }));
  };

  const removeAllEstimateStorage = () => {
    removeReservationEstimate();
    removeReservationEstimate(true);
    updateEstimateParams({
      'item-id': undefined,
      'promo-code': '',
    });
  };

  useEffect(() => {
    if (currentOrgId) {
      updateEstimateParams({ 'organization-id': currentOrgId });
    }
  }, [currentOrgId]);

  const estimateUrl = buildEstimateUrl({ estimateRequestParams, currentOrgId });

  const shouldFetch = !!estimateRequestParams?.['item-id'];

  const { data, error } = useSWR<GenericApiResponse<CostEstimateReturn>>(
    shouldFetch ? `${estimateUrl}&scheduled=false` : null,
    get,
    {
      onSuccess: async response => {
        if (response.status === 'success') {
          saveEstimate()(response.data);
        } else {
          response?.messages && showFeedback(response.messages[0], 'error');
        }
      },
      revalidateOnFocus: false,
    }
  );

  const { data: scheduledData, error: scheduledError } = useSWR<
    GenericApiResponse<CostEstimateReturn>
  >(shouldFetch ? `${estimateUrl}&scheduled=true` : null, get, {
    onSuccess: async response => {
      if (response.status === 'success') {
        saveEstimate(true)(response.data);
      }
    },
    revalidateOnFocus: false,
  });

  const errorMessage =
    data?.status === 'fail'
      ? data?.messages?.concat().toString().replace(',', ', ')
      : '';

  const errorMessageScheduled =
    scheduledData?.status === 'fail' ? scheduledData?.messages?.[0] : '';

  const loading = data?.status !== 'fail' && !data?.data && !error;
  const loadingScheduled =
    scheduledData?.status !== 'fail' && !scheduledData?.data && !scheduledError;

  return (
    <ReservationEstimateContext.Provider
      value={{
        reservationEstimate:
          data?.status === 'success' ? data?.data : getDataFromStorage(),
        reservationEstimateSchedule:
          scheduledData?.status === 'success'
            ? scheduledData?.data
            : getDataFromStorage(true),
        removeAllEstimateStorage,
        errorMessage: errorMessage || errorMessageScheduled,
        loading: loading || loadingScheduled,
        updateEstimate: updateEstimateParams,
      }}
    >
      {children}
    </ReservationEstimateContext.Provider>
  );
};

export const useReservationEstimate = (): ReservationEstimateContextShape => {
  const context = useContext(ReservationEstimateContext);

  if (context === undefined) {
    throw new Error(
      'useReservationEstimateContext must be used within ReservationEstimateContextProvider'
    );
  }

  return context;
};

export { ReservationEstimateProvider };
