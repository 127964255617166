import { useContext } from 'react';

import { SpinnerOverlay } from '@/base-components/spinners-loaders';
import { LoadingContext } from '@/lib/context';

export const PageLoader = () => {
  const { loading } = useContext(LoadingContext);

  return <SpinnerOverlay display={loading ? 'grid' : 'none'} />;
};
