import moment from 'moment';
import { tz } from 'moment-timezone';

import * as Types from './types';

export const mapFleetItemInspections = (
  inspection: Types.FleetInspection
): Types.FleetDetailsTableRowData => {
  const inspectionDate = moment(inspection.completedAt);
  const timezone = tz(tz.guess()).zoneAbbr();
  let commentsCount = 0;

  const findFieldCb = (type: Types.FleetInspectionFieldType) => {
    return (field: Types.FleetInspectionField) => field.type === type;
  };

  /**
   * @description Associating array of images to respective viewGroup
   * @example
   * {
   *    exterior_pictures: [imgObj1, imgObj2, imgObj3, imgObj4],
   *    interior_pictures: [imgObj1, imgObj2],
   *    fuel_mileage_pictures: [imgObj1, imgObj2],
   * }
   */
  const detailSteps = inspection.steps.reduce((ACC, STEP) => {
    const updateACC = { ...ACC };
    const url =
      STEP.fields.find(findFieldCb(Types.FleetInspectionFieldType.PICTURE))
        ?.value || '';
    const comments =
      STEP.fields.find(findFieldCb(Types.FleetInspectionFieldType.TEXT))
        ?.value || '';
    const completedAt = `${moment(STEP.completedAt).format(
      'MM/DD/YYYY h:mm A'
    )} ${timezone}`;

    if (comments && !commentsCount) {
      commentsCount += 1;
    }

    const stepImage = {
      url,
      completedAt,
      completedBy: inspection.completedBy,
      timezone,
      comments,
      name: STEP.displayName,
      key: STEP.name,
      uuid: STEP.uuid,
    };

    if (!updateACC[STEP.viewGroup]) {
      updateACC[STEP.viewGroup || Types.FleetInspectionViewGroup.OTHER] = [
        stepImage,
      ];
    } else {
      updateACC[STEP.viewGroup] = [...updateACC[STEP.viewGroup], stepImage];
    }

    return updateACC;
  }, {} as Types.FleetInspectionDetails['steps']);

  // increment commentsCount
  const detailsModal: Types.FleetInspectionDetails = {
    type: inspection.type,
    date: inspectionDate,
    timezone,
    uuid: inspection.uuid,
    steps: detailSteps,
  };
  return {
    data: {
      detailsModal,
    },
    cells: [
      // date
      {
        label: {
          children: inspectionDate.format('MM/DD/YYYY'),
        },
        subLabel: {
          children: `${inspectionDate.format('hh:mm A')} ${timezone}`,
        },
      },
      // completedBy
      {
        label: {
          children: inspection.completedBy,
        },
      },
      // type
      {
        label: {
          children: inspection.type,
        },
      },
      // comments
      // TODO: need to figure out best way to accumulate this value
      {
        label: {
          children: commentsCount,
        },
      },
    ],
  };
};
