import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from '@fluidtruck/core';
import { SearchIcon } from '@fluidtruck/icons';

import { Spinner } from '@/base-components';

type SearchProps = InputProps & { isLoading?: boolean };

export const Search = ({
  isDisabled,
  isLoading,
  ...rest
}: SearchProps): JSX.Element => {
  const cursor = isDisabled || isLoading ? 'not-allowed' : 'pointer';
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        {isLoading ? <Spinner size="xs" /> : <SearchIcon color="fluidBlue" />}
      </InputLeftElement>
      <Input
        variant="outline"
        borderColor="fluidBlue"
        isDisabled={isDisabled}
        cursor={cursor}
        {...rest}
      />
    </InputGroup>
  );
};
