import { FlatTireIcon } from '@fluidtruck/icons';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, FlexRow, Heading, Text } from '@/base-components';

import * as Styles from './styles';

type ErrorProps = {
  start?: number;
  end?: number;
  message?: string;
  isErrorBoundary?: boolean;
  resetErrorBoundary?: () => void;
};

const Error = (props: ErrorProps) => {
  const { t } = useTranslation(['common']);
  const {
    start = 4,
    end = 4,
    isErrorBoundary = false,
    message = t('common:error.getYouBack'),
    resetErrorBoundary,
  } = props;

  return (
    <Styles.ErrorContainer>
      <Heading {...Styles.StyledHeader}>{t('common:error.awwTruck')}</Heading>
      <Text {...Styles.StyledSubheader}>{t('common:error.happens')}</Text>
      {isErrorBoundary ? (
        <FlexRow alignItems="center" mb={10} mt={12}>
          <FlatTireIcon {...Styles.iconProps} />
        </FlexRow>
      ) : (
        <FlexRow alignItems="center" mb={10} mt={12}>
          <Text {...Styles.textProps}>{start}</Text>
          <FlatTireIcon {...Styles.iconProps} />
          <Text {...Styles.textProps}>{end}</Text>
        </FlexRow>
      )}
      <Text textAlign="center" fontSize={{ base: 'xl', lg: '3xl' }}>
        {message}
      </Text>
      {isErrorBoundary && (
        <Button mt={2} onClick={resetErrorBoundary}>
          {t('common:messages.tryAgain')}
        </Button>
      )}
    </Styles.ErrorContainer>
  );
};

export default Error;
