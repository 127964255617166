import useSWR from 'swr';

import { get } from '@/utils/helpers';

import * as Mappings from './mappings';
import * as Types from './types';

// TODO: sort & map routeData to map items
// TODO: map routeEvents to markers
export const useFleetRoutingSwr: Types.UseFleetRoutingSwr = ({
  itemId,
  orgId,
  startTime,
  endTime,
  config,
}) => {
  const hasAllParams = !!(itemId && startTime && endTime);
  const swrURL = hasAllParams
    ? `api/scoring/org/${orgId}/item/${itemId}/route_information?start-date=${startTime}&end-date=${endTime}`
    : null;

  const { data, error, mutate, isValidating } =
    useSWR<Types.FleetRoutingMapped>(
      swrURL,
      url =>
        get(url).then((res: Types.FleetRoutingSwrModel) => {
          if (res.status === 'fail') return {} as Types.FleetRoutingMapped;
          return Mappings.mapfleetRouting(res.data);
        }),
      config
    );

  const isLoading =
    (!data && isValidating) || (error !== undefined && isValidating);

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  };
};
