import {
  DatePicker as TidalDatePicker,
  DatePickerProps,
} from '@fluidtruck/core';
import React, { useState } from 'react';

export const DatePicker = (
  props: Omit<
    DatePickerProps,
    'focused' | 'onFocusChange' | 'isOutsideRange' | 'renderMonthElement'
  >
): JSX.Element => {
  const [isFocused, setFocused] = useState(false);

  return (
    <TidalDatePicker
      focused={isFocused}
      onFocusChange={({ focused }: { focused: boolean }) => setFocused(focused)}
      isOutsideRange={() => false}
      {...props}
    />
  );
};
