import { createContext } from 'react';

import {
  ApplyInfo,
  Location,
  MapLocationInfo,
  SearchInfo,
  SortInfo,
  ViewInfo,
} from '@/hooks/use-items/types';
import { MapItemData } from '@/types/item-details-types';
export interface ItemsContextShape {
  setIsInitialSearch: (b: boolean) => void;
  isLoading: boolean;
  items: MapItemData[] | undefined;
  searchInfo: SearchInfo;
  updateSearchInfo: (s: SearchInfo) => void;
  updateSortInfo: (s: SortInfo) => void;
  sortInfo: SortInfo;
  updateApplyInfo: (a: ApplyInfo) => void;
  applyInfo: ApplyInfo;
  updateLocationBlocked: (b: boolean) => void;
  detailView: ViewInfo;
  updateDetailView: (v: ViewInfo) => void;
  locationBlocked: boolean;
  fleetNumbers: string[];
  selectedItem: number;
  updateSelectedItem: (n: number) => void;
  updateUserLocation: (l: Location) => void;
  userLocation: Location;
  updateMapLocation: (m: MapLocationInfo) => void;
  mapLocation: MapLocationInfo;
}

const ItemsContext = createContext<ItemsContextShape>({} as ItemsContextShape);

export { ItemsContext };

// For when we decouple the ItemsContext from the search page:
//
// interface Props {
//   children: ReactNode;
// }

// const ItemsProvider = ({ children }: Props) => {
//   ...
//
//   return (
//     <ItemsContext.Provider value={{}}>
//       {children}
//     </ItemsContext.Provider>
//   );
// };

// export const useItemsContext = (): ItemsContextShape => {
//   const context = useContext(ItemsContext);

//   if (context === undefined) {
//     throw new Error('useItemsContext must be used within ItemsContextProvider');
//   }

//   return context;
// };
// export { ItemsProvider };
