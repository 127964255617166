import type { Moment } from 'moment';

import { PAYMENT_ICONS } from '@/constants/payments';
import type { ItemCategories, ItemSubCats } from '@/types/item-details-types';
import type { BillingMethods } from '@/types/org-types';

import type { BillingMethod } from './billing-types';
import type { Coordinates } from './maps-types';

export type MomentDateString = Moment | Date | string;

export enum Insurance {
  DECLINED = 'declined_vehicle_insurance',
}

export enum CHARGE_ITEM_NAMES {
  ACCOUNT_MANAGER_FEE = 'account_manager_fee',
  BASIC_INSURANCE_CREDIT = 'basic_insurance_credit',
  BASIC_INSURANCE_CREDIT_REFUND = 'basic_insurance_credit_refund',
  BASIC_INSURANCE = 'basic_insurance',
  BASIC_INSURANCE_REFUND = 'basic_insurance_refund',
  BASIC_INSURANCE_TAX = 'basic_insurance_tax',
  BASIC_INSURANCE_TAX_REFUND = 'basic_insurance_tax_refund',
  BOOKING_FEE_CREDIT = 'booking_fee_credit',
  BOOKING_FEE_CREDIT_REFUND = 'booking_fee_credit_refund',
  BOOKING_FEE = 'booking_fee',
  BOOKING_FEE_REFUND = 'booking_fee_refund',
  BOOKING_FEE_TAX = 'booking_fee_tax',
  BOOKING_FEE_TAX_REFUND = 'booking_fee_tax_refund',
  CANCELLATION_FEE = 'cancellation_fee',
  CLEANING_FEE = 'cleaning_fee',
  CLEANING_FEE_TAX = 'cleaning_fee_tax',
  CLEANING_FEE_TAX_REFUND = 'cleaning_fee_tax_refund',
  CREDIT = 'credit',
  CREDIT_REFUND = 'credit_refund',
  CUSTOM = 'custom',
  CUSTOM_REFUND = 'custom_refund',
  CUSTOM_TAX = 'custom_tax',
  CUSTOM_TAX_REFUND = 'custom_tax_refund',
  DEBIT_DEPOSIT = 'debit_deposit',
  DECLINED_INSURANCE = 'declined_insurance',
  DECLINED_INSURANCE_REFUND = 'declined_insurance_refund',
  DEPOSIT = 'deposit',
  DEPOSIT_HOLD = 'deposit_hold',
  DEPOSIT_REFUND = 'deposit_refund',
  DEPOSIT_TAX = 'deposit_tax',
  DRIVER_CUP = 'driver_cup',
  ESTIMATE_CREDIT = 'estimate_credit',
  ESTIMATE_CREDIT_REFUND = 'estimate_credit_refund',
  ESTIMATE = 'estimate',
  ESTIMATE_REFUND = 'estimate_refund',
  ESTIMATE_TAX = 'estimate_tax',
  ESTIMATE_TAX_REFUND = 'estimate_tax_refund',
  EXTENSION_CREDIT = 'extension_credit',
  EXTENSION_CREDIT_REFUND = 'extension_credit_refund',
  EXTENSION = 'extension',
  EXTENSION_REFUND = 'extension_refund',
  EXTENSION_TAX = 'extension_tax',
  EXTENSION_TAX_REFUND = 'extension_tax_refund',
  FLUID_FEE = 'fluid_fee',
  FLUID_FEE_REFUND = 'fluid_fee_refund',
  FUEL = 'fuel',
  FUEL_REFUND = 'fuel_refund',
  FUEL_TAX = 'fuel_tax',
  FUEL_TAX_REFUND = 'fuel_tax_refund',
  FVIP_FEE = 'fvip_fee',
  INCORRECT_FVIP_TRANSFER = 'incorrect_fvip_transfer',
  INVALID = 'invalid',
  LATE = 'late',
  LATE_REFUND = 'late_refund',
  LATE_TAX = 'late_tax',
  LATE_TAX_REFUND = 'late_tax_refund',
  MILEAGE = 'mileage',
  MILEAGE_REFUND = 'mileage_refund',
  MILEAGE_TAX = 'mileage_tax',
  MILEAGE_TAX_REFUND = 'mileage_tax_refund',
  MISSING_INSPECTION_PICTURES_TAX = 'missing_inspection_pictures_tax',
  MISSING_INSPECTION_PICTURES_TAX_REFUND = 'missing_inspection_pictures_tax_refund',
  PREMIUM_INSURANCE_CREDIT = 'premium_insurance_credit',
  PREMIUM_INSURANCE_CREDIT_REFUND = 'premium_insurance_credit_refund',
  PREMIUM_INSURANCE = 'premium_insurance',
  PREMIUM_INSURANCE_REFUND = 'premium_insurance_refund',
  PROMO = 'promo',
  PROMO_REFUND = 'promo_refund',
  PREMIUM_INSURANCE_TAX = 'premium_insurance_tax',
  PREMIUM_INSURANCE_TAX_REFUND = 'premium_insurance_tax_refund',
  SMOKING_FEE_TAX = 'smoking_fee_tax',
  SMOKING_FEE_TAX_REFUND = 'smoking_fee_tax_refund',
}

export enum ReservationStatus {
  PENDING = 'pending',
  PENDING_ADMIN_APPROVAL = 'pending_admin_approval',
  APPROVED = 'approved',
  ACTIVE = 'active',
  RETURNED = 'returned',
  COMPLETED = 'completed',
  DENIED = 'denied',
  CANCELLED = 'cancelled',
}

export enum CHARGE_STATUS {
  PAID = 'paid',
  UNPAID = 'unpaid',
  WAIVED = 'waived',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  FAILED = 'failed',
  INVALID = 'invalid',
}

export interface ChargeLineItemShape {
  amount: number;
  description: string;
  'end-time': string;
  inserted_at: string;
  name: CHARGE_ITEM_NAMES;
  'start-time': string;
  status: CHARGE_STATUS;
  title: string;
  updated_at: string;
  details?: {
    id: number | string;
    start: string | null;
    end: string | null;
    'final-amount': number;
    'parent-charge-line-item-id': number;
    AssociatedLineItems: number | null;
    messages: string[];
  };
}

export interface InvoicePayments {
  'amount-due': number;
  'billing-method-id': number;
  'charge-line-items': ChargeLineItemShape[];
  'due-date': string;
  'inserted-at': string;
  status: string;
  title: string;
  'total-tax': number;
  'updated-at': string;
}
export interface Invoice {
  description: string;
  'organization-id': number;
  'end-time': string;
  'inserted-at': string;
  'start-time': string;
  status: string;
  title: string;
  'total-cost-estimate': number;
  'updated-at': string;
  'user-id': number;
  'insurance-options': {
    amount: number;
    description: string;
    name: string;
    title: string;
  }[];
  'invoice-payments': InvoicePayments[];
}

export interface ReservationEstimate {
  'drop-off': string;
  insurance: Insurance;
  'organization-id'?: number;
  'pick-up': string;
  'promo-code': string;
  invoice: Invoice;
  deliveryLat: number;
  deliveryLng: number;
}

export interface ReservationEstimateRequest extends ReservationEstimate {
  'item-id': number;
}

export interface ItemRates {
  id: string;
  daily: number;
  hourly: number;
  weekly: number;
  monthly: number;
}

interface ItemAdditionalCharges {
  id: number;
  'item-id': number;
  title: string;
  description: string;
  'maximum-unit-amount': number;
}

interface BorrowerLenderDetails {
  'user-id': number;
  first: string;
  last: string;
  'avatar-url'?: string;
  'average-rating': number;
  'phone-number': string;
  'identification-status'?: string;
}

interface ItemDetails {
  id: string;
  vin: string;
  year: string;
  model: string;
  manufacturer: string;
  fuel_capacity: number;
  category: string;
  subtitle: string;
  banner_url: string;
  cost_per_mile: number;
  serial_number: string;
  preferred_rate: string;
  unlimited_miles: boolean;
  key_access_method: string;
  key_access_autonomous: boolean;
  key_access_instructions: string;
  license_plate_state: string;
  license_plate_number: string;
  license_plate_country: string;
  special_instructions: string;
  mileage_limit_daily: number;
}

export interface ItemPictures {
  '3_4_rear'?: string;
  banner?: string;
  cargo_space?: string;
  driver_side?: string;
  full?: string;
  front?: string;
  front_seats?: string;
  id: string;
  index: number;
  large?: string;
  large_banner?: string;
  large_sq?: string;
  max?: string;
  medium?: string;
  original?: string;
  rear?: string;
  rear_seats?: string;
  small?: string;
  small_sq?: string;
  small_st?: string;
  small_st_2x?: string;
  small_st_3x?: string;
  standard?: string;
  standard_2x?: string;
  standard_3x?: string;
  standard_banner?: string;
  standard_banner_2x?: string;
  standard_banner_3x?: string;
  standard_sq?: string;
  thumb?: string;
  thumb_2x?: string;
  thumb_3x?: string;
  thumb_small?: string;
  thumb_url?: string;
  trunk?: string;
  url?: string;
  uuid?: string;
  x_large?: string;
  x_small_sq?: string;
  xx_large?: string;
  name:
    | '3_4_rear'
    | 'banner'
    | 'cargo_space'
    | 'driver_side'
    | 'full'
    | 'front'
    | 'front_seats'
    | 'id'
    | 'large'
    | 'large_banner'
    | 'large_sq'
    | 'max'
    | 'medium'
    | 'name'
    | 'original'
    | 'primary'
    | 'rear'
    | 'rear_seats'
    | 'small'
    | 'small_sq'
    | 'small_st'
    | 'small_st_2x'
    | 'small_st_3x'
    | 'standard'
    | 'standard_2x'
    | 'standard_3x'
    | 'standard_banner'
    | 'standard_banner_2x'
    | 'standard_banner_3x'
    | 'standard_sq'
    | 'thumb'
    | 'thumb_2x'
    | 'thumb_3x'
    | 'thumb_small'
    | 'thumb_url'
    | 'trunk'
    | 'url'
    | 'uuid'
    | 'x_large'
    | 'x_small_sq'
    | 'xx_large'
    | 'index';
}
export interface AdditionalCharges {
  id: string;
  title: string;
  multiplier: number;
  description: string;
  final_amount: number;
  applied_reason: string;
  maximum_unit_amount: number;
}

export interface ItemTelematics {
  'battery-range'?: number;
  'battery-sensing-enabled'?: boolean;
  'charge-state'?: string;
  configuration?: number;
  dedicated?: number;
  'deleted-at'?: string;
  'device-id'?: string;
  'door-lock-status'?: string;
  'door-sensing-enabled'?: boolean;
  'engine-status'?: string;
  'fuel-sensing-enabled'?: boolean;
  'full-charge-range'?: number;
  id?: number;
  identifier: string;
  'ignition-sensing-enabled'?: boolean;
  'inserted-at'?: string;
  installed?: number;
  ismi?: string;
  'item-id'?: number;
  'last-update-time'?: string;
  'locking-enabled'?: boolean;
  'mobilizing-enabled'?: boolean;
  model?: string;
  'sim-provider-id'?: number;
  'state-of-charge'?: number;
  'time-to-full-charge'?: number;
  type?: string;
  'updated-at'?: string;
}

interface ItemLocation {
  address: string;
  city: string;
  coordinates: Coordinates;
  'inserted-at': string;
  'postal-code': string;
  state: string;
  'unassisted-pickup': boolean;
}

// Non-version item shape
export interface Item {
  'additional-charges': AdditionalCharges[] | null;
  'advanced-notice-minutes': number;
  'approved-by-adminAt': string | null;
  'average-rating': number;
  'booking-count': number;
  'deleted-at': string | null;
  'dropoff-instructions': string;
  'estimated-cost'?: number;
  'featured-image-identifier': string;
  'first-published-at': string | null;
  'flagged-for-moderation': boolean;
  'fleet-number': string;
  'fluid-fleet-service': boolean;
  'inserted-at': string | null;
  'internal-ranking': number;
  'is-electric-vehicle': boolean;
  'is-vehicle': boolean;
  'item-additional-charges'?: ItemAdditionalCharges[];
  'item-specifications': {
    'cargo-height': number;
    'cargo-length': number;
    'cargo-volume': number;
    'cargo-width': number;
    manufacturer: string;
    model: string;
    wheelbase: number;
  };
  'market-id': number;
  'max-pay-as-you-go-days': number;
  'pickup-instructions': string;
  'sub-category': ItemSubCats | '';
  'telematic-devices': ItemTelematics | null;
  'updated-at': string | null;
  active: boolean;
  categories: ItemCategories | null;
  description: string;
  details: ItemDetails | null;
  id: number;
  instabook: boolean;
  ismi: number;
  location?: ItemLocation;
  make: string;
  model: string;
  pictures: ItemPictures[];
  rates: ItemRates | null;
  status: 'published' | 'draft' | '';
  telematics: ItemTelematics;
  title: string;
  type: string;
  uuid: string;
  vin: string;
}

export interface PublicItem {
  id: number;
  title: string;
  description: string;
  instabook: boolean;
  'is-vehicle': boolean;
  'is-electric-vehicle': boolean;
  'flagged-for-moderation': boolean;
  pictures: [
    {
      id: string;
      uuid: string;
      url: string;
      full: string;
      medium: string;
      large: string;
      index: string;
      standard: string;
      thumb: string;
    }
  ];
  'booking-count': number;
  active: boolean;
  'fluid-fleet-service': boolean;
  'max-pay-as-you-go-days': number;
  details: ItemDetails | null;
  location: {
    coordinates?: Coordinates;
    address: string;
    city: string;
    'inserted-at': string;
    'postal-code': string;
    state: string;
    'street-name': string;
    'street-number': string;
    'unassisted-pickup': boolean;
  };
  lender: {
    id: number;
    first: string;
    last: string;
    'email-verified': boolean;
    'avatar-url': string;
    'phone-number-verified': boolean;
    'average-rating': number;
    'app-login': boolean;
  };
  reviews: string | null;
  'item-available-to-organization': boolean;
  rates: ItemRates | null;
}

interface EstimateBillingMethods extends BillingMethods {
  'user-id': number;
  'organization-id': number;
  'inserted-at': string;
  'updated-at': string;
}

interface PolicyAndFees {
  title: string;
  description: string;
  amount: string;
  rate: string;
}

export interface CostEstimateReturn extends ReservationEstimate {
  'billing-methods': {
    'default-billing-method': BillingMethod | null;
    'billing-methods': EstimateBillingMethods[];
  };
  'billing-form': {
    'default-billing-method': BillingMethod | null;
    'billing-methods': EstimateBillingMethods[];
  };
  item: Item;
  'policy-and-fees': PolicyAndFees[];
}

export interface ReservationItem {
  id: number;
  'sub-category': string;
  title: string;
  description: string;
  'fleet-number': string;
  'thumbnail-url': string;
  location: {
    address: string;
    timezone: string;
    'inserted-at': string;
    'unassisted-pickup': boolean;
    coordinates: google.maps.LatLngLiteral;
  };
  vin: string;
  'first-name': string;
  'last-name': string;
  lender: BorrowerLenderDetails;
  telematics: {
    id: number;
    model: string;
    identifier: string;
    'locking-enabled': boolean;
  };
  categories: ItemCategories | null;
}

interface ReservationInspection {
  id: string;
  summary: {
    id: string;
    completed_at: null | string;
    coordinates: {
      id: string;
      lat: 0;
      lng: 0;
    };
  };
  damage_start: {
    id: string;
    has_damage: false;
    completed_at: null | string;
  };
  damage_detail: {
    id: string;
    notes: string;
    completed_at: null | string;
    pictures: [];
  };
  mileage_detail: {
    id: string;
    url: string;
    thumb_url: string;
    completed_at: null | string;
  };
  fuel_start?: {
    id: string;
    completed_at: null | string;
  };
  fuel_tank_detail: {
    id: string;
    url: string;
    thumb_url: string;
    completed_at: null | string;
  };
  key_access_display?: {
    id: string;
    completed_at: null | string;
  };
  fuel_tank_full_confirmation: {
    id: string;
    is_refilled: false;
    completed_at: null | string;
  };
  electric_vehicle_plug?: {
    id: string;
    url: string;
    thumb_url: string;
    completed_at: null | string;
  };
}

// NB Some of the nulls might have other possible types.
// This could also be broken down if we find a need
export interface Reservation {
  'additional-charges': AdditionalCharges[];
  'cost-per-mile': number;
  delivery?: {
    address: string;
    city: string;
    country: string;
    notes: string;
    'point-of-contact-name': string;
    'point-of-contact-phone': string;
    'postal-code': string;
    state: string;
  };
  details: {
    id: string;
    client_version: string;
    cost_per_mile: number;
    deposit_amount: number;
    dropoff_fuel_level: number;
    fuel_capacity: number;
    fuel_charge: number;
    late_charge: number;
    lender_returned_at: string | null;
    mileage_at_dropoff: number;
    mileage_at_pickup: number;
    mileage_limit_daily: number;
    mileage_charge: number;
    mileage_over: number;
    pickup_fuel_level: number;
    unlimited_miles: boolean;
    vehicle_insurance: string;
    version: string;
    dropoff_inspection: ReservationInspection;
    pickup_inspection: ReservationInspection;
  };
  'drop-off-instructions': string;
  'drop-off': string;
  'extension-charges': ExtensionCharges[] | null;
  inspection: {
    'drop-off-inspection': {
      notes: string;
      'mileage-url': string;
      'fuel-url': string;
      pictures: ItemPictures[];
      'completed-at': string | null;
    };
    'pick-up-inspection': {
      notes: string;
      'mileage-url': string;
      'fuel-url': string;
      pictures: ItemPictures[];
      'completed-at': string | null;
    };
  };
  item: ReservationItem;
  'key-access-autonomous': boolean;
  'key-access-instructions': string;
  'key-access-method': string;
  'mileage-limit-daily': number;
  'original-charge': {
    amount: number;
    title: string;
    'charge-line-items': ChargeLineItems[];
    'payment-method': PaymentMethod | null;
  };
  'other-charges': null;
  'payment-schedule':
    | {
        status: CHARGE_STATUS;
        amount: number;
        'due-date': string;
        'billing-method-nick-name': string;
        'billing-method-last-four': string;
      }[]
    | null;
  'pick-up-instructions': string;
  'pick-up': string;
  'policy-and-fees': PolicyAndFees[];
  'purchase-order': boolean;
  renter: {
    'user-id': number;
    first: string;
    last: string;
    'avatar-url'?: string;
    'average-rating': number;
    'phone-number': string;
    'identification-status'?: string;
  };
  'reservation-id': number;
  'scheduled-payment': boolean;
  status: string;
  'unlimited-miles': boolean;
}
export interface CostEstimateExtension {
  total: number;
  credit: number;
  reservationCostEstimates: {
    id: number;
    chargeLineItems: {
      amount: number;
      type: CHARGE_ITEM_NAMES;
    }[];
  }[];
  scheduledPayments: {
    amountDue: number;
    dueDate: string;
    chargeLineItems: {
      amount: number;
      type: CHARGE_ITEM_NAMES;
    }[];
  }[];
}

export interface ExtensionCharges {
  amount: number;
  'charge-line-items': ChargeLineItems[];
  'payment-method': PaymentMethod;
  title: string;
}

export interface PaymentMethod {
  type: string;
  last4: string;
  name: keyof typeof PAYMENT_ICONS;
}

export interface ChargeLineItems {
  id: number;
  charge_id: number;
  description: string;
  details?: {
    id: string;
    messages: string[];
    start: string | null;
    end: string | null;
    'final-amount': number;
    'parent-charge-line-item-id': number;
    AssociatedLineItems: null | number;
  } | null;
  amount: number;
  title: string;
  name: CHARGE_ITEM_NAMES;
  'invoice-id': number;
  'invoice-payment-id': number;
  'reservation-id': number;
  status: CHARGE_STATUS;
  'start-time': string;
  'end-time': string;
  inserted_at: string;
  updated_at: string;
}
export interface ReservationVehicle {
  item: Item;
  'pickup-location': {
    address: string;
    coordinates: google.maps.LatLngLiteral;
    id: number;
    'inserted-at': string;
    timezone: string;
    'unassisted-pickup': boolean;
  };
  reservation: {
    id: number;
    'cancellation-reason': string;
    'drop-off': string;
    'inserted-at': string;
    'item-id': number;
    'organization-id': number;
    'pick-up': string;
    'purchase-order': boolean;
    'renter-id': number;
    'started-at': string;
    status: ReservationStatus;
  };
  'telematics-location': google.maps.LatLngLiteral;
}
