import {
  Alert,
  AlertDescription,
  AlertIcon,
  useBoolean,
} from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DialogHeader, FlexCol, Form, SubmitButton } from '@/base-components';
import { useOrganizationContext, useUser } from '@/hooks';
import { post } from '@/utils/helpers';

const SuccessPage = ({ onClose }: { onClose: () => void }): ReactElement => {
  const { t } = useTranslation();
  const [successFailure, setSuccessFail] = useState<string | null>(null);
  const [isLoading, setLoading] = useBoolean();
  const { login } = useUser();
  const { handleSubmit, getValues } = useFormContext();
  const { actions } = useOrganizationContext();

  // Handle submit
  const submit = async (): Promise<void> => {
    setLoading.on();
    const body = {
      username: getValues('emailPhone'),
      password: getValues('password'),
      remember: true,
    };

    const headers = {
      Platform: 'FT Web App',
    };

    try {
      const { status } = await post('api/login', body, headers);

      if (status !== 'success') {
        throw new Error('failure: POST /api/login');
      }

      const loginUser = await login();

      // TODO Switch back to `loginUser['default-profile'].organization` once the API is fixed.
      const newOrg = loginUser.organizations.find(org => {
        return (
          org.organization.id === loginUser['default-profile'].organization.id
        );
      })?.organization;

      actions.setOrg({
        newOrg,
        makeRequest: false,
        updateRoute: false,
      });

      onClose();
    } catch (e) {
      setSuccessFail(t('common:login.error'));
    } finally {
      setLoading.off();
    }
  };

  return (
    <>
      {successFailure && (
        <Alert status="error" variant="subtle" mt={4}>
          <AlertIcon />
          {successFailure}
        </Alert>
      )}
      <Form height="100%" onSubmit={handleSubmit(submit)}>
        <FlexCol align="center" w="100%">
          <DialogHeader
            hideMobileClose
            header={t('login.passUpdated')}
            onClose={onClose}
          />
          <Alert mt={11} status="success" orientation="vertical">
            <AlertIcon />
            <AlertDescription data-test-id="password-updated-success">
              {t('login.passUpdatedText')}
            </AlertDescription>
          </Alert>
          <SubmitButton isLoading={isLoading} mt={11}>
            {t('button.login')}
          </SubmitButton>
        </FlexCol>
      </Form>
    </>
  );
};

export default SuccessPage;
