/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-param-reassign: ["error", { "props": false }] */
import type { Variant } from '@amplitude/experiment-js-client';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Divider, GridItem, SpanText, Text } from '@/base-components';
import { useExperiment, useOrganizationContext } from '@/hooks';
import { AmplitudeFeatureFlag } from '@/lib/constants';
import { ROLE_TYPES } from '@/types';
import { centsToDollars, formatMoney } from '@/utils';

import { useLeftNavMenuContext } from '../context/useLeftNavMenu';
import {
  DRIVER_MENU_ITEMS,
  FAILED_TRANSACTIONS_ITEM,
  FLEET_MENU_ITEMS,
  ORG_MENU_ITEMS,
  PERSONAL_MENU_ITEMS,
  UNIVERSAL_MENU_ITEMS,
} from '../MenuItemObjects';
import { MenuItemsShape } from '../types';
import { LeftNavMenuSection } from './LeftNavMenuSection';

export const LeftNavMenuContent = (): React.ReactElement => {
  const { t } = useTranslation('org_menu');

  // Amplitute Feature Flag
  const { getVariant } = useExperiment();
  const { value: coiFlag } = getVariant(
    AmplitudeFeatureFlag.INSURANCE
  ) as Variant;
  const { value: failedTransFlag } = getVariant(
    AmplitudeFeatureFlag.FAILED_TRANSACTIONS
  ) as Variant;

  const transactionsEnabled = failedTransFlag === ('show' as Variant);

  const [personalMenuItems, setPersonalMenuItems] =
    useState<MenuItemsShape[]>(PERSONAL_MENU_ITEMS);

  const [orgMenuItems, setOrgMenuItems] =
    useState<MenuItemsShape[]>(ORG_MENU_ITEMS);

  const {
    context: { userOrgRole, failedTransactions },
  } = useOrganizationContext();

  const { claimsUrl, isCollapsed, orgCategory, orgCreditAmt } =
    useLeftNavMenuContext();

  const updateClaimsMenu = (menu: MenuItemsShape[]): MenuItemsShape[] => {
    return menu.map(item => {
      if (item.testId === 'claims-menu' || item.testId === 'nav-claims-menu') {
        return {
          ...item,
          path: claimsUrl,
        };
      }

      return item;
    });
  };

  useEffect(() => {
    if (!claimsUrl) return;

    const newPersonalMenu = updateClaimsMenu(PERSONAL_MENU_ITEMS);
    setPersonalMenuItems(newPersonalMenu);

    const newOrgMenu = updateClaimsMenu(ORG_MENU_ITEMS);
    setOrgMenuItems(newOrgMenu);
  }, [claimsUrl]);

  useEffect(() => {
    if (coiFlag === ('show' as Variant)) return;
    const newPersonalMenu = PERSONAL_MENU_ITEMS.filter(
      item => item.featureFlag !== AmplitudeFeatureFlag.INSURANCE
    );
    setPersonalMenuItems(newPersonalMenu);
  }, []);

  useEffect(() => {
    const failedOrgMenu = [...orgMenuItems, FAILED_TRANSACTIONS_ITEM];

    if (!transactionsEnabled || failedTransactions.total === 0)
      return setOrgMenuItems(orgMenuItems);
    if (failedTransactions.total > 0) return setOrgMenuItems(failedOrgMenu);
  }, [failedTransactions, transactionsEnabled]);

  const isPersonal = orgCategory === 'Personal';

  const isDriver = userOrgRole === ROLE_TYPES.DRIVER;
  return (
    <>
      {isPersonal && (
        <LeftNavMenuSection
          data-test-id="left-nav-personal-grid"
          menuItems={personalMenuItems}
        />
      )}
      {isDriver && (
        <LeftNavMenuSection
          data-test-id="left-nav-personal-grid"
          menuItems={DRIVER_MENU_ITEMS}
        />
      )}
      {!isPersonal && !isDriver && (
        <>
          <LeftNavMenuSection
            data-test-id="left-nav-fleet-grid"
            sectionTitle={t('menu.fleet.title')}
            menuItems={FLEET_MENU_ITEMS}
          />
          <LeftNavMenuSection
            data-test-id="left-nav-company-grid"
            sectionTitle={t('menu.company.title')}
            menuItems={orgMenuItems}
          >
            {!!orgCreditAmt && (
              <GridItem colStart={2}>
                <Text fontSize="sm">
                  <SpanText color="darkGray">{t('menu.credit')}: </SpanText>
                  {formatMoney(centsToDollars(orgCreditAmt))}
                </Text>
              </GridItem>
            )}
          </LeftNavMenuSection>
        </>
      )}
      <Divider
        color="darkGray"
        mb="18px"
        mx={isCollapsed ? '18px' : 6}
        w="auto"
      />
      <LeftNavMenuSection
        data-test-id="left-nav-universal-grid"
        menuItems={UNIVERSAL_MENU_ITEMS}
      />
    </>
  );
};
