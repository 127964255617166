export const fluidBlue = `#00b2e3`;
export const defaultOptions: google.maps.drawing.DrawingManagerOptions = {
  drawingControl: false,
  drawingMode: 'polygon' as google.maps.drawing.OverlayType.POLYGON,
  polygonOptions: {
    fillColor: fluidBlue,
    strokeColor: fluidBlue,
    fillOpacity: 0.5,
    strokeWeight: 4,
    clickable: true,
    editable: true,
    draggable: true,
    zIndex: 1,
  },
};
