import useSWR from 'swr';

import { get } from '@/utils/helpers';

export const useAvailability = ({ itemId, startDate, endDate }) => {
  const url = `api/vehicles/${itemId}/availability?start-date=${startDate
    .utc()
    .format()}&end-date=${endDate.utc().format()}`;

  const { data, error } = useSWR(url, get);

  return {
    data: data?.data?.['booked-times'] || [],
    isLoading: !error && !data,
    error,
  };
};
