import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
} from '@fluidtruck/core';

import { useUser } from '@/hooks';
import { heights } from '@/theme';
import { chakra } from '@/utils';

import { LoginSignup } from '../../LoginSignup';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';
import ProfileHeader from '../../ProfileDropdown/ProfileHeader';
import ProfileOrgs from '../../ProfileDropdown/ProfileOrgs';
import * as Types from './types';

export const LoginMobileContainer = chakra('div', {
  baseStyle: {
    p: 5,
  },
  label: 'MobileRightMenu-LoginMobileContainer',
});

export const MobileRightMenu = ({
  isOpen,
  onClose,
  handleLoginFinish,
  handleSignupFinish,
}: Types.MenuProps) => {
  const { user } = useUser();

  const content = user?.id ? (
    <>
      <DrawerHeader>
        <ProfileHeader />
      </DrawerHeader>
      <DrawerBody sx={{ p: `0 0 ${heights.topNavMobile + 12}px 0` }}>
        <LoginMobileContainer>
          <ProfileDropdown>
            <ProfileOrgs handleClose={onClose} />
          </ProfileDropdown>
        </LoginMobileContainer>
      </DrawerBody>
    </>
  ) : (
    <>
      <DrawerBody sx={{ p: 0 }}>
        <LoginMobileContainer>
          <LoginSignup
            loginProps={{ onFinish: handleLoginFinish }}
            signupProps={{ onFinish: handleSignupFinish }}
          />
        </LoginMobileContainer>
      </DrawerBody>
    </>
  );

  return (
    <Drawer isFullHeight placement="top" onClose={onClose} isOpen={isOpen}>
      <DrawerContent>
        <DrawerCloseButton />
        {content}
      </DrawerContent>
    </Drawer>
  );
};
