import { ApiRoute, useGET } from '@/api-resources';

import {
  defaultEnd,
  defaultStart,
  mapDataToItems,
  mapEntriesToItems,
} from './helpers';

interface Props {
  isInitialSearch?: boolean;
  orgId?: number;
  lat?: number;
  lng?: number;
  rad?: number;
  startTime?: string;
  endTime?: string;
  pageSize?: number;
  type?: string;
  isInstantBook?: boolean;
  showEV?: boolean;
  fleetNumbersURI?: string;
}

export const useItems = ({
  isInitialSearch = true,
  orgId,
  lat,
  lng,
  rad = 100,
  pageSize = 100,
  startTime = defaultStart,
  endTime = defaultEnd,
  type,
  isInstantBook = false,
  showEV = false,
  fleetNumbersURI,
}: Props) => {
  const homeQuery = {
    orgId,
    lat,
    long: lng,
    rad,
  };

  const hasLocation = Boolean(lat && lng);

  const availablityQuery = {
    'start-time': startTime,
    'end-time': endTime,
    latitude: lat,
    longitude: lng,
    radius: rad,
    'page-size': pageSize,
    'vehicle-type': type,
    instabook: isInstantBook,
    'is-electric-vehicle': showEV,
    'fleet-numbers': fleetNumbersURI,
    'organization-id': orgId,
  };

  const { data, error } = useGET({
    path: ApiRoute.API_ITEMS_HOME,
    query: homeQuery,
    shouldFetch: isInitialSearch && hasLocation,
  });

  const { data: availablityData, error: availablityError } = useGET({
    path: ApiRoute.API_ITEMS,
    query: availablityQuery,
    shouldFetch: !isInitialSearch && hasLocation,
  });

  const finalData = isInitialSearch
    ? mapDataToItems(data?.data)
    : mapEntriesToItems(availablityData?.data?.entries);

  return {
    data: finalData,
    error: isInitialSearch ? error : availablityError,
    isLoading: isInitialSearch
      ? !data && !error
      : !availablityData && !availablityError,
  };
};
