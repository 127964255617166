import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

import { Action, MapState } from '@/lib/context/GoogleMaps/reducer/map_actions';
import {
  googleMapReducer,
  initialState,
} from '@/lib/context/GoogleMaps/reducer/map_reducer';

export interface GoogleMapContextShape {
  state: MapState;
  dispatch: Dispatch<Action>;
}

export const GoogleMapContext = createContext<
  GoogleMapContextShape | undefined
>(undefined);

const GoogleMapProvider = ({
  children,
  defaultLatLng,
}: {
  children: ReactNode;
  defaultLatLng?: google.maps.LatLngLiteral;
}): ReactElement => {
  const [state, dispatch] = useReducer(googleMapReducer, {
    ...initialState,
    latLng: defaultLatLng ? { ...defaultLatLng } : null,
  });

  return (
    <GoogleMapContext.Provider value={{ state, dispatch }}>
      {children}
    </GoogleMapContext.Provider>
  );
};

export const useGoogleMapContext = (): GoogleMapContextShape => {
  const context = useContext(GoogleMapContext);

  if (context === undefined) {
    throw new Error(
      'useGoogleMapContext must be used within GoogleMapProvider'
    );
  }

  return context;
};

export { GoogleMapProvider };
