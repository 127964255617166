import { cvv, number } from 'card-validator';
import moment from 'moment';
import { FieldValues, RegisterOptions, UseFormTrigger } from 'react-hook-form';

export const validator =
  (trigger: UseFormTrigger<FieldValues>) =>
  (e: React.ChangeEvent<HTMLInputElement>): Promise<boolean> =>
    trigger(e.target.name);

export const emailPhoneFormat =
  /^(?!\s)(?!.*\s$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|([0-9]{10})+$/;
export const alphaNumeric = /^[a-zA-Z0-9_]+$/;

export const passwordMinLength = (message: string): RegisterOptions => ({
  required: true,
  minLength: { value: 8, message },
});

export const emailPattern = (message: string): RegisterOptions => ({
  required: true,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
    message,
  },
});

export const emailPhonePattern = (message: string): RegisterOptions => ({
  required: true,
  pattern: {
    value: emailPhoneFormat,
    message,
  },
});

export const phonePattern = (message: string): RegisterOptions => ({
  required: true,
  pattern: {
    value: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\s*$/,
    message,
  },
});

export const zipcodePattern = (message: string): RegisterOptions => ({
  required: true,
  pattern: {
    value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
    message,
  },
});

export const cardNumberValidate = (message?: string): RegisterOptions => ({
  required: true,
  validate: value => number(value)?.isValid || message,
});

export const cvvValidate = (message?: string): RegisterOptions => ({
  required: true,
  validate: value => cvv(value).isValid || message,
});

export const cardExpValidate = (message?: string): RegisterOptions => ({
  required: true,
  validate: value => {
    const isNotExpired = moment().startOf('month') <= moment(value, 'MM/YY');
    return isNotExpired || message;
  },
});

export const depositRegistration = (message: string): RegisterOptions => ({
  required: true,
  validate: value => {
    const numeric = Number(value);
    const isValid = !Number.isNaN(numeric);
    return (isValid && numeric >= 0.01 && numeric <= 0.99) || message;
  },
});

export const nameMaxLength = { required: true, maxLength: 255 };

export const vinValidation = (
  messageLength: string,
  messagePattern: string
): RegisterOptions => ({
  required: true,
  minLength: {
    value: 17,
    message: messageLength,
  },
  maxLength: {
    value: 17,
    message: messageLength,
  },
  pattern: {
    value: alphaNumeric,
    message: messagePattern,
  },
});

export const licensePlateValidation = (message: string): RegisterOptions => ({
  pattern: { value: alphaNumeric, message },
});

// For Email Validation in Chakra-React-Select

// export const handleKeyDown: React.KeyboardEventHandler<Element> = event => {
//   const val = createOption(inputValue);
//   if (!inputValue) setError('');
//   switch (event.key) {
//     case 'Enter':
//     case 'Tab':
//       if (!emailregex.test(val.value)) {
//         setError(
//           t('account:organization.invite.invalidEmail', {
//             value: val.value,
//           })
//         );
//       } else {
//         setError('');
//         setEmails(prev => [...prev, val]);
//         setInputValue('');
//         event.preventDefault();
//       }
//   }
// };
