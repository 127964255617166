import { useRouter } from 'next/router';
import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from 'react';
import useSWR from 'swr';

import { ApiRoute, usePATCH } from '@/api-resources';
import { SORT_ORDER } from '@/base-components/data-tables';
import type {
  ErrorResponseType,
  FailedTransactionResponse,
  GenericApiResponse,
  OrgContextShape,
  OrgDetails,
  PagintedQueryParams,
  UseGetOrgShape,
  UserWithToken,
} from '@/types';
import { get } from '@/utils/helpers';

import { UserContext } from './UserContext';

export const OrganizationContext = createContext<OrgContextShape>(
  {} as OrgContextShape
);

// Helper method for setting default org
export const findMatchOrg = (user: UserWithToken) => {
  return user?.organizations.find(
    ORG => ORG.organization.id === user['default-organization-id']
  );
};

export const OrganizationContextProvider = (props: {
  children: React.ReactNode;
}): ReactElement => {
  const { children } = props;
  const { user } = useContext(UserContext);
  const router = useRouter();
  const { pathname, query } = router;

  const userPatch = usePATCH({
    path: ApiRoute.API_USERS,
  });

  // Account menu loads before user data is available to populate seed data
  const [org, setOrg] = useState<OrgDetails | undefined>(
    user?.organizations.find(
      ORG => ORG.organization.id === user?.['default-profile']?.organization?.id
    )?.organization
  );

  const userOrgProfile = user?.organizations.find(
    o => o.organization.id === user['default-organization-id']
  )?.profile;

  const userOrgRole = user?.organizations?.find(
    o => o.organization.id === org?.id
  )?.profile?.role;

  const setNewOrg: OrgContextShape['actions']['setOrg'] = async ({
    newOrg,
    updateRoute = true,
    makeRequest = true,
  }) => {
    if (updateRoute && !!query.orgId) {
      router.push(
        { pathname, query: { ...query, orgId: newOrg?.id.toString() } },
        undefined,
        {
          shallow: true,
        }
      );
    }

    if (makeRequest) {
      await userPatch.handler({
        body: {
          'default-organization-id': newOrg?.id,
        },
      });
    }

    setOrg(newOrg);
  };

  const refreshOrg = async () => {
    const updateOrg: GenericApiResponse<UseGetOrgShape> = await get(
      `api/organizations/${org?.id}`
    );
    if (updateOrg.status === 'fail') return;

    setOrg(updateOrg.data.organization);
  };

  // Failed Org Transactions: for NavMenu & DataTable
  const defaultQuery = {
    'page-number': '1',
    'page-size': 100,
    'sort-order': SORT_ORDER.DESC,
    'sort-field': 'due-date',
    'search-term': '',
  };
  const [tableQuery, setTableQuery] =
    useState<PagintedQueryParams>(defaultQuery);

  const key = org?.id
    ? `api/v1/organizations/${
        org?.id
      }/failed_transactions?${new URLSearchParams(tableQuery)}`
    : null;

  const {
    data: failedSwr,
    error: failedError,
    isValidating: failedValidating,
    isLoading,
    mutate,
  } = useSWR<FailedTransactionResponse, ErrorResponseType>(key, get, {
    revalidateOnFocus: false,
  });
  const failedTransactions = failedSwr?.entries || [];

  // Once we have the correct org, grab the user's profile off of it
  const actions = { setOrg: setNewOrg, refreshOrg };

  // Note: retaining the data shape below (org.organization) for downstream components
  const context = {
    isLoading,
    org: { organization: org },
    userOrgProfile,
    userOrgRole,
    failedTransactions: {
      orgId: query?.orgId?.toString() || '',
      total: failedSwr?.total || 0,
      data: failedTransactions,
      error: failedError,
      isValidating: failedValidating,
      mutate,
      tableQuery,
      setTableQuery,
    },
  };

  return (
    <OrganizationContext.Provider
      value={{
        context,
        actions,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
