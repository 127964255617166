import { chakra, Flex } from '@fluidtruck/core';

import { heights } from '@/theme';

export const ErrorContainer = chakra(Flex, {
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    height: `calc(100vh - ${heights.topNav}px)`,
  },
  label: 'ErrorContainer',
});

export const StyledHeader = {
  color: 'fluidBlue',
  lineHeight: 'normal',
  fontSize: { base: '7xl', lg: '8xl' },
};

export const StyledSubheader = {
  lineHeight: 'none',
  fontSize: { base: 'md', lg: 'lg' },
};

export const iconProps = {
  color: 'black',
  w: { base: '128px', lg: '232px' },
  h: { base: '128px', lg: '232px' },
};

export const textProps = {
  color: 'fluidBlue',
  fontSize: { base: '165px', lg: '325px' },
};
