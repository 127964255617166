import React, { createContext, ReactElement, ReactNode, useState } from 'react';

import { GenericApiResponse, ROLE_TYPES } from '@/types';

import { post } from '../../utils/helpers';

type InviteDetails = {
  inviteId: string;
  inviteCode: string;
};

export interface SignupShape {
  email: string;
  setEmail: (email: string) => void;
  pass: string;
  setPass: (pass: string) => void;
  phone: string;
  setPhone: (phone: string) => void;
  signupType: 'org' | string;
  setSignupType: (signupType: 'org' | string) => void;
  orgName: string;
  role?: ROLE_TYPES;
  setRole: (role: ROLE_TYPES | undefined) => void;
  setOrgName: (orgName: string) => void;
  orgId?: number;
  setOrgId: (orgId?: number) => void;
  inviteDetails?: InviteDetails;
  setInviteDetails: (inviteDetails?: InviteDetails) => void;
  acceptInvite: () => Promise<
    GenericApiResponse<{ 'organization-id': number }>
  >;
}
export const SignUpContext = createContext<SignupShape | undefined>(undefined);

const SignupContexProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const [signupType, setSignupType] = useState('');
  const [orgName, setOrgName] = useState('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [phone, setPhone] = useState('');
  const [orgId, setOrgId] = useState<number>();
  const [role, setRole] = useState<ROLE_TYPES | undefined>();
  const [inviteDetails, setInviteDetails] = useState<InviteDetails>();

  const acceptInvite = async (): Promise<
    GenericApiResponse<{ 'organization-id': number }>
  > => {
    const body: { 'invitation-id': number; 'invite-code'?: string } = {
      'invitation-id': Number(inviteDetails?.inviteId),
    };
    if (inviteDetails?.inviteCode) {
      body['invite-code'] = inviteDetails.inviteCode;
    }
    const response = await post('api/organizations/invitations/accept', body);
    return response;
  };

  return (
    <SignUpContext.Provider
      value={{
        email,
        setEmail,
        pass,
        setPass,
        phone,
        role,
        setRole,
        setPhone,
        signupType,
        setSignupType,
        orgName,
        setOrgName,
        orgId,
        setOrgId,
        inviteDetails,
        setInviteDetails,
        acceptInvite,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export { SignupContexProvider };
