import { ArrowLeftIcon } from '@fluidtruck/icons';
import React from 'react';

import { MotionButton } from '@/base-components';

import { useLeftNavMenuContext } from '../context/useLeftNavMenu';
import { MenuNavigationCollapse } from '../MenuNavigationCollapse';
import * as Styles from './styles';

const ButtonIcon = ({ closed }: { closed?: boolean }): React.ReactElement => {
  const styles = closed ? { transform: 'rotate(180deg)' } : {};
  return <ArrowLeftIcon alignSelf="center" h={6} {...styles} />;
};

export const LeftNavContainer = (): React.ReactElement => {
  const { isCollapsed, isMobile, collapse } = useLeftNavMenuContext();
  return (
    <>
      {/* This desktop button needs to be inside the container on desktop */}
      {!isMobile && (
        <MotionButton
          initial="closed"
          aria-label="Close Navigation"
          animate={isCollapsed ? 'closed' : 'open'}
          data-test-id="left-nav-collapse-button"
          onClick={collapse.toggle}
          sx={Styles.buttonStyles}
          variants={Styles.buttonVariants}
        >
          <ButtonIcon closed={isCollapsed} />
        </MotionButton>
      )}
      <MenuNavigationCollapse />
    </>
  );
};
