import moment from 'moment';

export const InsuranceTypes = {
  approved: 'approved',
  insurance: 'insurance',
  declined: 'declined_vehicle_insurance',
};

export const defaultReservationStart = moment().startOf('hour').add(2, 'hour');
export const defaultReservationEnd = moment().startOf('hour').add(3, 'hour');
