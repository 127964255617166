import { theme } from '@fluidtruck/core';

const {
  breakpoints: tidalBreakpoints,
  zIndices: tidalzIndices,
  shadows: tidalShadows,
} = theme;

// https://tidal.fluidtruck.com/docs/theming/theme
/**
 * zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },

const breakpoints = createBreakpoints({
  sm: '30em', // 480px
  md: '48em',  // 768px
  lg: '62em', // 991px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
  tablet: '64em', // 1024px
  smallDesktop: '1025px',
  baseDesktop: '90em', // 1440px
  largerDesktop: '95em', // 1520px
  landscape: '812px',  // mobile landscape
  ipadPro: '834px'
});


/** BoxShadows
 * https://tidal.fluidtruck.com/docs/features/style-props#shadow
 const shadows = {
   xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
   sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
   base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
   lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
   xl:
     "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
   "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
   outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
   inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
   none: "none",
   "dark-lg":
     "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px",
 }
 */

export const breakpoints = {
  ...tidalBreakpoints,
  xs: '375px',
};

export const shadows = {
  ...tidalShadows,
  inset: 'inset 2px 3px 4px rgba(0,0,0,0.1)',
  card: '2px 3px 4px rgba(0, 0, 0, 0.1)',
};

export const zIndices = {
  ...tidalzIndices,
  mapOverlay: 4,
  banner: 20,
  pageLoader: 2000,
};

/**
 * Adding and overriding theme styles
 * https://tidal.fluidtruck.com/docs/theming/theme#black--white
 */
