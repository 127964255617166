import { Avatar, Divider } from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Box, FlexCol, FlexRow } from '@/base-components';
import {
  Heading,
  SpanText,
  SubHeading,
} from '@/base-components/typography/Common';
import { useOrganizationContext, useUser } from '@/hooks';

const ProfileHeader = (): JSX.Element => {
  const { user } = useUser();
  const {
    context: { org },
  } = useOrganizationContext();
  const { t } = useTranslation('common');
  const currentOrg = org?.organization;

  return (
    <>
      <FlexRow pb="18px">
        <Avatar
          src={user?.['avatar-url']}
          sx={{
            width: '48px',
            height: '48px',
            svg: {
              height: '100%',
              width: '100%',
            },
          }}
        />
        <FlexCol pl={3}>
          <Heading fontSize="md">{currentOrg?.name}</Heading>
          <SubHeading color="darkgray" fontSize="xs" mt={1}>
            {t('vehicle_limit')}:{' '}
            <SpanText color="blackAlpha.700">
              {currentOrg?.['borrow-limit']}
            </SpanText>
          </SubHeading>
        </FlexCol>
      </FlexRow>
      <Box>
        <Divider />
      </Box>
    </>
  );
};

export default ProfileHeader;
