import { ReactNode } from 'react';

import {
  BannerMainContainer,
  BannerMainContent,
  DriverCupBanner,
} from '@/components/Banners';
import {
  NavigationMenuProvider,
  useLeftNavMenu,
} from '@/components/LeftNavMenu';
import { LeftNavContainer } from '@/components/LeftNavMenu/components';
import NavBar from '@/components/Nav/NavBar';
import { useUser } from '@/hooks';
import { DriverScoreContextProvider } from '@/lib/context/DriverScoreContext';
import { useBoolean } from '@/utils/tidal-utils';

import * as Styles from '../styles';

interface Props {
  children?: ReactNode;
}

const DriverScoreLayout = ({ children }: Props) => {
  const { user } = useUser();
  const [isCollapsed, collapse] = useBoolean(true);

  const navContext = useLeftNavMenu({
    collapse,
    isCollapsed,
  });

  const renderContent = (() => (
    <DriverScoreContextProvider>
      <Styles.DriverScoreBody>
        <BannerMainContainer backgroundColor="darkBlue">
          <BannerMainContent>
            <DriverCupBanner />
          </BannerMainContent>
        </BannerMainContainer>
        <Styles.DriverScoreContent>{children}</Styles.DriverScoreContent>
      </Styles.DriverScoreBody>
    </DriverScoreContextProvider>
  ))();

  if (!user) {
    return (
      <Styles.DriverScoreDefaultGrid>
        <NavBar />
        {renderContent}
      </Styles.DriverScoreDefaultGrid>
    );
  }

  return (
    <NavigationMenuProvider value={navContext}>
      <NavBar isCollapsed={isCollapsed} toggleMobileMenu={collapse.toggle} />
      <Styles.CommonContainerGrid>
        <LeftNavContainer />
        {renderContent}
      </Styles.CommonContainerGrid>
    </NavigationMenuProvider>
  );
};

export default DriverScoreLayout;
