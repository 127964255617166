import {
  forwardRef,
  Input as TidalInput,
  InputAddonProps,
  InputElementProps,
  InputGroup as TidalInputGroup,
  InputGroupProps,
  InputLeftAddon as TidalInputLeftAddon,
  InputLeftElement as TidalInputLeftElement,
  InputProps,
  InputRightAddon as TidalInputRightAddon,
  InputRightElement as TidalInputRightElement,
  Textarea as TidalTextarea,
  TextareaProps,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

// INPUTS
export const Input = forwardRef<InputProps, 'input'>((props, ref) => (
  <TidalInput {...props} ref={ref} sx={{ textOverflow: 'ellipsis' }} />
));

export const InputGroup = (props: InputGroupProps): ReactElement => {
  return <TidalInputGroup {...props} />;
};

// NOTE: addon for text
export const InputLeftAddon = (props: InputAddonProps): ReactElement => {
  return <TidalInputLeftAddon {...props} />;
};

export const InputRightAddon = (props: InputAddonProps): ReactElement => {
  return <TidalInputRightAddon {...props} />;
};

export type { InputElementProps };
// NOTE: element for icons, z-index = 2
export const InputLeftElement = (props: InputElementProps): ReactElement => {
  return <TidalInputLeftElement {...props} />;
};

export const InputRightElement = (props: InputElementProps): ReactElement => {
  return <TidalInputRightElement {...props} />;
};

// TEXTAREA
export const InpTextareaut = forwardRef<TextareaProps, 'button'>(
  (props, ref) => <TidalTextarea {...props} ref={ref} />
);
