import {
  BankIcon,
  CardAmexIcon,
  CardDiscoverIcon,
  CardGenericIcon,
  CardMastercardIcon,
  CardPOIcon,
  CardVisaIcon,
} from '@fluidtruck/icons';

export const PAYMENT_ICONS = {
  Bank: BankIcon,
  'American Express': CardAmexIcon,
  Discover: CardDiscoverIcon,
  'Diners Club': CardGenericIcon,
  JCB: CardGenericIcon,
  MasterCard: CardMastercardIcon,
  PurchaseOrder: CardPOIcon,
  Unknown: CardGenericIcon,
  UnionPay: CardGenericIcon,
  Visa: CardVisaIcon,
};

export const CARD_ICONS = Object.freeze({
  americanExpress: CardAmexIcon,
  bankIcon: BankIcon,
  bankAccount: BankIcon,
  discover: CardDiscoverIcon,
  generic: CardGenericIcon,
  masterCard: CardMastercardIcon,
  visa: CardVisaIcon,
});

// Type values
export const PAYMENT_TYPE = {
  DEFAULT: 'default',
  SCHEDULED: 'payAsYouGo',
  PAY_TYPE: 'payment-type',
  TYPE_PURCHASE_ORDER: 'purchaseOrder',
  TYPE_ACH: 'ach',
  TYPE_BANK_ACCOUNT: 'Bank Account',
  TYPE_STRIPE: 'stripe',
  TYPE_STRIPE_BANK: 'stripe_bank',
};

// Status values
export const STATUS_NEW = 'new';
