import type { MutatorCallback } from 'swr';

import type {
  AddressShape,
  ROLE_TYPES,
  UserOrgInfo,
  UserProfileInfo,
} from '@/types/user-types';

import type { ChargeLineItemShape, PagintedQueryParams } from '.';
import type {
  ACHDetails,
  BillingTypeOptions,
  CreditDetails,
} from './billing-types';

export interface OrgSettingsWithACH extends OrgSettings {
  'ach-enabled': boolean;
}

export interface OrgSettings {
  externalReferenceNumberEnabled: boolean;
  'my-fleet-id': string;
  'organization-id': number;
  'internal-type': string;
  'book-with-purchase-order': boolean;
  'credit-card-payg-enabled': boolean;
  'geofence-enabled': boolean;
  'maintenance-enabled': boolean;
  'fso-enabled': boolean;
  dispatcher: boolean;
}

export interface OrgDetails {
  'ach-enabled': boolean;
  'address-id'?: number;
  'average-rating': number;
  id: number;
  name: string;
  category: string;
  'customer-id'?: string;
  'borrow-count': number;
  'borrow-limit': number;
  'borrow-limit-request': number;
  'default-billing-method-id'?: number;
  'dot-number'?: string;
  'driver-can-start-pickup': boolean;
  'mailing-address-id'?: number;
  'inserted-at': string;
  'updated-at': string;
  'organization-settings': OrgSettings;
  'waive-deposit': boolean;
  profiles: null;
}

export interface UseGetOrgShape {
  address: AddressShape;
  'credit-amount': number;
  documents: OrgDocsShape[];
  'is-dsp-user': boolean;
  'mailing-address': AddressShape;
  'org-has-reservations-with-no-insurance': boolean;
  organization: OrgDetails;
  profiles: null | UserEntry[];
}

export enum InsuranceStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export interface OrgDocsShape {
  id: number;
  'organization-id': number;
  type: string;
  url: string;
  status: InsuranceStatus;
  updated_at: string;
  inserted_at: string;
  'approved-at': string | null;
  'expires-at': string | null;
  comments?: string;
}

export interface UserEntry {
  user: UserOrgInfo;
  profile: UserProfileInfo;
}

export interface OrgProfileEntriesShape {
  page: number;
  'page-size': number;
  'total-pages': number;
  total: number;
  entries: UserEntry[];
}

export interface OrganizationShape {
  organization: OrgDetails;
  documents: OrgDocsShape[];
  profiles: OrgProfileEntriesShape;
  address?: AddressShape;
  'mailing-address'?: AddressShape;
}

export interface BillingMethods {
  details?: ACHDetails & CreditDetails;
  id: number;
  'is-default': boolean;
  type: BillingTypeOptions;
}

export interface OrgBillingShape {
  'ach-enabled': boolean;
  'billing-methods': BillingMethods[];
  'borrow-limit': number;
  'insurance-expiration-date': string | null;
  'insurance-status': string;
  name: string;
  'organization-id': number;
  'profile-id': number;
  role: ROLE_TYPES;
}

export interface OrgProfilesEntryShape {
  identification: {
    expiration: null | string;
    status: string;
    'updated-at': null | string;
  };
  score: {
    'overall-score': number;
    'overall-grade': string;
  } | null;
  user: UserOrgInfo;
  profile: UserProfileInfo;
}
export interface OrgProfilesShape {
  page: number;
  'page-size': number;
  total: number;
  'total-pages': number;
  entries: OrgProfilesEntryShape[];
}

export interface OrgInviteShape {
  id: number;
  code: string;
  email: string;
  role: string;
  'organization-id': number;
  'expires-at': string;
  'inserted-at': string;
  'updated-at': string;
  'user-id': number;
}

export interface OrgContextShape {
  context: {
    org: { organization: OrgDetails | undefined };
    userOrgProfile?: UserProfileInfo;
    userOrgRole?: ROLE_TYPES;
    isLoading: boolean;
    failedTransactions: {
      total: number;
      data: FailedTransaction[];
      error: any;
      isValidating: boolean;
      mutate: MutatorCallback;
      orgId?: string;
      setTableQuery: (args: any) => void;
      tableQuery: PagintedQueryParams;
    };
  };
  actions: {
    setOrg: (args: {
      newOrg: OrgDetails | undefined;
      updateRoute?: boolean;
      makeRequest?: boolean;
    }) => void;
    refreshOrg: () => Promise<void>;
  };
}

export interface UseOrgProfileArgs {
  end?: Date;
  orgId?: number;
  page?: number;
  pageSize?: number;
  start?: Date;
  term?: string;
}
export interface OrgInvitesDataShape {
  entries: OrgInviteShape[];
  total: number;
  page: number;
  'page-size': number;
  'total-pages': number;
}
export interface FTReservation {
  id: number;
  'item-id': number;
  'fleet-number': string;
}
export interface FailedTransaction {
  id: number;
  'invoice-id': number;
  'due-date': string;
  'amount-due': number;
  status: string;
  title: string;
  errors?: string;
  'number-of-failures': number;
  'inserted-at': string;
  'updated-at': string;
  'total-tax': number;
  'sum-paid': number;
  reservations: FTReservation[];
  organization: OrgDetails;
  'billing-method': BillingMethods;
  'charge-line-items': ChargeLineItemShape[];
}

export type FailedTransactionResponse = {
  total: number;
  'page-size': number;
  page: number;
  'total-pages': number;
  entries: FailedTransaction[];
};

export type FailedTransactionRetryResponse = {
  errors: string[];
  'billing-method-id': number;
  'billing-method': BillingMethods;
  'charge-line-items': ChargeLineItemShape[];
} & Omit<FailedTransaction, 'reservations'>;
