import {
  Box,
  ChakraReactSelect,
  FormControl,
  FormLabel,
  GroupBase,
  isString,
  OptionGroup,
} from '@fluidtruck/core';
import { useTranslation } from 'next-i18next';
import { ReactElement } from 'react';

import type { CRSProps } from '../ReactSelect';

/**
 * @description MonthlySelect
 * @example
    <MonthlySelect
      menuPlacement="auto"
      id="driver-history-select"
      value={startOfMonth}
      options={lastSixMonths}
      onChange={handleMonthSelect}
      />
 */

export const MonthlySelect = ({
  options = [],
  noLabel,
  label,
  value: valueProp,
  isInvalid,
  'data-test-id': testId,
  isMulti = false,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  sx,
  ...rest
}: CRSProps): ReactElement => {
  const { t } = useTranslation('common');
  const filteredValue = options.find(opt => {
    if ('value' in opt) return opt?.value === (valueProp as unknown as string);
    return opt || {};
  });

  const input = (option?: any) => {
    return isString(option) ? filteredValue : option;
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    onChangeProp?.(newValue, actionMeta.action);
  };

  return (
    <Box data-test-id={testId} as={FormControl} isInvalid={isInvalid} sx={sx}>
      {!noLabel && <FormLabel>{label}</FormLabel>}
      <ChakraReactSelect<OptionGroup, typeof isMulti, GroupBase<OptionGroup>>
        options={options}
        onBlur={onBlurProp}
        {...rest}
        onChange={handleChange}
        value={input(valueProp)}
        menuPlacement="auto"
        placeholder={`${t('button.search')}...`}
        chakraStyles={{
          control: provided => ({
            ...provided,
            borderBottom: 'none',
            color: 'fluidBlue',
          }),
        }}
      />
    </Box>
  );
};
