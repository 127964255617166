import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HelpTextProps,
  Textarea,
} from '@fluidtruck/core';
import React from 'react';

export interface FormTextareaProps extends FormControlProps {
  inputProps: React.ComponentProps<typeof Textarea>;
  labelProps?: FormLabelProps;
  errorProps?: FormErrorMessageProps;
  helpTextProps?: HelpTextProps;
  errorMessage?: string;
  helperText?: string;
}

/** FormTextarea
 * Similiar to Material-UI TextField Multiline Component
 * https://tidal.fluidtruck.com/docs/form/form-control
 * https://tidal.fluidtruck.com/docs/form/text-area
 */
export const FormTextarea = (props: FormTextareaProps): React.ReactElement => {
  const {
    errorMessage,
    errorProps,
    helperText,
    helpTextProps,
    id: idProps,
    inputProps,
    isInvalid,
    label,
    labelProps,
    mt = 0,
    placeholder,
    ...rest
  } = props;

  const { name, ref: inputRef, ...inputRest } = inputProps;
  const id = idProps || name;
  const inputId = `${id}-input`;

  return (
    <FormControl mt={mt} id={id} isInvalid={isInvalid} name={name} {...rest}>
      <FormLabel htmlFor={inputId} mb={0} {...labelProps}>
        {label}
      </FormLabel>
      <Textarea
        id={inputId}
        isInvalid={isInvalid}
        name={name}
        ref={inputRef}
        resize="none"
        placeholder={placeholder}
        {...inputRest}
      />
      {helperText && (
        <FormHelperText {...helpTextProps}>{helperText}</FormHelperText>
      )}
      {errorMessage && isInvalid && (
        <FormErrorMessage {...errorProps}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};
