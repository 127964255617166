import { useContext } from 'react';

import { FeedbackContext } from '@/lib/context';
import { get } from '@/utils/helpers';

import * as Types from './types';

export const useVinValidation: Types.Hook = () => {
  const { showFeedback } = useContext(FeedbackContext);

  const handler = async (vin: string): Promise<Types.VPICDataShape> => {
    const response = await get(
      `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`
    );

    if (!response) showFeedback('failed vin validation', 'error');

    return response;
  };

  return { handler };
};
