import type { GroupBase, OptionGroup, SingleValue } from '@fluidtruck/core';
import { AsyncSelect, useBoolean } from '@fluidtruck/core';
import { useRouter } from 'next/router';

import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';
import { get as getRequest } from '@/utils/helpers';

/* Note: moved to utils */
export const loadOptionsFetcher = async (path: string, params?: any) => {
  try {
    const qs = new URLSearchParams(params);
    const updateFnc = async () => getRequest(`${path}?${qs}`);
    const res = await updateFnc();

    if (!res) throw new Error();
    return res;
  } catch (e) {
    console.error(`Error retrieving orgs: ${(e as Error).message || e}`);
  }
};

type ItemShape = {
  'item-id': number;
  'item-title': string;
  'fleet-number': string;
  itemID: number;
  itemTitle: string;
};

export type ItemOption = OptionGroup & ItemShape;

type ItemSearchProps = {
  placeholder?: string;
  closePopover?: () => void;
  myFleet?: {
    orgId: number;
    onChange: (item: SingleValue<ItemOption>) => void;
  };
};

/**
 * ItemSearch - AsyncSelect wrapper for admin api/items
 * @link https://api-platform.stage.fluidtruck.io/docs#/items/get_items_preview
 * @description
 *  searches by item's ID, fleet number, title
 *  if `myFleet` is provided, `api/v1/myfleet/${myFleet.orgId}/items_preview` is used
 * @param props
   ```
   closePopover?: () => void;
   placeholder?: string;
   myFleet?: { orgId: number; onChange: (orgId: number) => void};
   ```
 */

export const ItemSearchSelect = ({
  myFleet,
  placeholder = 'Search items...',
  closePopover,
}: ItemSearchProps) => {
  const router = useRouter();
  const [isFetching, setFetching] = useBoolean();

  const path = !myFleet
    ? 'api/items_preview'
    : `api/v1/myfleet/${myFleet?.orgId}/items_preview`;

  const fetcher = async (
    query: string,
    callback: (arg: ItemOption[]) => void
  ) => {
    const _query = { term: query || '' };

    const res = await loadOptionsFetcher(path, _query);

    let options: ItemOption[] = [];

    if (!!myFleet) {
      options = res?.items?.map((item: ItemShape) => ({
        ...item,
        label: item?.itemTitle,
        value: item?.itemID?.toString(),
      }));
    }
    if (!myFleet) {
      options = res?.data?.entries?.map((item: ItemShape) => ({
        ...item,
        label: `${item?.['item-title']}`,
        value: item?.['item-id']?.toString(),
      }));
    }

    callback(options);
  };

  const loadOptions = useDebouncedCallback(
    (_query: string, callback: (arg: ItemOption[]) => void) =>
      fetcher(_query, callback),
    500
  );

  const handleSelect = async (item: SingleValue<ItemOption>) => {
    if (!item) return;

    setFetching.on();

    try {
      const res = await fetch(`/api/builditem?id=${item.value}`);
      if (!res.ok) {
        throw new Error(`There was a problem fetching item: ${item.value}.`);
      }
      const data = await res.json();
      if (data.url) {
        setFetching.off();
        closePopover?.();
        router.push(data.url);
      }
    } catch (err) {
      setFetching.off();
      console.error(err);
    }
  };

  const props = {
    cacheOptions: true,
    loadOptions,
    placeholder,
    isClearable: true,
    isLoading: isFetching,
    onChange: (value: SingleValue<ItemOption>) => {
      if (!!myFleet) {
        return myFleet.onChange(value);
      }
      handleSelect(value);
    },
  };

  return <AsyncSelect<ItemOption, false, GroupBase<ItemOption>> {...props} />;
};
