import React from 'react';

import { Grid as Container } from '@/base-components';
import { DefaultLayout } from '@/components/page-layouts';
import { heights } from '@/theme';

const DefaultPage = ({ children }: { children: React.ReactNode }) => (
  <Container
    templateColumns="1fr"
    h="100%"
    w="100%"
    templateRows={{
      base: `${heights.topNavMobile}px 1fr`,
      lg: `${heights.topNav}px 1fr`,
    }}
  >
    <DefaultLayout>{children}</DefaultLayout>
  </Container>
);

export default DefaultPage;
