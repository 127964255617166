import {
  NumberDecrementStepper,
  NumberDecrementStepperProps,
  NumberIncrementStepper,
  NumberIncrementStepperProps,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps,
  NumberInputStepper,
  NumberInputStepperProps,
} from '@fluidtruck/core';
import React from 'react';

interface NumberFieldProps extends NumberInputFieldProps {
  ref?: React.Ref<HTMLInputElement>;
}

export interface NumberGroupProps {
  containerProps: NumberInputProps;
  fieldProps: NumberFieldProps;
  decProps: NumberDecrementStepperProps;
  incProps: NumberIncrementStepperProps;
  stepsProps: NumberInputStepperProps;
}

/** NumberGroup
 * Composes all of the Number Input Components
 * I sugguest wrapping this in the FormControl components
 * https://tidal.fluidtruck.com/docs/form/number-input
 */
export const NumberGroup = (props: NumberGroupProps): React.ReactElement => {
  const { containerProps, fieldProps, decProps, incProps, stepsProps } = props;
  const { ref: inputRef, ...fieldRest } = fieldProps;

  return (
    <NumberInput allowMouseWheel {...containerProps}>
      <NumberInputField {...fieldRest} ref={inputRef} />
      <NumberInputStepper color="blue.500" {...stepsProps}>
        <NumberIncrementStepper {...incProps} />
        <NumberDecrementStepper {...decProps} />
      </NumberInputStepper>
    </NumberInput>
  );
};
