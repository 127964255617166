import React from 'react';

import { forwardRef } from '@/utils';

import {
  FormControl,
  FormControlLabel as FormLabel,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabelProps,
  HelpTextProps,
} from './elements';
import { Input } from './Inputs';

export interface FormInputProps extends FormControlProps {
  inputProps: React.ComponentProps<typeof Input>;
  labelProps?: FormLabelProps;
  errorProps?: FormErrorMessageProps;
  helpTextProps?: HelpTextProps;
  errorMessage?: string;
  helperText?: string;
  isLoading?: boolean;
}

/** FormInputGroup
 * Similiar to Material-UI TextField Component
 * https://tidal.fluidtruck.com/docs/form/form-control
 * https://tidal.fluidtruck.com/docs/form/input
 */

export const FormInputGroup = forwardRef<FormInputProps, 'div'>(
  (props, ref) => {
    const {
      errorMessage,
      errorProps,
      helperText,
      helpTextProps,
      id: idProp,
      inputProps,
      isInvalid,
      label,
      labelProps,
      mt = 0,
      placeholder: placeholderProp = '',
      ...rest
    } = props;
    const { size = 'sm', name, ...inputRest } = inputProps;
    const id = idProp || name;
    const inputId = `${id}-input`;
    // NOTE: swap the palceholder with label if there's an error message
    // error messages replace input labels
    const placeholder = isInvalid && errorMessage ? label : placeholderProp;

    const renderLabelComponents = ((): JSX.Element => {
      if (isInvalid && errorMessage) {
        return (
          <FormErrorMessage {...errorProps}>{errorMessage}</FormErrorMessage>
        );
      }
      return (
        <FormLabel aria-label={name} htmlFor={inputId} mb={0} {...labelProps}>
          {label}
        </FormLabel>
      );
    })();

    return (
      <FormControl
        ref={ref}
        mt={mt}
        id={id}
        isInvalid={isInvalid}
        name={name}
        {...rest}
      >
        {renderLabelComponents}
        <Input
          name={name}
          id={inputId}
          isInvalid={isInvalid}
          size={size}
          pl={0}
          placeholder={placeholder}
          {...inputRest}
        />
        {helperText && (
          <FormHelperText {...helpTextProps}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
