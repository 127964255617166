import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { DriverData, IDriverScore } from '@/types';
import { get } from '@/utils/helpers';

type Props = {
  children: React.ReactNode;
};

const DriverScoreContext = createContext<IDriverScore>({
  actions: {
    setIsLoading: () => false,
  },
  context: { driverData: [], isLoading: false },
});

export const DriverScoreContextProvider = ({
  children,
}: Props): React.ReactElement => {
  const router = useRouter();
  const { t } = useTranslation('team');

  const [driverData, setDriverData] = useState<DriverData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { marketID = 0, weekID = 0 } = router.query;

  const driverDataUrl = `api/cup?marketID=${marketID}&weekID=${weekID}`;

  const { data, error } = useSWR(driverDataUrl, get);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
      if (data.data && data.status !== 'fail') {
        setDriverData(data.data);
      }
    }
  }, [data, setDriverData, setIsLoading]);

  const context = { driverData, isLoading };
  const actions = { setIsLoading };

  if (error) {
    return <h2>{t('errorNoDriverScore')}</h2>;
  }

  return (
    <DriverScoreContext.Provider
      value={{
        actions,
        context,
      }}
    >
      {children}
    </DriverScoreContext.Provider>
  );
};

export default DriverScoreContext;
