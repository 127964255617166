import moment from 'moment';

import * as ApiTypes from '@/api-resources/contracts/api-contracts/my_fleet/shared';

import * as Types from './types';

// Maps org admins to geofence notif recipients for autofcomplete options
export const mapAdminProfiles: Types.MapAdminProfiles = (profiles, userIds) => {
  if (!profiles?.length) return {} as Types.UserMultiSelectShape;

  const userOptions = profiles.map(profile => ({
    label: `${profile.user.firstName} ${profile.user.lastName}`,
    value: profile.user.id.toString(),
  }));
  const selectedUsers = (userIds?.length ? userOptions : []).filter(user => {
    return userIds?.some(val => val.toString() === user.value);
  });

  return {
    userOptions,
    selectedUsers,
  };
};

// maps data string - start: 9 to "09:00:00"
export const formatNum = (hour: number | string): string => {
  return moment().hour(Number(hour)).format('H:00');
};

// maps data string - start: "09:32:03" to 9
export const formatStr = (hour: string): number => {
  const formatHour = moment(hour, 'HH').format('HH');
  return Number(formatHour);
};

export const mapDayValues: Types.MapDayValues = data => {
  const newRange = data?.map(dateTime => ({
    start: formatStr(dateTime.start),
    end: formatStr(dateTime.end),
  }));
  return newRange;
};

// Maps API Data to FormState Object
export const mapGeofenceMonitoring: Types.MapGeofenceMonitoring = data => {
  const defaultDayRangeForm = {
    checked: false,
    times: [{ start: '', end: '' }],
  };

  let monitorType = Types.MONITOR.NONE;
  let isDailySchedule = false;
  let dailyStart: string | number = '';
  let dailyEnd: string | number = '';

  // Check if schedule times is the same daily
  if (data?.schedule?.length === 7) {
    const sorted = data?.schedule?.sort((a, b) => {
      return Types.daysArray.indexOf(a.day) - Types.daysArray.indexOf(b.day);
    });

    const firstStart = formatStr(data?.schedule[0].times[0].start);
    const firstEnd = formatStr(data?.schedule[0].times[0].end);

    const allDays = Types.daysArray.every(
      (value, idx) => value === sorted[idx].day
    );
    const sameTimes = sorted
      ?.map(day => {
        if (day.times.length > 1) return false;
        return (
          formatStr(day.times[0].start) === firstStart &&
          formatStr(day.times[0].end) === firstEnd
        );
      })
      .every(Boolean);
    if (sameTimes && allDays) {
      dailyStart = firstStart;
      dailyEnd = firstEnd;
    }
    isDailySchedule = sameTimes && allDays;
  }

  const newScheduleState: Types.ScheduleFormShape = Types.daysArray.reduce(
    (acc, cur) => {
      const dayData = data?.schedule?.find(day => day.day === cur);

      if (!data || !data?.schedule?.length || !dayData || isDailySchedule) {
        return { ...acc, [cur]: defaultDayRangeForm };
      }

      const result = {
        ...acc,
        [cur]: { checked: true, times: mapDayValues(dayData.times) },
      };
      return result;
    },
    {} as Types.ScheduleFormShape
  );

  if (data) {
    monitorType = isDailySchedule ? Types.MONITOR.DAILY : Types.MONITOR.CUSTOM;
  }

  return {
    dailyStart,
    dailyEnd,
    monitorType: (data?.doNotMonitor && Types.MONITOR.NONE) || monitorType,
    schedule: newScheduleState,
  };
};

export const mapGeofenceNotification: Types.MapGeofenceNotification = data => {
  if (!data || !data.length)
    return {
      rangeStart: '',
      rangeEnd: '',
      userIds: [],
      notificationType: Types.NOTIFS.REAL_TIME,
      sendSMS: false,
    };

  const notifs = data[0];
  const notificationType =
    !notifs?.realTime && !!notifs?.start && !!notifs?.end
      ? Types.NOTIFS.RANGE
      : Types.NOTIFS.REAL_TIME;

  return {
    rangeStart: notifs?.start ? formatStr(notifs.start) : '',
    rangeEnd: notifs?.end ? formatStr(notifs.end) : '',
    userIds: notifs?.userIds || [],
    notificationType,
    sendSMS: notifs?.notificationMedium === Types.NOTIFS.SMS || false,
  };
};

// Maps Daily Monitoring FormState Object for API Object
export const formatDailyMonitoring: Types.FormatDailyMonitoring = data => {
  const validDailyStart = typeof data?.dailyStart === 'number';
  const validDailyEnd = typeof data?.dailyEnd === 'number';
  if (!data || !validDailyStart || !validDailyEnd)
    return {
      doNotMonitor: false,
    };

  const dailySchedule = Types.daysArray.reduce((acc, cur, idx) => {
    if (validDailyStart && validDailyEnd) {
      acc[idx] = {
        day: cur,
        times: [
          {
            start: formatNum(data.dailyStart as number),
            end: formatNum(data.dailyEnd as number),
          },
        ],
      };
    }
    return acc;
  }, [] as ApiTypes.GeofenceSchedule[]);

  return {
    doNotMonitor: false,
    schedule: dailySchedule,
  };
};

// Maps Custom Monitoring Schedule FormState Object for API Object
export const formatCustomMonitoring: Types.FormatCustomMonitoring = schedule => {
  if (!schedule)
    return {
      doNotMonitor: false,
    };

  const entries = Object.entries(schedule);
  const customSchedule = entries.flatMap(([key, value]) => {
    if (
      !value.checked ||
      value.times.some(
        t => typeof t.start !== 'number' || typeof t.end !== 'number'
      )
    )
      return [];

    const timesArray = value.times.flatMap(time => {
      if (typeof time?.start !== 'number' || typeof time?.end !== 'number')
        return [];

      return {
        start: formatNum(time?.start),
        end: formatNum(time?.end),
      };
    });

    return {
      day: key as ApiTypes.Day,
      times: timesArray,
    };
  });

  return {
    doNotMonitor: false,
    schedule: customSchedule,
  };
};

// Maps initial Monitoring FormState Object for API Object
export const formatMonitoring: Types.FormatMonitoring = data => {
  if (!data) return {} as ApiTypes.GeofenceMonitoring;
  const doNotMonitor = data?.monitorType === Types.MONITOR.NONE;
  let newMonitoringDataObject: ApiTypes.GeofenceMonitoring = {
    doNotMonitor: true,
  };
  if (!data || doNotMonitor) {
    return newMonitoringDataObject;
  }
  const isDaily = data.monitorType === Types.MONITOR.DAILY;
  const isCustom = data.monitorType === Types.MONITOR.CUSTOM;
  const dailyTimesMissing = data?.dailyStart === '' || data?.dailyEnd === '';

  if (isDaily && !dailyTimesMissing) {
    newMonitoringDataObject = formatDailyMonitoring(data);
  }

  if (isCustom && data?.schedule) {
    newMonitoringDataObject = formatCustomMonitoring(data?.schedule);
  }
  return newMonitoringDataObject;
};

// Maps Notifications FormState Object for API Object
export const formatNotifications: Types.FormatNotifications = data => {
  let notifsObj: ApiTypes.GeofenceNotification = { realTime: false };
  const notifications = [];

  if (data?.monitoring?.monitorType === Types.MONITOR.NONE) {
    notifsObj = {
      realTime: false,
      start: '',
      end: '',
      notificationMedium: Types.NOTIFS.NONE,
      userIds: [],
    };
    notifications.push(notifsObj);
    return notifications;
  }
  const isRealTime =
    data?.notifications?.notificationType === Types.NOTIFS.REAL_TIME;

  const userIds =
    data?.userNotifications?.selectedUsers &&
    data?.userNotifications?.selectedUsers.map(usr => Number(usr?.value));

  const notificationMedium = data?.notifications?.sendSMS
    ? Types.NOTIFS.SMS
    : Types.NOTIFS.NONE;

  if (isRealTime) {
    notifsObj = {
      realTime: true,
      notificationMedium,
      userIds,
    };
  }
  if (
    !isRealTime &&
    data?.notifications?.rangeStart &&
    data?.notifications?.rangeEnd
  ) {
    notifsObj = {
      realTime: false,
      start: formatNum(data?.notifications?.rangeStart),
      end: formatNum(data?.notifications?.rangeEnd),
      notificationMedium,
      userIds,
    };
  }
  notifications.push(notifsObj);
  return notifications;
};
