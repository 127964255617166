import {
  Box as TidalBox,
  BoxProps,
  Center as TidalCenter,
  chakra,
  Container as TidalContainer,
  ContainerProps,
  Divider as TidalDivider,
  DividerProps,
  Flex,
  FlexProps,
  forwardRef,
  Grid as TidalGrid,
  GridItem as TidalGridItem,
  GridItemProps,
  GridProps,
  HStack as TidalHStack,
  List as TidalList,
  ListItem as TidalListItem,
  ListItemProps,
  ListProps,
  Stack as TidalStack,
  StackProps,
  VStack as TidalVStack,
} from '@fluidtruck/core';
import React, { ReactElement } from 'react';

/** Container
 * default styles -
    width: 100%;
    margin-inline: auto;
    max-width: 60ch;
    padding-inline: 1rem;
 * https://tidal.fluidtruck.com/docs/layout/container
 */
export const Container = (props: ContainerProps): ReactElement => {
  return <TidalContainer {...props} />;
};

/** Flex
 * `div` with `display: flex;` applied
 * https://tidal.fluidtruck.com/docs/layout/flex
 */
export type { FlexProps };

export const FlexRow = forwardRef<FlexProps, 'div'>(
  (props, ref): ReactElement => {
    return <Flex ref={ref} {...props} />;
  }
);
export const FlexCol = forwardRef<FlexProps, 'div'>(
  (props, ref): ReactElement => {
    return <Flex direction="column" ref={ref} {...props} />;
  }
);

/** Stacks
 * stack elements together and apply a space between them
 * spacing={4} = 16px
 * https://tidal.fluidtruck.com/docs/layout/stack
 */
export type { StackProps };
export const Stack = (props: StackProps): ReactElement => {
  return <TidalStack {...props} />;
};

export const HStack = (props: StackProps): ReactElement => {
  return <TidalHStack {...props} />;
};

export const VStack = (props: StackProps): ReactElement => {
  return <TidalVStack {...props} />;
};

/** BOX
 * Basic `div` can be used instead of `chakra.div`
 * https://tidal.fluidtruck.com/docs/layout/box
 */
export const Box = forwardRef<BoxProps, 'div'>((props, ref): ReactElement => {
  return <TidalBox ref={ref} {...props} />;
});
export type { BoxProps };

/** DIVIDER
 * props: orientation, size, variant, style props
 * https://tidal.fluidtruck.com/docs/layout/divider
 */
export const Divider = (props: DividerProps): ReactElement => {
  return <TidalDivider {...props} />;
};

/** CSS GRID
 * https://tidal.fluidtruck.com/docs/layout/grid
 */
export const Grid = forwardRef<GridProps, 'div'>((props, ref) => (
  <TidalGrid ref={ref} {...props} />
));

export const GridItem = (props: GridItemProps): ReactElement => {
  return <TidalGridItem {...props} />;
};
// Handy component to center items vert/horz within an area
export const CenterGrid = (props: GridProps): ReactElement => {
  const styles = {
    height: 'inherit',
    justifyContent: 'center',
    alignContent: 'center',
  };
  return <TidalGrid {...styles} {...props} />;
};
export type { GridProps, GridItemProps } from '@fluidtruck/core';

/** CENTER
 * https://tidal.fluidtruck.com/docs/layout/center
 */
export const Center = (props: FlexProps): ReactElement => {
  return <TidalCenter {...props} />;
};

/** List
 * https://tidal.fluidtruck.com/docs/layout/list
 */
export const List = (props: ListProps): ReactElement => {
  return <TidalList {...props} />;
};

export const ListItem = (props: ListItemProps): ReactElement => {
  return <TidalListItem {...props} />;
};

/**
 * @description `Paper` div container
 * Similar to Material-UI Paper component
 * which provides "spacing" and "elevation" (boxShadow)
 * spacing={4} = 16px
 * borderShadow="'0px 3px 3px -2px rgb(0 0 0 / 20%)'" = elevation={3}
 * chakra theme box-shadow:  */

export const Paper = chakra(TidalStack, {
  baseStyle: {
    border: '1px',
    boxShadow: 'xl',
    borderColor: 'keylineGray',
  },
});

/**
 * @description `ColumnContainer` flex column
 * Column that provided design system rules,
 * border, radius, and boxShadow
 * styles can be added/overriden
 * ie: reservation review
 */

export const ColumnContainer = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    border: { base: 'none', lg: '1px' },
    borderColor: { base: 'transparent', lg: 'gray.200' },
    borderRadius: { base: 'none', lg: 'base' },
    shadow: { base: 'none', lg: 'base' },
  },
  label: 'ColumnContainer',
});
